import { makeStyles } from "@material-ui/core/styles";

interface Props {
  status: number;
}

export const useStyles = makeStyles((theme) => ({
  root: {
    width: (props: Props) => (props.status === 5 ? "50%" : props.status === 6 ? "70%" : "100%"),
  },
  stepper: {
    padding: 0,
    "& .MuiSvgIcon-root": {
      width: "29px",
      height: "29px",
    },
    "& .MuiSvgIcon-root:not(.Mui-error)": {
      border: "1px solid #979797",
      borderRadius: "100%",
    },
    "& .MuiStepIcon-text": {
      display: "none",
    },
    "& .MuiStepIcon-root circle": {
      fill: "transparent",
    },

    "& .MuiStepLabel-label": {
      fontWeight: 500,
    },
    "& .MuiStepLabel-label:not(.Mui-error)": {
      color: theme.palette.primary.main,
    },

    "& .MuiStepIcon-root.MuiStepIcon-completed:not(.Mui-error)": {
      color: theme.palette.primary.main,
    },
  },
}));
