import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  toggleOption: {
    border: "1px solid gray",
    backgroundColor: "#fff",
    maxWidth: 116,
    justifyContent: "space-between",
    height: 36,
    textTransform: "capitalize",
    wordBreak: "break-all",
    whiteSpace: "nowrap",
    marginRight: 1,
    "&.Mui-selected": {
      color: theme.palette.primary.main,
      backgroundColor: "#a4171730",
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  completed: {
    "&.Mui-selected": {
      color: "#17A45E",
      backgroundColor: "#17a45e26",
      border: `1px solid #17A45E`,
    },
  },
  pending: {
    "&.Mui-selected": {
      color: "#FFA006",
      backgroundColor: "#ffbd2029",
      border: `1px solid #FFA006`,
    },
  },

  toggleContainer: {},
}));
