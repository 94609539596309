import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Collapse, Grid } from "@material-ui/core";

import Table from "../Table";
import PatientDetail from "./PatientDetail";
import PatientsFilters from "./PatientsFilters";

import {
  TABLE_ROW_EDIT_ACTION,
  PATIENTS_FILTERS,
  PATIENTS_FILTERS_EXTENSIONS,
  PATIENTS_DEFAULT_SORTING,
  AGENCY_PATIENTS_TABLE_COLUMNS,
  SUPER_ADMIN_PATIENTS_TABLE_COLUMNS,
  AGENCY_PATIENTS_TABLE_COLUMN_EXTENSIONS,
  FACILITY_PATIENTS_TABLE_COLUMNS,
} from "../../../constants";
import { useRole } from "../../../hooks";
import { Navigation } from "../../../lib";
import {
  useDispatch,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../state";

import { useStyles } from "./PatientsTable.styles";

interface Props {
  view: "super_admin" | "agency" | "facility" | "global";
  isFiltersVisible: boolean;
  tabView?: any;
  entity?: any;
}

// TODO add types and interfaces
const PatientsTable = (props: Props) => {
  const classes = useStyles(props);
  const { id }: any = useParams();
  const { isFacilityRole, isAgencyBiller } = useRole();
  const dispatch = useDispatch();
  const { view, isFiltersVisible }: Props = props;

  const patients = useSelector(agencySelectors.patients);
  const patientsLoading = useSelector(agencySelectors.patientsLoading);
  const searchQuery = useSelector(agencySelectors.searchQuery);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  const [selectedFilters, setSelectedFilters] = useState<any[]>(PATIENTS_FILTERS);

  const handleRemoveFilter = useCallback(
    (status) => {
      const updatedFilters = selectedFilters.map((filter) => ({
        ...filter,
        value:
          typeof filter.value !== "string"
            ? filter.value.filter((filterValue) => status !== filterValue)
            : "",
      }));
      setSelectedFilters(updatedFilters);
    },
    [selectedFilters],
  );

  const handleFilterChange = useCallback(
    (fieldName, value) => {
      const updatedFilters = selectedFilters.map((filter) =>
        filter.columnName === fieldName ? { ...filter, value } : filter,
      );
      setSelectedFilters(updatedFilters);
    },
    [selectedFilters],
  );

  useEffect(() => {
    const entityId = view === "super_admin" ? props.entity.id : activeEntityId;
    const _view =
      view === "global"
        ? "global"
        : view === "super_admin" || view === "agency"
        ? "agency"
        : "facility";
    dispatch(agencyActions.getPatients(entityId, _view));
  }, [view, id, dispatch, props.entity?.id, activeEntityId]);

  const handleEditPatient = useCallback(
    (patient) => {
      dispatch(agencyActions.setEditablePatient(patient));
      Navigation.go(`/${isFacilityRole ? "facility" : "agency"}/patients/${patient.id}`);
    },
    [dispatch, isFacilityRole],
  );

  return (
    <>
      <Collapse in={isFiltersVisible} timeout="auto" unmountOnExit className={classes.container}>
        <Grid container>
          <PatientsFilters
            view={view}
            selectedFilters={selectedFilters}
            handleFilterChange={handleFilterChange}
            handleRemoveFilter={handleRemoveFilter}
          />
        </Grid>
      </Collapse>
      <Table
        {...props}
        loading={patientsLoading}
        rows={patients}
        columns={
          view === "global"
            ? SUPER_ADMIN_PATIENTS_TABLE_COLUMNS
            : view === "facility"
            ? FACILITY_PATIENTS_TABLE_COLUMNS
            : AGENCY_PATIENTS_TABLE_COLUMNS
        }
        actions={
          !isAgencyBiller && view !== "super_admin" && view !== "global" && [TABLE_ROW_EDIT_ACTION]
        }
        editHandler={!isAgencyBiller && handleEditPatient}
        searchValue={searchQuery}
        withFilters={true}
        withPagination={true}
        selectedFilters={selectedFilters}
        withDetail={true}
        rowDetailComponent={PatientDetail}
        tableColumnExtensions={AGENCY_PATIENTS_TABLE_COLUMN_EXTENSIONS}
        tableFiltersExtensions={PATIENTS_FILTERS_EXTENSIONS}
        defaultSorting={PATIENTS_DEFAULT_SORTING}
        exportEnabled={true}
        exportedFileName="Caregivers"
      />
    </>
  );
};

export default React.memo(PatientsTable);
