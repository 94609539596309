import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: "2em",
  },
  header: {
    padding: "0 2em 1.5em",
  },
  sectionTitle: {
    padding: "0 2em 1em",

    "& .MuiTypography-root": {
      fontSize: "1.25em",
      fontWeight: 500,
    },
  },
  controlsWrap: {
    width: "65vw",
  },
  formControl: {
    width: "50%",
    minWidth: 330,
    padding: "0 2em 2em",
  },
  fileUploadWrap: {
    padding: "0 2em 1em",
  },
  formActions: {
    marginTop: 64,
    borderTop: "1px solid #E0E0E0",
    padding: "0 2em",
  },
}));
