import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";

import Modal from "../../components/modals/Modal";
import FileUpload from "../../components/forms/FileUpload";
import { SearchIcon, CloseIcon } from "../../components/icons";
import FilterButton from "../../components/buttons/FilterButton/FilterButton";
import UploadUpdatesTable from "../../components/tables/UploadUpdatesTable";

import { Form, useFormik, useInputDebounced, yup } from "../../lib";
import { agencyActions, uiActions, uiSelectors, useAction, useSelector } from "../../state";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(3),
  },
  closeIcon: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  modalFooter: {
    paddingTop: theme.spacing(3),
  },
}));

const initialValues = { fileName: "" };
const validationSchema = yup.object({
  fileName: yup.string().required("Upload file is required"),
});

const UploadUpdatesPage = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const setLoading = useAction(uiActions.setLoading);
  const closeUploadModal = useAction(uiActions.closeUploadModal);
  const uploadModal = useSelector(uiSelectors.uploadModal);

  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(agencyActions.setSearchQuery);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const handleCloseModal = useCallback(() => {
    closeUploadModal();
  }, [closeUploadModal]);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      setLoading(true);
      // await uploadUpdateFile(values);
      setLoading(false);
      handleCloseModal();
    },
  });

  return (
    <Box>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item>
          <Typography variant="h4" component="h1">
            Upload updates
          </Typography>
        </Grid>

        <Grid item xs={4}>
          <TextField
            fullWidth
            id="search"
            label="Search for caregiver id or name"
            variant="outlined"
            value={searchQuery}
            // @ts-ignore
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item>
          <Grid container>
            <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
          </Grid>
        </Grid>
      </Grid>

      <UploadUpdatesTable isFiltersVisible={expanded} />

      {/* TODO update UI */}
      <Modal open={uploadModal?.open} handleClose={handleCloseModal}>
        <Grid
          component="header"
          container
          justifyContent="space-between"
          alignItems="center"
          classes={{ container: classes.header }}
        >
          <Grid item>
            <Typography variant="h4" component="h1">
              Upload updates
            </Typography>
          </Grid>

          <Grid item>
            <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>

        <Form form={form}>
          <FileUpload form={form} name="name" accept="image/*" uploadType="private" />

          <Grid
            component="footer"
            container
            justifyContent="space-between"
            alignItems="center"
            classes={{ container: classes.modalFooter }}
          >
            <Grid item>
              {form.touched.fileName && Boolean(form.errors.fileName) && (
                <FormHelperText error variant="outlined">
                  {form.errors.fileName}
                </FormHelperText>
              )}
            </Grid>

            <Grid item>
              <Button variant="contained" color="primary" size="large" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      </Modal>
    </Box>
  );
};

export default UploadUpdatesPage;
