import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Table from "../Table";

import {
  useAction,
  superAdminActions,
  useSelector,
  superAdminSelectors,
  useDispatch,
} from "../../../state";
import {
  TABLE_ROW_EDIT_ACTION,
  TABLE_ROW_DELETE_ACTION,
  SUPER_ADMIN_LABS_TABLE_COLUMNS,
  SUPER_ADMIN_LABS_TABLE_COLUMN_EXTENSIONS,
} from "../../../constants";

import SuperAdminPages from "../../../pages/super-admin";

// TODO add types and interfaces
const LabsTable = ({ ...props }) => {
  const history = useHistory();
  const labs = useSelector(superAdminSelectors.labs);
  const getLabs = useAction(superAdminActions.getLabs);
  const dispatch = useDispatch();
  const handleDelete = r => {
    dispatch(superAdminActions.deleteLab(r.id));
  };
  useEffect(() => {
    getLabs();
  }, [getLabs]);

  return (
    <Table
      {...props}
      rows={labs}
      editHandler={e => history.push(SuperAdminPages.editLab.path.replace(":id", e.id))}
      deleteHandler={handleDelete}
      columns={SUPER_ADMIN_LABS_TABLE_COLUMNS}
      tableColumnExtensions={SUPER_ADMIN_LABS_TABLE_COLUMN_EXTENSIONS}
      actions={[TABLE_ROW_EDIT_ACTION, TABLE_ROW_DELETE_ACTION]}
      exportEnabled={false}
      exportedFileName="Labs"
    />
  );
};

export default React.memo(LabsTable);
