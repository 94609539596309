import React from "react";
import { Box, Button, Typography } from "@material-ui/core";

import { AuthPages } from "./";
import PasswordField from "../../components/inputs/PasswordField";

import { Form, Navigation, useFormik, useLocation, yup } from "../../lib";
import { authActions, useAction } from "../../state";

import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  password1: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const NewPasswordPage = () => {
  const classes = useStyles();
  const resetPassword = useAction(authActions.resetPassword);
  const location = useLocation();

  const form = useFormik({
    initialValues: {
      password1: "",
      password2: "",
    },
    validationSchema,
    async onSubmit(values) {
      await resetPassword({
        ...values,
        newPassword: values.password1,
        token: location.query.token,
      });
      Navigation.replace(AuthPages.login);
    },
  });

  return (
    <Box className={classes.authCard}>
      <Typography variant="h2" component="h1" className={classes.titleText}>
        Create new password
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitleText}>
        It’s a good idea to use a strong password that you’re not using elsewhere.
      </Typography>
      <Form form={form} className={classes.form}>
        <PasswordField
          name="password1"
          label="New password"
          value={form.values.password1}
          handleChange={form.handleChange}
          touched={form.touched.password1}
          error={form.errors.password1}
        />
        <PasswordField
          name="password2"
          label="Re-enter new password"
          value={form.values.password2}
          handleChange={form.handleChange}
          touched={form.touched.password2}
          error={form.errors.password2}
        />

        <div className={classes.buttonBox + " " + classes.buttonBoxSingle}>
          <Button color="primary" variant="contained" type="submit" disabled={form.isSubmitting}>
            Reset Password
          </Button>
        </div>
      </Form>
    </Box>
  );
};

export default React.memo(NewPasswordPage);
