import { ROLES, useSelector, authSelectors } from "../state";

export const useRole = () => {
  const roles = useSelector(authSelectors.roles);

  // we do not support multiple roles yet
  const role = Boolean(roles.length) ? roles[0]?.name : null;

  const isSuperAdmin = role === ROLES.SUPER_ADMIN;

  // Agency roles
  const isAgencyBiller = role === ROLES.AGENCY_BILLER;
  const isAgencyAdmin = role === ROLES.AGENCY_ADMIN;
  const isAgencyScheduler = role === ROLES.AGENCY_ADMIN;

  // Facility roles
  const isFacilityAdmin = role === ROLES.FACILITY_ADMIN;
  const isFacilityIntake = role === ROLES.FACILITY_INTAKE;
  const isFacilityProvider = role === ROLES.FACILITY_PROVIDER;
  const isFacilityBiller = role === ROLES.FACILITY_BILLER;
  const isFacilityRole =
    isFacilityAdmin || isFacilityIntake || isFacilityProvider || isFacilityBiller;
  const isAgencyRole = isAgencyAdmin || isAgencyScheduler || isAgencyBiller;
  return {
    role,
    isSuperAdmin,
    isAgencyRole,
    isFacilityAdmin,
    isFacilityIntake,
    isFacilityProvider,
    isFacilityRole,
    isAgencyAdmin,
    isAgencyScheduler,
    isAgencyBiller,
  };
};
