import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(4),
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 16,
  },
  closeIcon: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  modalFooter: {
    paddingTop: theme.spacing(3),
  },
  field: {
    marginLeft: 5,
    marginTop: 10,
  },
}));
