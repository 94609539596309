import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
    },
    content: {
      position: "relative",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: "16px 40px 40px 96px",
      margin: "0px auto",
      maxWidth: "calc(100vw - 17px)",
    },
    backdrop: {
      zIndex: theme.zIndex.modal + 100,
      backgroundColor: "rgb(7 7 7 / 70%)",
    },
  }),
  {
    classNamePrefix: "MainLayout",
  },
);
