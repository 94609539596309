import { file } from "./state";
import { authClient } from "../auth/actions";

import { uiActions } from "../ui/actions";
import { getFormData } from "../../utils";
import { UPLOAD_PRIVATE_API, UPLOAD_PUBLIC_API, FACILITIES_API, AGENCY_API } from "../../constants";
import { agencyActions } from "../agency/actions";

const { actions } = file;

export const fileActions = {
  ...actions,

  uploadPublic(file) {
    return async (dispatch) => {
      const response = await authClient.post(UPLOAD_PUBLIC_API, getFormData({ file }), {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        dispatch(uiActions.showSuccess("File uploaded successfully"));
        dispatch(actions.setFile(response.data));
      } else {
        dispatch(uiActions.showError("Error uploading file " + response.data.message));
      }

      return response;
    };
  },
  uploadPrivate(file) {
    return async (dispatch) => {
      const response = await authClient.post(UPLOAD_PRIVATE_API, getFormData({ file }), {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        dispatch(uiActions.showSuccess("File uploaded successfully"));
        dispatch(actions.setFile(response.data));
      } else {
        dispatch(uiActions.showError("Error uploading file " + response.data.message));
      }
      return response;
    };
  },
  deleteFileById(fileId) {
    return async (dispatch) => {
      const response = await authClient.delete(`upload/${fileId}`);
      return response;
    };
  },
  getFileUrl(entityId, appt_id, rcid, fileId) {
    return async (dispatch, getState) => {
      const { auth } = getState();
      const entity_type = auth.roles[0].entity_type;
      const { status, data } = await authClient.get(
        `${
          entity_type === "medical_facility" ? FACILITIES_API : AGENCY_API
        }/${entityId}/appointments/${appt_id}/rate_code/${rcid}/form/${fileId}`,
      );
      //TODO: implement opening new tab below
      if (status === 200) {
        window.open(data.url, "_blank");
      } else {
        dispatch(uiActions.showError("Download failed " + data.message));
      }
      return data;
    };
  },
  updateFileApproveStatus(appt_id, rcid, fileId, approved, getAll = false) {
    return async (dispatch) => {
      const { status, data } = await authClient.post(
        `/appointments/${appt_id}/rate_code/${rcid}/update_file_approved/${fileId}`,
        { approved: approved },
      );
      if (status === 200) {
        if (getAll) {
          dispatch(agencyActions.getAppointments(null, "global"));
        } else {
          dispatch(agencyActions.setOpenedAppointment(data));
        }
      } else {
        dispatch(uiActions.showError("Approving file failed " + data.message));
      }
    };
  },

  deleteFile(entityId, appt_id, rcid, fileId, view, getAll = false): any {
    return async (dispatch) => {
      const { status, data } = await authClient.delete(
        `${FACILITIES_API}/${entityId}/appointments/${appt_id}/rate_code/${rcid}/form/${fileId}`,
      );
      //TODO: implement on backend and ask are you sure
      if (status === 200) {
        dispatch(uiActions.showSuccess("File successfully deleted"));

        if (getAll) {
          const _view = view === "super_admin" ? "global" : view;
          dispatch(agencyActions.getAppointments(entityId, _view));
        } else {
          const view = "facility";
          dispatch(agencyActions.getAppointmentByID(entityId, appt_id, view));
        }
      } else {
        dispatch(uiActions.showError("Delete file failed " + data.message));
      }
      return data;
    };
  },
};
