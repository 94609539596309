import React, { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Grid, IconButton, Typography } from "@material-ui/core";

import { ArrowBackIcon } from "../../../../components/icons";
import DefaultRateCodeForm from "../../../../components/forms/DefaultRateCodeForm";

import { useStyles } from "./DefaultRateCodePage.styles";

const DefaultRateCodePage = () => {
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();

  const handleBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            {id ? "Edit" : "Create"} Default Rate Code
          </Typography>
        </Grid>
      </Grid>

      <DefaultRateCodeForm />
    </>
  );
};

export default DefaultRateCodePage;
