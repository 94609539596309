import React, { useCallback } from "react";
import { Grid, Button, Typography, IconButton } from "@material-ui/core";
import Modal from "../../../../../components/modals/Modal";
import { CloseIcon } from "../../../../../components/icons";
import { useStyles } from "./AppointmentHistoryModal.styles";
import { useSelector } from "react-redux";
import { agencySelectors } from "../../../../../state";
import HistoryTable from "./HistoryTable";

const AppointmentHistoryModal = (props) => {
  const { open, setOpen } = props;
  const classes = useStyles();
  const appointmentHistoryList = useSelector(agencySelectors.appointmentHistoryList);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Modal open={open} handleClose={handleCloseModal}>
      <>
        <Grid
          container
          component="header"
          justifyContent="space-between"
          alignItems="center"
          classes={{ container: classes.header }}
        >
          <Grid item>
            <Typography variant="h4" component="h1" className={classes.activityTitle}>
              All activity{" "}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          component="header"
          justifyContent="space-between"
          alignItems="center"
          classes={{ container: classes.header }}
        >
          {" "}
          {!!appointmentHistoryList.length ? (
            <HistoryTable historyList={appointmentHistoryList}></HistoryTable>
          ) : (
            <>
              <Typography className={classes.activityTitle}>No activity yet</Typography>
              <Typography>
                Appointment activity will show here after any new updates are made to the
                appointment.
              </Typography>
            </>
          )}
        </Grid>

        <Grid
          container
          component="footer"
          justifyContent="flex-end"
          alignItems="center"
          classes={{ container: classes.modalFooter }}
          spacing={2}
        >
          <Grid item>
            <Button variant="contained" color="primary" size="large" onClick={handleCloseModal}>
              Close
            </Button>
          </Grid>
        </Grid>
      </>
    </Modal>
  );
};

export default AppointmentHistoryModal;
