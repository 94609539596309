import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 12px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 0,
    maxWidth: 450,
    minHeight: 78,
  },
  header: {
    paddingBottom: theme.spacing(3),
  },
  btn: {
    alignItems: "center",
    display: "flex",
    "& .MuiButton-label": {
      gap: "8px",
    },
    margin: "8px 0px",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
    textTransform: "uppercase",
  },
  titleContainer: {
    padding: "12px 0",
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.3,
    margin: "8px 0px",
  },
}));
