import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    position: "relative",
    paddingTop: "2em",
    padding: "0 2em 2em",
  },
  required: {
    display: "inline-block",
    color: "red",
    marginLeft: 16,
    fontSize: 12,
  },
  sectionTitle: {
    padding: "0 0em 1em",
    textTransform: "uppercase",
    fontWeight: "bold",
    "& .MuiTypography-root": {
      fontSize: "1.25em",
      fontWeight: 500,
      display: "inline-block",
    },
  },
  title: {
    fontSize: "1.25em",
    display: "inline-block",
    textTransform: "uppercase",
    fontWeight: 500,
  },
  controlsWrap: {
    width: "65vw",
    padding: "0em 2em 0em",
  },
  formControl: {
    padding: "0 24px 24px 0px",
    flexGrow: 1,
  },
  pageContainer: {
    padding: "0 2em 0em",
  },
  state: {
    width: 150,
  },
  switcherWrap: {
    width: "50%",
    minWidth: 330,
    flexGrow: "initial",
  },
  switcherGroup: {
    width: "100%",
  },
  switcher: {
    width: "50%",
    textTransform: "initial",
    borderColor: (props: any) =>
      props.isStatusError ? theme.palette.error.main : "rgba(0, 0, 0, 0.23)",

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  fileUploadWrap: {
    padding: "0 0em 1em",
  },
  formActions: {
    marginTop: 64,
    borderTop: "1px solid #E0E0E0",
    padding: "0 2em",
  },
}));
