import React from "react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Box, Button } from "@material-ui/core";

import {
  __DEV__,
  NODE_ENV,
  REACT_APP_ERRORS_DEV,
  REACT_APP_ERRORS_DSN,
  REACT_APP_VERSION,
} from "../../config";
import { Typography } from "@material-ui/core";
import logo from "../../assets/img/404_image.svg";
import errorLogo from "../../assets/img/500_image.svg";

const usingDevConfig = __DEV__ && !REACT_APP_ERRORS_DEV;
export function setUser(user) {
  // reportDialogUser = {
  //   name: user.username,
  //   email: user.email,
  // };
  Sentry.configureScope((scope) => {
    scope.setUser(user);
  });
}

const devConfig = usingDevConfig
  ? {
      beforeSend() {
        // Do not send errors in development.
        return null;
      },
    }
  : undefined;

if (__DEV__ && !usingDevConfig) {
  console.warn("Reporting errors to https://sentry.io during development.");
}

/** See https://docs.sentry.io/learn/configuration */
export function init() {
  Sentry.init({
    /** Where to send errors. This is NOT a secret. */
    dsn: REACT_APP_ERRORS_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: NODE_ENV,
    release: REACT_APP_VERSION,
    ...devConfig,
  });
}

export function ErrorSentryFallBack(props) {
  const is404 = props?.is404;
  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        left: 0,
        top: 0,
        position: "fixed",
        zIndex: is404 ? 1200 : 100,
        background: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box textAlign="center">
        {is404 ? <img alt="404" src={logo} /> : <img alt="500" src={errorLogo} />}
        <Typography
          variant="h3"
          style={{
            color: "#000000",
            fontSize: 24,
            fontWeight: "bold",
            letterSpacing: 0,
            marginTop: 30,
            textTransform: "uppercase",
          }}
        >
          {is404 ? "Ooops... Something is missing" : "Ooops... Something went wrong"}
        </Typography>
        <br />
        {!is404 && (
          <Button onClick={() => Sentry.showReportDialog()} style={{ marginBottom: 40 }}>
            Click here to report feedback.
          </Button>
        )}
        <br />
        <Button
          variant="contained"
          style={{
            color: "#FFFFFF",
            fontSize: 15,
            fontWeight: 600,
            height: 42,
            width: 140,
            borderRadius: 25,
            backgroundColor: "#A41717",
          }}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          Back to Home
        </Button>
      </Box>
    </Box>
  );
}
