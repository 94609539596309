import { auth, AuthState } from "./auth/state";
import { ui, UIState } from "./ui/state";
import { agency, AgencyState } from "./agency/state";
import { facility, FacilityState } from "./facility/state";
import { superAdmin, SuperAdminState } from "./superAdmin/state";
import { file, FileState } from "./file/state";
import { lists, ListsState } from "./lists/state";

import { AppStateInfo } from "./types";

// Export Actions, Selectors, Types
export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export * from "./agency/actions";
export * from "./agency/selectors";

export * from "./facility/actions";

export * from "./superAdmin/actions";
export * from "./superAdmin/selectors";

export * from "./file/actions";
export * from "./file/selectors";

export * from "./lists/actions";
export * from "./lists/selectors";

export interface AppState {
  auth: AuthState;
  ui: UIState;
  agency: AgencyState;
  facility: FacilityState;
  superAdmin: SuperAdminState;
  file: FileState;
  lists: ListsState;
}

const states: AppStateInfo[] = [
  // Export States
  auth,
  ui,
  agency,
  facility,
  superAdmin,
  file,
  lists,
];

export default states;
