import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  uploadWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 100,
    padding: "1.5em 0 1em",
    borderRadius: 8,
    border: "1px dashed #A8A8A8",
    borderColor: (props: any) => props.fileError && theme.palette.error.main,
    cursor: "pointer",
  },
  uploadIcon: {
    marginBottom: "0.5em",
  },
  uploadText: {
    fontSize: "0.875rem",
  },
}));
