import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, IconButton, Typography } from "@material-ui/core";

import { ArrowBackIcon } from "../../../components/icons";
import CreatePatientForm from "../../../components/forms/CreatePatientForm";

import { agencySelectors, useSelector } from "../../../state";

import { useStyles } from "./NewPatientPage.styles";

const NewPatientPage = ({ ...props }) => {
  const classes = useStyles();
  const history = useHistory();

  const editablePatient = useSelector(agencySelectors.editablePatient);
  const isEdit = Boolean(editablePatient) && editablePatient?.id;

  const handleBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            {isEdit ? "Edit" : "Create"} Caregiver
          </Typography>
        </Grid>
      </Grid>

      <CreatePatientForm />
    </>
  );
};

export default React.memo(NewPatientPage);
