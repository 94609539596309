export { bindActionCreators } from "redux";
export { connect, useDispatch, useSelector, useStore } from "react-redux";
export function arrayToObjById(arr: any[], idKey: string) {
  return arr?.reduce((acc, item) => {
    acc[item[idKey]] = item;
    return acc;
  }, {});
}
export function objToArray(obj: Record<any, any>) {
  const arr: any[] = [];
  Object.keys(obj).forEach(k => {
    arr.push(obj[k]);
  });
  return arr;
}
