import React, { useCallback, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, Chip } from "@material-ui/core";

import {
  useDispatch,
  agencyActions,
  superAdminActions,
  useSelector,
  agencySelectors,
  superAdminSelectors,
  authSelectors,
} from "../../../../state";
import { useRole } from "../../../../hooks";

import { useStyles } from "./BillingHistoryFilters.styles";

const BillingHistoryFilters = ({ handleFilterChange, selectedFilters }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSuperAdmin } = useRole();
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  let selector, actions;

  if (isSuperAdmin) {
    actions = superAdminActions;
    selector = superAdminSelectors;
  } else {
    actions = agencyActions;
    selector = agencySelectors;
  }

  useEffect(() => {
    dispatch(actions.getRateCodes(activeEntityId, true));
  }, [dispatch, actions, isSuperAdmin, activeEntityId]);

  const rateCodes: any[] = useSelector(selector.rateCodes);

  const rateCodeFilterValue = selectedFilters.find((filter) => filter.columnName === "code").value;

  const handleRateCodeFilterChange = useCallback(
    ({ target: { value } }) => {
      handleFilterChange("code", value);
    },
    [handleFilterChange],
  );

  return (
    <>
      <FormControl variant="outlined" className={classes.statusFormControl}>
        <InputLabel id="latest-appointment-status-label">Rate Code</InputLabel>
        <Select
          multiple
          id="rate-code"
          label="Rate Code"
          labelId="rate-code-label"
          value={rateCodeFilterValue}
          onChange={handleRateCodeFilterChange}
          renderValue={(selected: any) =>
            selected.map((selectedFilter, i) => (
              <Chip
                key={`selected-filter-${selectedFilter}-${i}`}
                size="small"
                variant="outlined"
                color="primary"
                label={selectedFilter}
              />
            ))
          }
        >
          {rateCodes.map((code) => (
            <MenuItem key={`statuses-${code.id}`} value={code.code || code.default_rate_code.code}>
              {code.code || code.default_rate_code.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default React.memo(BillingHistoryFilters);
