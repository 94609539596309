import React from "react";
import { Box, Grid, makeStyles, Typography } from "@material-ui/core";
import SimpleTable from "../../tables/SimpleTable";
import { UPDATES_APPOINTMENTS_RC_TABLE_COLUMNS } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 8,
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
  },
}));

const UpdateAppointmentDetails = ({ row }) => {
  const classes = useStyles();
  return (
    <Box p={2}>
      <Grid container justifyContent="space-between">
        <Typography variant="h5" className={classes.header}>
          Services
        </Typography>
      </Grid>

      <SimpleTable
        rows={row.appointment_rate_codes}
        columns={UPDATES_APPOINTMENTS_RC_TABLE_COLUMNS}
      />
    </Box>
  );
};

export default UpdateAppointmentDetails;
