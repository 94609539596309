import React, { useEffect } from "react";

import Table from "../Table";

import { useAction, agencyActions, useSelector, agencySelectors, authSelectors } from "../../../state";
import { NOTIFICATION_SETTINGS_TABLE_COLUMNS } from "../../../constants";

// TODO add types and interfaces
const NotificationSettingsTable = props => {
  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityId = activeEntity.id;
  const view = activeEntity.type === "home_health_agency" ? "agency_admin" : "facility_admin";
  let selector: any;
  let actions;

  // if (view === "super_user_all") {
  //   selector = superAdminSelectors;
  //   actions = superAdminActions;
  // } else if (view === "super_user_admin") {
  //   selector = superAdminSelectors;
  //   actions = superAdminActions;}
  if (view === "agency_admin") {
    selector = agencySelectors;
    actions = agencyActions;
  } else if (view === "facility_admin") {
    selector = agencySelectors;
    actions = agencyActions;
  }
  const getNotifications = useAction(actions.getNotifications);

  const notifications = useSelector(selector.notificationSettings);
  const notificationsLoading: boolean = useSelector(selector.notificationSettingsLoading);

  useEffect(() => {
    getNotifications(
      activeEntityId,
      view,
    );
  }, [getNotifications, view, props.entity, props.filter, activeEntityId]);

  return (
    <Table
      loading={notificationsLoading}
      rows={notifications}
      columns={NOTIFICATION_SETTINGS_TABLE_COLUMNS}
      {...props}
      exportEnabled={false}
      exportedFileName="Notification targets"
    />
  );
};

export default NotificationSettingsTable;
