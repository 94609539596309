import UsersPage from "./UsersPage";
import PatientsPage from "./PatientsPage/PatientsPage";
import UpcomingAppointmentsPage from "./UpcomingAppointmentsPage";
import CheckedInAppointmentsPage from "./CheckedInAppointmentsPage";
import UserPage from "./UsersPage/UserPage";
import AppointmentsPage from "../agency/Appointment/AppointmentsPage";
import UploadUpdatesPage from "../main/UploadUpdatesPage";
import { ROLES } from "../../state";
import UpcomingAppointmentsTablePage from "./UpcomingAppointmentsPage/UpcomingAppointmentsTablePage";
import BillingPage from "./BillingPage";
import NewPatientPage from "../agency/NewPatientPage";
import NewAppointmentPage from "./NewAppointmentPage";
import AppointmentPage from "../agency/Appointment/AppointmentPage/AppointmentPage";

export const FacilityPages = {
  billing: {
    anon: false,
    path: "/facility/billing-history",
    title: "Facility Billing",
    type: "PAGE_FACILITY_BILLING",
    view: BillingPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.FACILITY_ADMIN, ROLES.FACILITY_INTAKE, ROLES.FACILITY_BILLER],
  },
  upcomingAppointmentsIntake: {
    anon: false,
    path: "/facility/upcoming-appointments/table",
    title: "Upcoming Appointments",
    type: "PAGE_FACILITY_UPCOMING_INTAKE_APPOINTMENTS",
    view: UpcomingAppointmentsTablePage,
    roles: [ROLES.SUPER_ADMIN, ROLES.FACILITY_ADMIN, ROLES.FACILITY_INTAKE, ROLES.FACILITY_BILLER],
  },
  upcomingAppointments: {
    anon: false,
    path: "/facility/upcoming-appointments",
    title: "Upcoming Appointments",
    type: "PAGE_FACILITY_UPCOMING_APPOINTMENTS",
    view: UpcomingAppointmentsPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.FACILITY_ADMIN, ROLES.FACILITY_INTAKE, ROLES.FACILITY_BILLER],
  },
  createAppointment: {
    anon: false,
    path: "/facility/appointments/create/:originalAppointmentId",
    title: "Appointment",
    type: "PAGE_FACILITY_APPOINTMENT",
    view: NewAppointmentPage,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.FACILITY_ADMIN,
      ROLES.FACILITY_INTAKE,
      ROLES.FACILITY_PROVIDER,
    ],
  },
  appointment: {
    anon: false,
    path: "/facility/appointments/:id",
    title: "Appointment",
    type: "PAGE_FACILITY_APPOINTMENT",
    view: AppointmentPage,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.FACILITY_ADMIN,
      ROLES.FACILITY_INTAKE,
      ROLES.FACILITY_PROVIDER,
    ],
  },
  appointments: {
    anon: false,
    path: "/facility/appointments",
    title: "Appointments",
    type: "PAGE_FACILITY_APPOINTMENTS",
    view: AppointmentsPage,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.FACILITY_ADMIN,
      ROLES.FACILITY_INTAKE,
      ROLES.FACILITY_BILLER,
      ROLES.FACILITY_PROVIDER,
    ],
  },
  checkinAppointments: {
    anon: false,
    path: "/facility/checkedin-appointments",
    title: "Checked in Appointments",
    type: "PAGE_FACILITY_CHECKED_IN__APPOINTMENTS",
    view: CheckedInAppointmentsPage,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.FACILITY_ADMIN,
      ROLES.FACILITY_INTAKE,
      ROLES.FACILITY_BILLER,
      ROLES.FACILITY_PROVIDER,
    ],
  },
  editPatient: {
    anon: false,
    path: "/facility/patients/:id",
    title: "Edit Caregiver",
    type: "PAGE_FACILITY_EDIT_PATIENT",
    view: NewPatientPage,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.FACILITY_ADMIN,
      ROLES.FACILITY_INTAKE,
      ROLES.FACILITY_BILLER,
      ROLES.FACILITY_PROVIDER,
    ],
  },
  patients: {
    anon: false,
    path: "/facility/patients",
    title: "Caregivers",
    type: "PAGE_FACILITY_PATIENTS",
    view: PatientsPage,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.FACILITY_ADMIN,
      ROLES.FACILITY_INTAKE,
      ROLES.FACILITY_BILLER,
      ROLES.FACILITY_PROVIDER,
    ],
  },
  uploadUpdates: {
    anon: false,
    path: "/facility/upload-updates",
    title: "Upload Updates",
    type: "PAGE_FACILITY_UPLOAD_UPDATES",
    view: UploadUpdatesPage,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.FACILITY_ADMIN,
      ROLES.FACILITY_INTAKE,
      ROLES.FACILITY_BILLER,
      ROLES.FACILITY_PROVIDER,
    ],
  },
  createUser: {
    anon: false,
    path: "/facility/users/create",
    title: "Create Facility User",
    type: "PAGE_FACILITY_USER_CREATE_PAGE",
    view: UserPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.FACILITY_ADMIN],
  },
  editUser: {
    anon: false,
    path: "/facility/users/:id",
    title: "Edit Facility Users",
    type: "PAGE_FACILITY_USER_EDIT_PAGE",
    view: UserPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.FACILITY_ADMIN],
  },
  users: {
    anon: false,
    path: "/facility/users",
    title: "Users",
    type: "PAGE_FACILITY_USERS_PAGE",
    view: UsersPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.FACILITY_ADMIN],
  },
};

export default FacilityPages;

export const FacilityArea = {
  pages: FacilityPages,
};
