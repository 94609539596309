import { AuthPages } from "./auth";
import { SuperAdminPages } from "./super-admin";
import { AgencyPages } from "./agency";
import { FacilityPages } from "./facility";
import { MainPages } from "./main";
const Pages = {
  auth: AuthPages,
  agency: AgencyPages,
  superAdmin: SuperAdminPages,
  facilities: FacilityPages,
  main: MainPages,
};

export default Pages;

const RolesRequiredByDefault = ["SUPER_ADMIN"];

/**
 * Function to process each page in `Pages`.
 * @param {(page:PageDefinition,pageName:string,areaName:string,pages:typeof Pages)=>void} handler
 */
export function eachPage(handler) {
  Object.keys(Pages).forEach(areaName => {
    const area = Pages[areaName];
    Object.keys(area).forEach(pageName => {
      handler(area[pageName], pageName, areaName, Pages);
    });
  });
}

eachPage(page => {
  if (!page.anon && !page.roles) {
    page.roles = RolesRequiredByDefault;
  }
});
