import React from "react";
import clsx from "clsx";
import { Fade, List, ListItem, ListItemIcon } from "@material-ui/core";

import { RouterLink, useRouterLocation } from "../../../lib";

import { useStyles } from "../MainMenu.styles";
import logo from "../../../assets/logo/LevelUp-Logo1-01_300.png";
import logoSmall from "../../../assets/logo/LevelUp-Logo-only.png";

const Logo = ({ open }) => {
  const classes = useStyles();
  const location = useRouterLocation();

  return (
    <List>
      <ListItem
        button
        className={clsx(classes.listItem, classes.logoWrap)}
        component={RouterLink}
        to="/"
        selected={location.pathname === "/"}
      >
        <Fade in={open}>
          <div className={classes.logo}>
            <img src={logo} alt="Level Up logo" height={24} />
          </div>
        </Fade>
        <Fade in={!open}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <img src={logoSmall} alt="Level Up logo" height={24} />
          </ListItemIcon>
        </Fade>
      </ListItem>
    </List>
  );
};

export default React.memo(Logo);
