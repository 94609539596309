import React, { useEffect } from "react";
import { Box, Grid, IconButton, InputAdornment, TextField, Typography } from "@material-ui/core";

import { SearchIcon, TodayIcon } from "../../../components/icons";
import CheckedInAppointmentsTable from "../../../components/tables/CheckedInAppointmentsTable";

import {
  useAction,
  agencyActions,
  superAdminActions,
  useSelector,
  authSelectors,
} from "../../../state";
import { TODAY } from "../../../constants";
import { useInputDebounced } from "../../../lib";

import { useStyles } from "./CheckedInAppointmentsPage.styles";

const CheckedInAppointmentsPage = () => {
  const classes = useStyles();

  const firstName = useSelector(authSelectors.userFirstName);

  const getAppointments = useAction(agencyActions.getAppointments);
  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(superAdminActions.setSearchQuery);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityId = activeEntity.id;
  const view = activeEntity.type === "home_health_agency" ? "agency" : "facility";
  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  useEffect(() => {
    getAppointments(activeEntityId, view);
  }, [activeEntityId, getAppointments, view]);

  return (
    <Box>
      <Grid container justifyContent="space-between" component="header" className={classes.header}>
        <Grid item>
          <Grid container direction="column">
            <Grid item className={classes.title}>
              <Typography variant="h3" component="h1">
                Welcome, {firstName}!
              </Typography>
            </Grid>

            <Grid item className={classes.searchWrap}>
              <TextField
                fullWidth
                id="search"
                label="Search for caregiver id or name"
                variant="outlined"
                value={searchQuery}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container>
            <Typography variant="h6">
              <TodayIcon /> {TODAY}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <CheckedInAppointmentsTable searchQuery={searchQuery} />
    </Box>
  );
};

export default CheckedInAppointmentsPage;
