import React, { useCallback, useState } from "react";
import { Chip, Collapse, Grid } from "@material-ui/core";

import Table from "../Table";
import UploadUpdatesFilters from "./UploadUpdatesFilters";

import {
  // TYPES,
  TABLE_ROW_UPLOAD_ACTION,
  UPDATES_FILTERS,
  UPDATES_FILTERS_EXTENSIONS,
  UPLOAD_UPDATES_TABLE_COLUMNS,
  UPLOAD_UPDATES_TABLE_COLUMN_EXTENSIONS,
} from "../../../constants";
import { agencySelectors, useSelector } from "../../../state";

import { useStyles } from "./UploadUpdatesTable.styles";

// TODO add types and interfaces
const UploadUpdatesTable = props => {
  const classes = useStyles(props);

  const updates = useSelector(agencySelectors.updates);
  const searchQuery = useSelector(agencySelectors.searchQuery);

  const [selectedFilters, setSelectedFilters] = useState<any[]>(UPDATES_FILTERS);
  const filtersChips = selectedFilters.flatMap(filter => filter.value).filter(Boolean);

  const handleRemoveFilter = useCallback(
    status => {
      const updatedFilters = selectedFilters.map(filter => ({
        ...filter,
        value:
          typeof filter.value !== "string"
            ? filter.value.filter(filterValue => status !== filterValue)
            : "",
      }));
      setSelectedFilters(updatedFilters);
    },
    [selectedFilters],
  );

  const handleFilterChange = useCallback(
    (fieldName, value) => {
      const updatedFilters = selectedFilters.map(filter =>
        filter.columnName === fieldName ? { ...filter, value } : filter,
      );
      setSelectedFilters(updatedFilters);
    },
    [selectedFilters],
  );

  return (
    <>
      <Collapse
        in={props.isFiltersVisible}
        timeout="auto"
        unmountOnExit
        className={classes.container}
      >
        <Grid container>
          <UploadUpdatesFilters
            selectedFilters={selectedFilters}
            handleFilterChange={handleFilterChange}
          />
        </Grid>
      </Collapse>

      {!!filtersChips.length && (
        <Grid container className={classes.container} spacing={1}>
          {filtersChips.map((filter, i) => (
            <Chip
              key={`selected-filter-${filter}-${i}`}
              variant="outlined"
              color="primary"
              onDelete={() => handleRemoveFilter(filter)}
              // label={TYPES[filter] || filter}
              label={filter}
              className={classes.selectedFilter}
            />
          ))}
        </Grid>
      )}

      <Table
        {...props}
        rows={updates}
        columns={UPLOAD_UPDATES_TABLE_COLUMNS}
        actions={[TABLE_ROW_UPLOAD_ACTION]}
        searchValue={searchQuery}
        withFilters={true}
        selectedFilters={selectedFilters}
        tableColumnExtensions={UPLOAD_UPDATES_TABLE_COLUMN_EXTENSIONS}
        tableFiltersExtensions={UPDATES_FILTERS_EXTENSIONS}
        exportEnabled={true}
        exportedFileName="Updates"
      />
    </>
  );
};

export default React.memo(UploadUpdatesTable);
