import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
  },
  bottom: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
    margin: 0,
  },
}));
