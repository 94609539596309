import {
  APPOINTMENT_STEPS,
  APPOINTMENT_MISSED_APPOINTMENT_STEPS,
  APPOINTMENT_MISSED_SERVICES_STEPS,
} from "../../../constants";

export const isError = (status: number, index: number) => {
  switch (status) {
    case 5:
      return index === 1;
    case 6:
      return index === 2;
    default:
      return false;
  }
};

export const getActiveStep = (status: number) => {
  switch (status) {
    case 5:
      return 2;
    case 6:
      return 3;
    case 7:
      return 0;
    default:
      return status;
  }
};

export const getAppointmentSteps = (status: number) => {
  switch (status) {
    case 5:
      return APPOINTMENT_MISSED_APPOINTMENT_STEPS;
    case 6:
      return APPOINTMENT_MISSED_SERVICES_STEPS;
    default:
      return APPOINTMENT_STEPS;
  }
};
