import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { REACT_APP_API_URL } from "../../../../../config";
import { Breadcrumbs, Button, Grid, Link, TextField, Typography } from "@material-ui/core";
import { APPOINTMENT_STATUSES_STATES } from "../../../../../constants";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
  useDispatch,
} from "../../../../../state";
import { useStyles } from "./AppointmentHeader.styles";
import NumberFormat from "react-number-format";
import { useRole } from "../../../../../hooks";
import LocationUpdateConfirmationModal from "../AppointmentServices/LocationUpdateConfirmationModal";
import { CancelOutlinedIcon, CheckCalendarIcon, PrintIcon } from "../../../../../components";
import { format } from "date-fns";
import { EditOutlined } from "@material-ui/icons";

const AppointmentHeader = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isAgencyRole } = useRole();
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const { appointment_status_id: status, patient = {} } = openedAppointment;
  const isAppointmentCancelable =
    status === APPOINTMENT_STATUSES_STATES.SCHEDULED ||
    status === APPOINTMENT_STATUSES_STATES.UNSCHEDULED;
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const view = useSelector(authSelectors.activeEntityType);
  const [open, setOpen] = useState(false);
  const [locationUpdate, setLocationUpdate] = useState(false);
  const token = useSelector(authSelectors.token);
  const [showCheckInButton, setShowCheckInButton] = useState(false);
  const [editMrn, setEditMrn] = useState(false);
  const [updatedMrn, setUpdatedMrn] = useState(patient?.mrn);
  const { isSuperAdmin } = useRole();
  const editPatient = useAction(agencyActions.editPatient);
  const activeEntityType = useSelector(authSelectors.activeEntityType);
  const [isMrnDuplicate, setIsMrnDuplicate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (view !== "agency" && (status === 1 || status === 6)) {
      setShowCheckInButton(true);
    } else {
      setShowCheckInButton(false);
    }
  }, [status, view]);

  const updateAppointmentStatus = useAction(agencyActions.updateAppointmentStatus);

  const handleCancelAppointment = useCallback(() => {
    updateAppointmentStatus(
      APPOINTMENT_STATUSES_STATES.CANCELED,
      openedAppointment,
      activeEntityId,
      view,
    );
  }, [updateAppointmentStatus, openedAppointment, activeEntityId, view]);

  const handleDownloadReqForm = useCallback(() => {
    window.open(
      `${REACT_APP_API_URL}/facilities/${activeEntityId}/appointments/${openedAppointment.id}/download_requisition?token=${token}`,
      "_blank",
    );
  }, [activeEntityId, openedAppointment.id, token]);

  const handleCheckIn = useCallback(
    (updateLocation = false) => {
      if (updateLocation) {
        openedAppointment["updateLocationId"] = activeEntityId;
      }
      updateAppointmentStatus(
        APPOINTMENT_STATUSES_STATES.CHECKED_IN,
        openedAppointment,
        activeEntityId,
        view,
      );
    },
    [activeEntityId, openedAppointment, updateAppointmentStatus, view],
  );

  const handleCheckInAppointment = useCallback(() => {
    if (activeEntityId !== openedAppointment.medical_facility_id && view !== "super_admin") {
      setOpen(true);
    } else {
      handleCheckIn();
    }
  }, [activeEntityId, openedAppointment.medical_facility_id, handleCheckIn, view]);

  useEffect(() => {
    if (locationUpdate) {
      setLocationUpdate(false);
      handleCheckIn(true);
      setOpen(false);
    }
  }, [handleCheckIn, locationUpdate]);

  function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    //ToDo: set to go to all appointments. don't rely on go  back
    history.goBack();
  }

  useEffect(() => {
    setUpdatedMrn(patient.mrn);
  }, [patient.mrn]);

  const handleMrnChange = useCallback((e) => {
    setUpdatedMrn(e.target.value);
  }, []);

  const handleSavingMrn = useCallback(
    (e) => {
      editPatient(
        { ...patient, mrn: updatedMrn },
        activeEntityType,
        activeEntityId,
        false,
        openedAppointment.id,
      );
      setEditMrn(false);
    },
    [activeEntityId, activeEntityType, editPatient, openedAppointment.id, patient, updatedMrn],
  );

  async function handleBlur(mrn: string) {
    const mrnDuplicate: any = await dispatch(
      agencyActions.checkMrn(updatedMrn, activeEntityId, activeEntityType),
    );
    setIsMrnDuplicate(mrnDuplicate);
  }

  return (
    <Grid
      container
      component="header"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      className={classes.header}
    >
      <Grid item xs={8}>
        <Grid item>
          <Grid item xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/" onClick={handleClick}>
                All appointments
              </Link>
              <Typography color="textPrimary">
                {patient?.first_name} {patient?.last_name}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid item container alignItems="center" style={{ marginTop: 8 }}>
          <Grid item container direction="row" alignItems="center" style={{ gap: "8px" }}>
            <Typography variant="h4" component="h1" className={classes.title}>
              {patient?.first_name} {patient?.last_name}
            </Typography>
            Caregiver ID: {openedAppointment.patient_id}
            {!isAgencyRole && !editMrn && ` |  MRN #${patient?.mrn || " Unknown"}`}
            {!editMrn
              ? isSuperAdmin && (
                  <Button
                    onClick={() => {
                      setEditMrn(true);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                )
              : isSuperAdmin && (
                  <>
                    <TextField
                      variant="outlined"
                      label="MRN #"
                      value={updatedMrn}
                      size="small"
                      onChange={handleMrnChange}
                      onBlur={(e) => {
                        handleBlur(updatedMrn);
                      }}
                    />
                    <Button
                      color="primary"
                      disabled={isMrnDuplicate}
                      variant="contained"
                      className={classes.btn}
                      onClick={handleSavingMrn}
                    >
                      Save
                    </Button>
                    <Button
                      color="primary"
                      className={classes.btn}
                      onClick={() => {
                        setEditMrn(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </>
                )}
          </Grid>

          <Typography className={classes.detailContainer}>
            <NumberFormat
              format="+1 (###) ###-####"
              mask="_"
              value={patient?.phone}
              displayType="text"
            />{" "}
            {patient?.gender && <span> | {patient.gender} </span>}
            {patient?.date_of_birth && (
              <span>| DOB: {format(new Date(patient.date_of_birth), "MM/dd/yyyy")}</span>
            )}
          </Typography>
        </Grid>
      </Grid>

      <Grid item>
        {isAppointmentCancelable && (
          <Button color="primary" onClick={handleCancelAppointment}>
            <CancelOutlinedIcon fontSize="small" className={classes.icon} />
            Cancel Appointment
          </Button>
        )}
        {!isAgencyRole && (
          <Button
            color="primary"
            variant="contained"
            className={classes.btn}
            onClick={handleDownloadReqForm}
          >
            <PrintIcon fontSize="small" className={classes.icon} />
            Print requisition form
          </Button>
        )}
        {showCheckInButton && (
          <Button
            variant="contained"
            color="primary"
            className={classes.checkInBtn}
            onClick={handleCheckInAppointment}
          >
            <CheckCalendarIcon fontSize="small" className={classes.icon} />
            Check In
          </Button>
        )}
      </Grid>
      <LocationUpdateConfirmationModal
        open={open}
        setOpen={setOpen}
        setLocationUpdate={setLocationUpdate}
      />
    </Grid>
  );
};

export default AppointmentHeader;
