import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";

import { ButtonPill, DashboardHistoryChart } from "../../../components";

import {
  useAction,
  agencyActions,
  useSelector,
  authSelectors,
  agencySelectors,
} from "../../../state";
import Pages from "../../index";
import { useRole } from "../../../hooks";
import { RouterLink } from "../../../lib";
import { APPOINTMENT_STATUSES_STATES } from "../../../constants";

import { useStyles } from "./DashboardPage.styles";

// TODO add types and interfaces
// TODO divide on more smaller components
const DashboardPage = () => {
  const classes = useStyles();
  const { isAgencyBiller } = useRole();

  const [days, setDays] = useState(7);

  const firstName = useSelector(authSelectors.userFirstName);
  const updatesAmount = useSelector(agencySelectors.updatesAmount);
  const appointments = useSelector(agencySelectors.appointments);
  const updatesExpected = useSelector(agencySelectors.updatesExpectedAmount);
  const getUpdates = useAction(agencyActions.getUpdates);
  const getAppointments = useAction(agencyActions.getAppointments);
  const getExpectedUpdates = useAction(agencyActions.getExpectedUpdates);
  const getAppointmentsHistory = useAction(agencyActions.getAppointmentsHistory);

  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const handleHistoryDaysChange = useCallback((event) => {
    setDays(event.target.value);
  }, []);

  useEffect(() => {
    getUpdates(activeEntityId);
    getExpectedUpdates(activeEntityId);
    getAppointments(activeEntityId, "agency");
  }, [getUpdates, getExpectedUpdates, getAppointments, activeEntityId]);

  useEffect(() => {
    getAppointmentsHistory(activeEntityId, days);
  }, [getAppointmentsHistory, days, activeEntityId]);

  const missedAppointments = appointments.filter(
    (appt) => appt.appointment_status_id === APPOINTMENT_STATUSES_STATES.MISSED,
  );

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="space-between" component="header" className={classes.header}>
        <Typography id="welcome-text" variant="h3" component="h1">
          Welcome, {firstName}!
        </Typography>
        {!isAgencyBiller && (
          <ButtonPill
            id="create-new-appointment-button"
            color="primary"
            component={RouterLink}
            to="/agency/appointments/create"
          >
            Create new appointment
          </ButtonPill>
        )}
      </Grid>

      <Grid justifyContent="space-between" container>
        <Grid item xs={4} className={classes.cardWrap}>
          <Card
            variant="outlined"
            className={clsx(classes.firstRowCard, classes.fullHeight, classes.updateTotals)}
          >
            <CardHeader className={classes.cardPadding} title="Today" />
            <CardContent className={classes.cardPadding}>
              <Box className={classes.flex}>
                <Typography variant="h6" gutterBottom>
                  {updatesAmount}
                </Typography>
                <Typography className={classes.ml20} variant="subtitle1" gutterBottom>
                  Updates received
                </Typography>
              </Box>
              <Box className={classes.flex}>
                <Typography variant="h6" gutterBottom>
                  {updatesExpected}
                </Typography>
                <Typography className={classes.ml20} variant="subtitle1" gutterBottom>
                  Expected updates
                </Typography>
              </Box>
            </CardContent>
            <CardActions className={classes.cardPadding}>
              <Link to={Pages.agency.updates.path}>
                <Button size="large" color="primary">
                  View
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={8} className={classes.cardWrap}>
          <Card variant="outlined" className={clsx(classes.firstRowCard, classes.fullHeight)}>
            {/* TODO Change chart data based on select value */}
            <CardHeader
              classes={{ action: classes.cardHeaderAction }}
              className={classes.cardPadding}
              title="History"
              action={
                <Select
                  labelId="history-days"
                  id="history-days"
                  value={days}
                  onChange={handleHistoryDaysChange}
                >
                  <MenuItem value={7}>Last 7 days</MenuItem>
                  <MenuItem value={14}>Last 14 days</MenuItem>
                  <MenuItem value={30}>Last 30 days</MenuItem>
                </Select>
              }
            />
            <CardContent className={classes.cardPadding}>
              {/* TODO calc height based on (card width) - (rest of card controls) */}
              <DashboardHistoryChart height={300 - 56} />
            </CardContent>
            <CardActions className={classes.cardPadding}>
              <Link to={Pages.agency.history.path}>
                <Button size="large" color="primary">
                  View
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs className={classes.cardWrap}>
          <Card variant="outlined" className={clsx(classes.card, classes.fullHeight)}>
            <CardContent className={clsx(classes.cardPadding, classes.fullHeight)}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                className={classes.fullHeight}
              >
                <Typography variant="h6" gutterBottom>
                  {missedAppointments.length}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Missed appointments
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item className={classes.cardWrap}>
          <Grid container direction="column">
            <ButtonPill
              color="primary"
              component={RouterLink}
              to="/scheduled-appointments"
              badge={scheduledAppointments.length}
              className={classes.bottomLinks}
            >
              All scheduled appointments
            </ButtonPill>
            <ButtonPill
              color="primary"
              component={RouterLink}
              to="/awaiting-appointments"
              badge={awaitingAppointments.length}
              className={classes.bottomLinks}
            >
              All awaiting appointments
            </ButtonPill>
            <ButtonPill
              color="primary"
              component={RouterLink}
              to="/tomorrow-appointments"
              badge={tomorrowAppointments.length}
            >
              Tomorrow’s appointments
            </ButtonPill>
          </Grid>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default React.memo(DashboardPage);
