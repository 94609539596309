import React from "react";
import { useHistory } from "react-router-dom";
import { Fade, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import clsx from "clsx";

import { linksByRole } from "./LinksByRole";
import { RouterLink, useRouterLocation } from "../../../lib";

import { useStyles } from "../MainMenu.styles";
import { useSelector } from "react-redux";
import { authSelectors } from "../../../state";

const Navigation = ({ open }) => {
  const classes = useStyles();
  const location = useRouterLocation();
  const history = useHistory();

  const role = useSelector(authSelectors.activeRole);

  const listItemClassNames = clsx(classes.listItem, classes.navListItem);
  const listItemClasses = { selected: classes.listItemSelected };
  const itemIconClasses = { root: classes.listItemIcon };

  if (!role) history.push("/auth/login");

  return (
    <List>
      {linksByRole(role)?.map((item) => (
        <ListItem
          key={`item-${item.to}`}
          button
          id={item.label.split(" ").join("_")}
          className={listItemClassNames}
          classes={listItemClasses}
          component={RouterLink}
          to={item.to}
          selected={location.pathname.indexOf(item.to) !== -1}
        >
          <ListItemIcon classes={itemIconClasses}>{item.iconComponent}</ListItemIcon>
          <Fade in={open}>
            <ListItemText className={classes.listItemText} primary={item.label} />
          </Fade>
        </ListItem>
      ))}
    </List>
  );
};

export default React.memo(Navigation);
