import React from "react";
import { TextField, IconButton, InputAdornment } from "@material-ui/core";
import { SearchIcon } from "../icons";

const SearchField = ({ id, label, onChange, value }) => {
  return (
    <TextField
      fullWidth
      id={id}
      size="small"
      label={label}
      variant="outlined"
      value={value}
      // @ts-ignore
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default React.memo(SearchField);
