import React, { useEffect } from "react";

import Table from "../Table";

import {
  useAction,
  superAdminActions,
  useSelector,
  authSelectors,
  superAdminSelectors,
  // useDispatch,
} from "../../../state";
import {
  TABLE_ROW_DOWNLOAD_ACTION,
  SUPER_ADMIN_AGENCY_INVOICES_TABLE_COLUMNS,
} from "../../../constants";
import { REACT_APP_API_URL } from "../../../config";

interface Props {
  view: "super_admin" | "agency" | "facility";
  entity: any;
}

const InvoicesTable = (props: Props) => {
  const { view, entity } = props;
  // const dispatch = useDispatch();
  const getInvoices = useAction(superAdminActions.getInvoices);

  const invoices = useSelector(superAdminSelectors.invoices);
  const token = useSelector(authSelectors.token);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  useEffect(() => {
    const entityId = view === "agency" ? activeEntityId : entity?.id;
    getInvoices(entityId, view);
  }, [getInvoices, view, activeEntityId, entity?.id]);

  return (
    <Table
      {...props}
      rows={invoices}
      columns={SUPER_ADMIN_AGENCY_INVOICES_TABLE_COLUMNS}
      actions={[TABLE_ROW_DOWNLOAD_ACTION]}
      downloadHandler={(e) =>
        window.open(`${REACT_APP_API_URL}/download/${e.filename.id}?token=${token}`, "_blank")
      }
      exportEnabled={true}
      exportedFileName="Invoices"
    />
  );
};

export default React.memo(InvoicesTable);
