import React from "react";

import Table from "../../../components/tables/Table";

import FacilityPages from "../../../pages/facility";

import {
  UPCOMING_APPOINTMENTS_TABLE_COLUMNS as columns,
  UPCOMING_APPOINTMENTS_TABLE_EXTENSION as extensions,
} from "../../../constants";
import { useSelector, agencySelectors } from "../../../state";

interface UpcomingAppointmentsTableProps {
  searchQuery?: string;
  actions?: string[];
}

const UpcomingAppointmentsTable = ({ searchQuery, actions }: UpcomingAppointmentsTableProps) => {
  const upcomingAppointments = useSelector(agencySelectors.upcomingAppointments);
  const appointmentsLoading = useSelector(agencySelectors.appointmentsLoading);

  return (
    <Table
      rows={upcomingAppointments}
      columns={columns}
      tableColumnExtensions={extensions}
      loading={appointmentsLoading}
      searchValue={searchQuery}
      actions={actions}
      rowBaseUrl={FacilityPages.appointments.path}
      exportEnabled={true}
      exportedFileName="UpcommingAppointments"
    />
  );
};

export default UpcomingAppointmentsTable;
