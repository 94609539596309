import React from "react";
import { Typography } from "@material-ui/core";

import { AuthPages } from "./";

import { Navigation, useOnMount } from "../../lib";
import { authActions, useAction } from "../../state";

// TODO add types and interfaces
const LogoutPage = () => {
  const logout = useAction(authActions.logout);

  useOnMount(async () => {
    await logout();
    Navigation.reload(AuthPages.login);
  });

  return <Typography variant="h2">Logging out...</Typography>;
};

export default React.memo(LogoutPage);
