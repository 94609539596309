import React, { useEffect } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import EntityHeader from "../../../components/headers/EntityHeader";
import FacilityTabs from "../../../components/tabs/FacilityTabs";
import { superAdminActions, superAdminSelectors, useDispatch, useSelector } from "../../../state";

// TODO add types and interfaces
const FacilityPage = ({ ...props }) => {
  const facilities = useSelector(superAdminSelectors.facilities);
  const facilityId = Number(props.match.params.id);
  const facility = facilities.find((facility) => facility.id === facilityId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!facility) dispatch(superAdminActions.getFacilities());
  }, [dispatch, facility]);

  return (
    <Box>
      {!!facility ? (
        <>
          <EntityHeader entity={facility} />
          <FacilityTabs facility={facility} />
        </>
      ) : (
        <CircularProgress />
      )}
    </Box>
  );
};

export default React.memo(FacilityPage);
