import React from "react";

import Table from "../Table";

const SimpleTable = ({
  rows,
  columns,
  withHeaderRow = false,
  withPagination = false,
  ...props
}) => (
  <Table
    {...props}
    rows={rows ?? []}
    columns={columns}
    withHeaderRow={withHeaderRow}
    withPagination={withPagination}
  />
);

export default React.memo(SimpleTable);
