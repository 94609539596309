import React from "react";
import { FormHelperText, Table, TableBody, TableContainer, Typography } from "@material-ui/core";
import RateCodeRow from "./RateCodeRow";
import { useStyles } from "./RateCodesTable.styles";

const RateCodesTable = ({ form }) => {
  // const rateCodes = useSelector(agencySelectors.rateCodes);
  const rateCodes = form.values.rateCodes;
  const error =
    form.touched.rateCodes &&
    Boolean(form.errors.rateCodes) &&
    typeof form.errors.rateCodes === "string";
  const classes = useStyles({ error });

  return !!rateCodes && !!rateCodes.length ? (
    <TableContainer className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
          {rateCodes.map((code, index) => (
            <RateCodeRow
              key={`code-${code.id}`}
              index={index}
              form={form}
              code={code}
              error={error}
            />
          ))}
        </TableBody>
      </Table>

      {error && (
        <FormHelperText error variant="outlined">
          {form.errors.rateCodes}
        </FormHelperText>
      )}
    </TableContainer>
  ) : (
    <Typography className={classes.noRateCodes} align="center">
      No rate codes here yet.
    </Typography>
  );
};

export default RateCodesTable;
