import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: "2rem",
  },
  title: {
    paddingBottom: "2rem",
  },
  searchWrap: {
    minWidth: 400,
  },
}));
