import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "75%",
    margin: "0 auto",
  },
  header: {
    paddingBottom: 10,
  },
}));
