import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import PatientsTable from "../../../components/tables/PatientsTable";
import { AddButton, FilterButton } from "../../../components/buttons";
import AgencyPages from "../../../pages/agency";
import { useInputDebounced } from "../../../lib";
import { agencyActions, useAction } from "../../../state";
import { useStyles } from "./PatientsTab.styles";
import { SearchField } from "../../inputs";

// TODO add types and interfaces
const PatientsTab = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(agencyActions.setSearchQuery);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  return (
    <Box>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={4}>
          <SearchField
            id="search"
            label="Search for caregiver id or name"
            value={searchQuery}
            onChange={handleChange}
          />
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between">
            <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
            {props.view === "agency" && <AddButton to={AgencyPages.createPatient.path} />}
          </Grid>
        </Grid>
      </Grid>

      <PatientsTable view={props.view} tabView isFiltersVisible={expanded} entity={props.entity} />
    </Box>
  );
};

export default React.memo(PatientsTab);
