import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import ToggleButton, { AddButton, FilterButton } from "../../../../components/buttons";
import AppointmentsTable from "../../../../components/tables/AppointmentsTable";
import {
  FACILITY_APPOINTMENTS_TABLE_COLUMNS as facilitiesColumns,
  ALL_FACILITY_APPOINTMENTS_TABLE_COLUMNS as allFacColumns,
  SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_COLUMNS as agencyColumns,
  SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_COLUMN_EXTENSIONS as extensions,
  SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_SORTING as sortingOptions,
} from "../../../../constants";
import AgencyPages from "../../index";
import { useRole } from "../../../../hooks";
import { useInputDebounced } from "../../../../lib";
import { useAction, agencyActions, useSelector, authSelectors } from "../../../../state";
import { useStyles } from "./AppointmentsPage.styles";
import { SearchField } from "../../../../components/inputs";

// TODO add types and interfaces
const AppointmentsPage = () => {
  const classes = useStyles();
  const { isFacilityRole, isAgencyBiller } = useRole();
  const facility = useSelector(authSelectors.activeEntity);
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(agencyActions.setSearchQuery);
  const [appointmentList, setAppointmentList]: any = useState("current_fac");

  const handleToggleChange = (e) => {
    setAppointmentList(e);
  };

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  return (
    <Box>
      <Typography variant="subtitle2">All Appointments</Typography>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        classes={{ container: classes.filterRow }}
      >
        <Grid item container direction="row" xs={6} alignItems="center" spacing={3}>
          <Grid item xs={9}>
            <SearchField
              id="search"
              label="Search for caregiver id or name"
              value={searchQuery}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            {!!isFacilityRole && (
              <ToggleButton
                value={appointmentList}
                onChange={handleToggleChange}
                options={[
                  { label: "My Facility", value: "current_fac" },
                  { label: "All Facilities", value: "all_fac" },
                ]}
              />
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Grid container>
            <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
            {!isFacilityRole && !isAgencyBiller && (
              <AddButton to={AgencyPages.createAppointment.path} />
            )}
          </Grid>
        </Grid>
      </Grid>

      <AppointmentsTable
        view={isFacilityRole ? "facility" : "agency"}
        appointmentListType={appointmentList}
        tabView={false}
        isFiltersVisible={expanded}
        columns={
          isFacilityRole
            ? appointmentList === "all_fac"
              ? allFacColumns
              : facilitiesColumns
            : agencyColumns
        }
        extensions={extensions}
        rowBaseUrl={!isAgencyBiller && `/${isFacilityRole ? "facility" : "agency"}/appointments`}
        defaultSorting={sortingOptions}
        entity={facility}
      />
    </Box>
  );
};

export default React.memo(AppointmentsPage);
