import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  tab: {
    color: "#000",

    "&.MuiTab-textColorInherit": {
      opacity: 0.5,

      "&.Mui-selected": {
        opacity: 1,
      },
    },
  },
}));
