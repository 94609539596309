import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { AddButton } from "../../../../components/buttons";
import AgenciesTable from "../../../../components/tables/AgenciesTable";
import SuperAdminPages from "../../index";
import { useInputDebounced } from "../../../../lib";
import { useAction, superAdminActions } from "../../../../state";
import { useStyles } from "./AgenciesPage.styles";
import { SearchField } from "../../../../components/inputs";

// TODO add types and interfaces
const AgenciesPage = () => {
  const classes = useStyles();

  const getAgencies = useAction(superAdminActions.getAgencies);

  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(superAdminActions.setSearchQuery);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  useEffect(() => {
    getAgencies();
  }, [getAgencies]);

  return (
    <Box>
      <Typography variant="subtitle2">Agencies</Typography>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={4}>
          <SearchField id="search" label="Search" value={searchQuery} onChange={handleChange} />
        </Grid>

        <Grid item>
          <AddButton to={SuperAdminPages.createAgency.path} />
        </Grid>
      </Grid>

      <AgenciesTable />
    </Box>
  );
};

export default React.memo(AgenciesPage);
