import React, { useState } from "react";
import { Button, Grid, Switch, TextField, Typography } from "@material-ui/core";
import Modal from "../modals/Modal";
import {
  useAction,
  uiActions,
  superAdminActions,
  useSelector,
  superAdminSelectors,
  fileActions,
} from "../../state";
import { Form, useFormik } from "../../lib";
import { useStyles } from "./EditAgencyBundle.styles";
import { PriceFormatInput } from "../inputs";
import FileUpload from "../forms/FileUpload";
import { FileOutlinedIcon } from "../icons";

const EditAgencyBundleModal = (props) => {
  const { open, setOpen, bundle } = props;
  const classes = useStyles();
  const setLoading = useAction(uiActions.setLoading);
  const updateAgencyBundle = useAction(superAdminActions.updateAgencyBundle);
  const [showUpload, setShowUpload] = useState(false);
  const agency = useSelector(superAdminSelectors.activeAgency);
  const deleteFile = useAction(fileActions.deleteFileById);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: bundle,
    async onSubmit(values) {
      setLoading(true);
      await updateAgencyBundle(agency.id, values);
      setLoading(false);
      setOpen(false);
    },
  });

  const handleReplaceFile = () => {
    setShowUpload(true);
  };
  const handleRemoveFile = () => {
    deleteFile(form.values.filenames_id);
    form.setFieldValue("filenames_id", null);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Modal open={open} handleClose={handleCloseModal}>
      <Form form={form}>
        <Typography variant="subtitle2" className={classes.header}>
          Edit {bundle.name}
        </Typography>
        <Grid container alignItems="center" spacing={4}>
          <Grid item>
            <TextField
              id="bundle-rate"
              name="rate"
              size="small"
              label="price"
              variant="outlined"
              value={form.values.rate}
              onChange={form.handleChange}
              error={Boolean(form.touched?.rate) && Boolean(form.errors?.rate)}
              InputProps={{ inputComponent: PriceFormatInput as any }}
            />
          </Grid>
          <Grid item>
            <Switch
              id="bundle-active"
              color="primary"
              name="active"
              onChange={form.handleChange}
              checked={form.values.active}
            />
            Enabled
          </Grid>
          <Grid item>
            <Switch
              id="bundle-required-form"
              color="primary"
              name="form_required"
              onChange={form.handleChange}
              checked={form.values.form_required}
            />
            Form required
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <Typography variant="subtitle2" className={classes.header}>
              Form upload
            </Typography>
          </Grid>

          {form.values.filenames_id && (
            <Grid container item alignItems="center" style={{ gap: "8px", marginBottom: 8 }}>
              <FileOutlinedIcon />
              <Typography> {form.values.filenames_id}</Typography>
              <Button size="small" onClick={handleRemoveFile}>
                x
              </Button>

              {!showUpload && (
                <Button size="small" onClick={handleReplaceFile}>
                  Replace file
                </Button>
              )}
            </Grid>
          )}
          {(showUpload || !form?.values?.filenames_id) && (
            <Grid item xs={12}>
              <FileUpload
                form={form}
                name="filenames_id"
                accept="application/pdf"
                uploadType="private"
              />
            </Grid>
          )}
        </Grid>
        <Grid
          component="footer"
          container
          justifyContent="flex-end"
          alignItems="center"
          classes={{ container: classes.modalFooter }}
        >
          <Grid item>
            <Button color="primary" size="large" onClick={handleCloseModal}>
              cancel
            </Button>
          </Grid>

          <Grid item>
            <Button color="primary" size="large" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Modal>
  );
};

export default React.memo(EditAgencyBundleModal);
