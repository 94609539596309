import React, { useCallback, useEffect, useState } from "react";
import { Grid, Button, Typography, makeStyles } from "@material-ui/core";
import Modal from "../../../../../components/modals/Modal";
import { agencySelectors, authSelectors, useSelector } from "../../../../../state";

export const useStyles = makeStyles((theme) => ({
  title: {
    color: "#000000",
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
    marginBottom: 20,
  },
  text: {
    color: "#000000",
    fontSize: 16,
    letterSpacing: 0,
    maxWidth: 800,
    marginBottom: 10,
  },
}));

const LocationUpdateConfirmationModal = (props) => {
  const { open, setOpen, setLocationUpdate } = props;
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const activeEntity = useSelector(authSelectors.activeEntity);
  const classes = useStyles();
  const [location, setLocation] = useState("unknown");

  useEffect(() => {
    if (openedAppointment?.medical_facility_profile?.entity) {
      const { address, city, state } = openedAppointment.medical_facility_profile.entity;
      setLocation(`${city}, ${state} - ${address}`);
    }
  }, [openedAppointment]);
  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Modal open={open} handleClose={() => setOpen(false)}>
      <Typography className={classes.title}>Confirm Location Update</Typography>
      <Typography className={classes.text}>
        This appointment was scheduled at{" "}
        {openedAppointment?.medical_facility_profile?.entity?.name}: {location}. By checking in this
        caregiver, you will update the location to your current facility - {activeEntity.name}.
      </Typography>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            color="primary"
            size="large"
            onClick={handleCloseModal}
            style={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="large"
            onClick={() => setLocationUpdate(true)}
            style={{ textTransform: "capitalize" }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default LocationUpdateConfirmationModal;
