import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    header: {
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(2),
    },
  }),
  { classNamePrefix: "SuperAdminAgenciesPage" },
);
