import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { ArrowBackIcon } from "../../../components/icons";
import CreateAgencyForm from "../../../components/forms/CreateAgencyForm";
import ApplyRateCodesToAgency from "../../../components/forms/ApplyRateCodesToAgency";
import SuperAdminPages from "../index";
import { RouterLink } from "../../../lib";
import { useAction, superAdminActions } from "../../../state";

function getStepContent(props) {
  switch (props.activeStep) {
    case 0:
      return <CreateAgencyForm {...props} />;
    // return <ApplyRateCodesToAgency {...props} />;
    case 1:
      return <ApplyRateCodesToAgency {...props} />;
    default:
      return <Redirect to={SuperAdminPages.agencies.path} />;
  }
}

export const useStyles = makeStyles(() => ({
  header: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
    margin: 0,
  },
}));

const CreateAgencyPage = () => {
  const classes = useStyles();
  const clearActiveAgency = useAction(superAdminActions.clearActiveAgency);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  // TODO fill const with boolean based on is provided agency (from state) empty or not
  // const isEdit = location.pathname.split("/").indexOf("edit") > 0;
  const isEdit = false;

  useEffect(() => {
    clearActiveAgency();
  }, [clearActiveAgency]);

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton component={RouterLink} to="/super-admin/agencies">
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            {isEdit ? "Modify" : "Create"} Agency
          </Typography>
        </Grid>
      </Grid>

      {getStepContent({ activeStep, handleNext, handleBack })}
    </>
  );
};

export default React.memo(CreateAgencyPage);
