import React from "react";
import { Switch, TableRow, TableCell, TextField } from "@material-ui/core";
import clsx from "clsx";
import { PriceFormatInput } from "../../inputs";
import { useStyles } from "./RateCodesTable/RateCodesTable.styles";

const BundleRow = ({ index, form, code, error }) => {
  const classes = useStyles({ error });
  const isCodeSelected = form.values.bundles[index].active;

  return (
    <TableRow className={clsx(classes.tableRow, !isCodeSelected && classes.tableRowDisabled)}>
      <TableCell className={classes.switchCell}>
        <Switch
          id="bundle-active"
          color="primary"
          name={`bundles.${index}.active`}
          onChange={(e, v) => {
            form.setFieldValue(`bundles.${index}.active`, !isCodeSelected);
            const bundleRateCodes = new Set(
              form.values.bundles[index].home_health_agency_bundle_rate_codes.map(
                (rateCode) => rateCode.rate_code_id,
              ),
            );
            const newRateCodes = form.values.rateCodes.map((rc) => {
              if (bundleRateCodes.has(rc.id) && v) {
                //only activate rate code do not deactivate
                return {
                  ...rc,
                  active: true,
                };
              } else {
                return rc;
              }
            });
            form.setFieldValue("rateCodes", newRateCodes);
          }}
          checked={isCodeSelected}
        />
      </TableCell>
      <TableCell align="center" className={classes.rateCode}>
        {code.code}
      </TableCell>
      <TableCell className={classes.description}>{code.description}</TableCell>
      <TableCell className={classes.uploadFormCell}>
        {/*{isCodeSelected && (*/}
        {/*  <FileTextInput*/}
        {/*    form={form}*/}
        {/*    name={`bundle.${index}.form_location`}*/}
        {/*    label={`Upload form${Boolean(form.values.rateCodes[index].form_required) ? " *" : ""}`}*/}
        {/*    accept="application/pdf"*/}
        {/*    error={*/}
        {/*      form.touched.rateCodes &&*/}
        {/*      form.touched.rateCodes[index].form_location &&*/}
        {/*      Boolean(form.errors?.rateCodes) &&*/}
        {/*      Boolean(form.errors?.rateCodes[index]) &&*/}
        {/*      Boolean(form.errors?.rateCodes[index].form_location)*/}
        {/*    }*/}
        {/*  />*/}
        {/*)}*/}
      </TableCell>
      <TableCell className={classes.chargeCell}>
        {isCodeSelected && (
          <TextField
            fullWidth
            id="bundles-rate"
            name={`bundles.${index}.rate`}
            label="Charge *"
            value={form.values.bundles[index].rate}
            onChange={form.handleChange}
            error={
              Boolean(form.touched?.bundles) &&
              Boolean(form.touched?.bundles[index]) &&
              Boolean(form.errors?.bundles) &&
              Boolean(form.errors?.bundles[index]) &&
              Boolean(form.errors?.bundles[index].rate)
            }
            InputProps={{ inputComponent: PriceFormatInput as any }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default BundleRow;
