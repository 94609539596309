import { ENTITIES_API, RateCodeInitialValues, FacilityInitialValues } from "../../constants";
import { history, Navigation } from "../../lib";
import { superAdmin } from "./state";
import { AppThunk } from "../types";
import { authClient } from "../auth/actions";
import { agencyActions, uiActions } from "../";

const { actions } = superAdmin;

export const superAdminActions = {
  ...actions,

  // AGENCIES
  getAgencies(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/entities");
      if (status === 200) {
        const { homeHealthAgency } = data;
        dispatch(superAdminActions.setAgencies(homeHealthAgency));
      } else {
        dispatch(uiActions.showError("Failed to load Agencies " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getAgency(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/entities/" + id);
      if (status === 200) {
        dispatch(superAdminActions.setActiveAgency(data));
      } else if (status === 404) {
        Navigation.go("/*");
      } else {
        dispatch(uiActions.showError("Failed to load Agency " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  // FACILITIES
  getFacilities(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/entities");
      if (status === 200) {
        const { medicalFacilities } = data;
        dispatch(superAdminActions.setFacilities(medicalFacilities));
      } else {
        dispatch(uiActions.showError("Failed to load facilities " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getFacilityById(id: number) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/entities/" + id);
      if (status === 200) {
        dispatch(superAdminActions.setFacility(data));
      } else if (status === 404) {
        Navigation.go("/*");
      } else {
        dispatch(uiActions.showError("Failed to load facility " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createFacility(values: FacilityInitialValues) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.post(ENTITIES_API, values);
      if (status !== 200) {
        dispatch(uiActions.showError("Failed to create facility " + data.message));
      }
      dispatch(uiActions.setLoading(false));
      return status;
    };
  },
  editFacility(values: any) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const {
        id,
        first_name,
        last_name,
        email,
        entity_id,
        entity,
        printer,
        createdAt,
        updatedAt,
        deletedAt,
        ...rest
      } = values;
      const { data, status } = await authClient.put(ENTITIES_API + "/" + id, rest);
      if (status !== 200) {
        dispatch(uiActions.showError("Error Editing Facility " + data.message));
      } else {
        uiActions.showSuccess("Facility updated " + data.message);
      }
      dispatch(uiActions.setLoading(false));

      return status;
    };
  },

  //BUNDLES
  getDefaultBundles() {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/bundles");
      if (status === 200) {
        dispatch(superAdminActions.setDefaultBundles(data));
      } else {
        dispatch(
          uiActions.showError("Error getting default bundles as super admin " + data.message),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getDefaultBundleById(id) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/bundles/${id}`);
      if (status === 200) {
        dispatch(superAdminActions.setActiveDefaultBundle(data));
      } else {
        dispatch(uiActions.showError("Error getting default bundle" + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createDefaultBundle(values: any) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post("/bundles", values);
      if (status === 200) {
        dispatch(uiActions.openBundleSuccessModal(values));
      } else {
        dispatch(uiActions.showError("Error creating default bundle " + data.message));
      }
      dispatch(uiActions.setLoading(false));
      return status;
    };
  },
  getAllAppointments() {
    return async (dispatch, getState) => {
      dispatch(agencyActions.setAppointmentsLoading(true));
      const { superAdminFilterDocs = "false" } = getState().superAdmin;
      const {
        status,
        data,
        data: appointments,
      } = await authClient.get(`/appointments?filterDocs=${superAdminFilterDocs}`);
      if (status === 200) {
        dispatch(agencyActions.setAppointments(appointments));
      } else {
        dispatch(uiActions.showError("Error fetching appointments " + data.message));
      }

      dispatch(agencyActions.setAppointmentsLoading(false));
      return appointments;
    };
  },
  markAppointmentsAsReviewed(values: []) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post("/appointments/reviewed", values);
      if (status === 200) {
        dispatch(agencyActions.setAppointments(data));
      } else {
        dispatch(uiActions.showError("Error marking appointments as reviewed " + data.message));
      }
      dispatch(uiActions.setLoading(false));
      return status;
    };
  },
  updateDefaultBundle(id, values: any) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/bundles/${id}`, values);
      if (status === 200) {
        values.isEdit = true;
        dispatch(uiActions.openBundleSuccessModal(values));
      } else {
        dispatch(uiActions.showError("Error editing default bundle -" + data.message));
      }
      dispatch(uiActions.setLoading(false));
      return status;
    };
  },
  getAgencyBundles(entityId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`/agency/${entityId}/bundles`);
      if (status === 200) {
        dispatch(actions.setAgencyBundles(data));
      } else {
        dispatch(uiActions.showError("Error getting agency bundles -" + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  updateAgencyBundle(entity_id, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.put(
        `/agency/${entity_id}/bundle/${values.id}`,
        values,
      );
      if (status === 200) {
        dispatch(superAdminActions.getAgencyBundles(entity_id));
      } else {
        dispatch(
          uiActions.showError("Error updating agency bundle as super admin " + data.message),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  // RATE CODES
  getRateCodes(agencyId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get("/hha-rate-codes/" + agencyId);
      if (status === 200) {
        dispatch(superAdminActions.setRateCodes(data));
      } else {
        dispatch(
          uiActions.showError("Error getting agency rate codes as super admin " + data.message),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateAgencyRateCode(agencyId, rateCodeId, values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.put(
        "/hha-rate-codes/" + agencyId + "/rate-code/" + rateCodeId,
        values,
      );
      if (status === 200) {
        dispatch(superAdminActions.getRateCodes(agencyId));
      } else {
        dispatch(
          uiActions.showError("Error getting agency rate codes as super admin " + data.message),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getDefaultRateCodes(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get("/rate-codes");
      if (status === 200) {
        dispatch(superAdminActions.setDefaultRateCodes(data));
      } else {
        dispatch(
          uiActions.showError("Error getting default rate codes as super admin " + data.message),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getDefaultRateCode(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/rate-codes/" + id);
      if (status === 200) {
        dispatch(superAdminActions.setDefaultRateCode(data));
      } else {
        dispatch(uiActions.showError("Error getting default rate code " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createDefaultRateCode(values: RateCodeInitialValues) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.post("/rate-codes", values);

      if (status !== 200) dispatch(uiActions.showError("Error creating rate code " + data.message));

      dispatch(actions.setRateCode(data));
      dispatch(uiActions.setLoading(false));

      return status;
    };
  },
  updateDefaultRateCode(values: RateCodeInitialValues) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.put(`/rate-codes/${values.id}`, values);

      if (status !== 200) dispatch(uiActions.showError("Error creating rate code " + data.message));

      dispatch(actions.setRateCode(data));
      dispatch(uiActions.setLoading(false));

      return status;
    };
  },
  deleteDefaultRateCode(id) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.delete(`/rate-codes/${id}`);
      if (status !== 200) dispatch(uiActions.showError("Error deleting rate code " + data.message));
      dispatch(uiActions.setLoading(false));
      return status;
    };
  },
  getBundlesForRateCode(id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/rate-code-bundles/" + id);
      if (status === 200) {
        dispatch(superAdminActions.setBundlesForRateCode(data));
      } else {
        dispatch(uiActions.showError("Error getting bundles for rate code " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  // LABS
  getLabs(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/labs");
      if (status === 200) {
        dispatch(actions.setLabs(data));
      } else {
        dispatch(uiActions.showError("Error getting labs ") + data.message);
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getLabById(id) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/labs/" + id);
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        return data;
      } else {
        dispatch(uiActions.showError("Error getting lab ") + data.message);
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  saveLab(values) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      let result;
      if (values.id) {
        result = await authClient.put("/labs/" + values.id, values);
      } else {
        result = await authClient.post("/labs", values);
      }

      const { data, status } = result;

      if (status === 200) {
        dispatch(actions.setLabs(data));
      } else {
        dispatch(uiActions.showError("Error saving lab " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  deleteLab(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete("/labs/" + id);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Lab successfully deleted"));
        dispatch(superAdminActions.getLabs());
      } else {
        dispatch(uiActions.showError("Error deleting lab " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  // Regions

  deleteRegion(id: number): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.delete("/regions/" + id);
      if (status === 204) {
        dispatch(uiActions.showSuccess("Region successfully deleted"));
        dispatch(superAdminActions.getRegions());
      } else {
        dispatch(uiActions.showError("Error deleting region " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRegions(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/regions");
      if (status === 200) {
        dispatch(actions.setRegions(data));
      } else {
        dispatch(uiActions.showError("Error getting regions " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRegionById(id) {
    return authClient.get("/regions/" + id);
  },
  createRegion(values) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post("/regions", values);
      if (status === 200) {
        dispatch(actions.setRegions(data));
      } else {
        dispatch(uiActions.showError("Error creating region " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  saveRegion(values) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.put("/regions/" + values.id, values);
      if (status === 200) {
        dispatch(actions.setRegions(data));
      } else {
        dispatch(uiActions.showError("Error saving region " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  // Other
  getInvoices(agencyId, view): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      let url = `/agency/${agencyId}/invoices`;
      if (view === "facility") {
        url = `/facility/${agencyId}/invoices`;
      }

      const { data, status } = await authClient.get(url);
      if (status === 200) {
        dispatch(uiActions.setLoading(false));
        dispatch(superAdminActions.setInvoices(data));
      } else {
        dispatch(uiActions.showError("Error getting invoices " + data.message));
      }
    };
  },
  markInvoicePaid(entity_id, invoice_id, payload): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status } = await authClient.put(
        `/agency/${entity_id}/invoices/${invoice_id}`,
        payload,
      );
      if (status === 204) {
        dispatch(superAdminActions.getInvoices(entity_id, "super-admin"));
        dispatch(uiActions.showSuccess("Invoice payment updated"));
      } else {
        dispatch(uiActions.showError("Error updating invoice"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createAdmin(values): AppThunk<Promise<{ data: any; status: any }>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient
        .post("/admin-user", values)
        .catch((r) => r.response);

      if (status === 200) {
        dispatch(uiActions.showSuccess("Admin user successfully created"));
      } else {
        dispatch(uiActions.showError("Error creating admin user " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return { data, status };
    };
  },
  createFacilityUser(entityId: number, values: any, activeEntityType: any) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.post(`/facilities/${entityId}/users`, values);
      if (status === 200) {
        history.push(`/${activeEntityType}/users`);
        dispatch(uiActions.showSuccess("Facility user successfully created"));
      } else {
        dispatch(uiActions.showError("Error saving facility user " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return { data, status };
    };
  },
  updateFacilityUser(entityId: number, values: any, activeEntityType: any) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.put(
        `/facilities/${entityId}/users/${values.id}`,
        values,
      );
      if (status === 200) {
        history.push(`/${activeEntityType}/users`);
        dispatch(uiActions.showSuccess("Facility user successfully updated"));
      } else {
        dispatch(uiActions.showError("Error updating facility user " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return { data, status };
    };
  },
  getUsers(entityId, filter = "", role = "") {
    return async (dispatch) => {
      dispatch(actions.setUsersLoading(true));

      let result;

      if (entityId) {
        result = await authClient.get(`/entity/${entityId}/users?filter=${filter}&role=${role}`);
      } else {
        result = await authClient.get(`/entity/users?filter=${filter}&role=${role}`);
      }
      const users = result.data;
      if (result.status === 200) {
        dispatch(actions.setUsers(users));
      } else {
        dispatch(uiActions.showError("Error updating facility user " + result.data.message));
      }

      dispatch(actions.setUsersLoading(false));

      return users;
    };
  },

  // OTHER
  getEntities(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get("/entities");
      if (status === 200) {
        const { medicalFacilities, homeHealthAgency } = data;
        dispatch(superAdminActions.setAgencies(homeHealthAgency));
        dispatch(superAdminActions.setFacilities(medicalFacilities));
      } else {
        dispatch(uiActions.showError("Error fetching entities " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getRoles() {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status, data, data: roles } = await authClient.get("/roles");
      if (status === 200) {
        dispatch(superAdminActions.setRoles(roles));
      } else {
        dispatch(uiActions.showError("Error fetching roles " + data.message));
      }

      dispatch(uiActions.setLoading(false));

      return roles;
    };
  },

  setSearchQuery(query: string) {
    return actions.setSearchQuery(query);
  },
  getContractLink(entityId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get("/agency/" + entityId + "/profile/contract");
      dispatch(uiActions.setLoading(false));
      if (status === 200) {
        window.open(data.url, "_blank");
      } else {
        dispatch(uiActions.showError("Error generating contract link " + data.message));
      }
    };
  },
  getInvoiceLink(entityId, invoiceId) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.get(
        "/agency/" + entityId + "/invoices/" + invoiceId,
      );
      window.open(data.url, "_blank");
      dispatch(uiActions.setLoading(false));
      if (status === 200) {
        dispatch(uiActions.showSuccess("Download invoice link generated"));
      } else {
        dispatch(uiActions.showError("Error generating invoice link " + data.message));
      }
    };
  },
};
