import DashboardPage from "./DashboardPage";
import LabsPage from "./Labs/LabsPage/LabsPage";

import AgenciesPage from "./Agency/AgenciesPage/AgenciesPage";
import AgencyPage from "./Agency/AgencyPage";
import CreateAgencyPage from "./Agency/CreateAgencyPage";
import EditAgencyPage from "./Agency/EditAgencyPage";

import FacilitiesPage from "./Facility/FacilitiesPage/FacilitiesPage";
import FacilityPage from "./Facility/FacilityPage";
import CreateFacilityPage from "./Facility/CreateFacilityPage";
import EditFacilityPage from "./Facility/EditFacilityPage";

import DefaultRateCodesPage from "./DefaultRateCode/DefaultRateCodesPage";
import DefaultRateCodePage from "./DefaultRateCode/DefaultRateCodePage/DefaultRateCodePage";

import RegionsPage from "./Region/RegionsPage";
import CreateRegionPage from "./Region/CreateRegionPage";

import LabsFormPage from "./Labs/LabsFormPage";

import AllUsersPage from "./Users/AllUsersPage/AllUsersPage";
import AdminUsersPage from "./Users/AdminUsersPage/AdminUsersPage";
import UsersFormPage from "./Users/UsersFormPage";

import { ROLES } from "../../state";
import DefaultBundlesPage from "./DefaultBundles/DefaultBundlesPage";
import DefaultBundleFormPage from "./DefaultBundles/DefaultBundlesPage/DefaultBundleFormPage";
import SuperAdminAppointmentsPage from "./Appointments";
import AppointmentPage from "../agency/Appointment/AppointmentPage/AppointmentPage";
import SuperAdminCaregiversPage from "./Caregivers";

export const SuperAdminPages = {
  dashboard: {
    anon: false,
    path: "/super-admin/dashboard",
    title: "Dashboard",
    type: "PAGE_SUPER_ADMIN_DASHBOARD",
    view: DashboardPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  createAgency: {
    anon: false,
    path: "/super-admin/agencies/new",
    title: "Agency Contract",
    type: "PAGE_SUPER_ADMIN_CREATE_AGENCY",
    view: CreateAgencyPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  editAgency: {
    anon: false,
    path: "/super-admin/agencies/:id/edit",
    title: "Agency",
    type: "PAGE_SUPER_ADMIN_AGENCY_EDIT",
    view: EditAgencyPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  agency: {
    anon: false,
    path: "/super-admin/agencies/:id",
    title: "Agency",
    type: "PAGE_SUPER_ADMIN_AGENCY",
    view: AgencyPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  agencies: {
    anon: false,
    path: "/super-admin/agencies",
    title: "Agencies",
    type: "PAGE_SUPER_ADMIN_AGENCIES",
    view: AgenciesPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  createLab: {
    anon: false,
    path: "/super-admin/labs/create",
    title: "Lab Form",
    type: "PAGE_SUPER_ADMIN_LAB_FORM",
    view: LabsFormPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  editLab: {
    anon: false,
    path: "/super-admin/labs/:id",
    title: "Edit Lab",
    type: "PAGE_SUPER_ADMIN_EDIT_LAB",
    view: LabsFormPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  labs: {
    anon: false,
    path: "/super-admin/labs",
    title: "Labs",
    type: "PAGE_SUPER_ADMIN_LABS",
    view: LabsPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  appointmentDetails: {
    anon: false,
    path: "/super-admin/appointment/:id",
    title: "Appointment Details",
    type: "PAGE_SUPER_ADMIN_APPOINTMENT_DETAILS",
    view: AppointmentPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  appointments: {
    anon: false,
    path: "/super-admin/appointments",
    title: "Appointments",
    type: "PAGE_SUPER_ADMIN_APPOINTMENTS",
    view: SuperAdminAppointmentsPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  createDefaultRateCodes: {
    anon: false,
    path: "/super-admin/default-rate-codes/create",
    title: "Create Rate Code",
    type: "PAGE_SUPER_ADMIN_CREATE_RATE_CODE",
    view: DefaultRateCodePage,
    roles: [ROLES.SUPER_ADMIN],
  },
  editDefaultRateCode: {
    anon: false,
    path: "/super-admin/default-rate-codes/:id",
    title: "Edit Rate Code",
    type: "PAGE_SUPER_ADMIN_EDIT_RATE_CODE",
    view: DefaultRateCodePage,
    roles: [ROLES.SUPER_ADMIN],
  },
  defaultBundleFormPage: {
    anon: false,
    path: "/super-admin/default-bundles/create",
    title: "Default Bundles Form",
    type: "PAGE_SUPER_ADMIN_CREATE_BUNDLES",
    view: DefaultBundleFormPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  editDefaultBundle: {
    anon: false,
    path: "/super-admin/default-bundles/:id",
    title: "Edit Rate Code",
    type: "PAGE_SUPER_ADMIN_EDIT_DEFAULT_BUNDLE",
    view: DefaultBundleFormPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  defaultBundles: {
    anon: false,
    path: "/super-admin/default-bundles",
    title: "Default Bundles",
    type: "PAGE_SUPER_ADMIN_BUNDLES",
    view: DefaultBundlesPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  defaultRateCodes: {
    anon: false,
    path: "/super-admin/default-rate-codes",
    title: "Default Rate Codes",
    type: "PAGE_SUPER_ADMIN_RATE_CODES",
    view: DefaultRateCodesPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  caregiversPage: {
    anon: false,
    path: "/super-admin/caregivers",
    title: "Caregivers",
    type: "PAGE_SUPER_ADMIN_CAREGIVERS",
    view: SuperAdminCaregiversPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  editFacility: {
    anon: false,
    path: "/super-admin/facilities/:id/edit",
    title: "Facility",
    type: "PAGE_SUPER_ADMIN_EDIT_FACILITY",
    view: EditFacilityPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  createFacility: {
    anon: false,
    path: "/super-admin/facilities/create",
    title: "Create Medical Facility",
    type: "PAGE_SUPER_ADMIN_CREATE_FACILITY",
    view: CreateFacilityPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  facility: {
    anon: false,
    path: "/super-admin/facilities/:id",
    title: "Facility",
    type: "PAGE_SUPER_ADMIN_FACILITY",
    view: FacilityPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  facilities: {
    anon: false,
    path: "/super-admin/facilities",
    title: "Facilities",
    type: "PAGE_SUPER_ADMIN_FACILITIES",
    view: FacilitiesPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  createRegion: {
    anon: false,
    path: "/super-admin/regions/create",
    title: "Create Region",
    type: "PAGE_SUPER_ADMIN_REGION_ADD",
    view: CreateRegionPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  editRegion: {
    anon: false,
    path: "/super-admin/regions/:id",
    title: "Edit Region",
    type: "PAGE_SUPER_ADMIN_REGION_EDIT",
    view: CreateRegionPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  regions: {
    anon: false,
    path: "/super-admin/regions",
    title: "Regions",
    type: "PAGE_SUPER_ADMIN_REGIONS",
    view: RegionsPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  createUsers: {
    anon: false,
    path: "/super-admin/admin-users/create",
    title: "Create Users",
    type: "PAGE_SUPER_ADMIN_USERS_CREATE",
    view: UsersFormPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  allUsers: {
    anon: false,
    path: "/super-admin/all-users",
    title: "Users",
    type: "PAGE_SUPER_ADMIN_USERS",
    view: AllUsersPage,
    roles: [ROLES.SUPER_ADMIN],
  },
  adminUsers: {
    anon: false,
    path: "/super-admin/admin-users",
    title: "Users",
    type: "PAGE_SUPER_ADMIN_USERS",
    view: AdminUsersPage,
    roles: [ROLES.SUPER_ADMIN],
  },
};

export default SuperAdminPages;

export const SuperAdminArea = {
  pages: SuperAdminPages,
};
