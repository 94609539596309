import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ListsState {
  facilitiesList?: any[];
  agenciesList?: any[];
  facilityListLoading?: boolean;
}

const initialState: ListsState = {
  facilitiesList: [],
  agenciesList: [],
  facilityListLoading: false,
};

export const lists = {
  ...createSlice({
    name: "lists",
    initialState,
    reducers: {
      setFacilityList(state, { payload }: PayloadAction<any>) {
        state.facilitiesList = payload;
      },
      setAgenciesList(state, { payload }: PayloadAction<any>) {
        state.agenciesList = payload;
      },
      setFacilityListLoading(state, { payload }: PayloadAction<boolean>) {
        state.facilityListLoading = payload;
      },
    },
  }),
  persist: false,
};
