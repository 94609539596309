import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useInputDebounced } from "../../../lib";
import { useAction, agencyActions } from "../../../state";
import { useStyles } from "./CaregiversPage.styles";
import { SearchField } from "../../../components/inputs";
import { FilterButton } from "../../../components";
import PatientsTable from "../../../components/tables/PatientsTable";

const SuperAdminCaregiversPage = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);
  const setSearchQuery = useAction(agencyActions.setSearchQuery);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  return (
    <Box>
      <Typography variant="subtitle2">All Caregivers</Typography>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        classes={{ container: classes.filterRow }}
      >
        <Grid item xs={3}>
          <SearchField
            id="search"
            label="Search for caregiver id or name"
            value={searchQuery}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={9} container style={{ justifyContent: "flex-end" }}>
          <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
        </Grid>
      </Grid>

      <PatientsTable view={"global"} isFiltersVisible={expanded} entity={0} />
    </Box>
  );
};

export default React.memo(SuperAdminCaregiversPage);
