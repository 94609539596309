import React, { useCallback, useState } from "react";
import { Box, Tab, Tabs } from "@material-ui/core";
import TabPanel from "../TabPanel";
import ScheduledAppointmentsTabs from "../ScheduledAppointmentsTabs";
import CheckedInAppointmentsTable from "../../tables/CheckedInAppointmentsTable";
import { TABLE_ROW_UPLOAD_ACTION } from "../../../constants";
import { useStyles } from "./UpcomingAppointmentsTabs.styles";

const UpcomingAppointmentsTabs = () => {
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState("scheduled");
  const handleChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  return (
    <Box className={classes.container}>
      <Tabs indicatorColor="primary" value={selectedTab} onChange={handleChange}>
        <Tab
          id="tab-scheduled"
          disableRipple
          value="scheduled"
          label="Scheduled"
          className={classes.tab}
        />
        <Tab
          id="tab-checked-in"
          disableRipple
          value="checked-in"
          label="Checked In"
          className={classes.tab}
        />
      </Tabs>
      <TabPanel value={selectedTab} index="scheduled">
        <ScheduledAppointmentsTabs />
      </TabPanel>
      <TabPanel value={selectedTab} index="checked-in">
        <CheckedInAppointmentsTable actions={[TABLE_ROW_UPLOAD_ACTION]} />
      </TabPanel>
    </Box>
  );
};

export default UpcomingAppointmentsTabs;
