import { makeStyles, withStyles } from "@material-ui/core/styles";
import { AccordionSummary as MuiAccordionSummary } from "@material-ui/core";

export const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,

    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const useStyles = makeStyles((theme) => ({
  rateCodeSummary: {
    paddingLeft: 26,

    "& .MuiAccordionSummary-content": {
      flexDirection: "column",
    },
  },
  rateCode: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  code: {
    marginRight: 24,
  },
  cancelIcon: {
    width: 32,
    height: 32,
  },
  checkIcon: {
    width: 32,
    height: 32,
    color: "#76E269",
  },
  checkbox: {
    "&.MuiCheckbox-root": {
      color: theme.palette.primary.main,
    },
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  more: {},
}));
