import React from "react";
import Table from "../Table";
import { useSelector, agencySelectors, Entity } from "../../../state";
import UpdateAppointmentDetails from "./UpdateAppointmentDetails";

interface Props {
  id?: string;
  entity?: Entity;
  view: "agency" | "facility" | "super_admin";
  extensions?: any;
  isFiltersVisible: boolean;
  columns: any;
  tabView?: boolean;
  rowBaseUrl?: string;
  defaultSorting?: any;
}
// TODO add types and interfaces
const _UpdatesAppointmentsTable = (props: Props) => {
  const appointments = useSelector(agencySelectors.appointmentUpdates);

  return (
    <Table
      {...props}
      rows={appointments ?? []}
      columns={props.columns}
      loading={!appointments}
      withFilters={false}
      tableColumnExtensions={props.extensions}
      exportEnabled={true}
      exportedFileName="Appointment_Updates"
      withDetail={true}
      rowDetailComponent={UpdateAppointmentDetails}
    />
  );
};

export const UpdatesAppointmentsTable = React.memo(_UpdatesAppointmentsTable);
