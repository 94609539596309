import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppState } from "./types";

export function useTrue() {
  return [true];
}

// first overload for thunk action creators
function useAction<
  T extends (
    ...args: any[]
  ) => (dispatch: any, getState: () => AppState, extraArgument: any) => any
>(
  actionCreator: T,
  deps?: ReadonlyArray<unknown>
): T extends (...args: infer A) => (...args: any[]) => infer R
  ? (...args: A) => R
  : never
// second overload for regular action creators
function useAction<T extends (...args: any[]) => any>(
  actionCreator: T,
  deps?: ReadonlyArray<unknown>
): T
// lastly expect a regular action
function useAction<T extends { type: string }>(
  action: T,
  deps?: ReadonlyArray<unknown>
): () => T

/** Creates a callback function to dispatch the given action. */
function useAction<T extends Function>(action: T) {
  const dispatch = useDispatch();
  return useCallback((...args) => {
    dispatch(action(...args));
  }, [dispatch, action]);
}

export { useAction };
