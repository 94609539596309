import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";

import { AddButton } from "../../../../components/buttons";
import LabsTable from "../../../../components/tables/LabsTable";

import SuperAdminPages from "../../index";

import { useStyles } from "./LabsPage.styles";

// TODO add types and interfaces
export default function LabsPage() {
  const classes = useStyles();

  return (
    <Box>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item>
          <Typography variant="subtitle2">Labs</Typography>
        </Grid>

        <Grid item>
          <AddButton to={SuperAdminPages.createLab.path} />
        </Grid>
      </Grid>

      <LabsTable />
    </Box>
  );
}
