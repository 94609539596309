import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid, TextField } from "@material-ui/core";

import { Form, useFormik } from "../../../lib";
import { LABS_INITIAL_VALUES } from "../../../constants";
import { useAction, superAdminActions } from "../../../state";
import { CREATE_REGION_VALIDATION_SCHEMA as validationSchema } from "../../../utils";

import { useStyles } from "./LabForm.styles";

const LabForm = () => {
  const { id } = useParams();
  const history = useHistory();

  const saveLab = useAction(superAdminActions.saveLab);
  const getLabById = useAction(superAdminActions.getLabById);

  const classes = useStyles({});

  const [initialValues, setInitialValues] = useState(LABS_INITIAL_VALUES);

  useEffect(() => {
    (async () => {
      if (id) {
        const data:any = await getLabById(id);
        setInitialValues(data);
      }
    })();
  }, [id, getLabById]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    async onSubmit(values) {
      await saveLab(values);
      //TODO: check if successful
      history.push("/super-admin/labs");
    },
  });

  return (
    <Form form={form} className={classes.form}>
      <Grid container spacing={3} className={classes.end}>
        <Grid xs={6} item>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            name="name"
            label="Name"
            value={form.values.name}
            onChange={form.handleChange}
            error={form.touched.name && Boolean(form.errors.name)}
            helperText={form.touched.name && form.errors.name}
          />
        </Grid>
        <Grid xs={6} item>
          <TextField
            fullWidth
            variant="outlined"
            id="number"
            name="number"
            label="Number"
            value={form.values.number}
            onChange={form.handleChange}
            error={form.touched.number && Boolean(form.errors.number)}
            helperText={form.touched.number && form.errors.number}
          />
        </Grid>{" "}
        <Grid xs={12} className={classes.bottom} item>
          <TextField
            fullWidth
            variant="outlined"
            id="email"
            name="email"
            label="Email"
            value={form.values.email}
            onChange={form.handleChange}
            error={form.touched.email && Boolean(form.errors.email)}
            helperText={form.touched.email && form.errors.email}
          />
        </Grid>
        <Grid item className={classes.end}>
          <Button variant="contained" color="primary" size="large" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default React.memo(LabForm);
