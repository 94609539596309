import React, { useCallback, useState } from "react";
import { Box, Tab, Tabs } from "@material-ui/core";

import TabPanel from "../TabPanel";
import BillingHistoryTab from "../BillingHistoryTab";
import InvoicesTable from "../../tables/InvoicesTable";

import { useStyles } from "./BillingTabs.styles";
import ViewOnlyAgencyRateCodesTable from "../../tables/ViewOnlyAgencyRateCodesTable/ViewOnlyAgencyRateCodesTable";

interface Props {
  view: "super_admin" | "agency" | "facility";
  entity: any;
}
const BillingTabs = (props: Props) => {
  const { view, entity } = props;

  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState("history");

  const handleChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  return (
    <Box className={classes.container}>
      <Tabs centered value={selectedTab} onChange={handleChange}>
        <Tab
          value="history"
          label="History"
          id="tab-history"
          disableRipple
          className={classes.tab}
        />
        {/* {view === "agency" && (
          <Tab
            value="invoices"
            label="Invoices"
            id="tab-invoices"
            disableRipple
            className={classes.tab}
          />
        )} */}
        {view === "agency" && (
          <Tab
            value="contract"
            label="Rate Codes"
            id="tab-contract"
            disableRipple
            className={classes.tab}
          />
        )}
      </Tabs>
      <TabPanel value={selectedTab} index="history">
        <BillingHistoryTab view={view} />
      </TabPanel>
      <TabPanel value={selectedTab} index="invoices">
        <InvoicesTable view={view} entity={entity} />
      </TabPanel>
      <TabPanel value={selectedTab} index="contract">
        <ViewOnlyAgencyRateCodesTable agency={true} />
      </TabPanel>
    </Box>
  );
};

export default BillingTabs;
