import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tabs, Tab } from "@material-ui/core";

import UsersTable from "../../tables/UsersTable";
import TabPanel from "../TabPanel";
import AppointmentsTab from "../AppointmentsTab";
import BillingHistoryTab from "../BillingHistoryTab";

import {
  SUPER_ADMIN_FACILITY_APPOINTMENTS_TABLE_COLUMNS as columns,
  SUPER_ADMIN_FACILITY_APPOINTMENTS_TABLE_COLUMN_EXTENSIONS as extensions,
} from "../../../constants";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  tab: {
    color: "#000",

    "&.MuiTab-textColorInherit": {
      opacity: 0.5,

      "&.Mui-selected": {
        opacity: 1,
      },
    },
  },
}));

const FacilityTabs = ({ facility }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState("appointments");

  const handleChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  return (
    <Box className={classes.container}>
      <Tabs value={selectedTab} onChange={handleChange}>
        <Tab
          value="appointments"
          label="Appointments"
          id="tab-appointments"
          disableRipple
          className={classes.tab}
        />
        <Tab
          value="billing-history"
          label="Billing History"
          id="tab-billing-history"
          disableRipple
          className={classes.tab}
        />
        <Tab value="users" label="Users" id="tab-users" disableRipple className={classes.tab} />
      </Tabs>
      <TabPanel value={selectedTab} index="appointments">
        <AppointmentsTab
          view="super_admin"
          entity={facility}
          columns={columns}
          extensions={extensions}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index="billing-history">
        <BillingHistoryTab view="facility" />
      </TabPanel>
      <TabPanel value={selectedTab} index="users">
        <UsersTable view="facility" entity={facility} />
      </TabPanel>
    </Box>
  );
};

export default React.memo(FacilityTabs);
