import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import PatientsTable from "../../components/tables/PatientsTable";
import { AddButton, FilterButton } from "../../components/buttons";
import AgencyPages from "./index";
import { useRole } from "../../hooks";
import { useInputDebounced } from "../../lib";
import { agencyActions, useAction } from "../../state";
import { SearchField } from "../../components/inputs";

export const useStyles = makeStyles(
  (theme) => ({
    header: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  }),
  { classNamePrefix: "AgencyPatientsPage" },
);

// TODO add types and interfaces
const PatientsPage = () => {
  const classes = useStyles();
  const { isAgencyBiller } = useRole();

  const [expanded, setExpanded] = useState(false);

  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(agencyActions.setSearchQuery);
  const setEditablePatient = useAction(agencyActions.setEditablePatient);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  useEffect(() => {
    setEditablePatient(null);
  }, [setEditablePatient]);

  return (
    <Box>
      <Typography variant="subtitle2">Caregivers</Typography>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={4}>
          <SearchField
            id="search"
            label="Search for caregiver id or name"
            value={searchQuery}
            onChange={handleChange}
          />
        </Grid>

        <Grid item>
          <Grid container>
            <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
            {!isAgencyBiller && <AddButton to={AgencyPages.createPatient.path} />}
          </Grid>
        </Grid>
      </Grid>

      <PatientsTable view="agency" isFiltersVisible={expanded} />
    </Box>
  );
};

export default React.memo(PatientsPage);
