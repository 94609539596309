import React, { useEffect } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@material-ui/core";
import AgencyBundleTable from "../../tables/AgencyBundlesTable";
import { SearchIcon } from "../../icons";
import { agencyActions, useAction } from "../../../state";
import { useInputDebounced } from "../../../lib";

const AgencyBundleTab = () => {
  const setSearchQuery = useAction(agencyActions.setSearchQuery);
  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  return (
    <Box style={{ borderTop: "1px solid rgba(224, 224, 224, 1)" }}>
      <TextField
        size="small"
        id="search"
        label="Search"
        variant="outlined"
        style={{ width: 500, marginBottom: 16, marginTop: 16 }}
        value={searchQuery}
        // @ts-ignore
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton type="submit">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <AgencyBundleTable />
    </Box>
  );
};

export default React.memo(AgencyBundleTab);
