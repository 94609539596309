import React, { Fragment, useEffect } from "react";
import { Typography } from "@material-ui/core";

import Table from "../../Table";

import {
  APPOINTMENTS_BY_HOUR_TABLE_COLUMNS as columns,
  APPOINTMENTS_BY_HOUR_TABLE_COLUMN_EXTENSIONS as extensions,
} from "../../../../constants";
import { useAction, agencyActions, useSelector, agencySelectors, authSelectors } from "../../../../state";

import { useStyles } from "./AppointmentsTablesByHour.styles";

const AppointmentsTablesByHour = ({ selectedFilters, tableFiltersExtensions }) => {
  const classes = useStyles();

  const getAppointments = useAction(agencyActions.getAppointments);

  const searchQuery = useSelector(agencySelectors.searchQuery);
  const appointmentsByHour = useSelector(agencySelectors.appointmentsByHour);
  const activeEntity = useSelector(authSelectors.activeEntity);
  const view = activeEntity.type === "home_health_agency" ? "agency" : "facility";

  useEffect(() => {
    getAppointments(activeEntity.id, view);
  }, [activeEntity.id, getAppointments, view]);

  return (
    <>
      {Object.keys(appointmentsByHour).map((hour) => (
        <Fragment key={`appointment-${hour}`}>
          <Typography className={classes.hour}>{hour}</Typography>
          <Table
            rows={appointmentsByHour[hour]}
            columns={columns}
            searchValue={searchQuery}
            withFilters={true}
            selectedFilters={selectedFilters}
            tableColumnExtensions={extensions}
            tableFiltersExtensions={tableFiltersExtensions}
            withHeaderRow={false}
            withPagination={false}
          />
        </Fragment>
      ))}
    </>
  );
};

export default AppointmentsTablesByHour;
