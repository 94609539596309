import React from "react";
import { Box, Grid, Button, FormHelperText, Typography } from "@material-ui/core";

import FileUpload from "../FileUpload";
import RateCodesTable from "./RateCodesTable";

import { Form, useFormik } from "../../../lib";
import { useAction, agencyActions, useSelector, agencySelectors } from "../../../state";
import { CREATE_AGENCY_RATE_CODES_INITIAL_VALUES as initialValues } from "../../../constants";
import { CREATE_AGENCY_RATE_CODES_VALIDATION_SCHEMA as validationSchema } from "../../../utils";

import { useStyles } from "./ApplyRateCodesToAgency.styles";
import BundleChooser from "./BundleChooser";

const ApplyRateCodesToAgency = ({ activeStep, handleNext, handleBack }) => {
  const classes = useStyles();
  const agency = useSelector(agencySelectors.agency) || {};
  const rateCodes = useSelector(agencySelectors.rateCodes);
  const bundles = useSelector(agencySelectors.bundles);
  const updateAgencyRateCodes = useAction(agencyActions.updateAgencyRateCodes);
  // useEffect(() => {
  //   dispatch(agencyActions.getRateCodes(agency.id));
  // }, [agency]);
  const form = useFormik({
    initialValues: {
      ...initialValues,
      rateCodes,
      bundles,
    },
    validationSchema,
    async onSubmit(values) {
      await updateAgencyRateCodes({ id: agency.id, ...values });
      handleNext();
    },
  });

  return (
    <Box>
      <Form form={form} className={classes.form}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item className={classes.controlsWrap}>
            <header className={classes.header}>
              <Typography variant="h6">Bundles</Typography>
              <Typography>Setup the Bundles for this agency</Typography>
            </header>
            <BundleChooser form={form} />
            <header className={classes.header}>
              <Typography variant="h6">Rate codes</Typography>
              <Typography>Setup the rate codes, forms and charges for this agency</Typography>
            </header>
            <RateCodesTable form={form} />

            <Box className={classes.sectionTitle}>
              <Typography>Contract upload</Typography>
            </Box>

            <Box className={classes.fileUploadWrap}>
              <FileUpload
                form={form}
                name="contract"
                accept="application/pdf"
                uploadType="private"
              />
              {form.touched.contract && Boolean(form.errors.contract) && (
                <FormHelperText error variant="outlined">
                  {form.errors.contract}
                </FormHelperText>
              )}
            </Box>
            <Grid container justifyContent="flex-end" className={classes.formActions} spacing={4}>
              <Grid item>
                <Button variant="outlined" color="primary" size="large" onClick={handleBack}>
                  Back
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="large" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

export default ApplyRateCodesToAgency;
