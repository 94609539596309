import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

import Table from "../../../components/tables/Table";

import { AGENCY_VIEW_RATE_CODES_TABLE_COLUMNS as columns } from "../../../constants";
import {
  useAction,
  useSelector,
  agencyActions,
  agencySelectors,
  authSelectors,
} from "../../../state";

export const useStyles = makeStyles(
  (theme) => ({
    header: {
      paddingBottom: theme.spacing(3),
    },
  }),
  { classNamePrefix: "AgenciesRatePage" },
);

// TODO add types and interfaces
const RateCodesPage = () => {
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const classes = useStyles();
  const getRateCodes = useAction(agencyActions.getRateCodes);

  const rateCodes = useSelector(agencySelectors.rateCodes);

  useEffect(() => {
    getRateCodes(activeEntityId);
  }, [getRateCodes, activeEntityId]);

  return (
    <Box>
      <Grid
        container
        component="header"
        justify="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item>
          <Typography variant="h4" component="h1">
            Rate codes
          </Typography>
        </Grid>
      </Grid>

      <Table rows={rateCodes} columns={columns} />
    </Box>
  );
};

export default React.memo(RateCodesPage);
