import React from "react";
import { Chart, ArgumentAxis, ValueAxis } from "@devexpress/dx-react-chart-material-ui";

// TODO add types and interfaces
const ChartBase = ({ ...props }) => {
  return (
    <Chart {...props}>
      <ArgumentAxis />
      <ValueAxis />

      {props.series}
    </Chart>
  );
};

export default React.memo(ChartBase);
