import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import AppointmentsTable from "../../../components/tables/AppointmentsTable";
import {
  SUPER_ADMIN_ALL_APPOINTMENTS_TABLE_COLUMNS as superAdminColumns,
  SUPER_ADMIN_ALL_APPOINTMENTS_COLUMN_EXTENSIONS as extensions,
  SUPER_ADMIN_APPOINTMENTS_TABLE_SORTING as sortingOptions,
  SUPER_ADMIN_ALL_APPTS_DEFAULT_HIDDEN_COLUMNS as defaultHiddenColumns,
} from "../../../constants";
import { useInputDebounced } from "../../../lib";
import { useAction, agencyActions, superAdminActions } from "../../../state";
import { useStyles } from "./AppointmentsPage.styles";
import { SearchField } from "../../../components/inputs";
import { FilterButton } from "../../../components";
import ApptRateCodeDetails from "../../../components/tables/AppointmentsTable/ApptRateCodeDetails";

const SuperAdminAppointmentsPage = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);
  const setSearchQuery = useAction(agencyActions.setSearchQuery);
  const [selection, setSelection]: any = useState([]);
  const markAppointmentsAsReviewed = useAction(superAdminActions.markAppointmentsAsReviewed);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const handleMarkAsReviewed = useCallback(() => {
    markAppointmentsAsReviewed(selection);
    setSelection([]);
  }, [markAppointmentsAsReviewed, selection]);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  return (
    <Box>
      <Typography variant="subtitle2">All Appointments</Typography>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        classes={{ container: classes.filterRow }}
      >
        <Grid item xs={3}>
          <SearchField
            id="search"
            label="Search for caregiver id or name"
            value={searchQuery}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={9} container style={{ justifyContent: "flex-end" }}>
          <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
          <Button
            color="primary"
            variant="contained"
            disabled={!selection.length}
            onClick={handleMarkAsReviewed}
          >
            Mark as reviewed
          </Button>
        </Grid>
      </Grid>

      <AppointmentsTable
        view={"global"}
        tabView={false}
        isFiltersVisible={expanded}
        columns={superAdminColumns}
        extensions={extensions}
        selection={selection}
        onSelectionChange={setSelection}
        showSelection={true}
        showSuperAdminFilters={true}
        defaultSorting={sortingOptions}
        defaultHiddenColumnNames={defaultHiddenColumns}
        rowDetailComponent={ApptRateCodeDetails}
        withDetail={true}
      />
    </Box>
  );
};

export default React.memo(SuperAdminAppointmentsPage);
