import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@material-ui/core";

import SimpleTable from "../../SimpleTable";

import { useRole } from "../../../../hooks";
import { useAction, agencyActions } from "../../../../state";
import { APPOINTMENT_HISTORY_TABLE_COLUMNS } from "../../../../constants";

import { useStyles } from "./PatientDetail.styles";

const PatientDetail = ({ row }) => {
  const classes = useStyles();
  const history = useHistory();
  const { isFacilityRole, isAgencyBiller, isSuperAdmin } = useRole();

  const setAppointmentPatient = useAction(agencyActions.setAppointmentPatient);
  const setAppointmentExistingPatientID = useAction(agencyActions.setAppointmentExistingPatientID);

  const handleNewAppointment = useCallback(async () => {
    const patient_id = row.id;

    await setAppointmentExistingPatientID(patient_id);
    await setAppointmentPatient({
      ...row,
      patient_id,
      patientType: "existing",
      skipClear: true,
    });

    history.push("/agency/appointments/create");
  }, [row, setAppointmentExistingPatientID, setAppointmentPatient, history]);

  return (
    <Box className={classes.wrapper}>
      <Grid container justifyContent="space-between" component="header" className={classes.header}>
        <Typography variant="h6">Appointment history</Typography>

        {!isFacilityRole && !isAgencyBiller && !isSuperAdmin && (
          <Button variant="outlined" color="primary" onClick={handleNewAppointment}>
            New appointment
          </Button>
        )}
      </Grid>

      {Boolean(row.appointments.length) ? (
        <SimpleTable
          rows={row.appointments}
          columns={APPOINTMENT_HISTORY_TABLE_COLUMNS}
          rowBaseUrl={
            !isSuperAdmin &&
            !isAgencyBiller &&
            (isFacilityRole ? "/facility/appointments" : "/agency/appointments")
          }
        />
      ) : (
        <Typography align="center">No appointments yet</Typography>
      )}
    </Box>
  );
};

export default React.memo(PatientDetail);
