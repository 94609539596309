import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Chip, Collapse, Grid } from "@material-ui/core";
import Table from "../Table";
import BillingHistoryFilters from "./BillingHistoryFilters";

import {
  TABLE_ROW_VIEW_ACTION,
  BILLING_FILTERS,
  BILLING_FILTERS_EXTENSIONS,
  SUPER_ADMIN_AGENCY_BILLING_HISTORY_TABLE_COLUMNS,
  SUPER_ADMIN_AGENCY_BILLING_HISTORY_TABLE_COLUMN_EXTENSIONS,
} from "../../../constants";
import { history } from "../../../lib";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../state";

import { useStyles } from "./BillingHistoryTable.styles";

interface Props {
  view: "super_admin" | "agency" | "facility";
  entity: any;
  isFiltersVisible: any;
  tabView?: any;
}

// TODO add types and interfaces
const BillingHistoryTable = (props: Props) => {
  const { view, isFiltersVisible } = props;

  const classes = useStyles(props);
  const { id }: any = useParams();

  const getBilling = useAction(agencyActions.getBilling);

  const searchQuery = useSelector(agencySelectors.searchQuery);
  const billingHistory = useSelector(agencySelectors.billingHistory);

  const [selectedFilters, setSelectedFilters] = useState<any[]>(BILLING_FILTERS);
  const filtersChips = selectedFilters.flatMap((filter) => filter.value).filter(Boolean);
  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityId = activeEntity.type === "global" ? id : activeEntity.id;
  const activeEntityType = useSelector(authSelectors.activeEntityType);

  const handleRemoveFilter = useCallback(
    (status) => {
      const updatedFilters = selectedFilters.map((filter) => ({
        ...filter,
        value: filter.value.filter((filterValue) => status !== filterValue),
      }));
      setSelectedFilters(updatedFilters);
    },
    [selectedFilters],
  );

  const handleFilterChange = useCallback(
    (fieldName, value) => {
      const updatedFilters = selectedFilters.map((filter) =>
        filter.columnName === fieldName ? { ...filter, value } : filter,
      );
      setSelectedFilters(updatedFilters);
    },
    [selectedFilters],
  );

  useEffect(() => {
    getBilling(activeEntityId, view);
  }, [getBilling, view, activeEntityId]);

  const handleView = useCallback(
    (row) => {
      history.push(`/${activeEntityType}/appointments/${row.appointment_id}`);
    },
    [activeEntityType],
  );

  return (
    <>
      <Collapse in={isFiltersVisible} timeout="auto" unmountOnExit className={classes.container}>
        <Grid container>
          <BillingHistoryFilters
            selectedFilters={selectedFilters}
            handleFilterChange={handleFilterChange}
          />
        </Grid>
      </Collapse>

      {!!filtersChips.length && (
        <Grid container spacing={1} className={classes.container}>
          {filtersChips.map((filter, i) => (
            <Chip
              key={`selected-filter-${filter}-${i}`}
              variant="outlined"
              color="primary"
              onDelete={() => handleRemoveFilter(filter)}
              label={filter}
              className={classes.selectedFilter}
            />
          ))}
        </Grid>
      )}

      {!!billingHistory.length && (
        <Table
          {...props}
          rows={billingHistory}
          columns={SUPER_ADMIN_AGENCY_BILLING_HISTORY_TABLE_COLUMNS}
          searchValue={searchQuery}
          tableColumnExtensions={
            activeEntityType !== "super_admin" &&
            SUPER_ADMIN_AGENCY_BILLING_HISTORY_TABLE_COLUMN_EXTENSIONS
          }
          tableFiltersExtensions={BILLING_FILTERS_EXTENSIONS}
          viewHandler={handleView}
          actions={activeEntityType !== "super_admin" && [TABLE_ROW_VIEW_ACTION]}
          withFilters={true}
          selectedFilters={selectedFilters}
          exportEnabled={true}
          exportedFileName="Billing"
        />
      )}
    </>
  );
};

export default React.memo(BillingHistoryTable);
