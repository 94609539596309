import React, { useCallback, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Checkbox,
  TextField,
} from "@material-ui/core";
import {
  APPOINTMENT_STATUSES_STATES,
  APPOINTMENT_STATUSES_STATES_ARRAY,
} from "../../../../constants";
import { getStatusLabel } from "../../../tags/StatusTag";
import { useStyles } from "./AppointmentsFilters.styles";
import {
  listsActions,
  listsSelectors,
  superAdminActions,
  superAdminSelectors,
  useAction,
  useSelector,
} from "../../../../state";
import { DateRangePicker, DateRangeDelimiter, DateRange } from "@material-ui/pickers";

const AppointmentsFilters = ({
  selectedFilters,
  handleFilterChange,
  handleRemoveFilter,
  showSuperAdminFilters = false,
}) => {
  const classes = useStyles();
  const statusFilterValue = selectedFilters.find(
    (filter) => filter.columnName === "appointment_status_id",
  ).value;
  const facFilterValue = selectedFilters.find((filter) => filter.columnName === "facility")?.value;
  const agencyFilterValue = selectedFilters.find((filter) => filter.columnName === "agency")?.value;
  const reviewFilterValue = selectedFilters.find(
    (filter) => filter.columnName === "reviewed",
  )?.value;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedDate, handleDateChange] = useState<DateRange<Date>>([null, null]);

  const getFacilities = useAction(listsActions.getFacilitiesList);
  const getAppointments = useAction(superAdminActions.getAllAppointments);
  const setSuperAdminFilterDocs = useAction(superAdminActions.setSuperAdminFilterDocs);
  const superAdminFilterDocs = useSelector(superAdminSelectors.superAdminFilterDocs);
  const facilities = useSelector(listsSelectors.facilitiesList);

  const getAgencies = useAction(listsActions.getAgenciesList);
  const agencies = useSelector(listsSelectors.agenciesList);

  useEffect(() => {
    if (!!showSuperAdminFilters) {
      getFacilities();
      getAgencies();
    }
  }, [getFacilities, getAgencies, showSuperAdminFilters]);

  const handleStatusFilterChange = useCallback(
    ({ target: { value } }) => {
      handleFilterChange("appointment_status_id", value);
      setIsOpen(false);
    },
    [handleFilterChange],
  );
  const handleFacFilterChange = useCallback(
    ({ target: { value } }) => {
      handleFilterChange("facility", value);
      setIsOpen(false);
    },
    [handleFilterChange],
  );
  const handleAgencyFilterChange = useCallback(
    ({ target: { value } }) => {
      handleFilterChange("agency", value);
      setIsOpen(false);
    },
    [handleFilterChange],
  );

  const handleDateFilterChange = useCallback(
    (value) => {
      handleDateChange(value);
      handleFilterChange("datetime", value);
    },
    [handleFilterChange],
  );
  const handleReviewedFilterChange = useCallback(
    ({ target: { checked } }) => handleFilterChange("reviewed", checked),
    [handleFilterChange],
  );
  const handleHasDocsFilterChange = useCallback(
    async ({ target: { checked } }) => {
      await setSuperAdminFilterDocs(checked);
      getAppointments();
    },
    [getAppointments, setSuperAdminFilterDocs],
  );

  return (
    <>
      <FormControl variant="outlined" className={classes.statusFormControl} size="small">
        <InputLabel id="latest-appointment-status-label">Appointment status</InputLabel>
        <Select
          multiple
          id="appointment-status"
          label="Appointment status"
          labelId="appointment-status-label"
          value={statusFilterValue}
          onChange={handleStatusFilterChange}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          renderValue={(selected: any) =>
            selected.map((selectedFilter, i) => (
              <Chip
                key={`selected-filter-${selectedFilter}-${i}`}
                size="small"
                variant="outlined"
                color="primary"
                label={getStatusLabel(selectedFilter)}
                style={{ marginRight: 8 }}
                onDelete={() => handleRemoveFilter(selectedFilter, "appointment_status_id")}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            ))
          }
        >
          {APPOINTMENT_STATUSES_STATES_ARRAY.map((status, i) => (
            <MenuItem
              key={`statuses-${APPOINTMENT_STATUSES_STATES[status]}-${i}`}
              value={APPOINTMENT_STATUSES_STATES[status]}
            >
              {getStatusLabel(APPOINTMENT_STATUSES_STATES[status])}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {!!showSuperAdminFilters && (
        <>
          <FormControl size="small" className={classes.statusFormControl}>
            <DateRangePicker
              startText="From Date"
              endText="To Date"
              inputFormat="MM/dd/yyyy"
              label="From Date"
              value={selectedDate}
              onChange={handleDateFilterChange}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField {...startProps} size="small" />
                  <DateRangeDelimiter> to </DateRangeDelimiter>
                  <TextField {...endProps} size="small" />
                </React.Fragment>
              )}
            />
          </FormControl>
          <FormControl variant="outlined" className={classes.statusFormControl} size="small">
            <InputLabel id="facility-label">Agency scheduled by</InputLabel>
            <Select
              id="agency"
              label="Agency scheduled by"
              labelId="agency-label"
              value={agencyFilterValue}
              onChange={handleAgencyFilterChange}
            >
              <MenuItem value="">ALL</MenuItem>
              {agencies?.map((type) => (
                <MenuItem key={type?.id} value={type?.name}>
                  {type?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.statusFormControl} size="small">
            <InputLabel id="facility-label">Facility scheduled at</InputLabel>
            <Select
              id="facility"
              label="Facility scheduled at"
              labelId="facility-label"
              value={facFilterValue}
              onChange={handleFacFilterChange}
            >
              <MenuItem value="">ALL</MenuItem>
              {facilities?.map((type) => (
                <MenuItem key={type?.id} value={type?.name}>
                  {type?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingBottom: 24,
            }}
            size="small"
          >
            <Checkbox checked={reviewFilterValue} onChange={handleReviewedFilterChange} /> Reviewed
          </FormControl>

          <FormControl
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              paddingBottom: 24,
            }}
            size="small"
          >
            <Checkbox checked={superAdminFilterDocs} onChange={handleHasDocsFilterChange} /> Has
            pending documents
          </FormControl>
        </>
      )}
    </>
  );
};

export default React.memo(AppointmentsFilters);
