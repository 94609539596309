import {
  format,
  startOfWeek,
  endOfWeek,
  startOfToday,
  endOfToday,
  eachDayOfInterval,
  eachHourOfInterval,
  add,
} from "date-fns";
import { formatToTime, formatToFullDayOfWeek, formatToShortDayOfWeek } from "../utils";

export const APPOINTMENT_STATUSES_STATES = {
  SCHEDULED: 1,
  CHECKED_IN: 2,
  PROVIDED: 3,
  COMPLETED: 4,
  CANCELED: 5,
  MISSED: 6,
  UNSCHEDULED: 7,
};

export const RATE_CODE_STATUSES_STATES = {
  SCHEDULED: 1,
  PENDING: 2,
  PROVIDED: 3,
  COMPLETED: 5,
  CANCELED: 4,
};

export const APPOINTMENT_STATUSES_STATES_ARRAY = Object.keys(APPOINTMENT_STATUSES_STATES);

export const STATUSES_AVAILABILITY = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
  TRUE: true,
  FALSE: false,
};

// export const TYPES = {
//   INSURED: "Insured",
//   UNINSURED: "Uninsured",
// };

// export const TYPES_ARRAY = Object.keys(TYPES);

export const CREATE_APPOINTMENT_STEPS = ["Caregiver", "Services", "Location & Date"];

export const APPOINTMENT_STEPS = [
  "Scheduled",
  "Checked in",
  "Updates in progress",
  "Updates Completed",
];

export const APPOINTMENT_MISSED_APPOINTMENT_STEPS = ["Scheduled", "Canceled appointment"];

export const APPOINTMENT_MISSED_SERVICES_STEPS = ["Scheduled", "Checked in", "Missing Services"];

export const US_STATES = [
  {
    label: "New Jersey",
    value: "NJ",
  },
  {
    label: "New York",
    value: "NY",
  },
];

export const WEEK_DAYS = eachDayOfInterval({
  start: startOfWeek(new Date()),
  end: endOfWeek(new Date()),
}).map((day) => ({
  label: formatToFullDayOfWeek(day).toString(),
  value: formatToShortDayOfWeek(day).toString().toLowerCase(),
}));

interface Time {
  label: string;
  value: string | number;
}

export const DAY_HOURS = eachHourOfInterval({
  start: startOfToday(),
  end: endOfToday(),
}).map((hour) => {
  const options: Time = {
    label: formatToTime(hour),
    value: format(hour, "HH"),
  };
  return options;
});

const closed: Time = {
  label: "Closed",
  value: "",
};

DAY_HOURS.unshift(closed);

export const TODAY = format(new Date(), "EEEE, MMMM d, yyyy");
export const TOMORROW = format(add(new Date(), { days: 1 }), "EEEE, MMMM d, yyyy");
