import React, { useState, useCallback } from "react";
import { Box, Stepper, Step, StepLabel } from "@material-ui/core";
import {
  AppointmentPatient,
  AppointmentServices,
  AppointmentTime,
  Review,
} from "../../forms/CreateAppointmentForms";
import {
  agencyActions,
  authSelectors,
  facilityActions,
  useAction,
  useSelector,
} from "../../../state";
import { CREATE_APPOINTMENT_STEPS } from "../../../constants";
import { useStyles } from "./CreateAppointmentStepper.styles";
import { useOnMount, useLocation } from "../../../lib";

function getStepContent(props) {
  switch (props.activeStep) {
    case 0:
      return <AppointmentPatient {...props} />;
    case 1:
      return <AppointmentServices {...props} />;
    case 2:
      return <AppointmentTime {...props} />;
    default:
      return "Unknown step";
  }
}
interface CreateAppointmentStepperProps {
  entityType: string;
}
const CreateAppointmentStepper = (props: CreateAppointmentStepperProps) => {
  const classes = useStyles();
  const location = useLocation();
  const setAppointmentPatient = useAction(agencyActions.setAppointmentPatient);
  const saveAppointmentServices = useAction(agencyActions.saveAppointmentServices);
  const setAppointmentConfirmed = useAction(agencyActions.setAppointmentConfirmed);
  const setConfirmedAppointment = useAction(agencyActions.setConfirmedAppointment);
  const resetActiveAppointment = useAction(agencyActions.resetActiveAppointment);
  //For Facility followup
  const getPatientByAppointment = useAction(facilityActions.getPatientByAppointment);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  const [activeStep, setActiveStep] = useState(0);
  useOnMount(() => {
    (async () => {
      if (props.entityType === "facility") {
        setActiveStep(1);

        await getPatientByAppointment(activeEntityId, location.params.originalAppointmentId);
      }
    })();
  });

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const handleReset = useCallback(() => {
    setAppointmentPatient(null);
    saveAppointmentServices(null);
    setAppointmentConfirmed(false);
    setConfirmedAppointment(null);
    resetActiveAppointment();
    setActiveStep(0);
  }, [
    setAppointmentPatient,
    saveAppointmentServices,
    setAppointmentConfirmed,
    setConfirmedAppointment,
    resetActiveAppointment,
  ]);

  const isCompleted = activeStep === CREATE_APPOINTMENT_STEPS.length;
  const stepProps = {
    activeStep,
    handleNext,
    handleBack,
    handleReset,
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {CREATE_APPOINTMENT_STEPS.map((label) => (
          <Step className={classes.stepperLabel} key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>{!isCompleted ? getStepContent(stepProps) : <Review {...stepProps} />}</Box>
    </div>
  );
};

export default React.memo(CreateAppointmentStepper);
