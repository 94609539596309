import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddButton } from "../../../components/buttons";
import SuperAdminPages from "../index";
import { superAdminActions } from "../../../state";
import DefaultRateCodesTable from "../../../components/tables/DefaultRateCodesTable";

export const useStyles = makeStyles(
  (theme) => ({
    header: {
      paddingBottom: theme.spacing(3),
    },
  }),
  { classNamePrefix: "SuperAdminAgenciesPage" },
);

// TODO add types and interfaces
export default function DefaultRateCodesPage() {
  const classes = useStyles();
  const history = useHistory();

  const handleEdit = useCallback(
    (row) => {
      history.push(SuperAdminPages.editDefaultRateCode.path.replace(":id", row.id));
    },
    [history],
  );

  const handleDelete = useCallback(async (row) => {
    await superAdminActions.deleteDefaultRateCode(row.id);
  }, []);

  return (
    <Box>
      <Grid
        container
        component="header"
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item>
          <Typography variant="subtitle2">Default Rate codes</Typography>
        </Grid>
        <Grid item>
          <AddButton to={SuperAdminPages.createDefaultRateCodes.path} />
        </Grid>
      </Grid>

      <DefaultRateCodesTable editHandler={handleEdit} deleteHandler={handleDelete} />
    </Box>
  );
}
