import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useAction, agencyActions, useSelector, authSelectors } from "../../../state";
import { useStyles } from "./UpdatesPage.styles";
import { UpdatesAppointmentsTable } from "../../../components/tables/AppointmentsTable";
import {
  UPDATES_APPOINTMENTS_TABLE_COLUMNS,
  UPDATES_APPOINTMENTS_TABLE_EXTENSIONS as extensions,
} from "../../../constants";
import { useLocation, history } from "../../../lib";
import ToggleButton from "../../../components/buttons";
import { SearchField } from "../../../components/inputs";

const UpdatesPage = () => {
  const classes = useStyles();
  const location = useLocation();
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const [updatesType, setUpdatesType] = useState("expected-updates");
  const [query, setQuery] = useState("");
  const getAppointmentUpdates = useAction(agencyActions.getAppointmentUpdates);

  const handleToggleChange = useCallback((event) => {
    if (!event) {
      return;
    }
    setUpdatesType(event);
  }, []);

  useEffect(() => {
    history.push(`${location.pathname}?q=${query}&updatesType=${updatesType}`);
  }, [location.pathname, query, updatesType]);

  useEffect(() => {
    location.query.updatesType &&
      getAppointmentUpdates(activeEntityId, location.query.q, location.query.updatesType);
  }, [activeEntityId, getAppointmentUpdates, location.query.q, location.query.updatesType]);

  return (
    <Box>
      <Typography variant="subtitle2">Updates</Typography>
      <Grid container alignItems="center" classes={{ container: classes.header }} spacing={3}>
        <Grid item xs={5}>
          <SearchField
            id="search"
            label="Search for caregiver id or name"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <ToggleButton
            onChange={handleToggleChange}
            value={updatesType}
            options={[
              { label: "Canceled", value: "canceled" },
              { label: "In progress", value: "expected-updates", class: "pending" },
              { label: "Completed", value: "updates", class: "completed" },
            ]}
          />
        </Grid>
      </Grid>
      <UpdatesAppointmentsTable
        extensions={extensions}
        view="agency"
        columns={UPDATES_APPOINTMENTS_TABLE_COLUMNS}
        isFiltersVisible={false}
      />
    </Box>
  );
};

export default UpdatesPage;
