import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    minWidth: 400,
    maxWidth: 1000,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    zIndex: theme.zIndex.modal + 200,
    overflow: "auto",
    maxHeight: "95vh",
  },
}));
