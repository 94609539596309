import React from "react";
import { Box, Typography } from "@material-ui/core";

import BillingTabs from "../../components/tabs/BillingTabs";
import { authSelectors, useSelector } from "../../state";

const BillingPage = () => {
  const activeEntity = useSelector(authSelectors.activeEntity);
  return (
    <Box>
      <Typography variant="subtitle2">Billing</Typography>
      <BillingTabs view="facility" entity={activeEntity} />
    </Box>
  );
};

export default BillingPage;
