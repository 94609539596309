import React from "react";
import { FormHelperText, Table, TableBody, TableContainer, Typography } from "@material-ui/core";
import { useStyles } from "./RateCodesTable/RateCodesTable.styles";
import BundleRow from "./BundleRow";

const BundleChooser = ({ form }) => {
  const bundles = form.values.bundles;

  const error =
    form.touched.bundles && Boolean(form.errors.bundles) && typeof form.errors.bundles === "string";
  const classes = useStyles({ error });
  return !!bundles?.length ? (
    <TableContainer className={classes.root}>
      <Table className={classes.table}>
        <TableBody>
          {bundles?.map((code, index) => (
            <BundleRow
              key={`bundle-${code.id}`}
              index={index}
              form={form}
              code={code}
              error={error}
            />
          ))}
        </TableBody>
      </Table>

      {error && (
        <FormHelperText error variant="outlined">
          {form.errors.bundles}
        </FormHelperText>
      )}
    </TableContainer>
  ) : (
    <Typography className={classes.noRateCodes} align="center">
      No Bundles here yet.
    </Typography>
  );
};
export default BundleChooser;
