import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import qs from "query-string";
import { KEY_PREFIX } from "redux-persist";
// Local
import { REACT_APP_API_URL, REACT_APP_PERSIST_KEY } from "../../config";
import { auth, AuthState } from "./state";
import { AppThunk, Entity } from "../types";
import { uiActions } from "../ui/actions";
import { USERS_API } from "../../constants";
import { setUser } from "../../components";
import { Navigation } from "../../lib";
import { dashboardPath } from "../../pages/auth/LoginPage";

const { actions } = auth;

interface AuthResponse {
  user?: {
    id: number;
    roles: string[];
    email: string;
    entities: Entity[];
  };
  token?: string;
  id?: number;
}

export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: AuthResponse): AppThunk {
    let authStateFromResponse: AuthState;
    if (authResponseData) {
      const { user, token } = authResponseData;
      if (user) {
        authStateFromResponse = {
          userId: user.id,
          roles: user.roles,
          token,
          userName: user.email,
          entities: user.entities,
          activeEntityId: +user.entities[0].id,
          activeRole: user.entities[0].entity_roles[0],
        };
      }
    }
    return async (dispatch, getState) => {
      let authState: AuthState;
      if (authStateFromResponse) {
        authState = authStateFromResponse;
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState);
    };
  },
  login(values: { email: string; password: string }): AppThunk<any> {
    return async (dispatch) => {
      const {
        data: { user, token },
      } = await axios
        .post("/auth/login", values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        })
        .catch((error) => {
          console.error(error);
          dispatch(uiActions.showError("Login Failed"));
          throw new Error("invalid login");
        });
      setUser({ email: user?.email, id: user?.id });

      const authState: AuthState = {
        userId: user.id,
        roles: user.roles,
        token,
        userName: user.email,
        user,
        entities: user.entities,
        activeEntityId: +user.entities[0].id,
        activeRole: user.entities[0].entity_roles[0],
      };
      createApiClient(authState);
      dispatch(actions.setAuthState(authState));
      const query = qs.parse(window.location.search) ?? {};
      const after = query.after ?? "";
      if (Boolean(user) && Boolean(Object.keys(user).length)) {
        Navigation.go(after || dashboardPath(user.roles));
      }
    };
  },
  logout() {
    return async (dispatch) => {
      return logout(dispatch);
    };
  },
  recoverPassword(values: { email: string }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      await axios.post("/auth/password/forgot", values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      await logout(dispatch);
    };
  },
  resetPassword(values: {
    newPassword: string;
    password1: string;
    password2: string;
    token: string;
  }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      try {
        await axios.put("/auth/password/reset", values, {
          baseURL: REACT_APP_API_URL,
          headers: { "Content-Type": "application/json" },
        });
        await logout(dispatch);
        dispatch(uiActions.showSuccess("Password reset"));
      } catch (e) {
        dispatch(uiActions.showError("Password reset failed " + e.res.message));
      }
    };
  },
  confirmAccount(values: { email: string; newPassword: string; token: string }): AppThunk {
    return async (dispatch) => {
      await axios.put("/auth/confirm-account", values, {
        baseURL: REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
      });
      // dispatch(actions.setAuthState(undefined));
    };
  },
  updateProfile(values: { first_name: string; last_name: string }): AppThunk {
    return async (dispatch) => {
      const {
        data: { user },
      } = await authClient.put(`${USERS_API}/me`, values);

      const authState: AuthState = {
        userId: user.id,
        roles: user.roles,
        userName: user.email,
        user,
        entities: user.entities,
        activeEntityId: +user.entities[0].id,
        activeRole: user.entities[0].entity_roles[0],
      };
      dispatch(actions.setAuthState(authState));
    };
  },
  changePassword(values: { oldPassword: string; newPassword: string }): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        const { status } = await authClient.post("/auth/password/change", values);
        dispatch(uiActions.setLoading(false));
        if (status === 200) {
          dispatch(uiActions.showSuccess("Password successfully changed!"));
        } else {
          dispatch(uiActions.showError("Password change failed"));
        }
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Password change failed " + e.res.message));
        throw e;
      }
    };
  },
  impersonate(entityId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      try {
        const { status, data } = await authClient.get("/entity/" + entityId + "/impersonate");
        if (status === 200) {
          const authState: AuthState = {
            userId: data.user.id,
            roles: data.user.roles,
            token: data.token,
            userName: data.user.email,
            user: data.user,
            entities: [data.entity],
            activeEntityId: +entityId,
            activeRole: data.user.entities[0].entity_roles[0],
          };
          if (data.user.roles[0].entity_type === "medical_facility") {
            Navigation.go("/facility/upcoming-appointments");
          } else if (data.user.roles[0].entity_type === "home_health_agency") {
            Navigation.go("/agency/dashboard");
          }
          //TODO: handle for else case
          createApiClient(authState);
          dispatch(actions.setImpersonateAuthState(authState));
          dispatch(uiActions.setLoading(false));
        } else {
          dispatch(uiActions.setLoading(false));
          dispatch(uiActions.showError("Login impersonate mode failed - " + data.message));
        }
      } catch (e) {
        dispatch(uiActions.setLoading(false));
        dispatch(uiActions.showError("Login impersonate mode failed - " + e.res.message));
        throw e;
      }
    };
  },
  exitImpersonateMode(): AppThunk {
    return async (dispatch, getState) => {
      const oldAuth = getState().auth.oldAuth;
      createApiClient(oldAuth);
      dispatch(actions.exitImpersonateAuthState());
      Navigation.go("/super-admin/dashboard");
    };
  },
};

/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.delete(url, config));
  },
  download(url: string, fileName: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(
      apiClient
        .get(url, { method: "GET", responseType: "blob", ...config })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
          return response;
        })
        .catch((e) => {
          console.error(e);
          return e;
        }),
    );
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.get<T>(url, config));
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.post<T>(url, data, config));
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return handleAuthResponse(apiClient.put<T>(url, data, config));
  },
};

/** Connection used to make authorized, authenticated API calls. */
let apiClient: AxiosInstance;

function createApiClient(state: AuthState) {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_API_URL,
    headers: {},
  };
  if (state && state.token) {
    config.headers.Authorization = `Bearer ${state.token}`;
  }
  config.headers["Content-Type"] = "application/json";
  apiClient = axios.create(config);
}

/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  promise = promise.catch((err) => {
    error = err;
    console.error({ err });
    return err.response;
  });
  const res = await promise;
  if (error) {
    if (res) {
      const status = res.status;
      if (status === 401) {
        redirectToLogin();
        // } else if (status === 403) {
        //   redirectToHomepage();
      }
    }
  }
  return res;
}
export function redirectToHomepage() {
  window.location.replace("/");
}

export function redirectToLogin() {
  window.location.replace(
    "/auth/login?after=" + encodeURIComponent(window.location.pathname + window.location.search),
  );
}

function logout(dispatch): Promise<void> {
  return new Promise((resolve, reject) => {
    // NOTE: We could do  window.localStorage.clear(); but other JS might be
    // using localStorage, so just remove the key that our Redux app saves.
    window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
    dispatch(actions.setAuthState(undefined));
    resolve();
  });
}

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
function serializeParams(params: Record<string, any>) {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }
  const formattedParams = {};
  const keys = Object.keys(params);
  const { length } = keys;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Object.prototype.toString.call(value) === "[object Date]") {
      // Format Dates...
      value = value.toISOString();
    } else if (value !== null && typeof value === "object") {
      // Format objects and arrays...
      value = JSON.stringify(value);
    }
    formattedParams[key] = value;
  }
  // URLSearchParams does not handle arrays...
  // return new URLSearchParams(formattedParams).toString();
  return qs.stringify(formattedParams);
}

axios.defaults.paramsSerializer = serializeParams;

// #region Types

/** Return value for API call wrappers. */
// eslint-disable-next-line
type ApiCall<T = any> = AppThunk<Promise<AxiosResponse<T>>>;

// #endregion
