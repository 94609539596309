import React, { useCallback, useState } from "react";
import { Button, Card, Grid, Typography } from "@material-ui/core";
import { useRole } from "../../../../../hooks";

import {
  agencyActions,
  agencySelectors,
  authSelectors,
  useAction,
  useDispatch,
  useSelector,
} from "../../../../../state";
import { useStyles } from "./AppointmentSupportingDocs.styles";
import { CloudUploadOutlinedIcon } from "../../../../../components";
import { Form, useFormik } from "../../../../../lib";
import Dropzone from "../../../../../components/forms/FileUpload/Dropzone";
import FileUploadItem from "../../../../../components/forms/FileUpload/FileUploadItem";

const AppointmentSupportingDocs = () => {
  const classes = useStyles();
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const supporting_docs = useSelector(agencySelectors.supportingDocs);
  const [uploadExpanded, setUploadExpanded] = useState(false);
  const deleteFile = useAction(agencyActions.deleteSupportingDoc);
  const downloadFile = useAction(agencyActions.downloadSupportingDoc);
  const entity_id = useSelector(authSelectors.activeEntityId);
  const view = useSelector(authSelectors.activeEntityType);
  const { isFacilityRole } = useRole();
  const dispatch = useDispatch();

  const handleButtonClick = useCallback(() => {
    setUploadExpanded(!uploadExpanded);
  }, [uploadExpanded]);

  const handleDelete = useCallback(
    (fileId) => {
      deleteFile(entity_id, openedAppointment.id, fileId, view);
    },
    [deleteFile, entity_id, openedAppointment.id, view],
  );

  const form = useFormik({
    initialValues: {
      filenames_id: "",
    },
    async onSubmit() {},
  });

  const handleSupportingDocsUpload = useCallback(
    async ({ fileNames }) => {
      const success = await dispatch(
        agencyActions.uploadSupportingDocuments(entity_id, openedAppointment.id, fileNames, view),
      );
      success && setUploadExpanded(false);
    },
    [dispatch, entity_id, openedAppointment.id, view],
  );

  return (
    <Form form={form}>
      <Card variant="outlined" className={classes.root}>
        <Grid
          container
          component="header"
          alignItems="center"
          justifyContent="space-between"
          className={classes.titleContainer}
        >
          <Grid item>
            <Typography className={classes.title}>Supporting Documents</Typography>
            {!isFacilityRole && (
              <Typography className={classes.subtitle}>
                Please provide any supporting documentation for this patient
              </Typography>
            )}
          </Grid>
          {!isFacilityRole && (
            <Grid item>
              <Button
                color="primary"
                size="small"
                onClick={handleButtonClick}
                className={classes.btn}
              >
                <CloudUploadOutlinedIcon />
                <Typography> Upload Document</Typography>
              </Button>
            </Grid>
          )}
          {uploadExpanded && !isFacilityRole && (
            <Grid item xs={12}>
              <Dropzone
                maxFiles={0}
                accept="application/pdf"
                name="filenames_id"
                uploadType="private"
                form={form}
                onUpload={handleSupportingDocsUpload}
                id={openedAppointment.id}
                multiple={true}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            {!!supporting_docs.length ? (
              <>
                {supporting_docs.map((file) => (
                  <FileUploadItem
                    key={file?.filename_id}
                    primaryText={file.filename?.name}
                    downloadEnabled={true}
                    deleteEnabled={!isFacilityRole}
                    background={false}
                    downloadHandler={() =>
                      downloadFile(entity_id, openedAppointment.id, file.id, view)
                    }
                    deleteHandler={() => handleDelete(file.id)}
                  />
                ))}
              </>
            ) : (
              <>
                {isFacilityRole && (
                  <Typography className={classes.subtitle}>No documents to display</Typography>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </Form>
  );
};

export default AppointmentSupportingDocs;
