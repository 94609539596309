import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    position: "relative",
    paddingTop: "2em",
  },
  sectionTitle: {
    padding: "0 2em 1em",

    "& .MuiTypography-root": {
      fontSize: "1.25em",
      fontWeight: 500,
    },
  },
  controlsWrap: {
    width: "65vw",
  },
  formControl: {
    maxWidth: "50%",
    padding: "0 2em 2em",
    flexGrow: 1,
  },
  state: {
    width: 150,
  },
  address: {
    maxWidth: "100%",
  },
  switcherWrap: {
    width: "50%",
    minWidth: 330,
    flexGrow: "initial",
  },
  switcherGroup: {
    width: "100%",
  },
  switcher: {
    width: "50%",
    textTransform: "initial",
    borderColor: (props: any) =>
      props.isStatusError ? theme.palette.error.main : "rgba(0, 0, 0, 0.23)",

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  select: {
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  hoursTitle: {
    display: "flex",
    alignItems: "center",
    maxWidth: 180,
  },
  hours: {
    maxWidth: 200,
  },
  fileUploadWrap: {
    padding: "0 2em 1em",
  },
  formActions: {
    marginTop: 64,
    borderTop: "1px solid #E0E0E0",
    padding: "0 2em",
  },
}));
