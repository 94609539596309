import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";

import { dashboardPath } from "../../auth/LoginPage";
import { authSelectors, useSelector } from "../../../state";

export function HomePage() {
  const roles = useSelector(authSelectors.roles);
  const history = useHistory();

  useEffect(() => {
    if (!roles.length) return;
    const dashPath = dashboardPath(roles);
    history.push(dashPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);

  return <Box></Box>;
}
