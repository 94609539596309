import React from "react";
import { RootStateOrAny } from "react-redux";

import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Snackbar } from "@material-ui/core";

import { uiActions, useAction, useSelector } from "../state";

const Notification = () => {
  const notification = useSelector((state: RootStateOrAny) => state.ui.notification);
  const hideNotification = useAction(uiActions.hideNotification);

  return (
    <Snackbar
      open={!!notification}
      onClose={hideNotification}
      autoHideDuration={notification?.duration}
      message={notification?.message}
    >
      {notification?.variant && (
        <Alert
          severity={notification.variant}
          action={
            <IconButton size="small" color="inherit" onClick={hideNotification}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {notification.message}
        </Alert>
      )}
    </Snackbar>
  );
};

export default Notification;
