import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";

import { ArrowBackIcon } from "../../../components/icons";
import ProfileForm from "../../../components/forms/Profile/ProfileForm";

import SuperAdminPages from "..";
import { RouterLink } from "../../../lib";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
  },
  bottom: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
    margin: 0,
  },
}));

const UsersFormPage = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton component={RouterLink} to={SuperAdminPages.adminUsers.path}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            Create User
          </Typography>
        </Grid>
      </Grid>

      <ProfileForm isNewUser={true} />
    </>
  );
};

export default UsersFormPage;
