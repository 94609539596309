import React from "react";
import { Grid } from "@material-ui/core";
import { DescriptionIcon } from "../icons";

export const InvoiceLink = ({ location, filename }) => {
  return (
    <Grid container direction="row" alignItems="center">
      <DescriptionIcon />
      {filename.name}
    </Grid>
  );
};

export const renderInvoiceLink = (row) => <InvoiceLink {...row} />;
