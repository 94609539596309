import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    header: {
      paddingBottom: theme.spacing(3),
    },
  }),
  { classNamePrefix: "FacilityUsersPage" },
);
