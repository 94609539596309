import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: 20,
  },
  controlsWrap: {
    width: "65vw",
  },
  formActions: {
    marginTop: 36,
    borderTop: "1px solid #E0E0E0",
    padding: "0 32px",
  },
  services: {
    padding: "32px 32px 0",
  },
  servicesTitle: {
    marginBottom: "12px",
  },
  servicesGroup: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
  },
  service: {
    // TODO figure out another way to override css props
    minWidth: `calc(24% - ${theme.spacing(4)}px)`,
    textTransform: "initial",
    padding: `${theme.spacing(4)}px !important`,
    marginLeft: `${theme.spacing(2)}px !important`,
    marginRight: `${theme.spacing(2)}px !important`,
    marginBottom: `${theme.spacing(4)}px !important`,
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    borderColor: (props: any) =>
      props.isServicesError ? `${theme.palette.error.main} !important` : "",
    borderRadius: "4px !important",

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));
