import React from "react";
import { Grid, Button, Typography, makeStyles } from "@material-ui/core";
import Modal from "./Modal";
import { useSelector, superAdminSelectors } from "../../state";

export const useStyles = makeStyles((theme) => ({
  title: {
    color: "#000000",
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
    marginBottom: 20,
  },
  text: {
    color: "#000000",
    fontSize: 16,
    letterSpacing: 0,
    maxWidth: 800,
    marginBottom: 10,
  },
}));

const DeactivateBundleRateCodeConfirmationModal = (props) => {
  const { open, setOpen, setRateCodeUpdate, setCancelUpdate, form } = props;
  const classes = useStyles();
  const bundleForRateCodes = useSelector(superAdminSelectors.bundlesForRateCodes);

  return (
    <Modal open={open} handleClose={() => setOpen(false)}>
      <Typography className={classes.title}>Deactivate Rate Code?</Typography>
      <Typography className={classes.text}>
        You are deactivating a rate code that's part of the following active bundles:
      </Typography>
      <ul>
        {bundleForRateCodes.map((obj) => (
          <li>{obj.description} </li>
        ))}
      </ul>
      <Typography className={classes.text}>
        Once deactivated, the rate code will no longer be included in the bundle(s).
      </Typography>
      <Grid container justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button
            color="primary"
            size="large"
            onClick={() => {
              form.setFieldValue("active", true);
              setCancelUpdate(true);
              setOpen(false);
            }}
            style={{ textTransform: "capitalize" }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="large"
            onClick={() => setRateCodeUpdate(true)}
            style={{ textTransform: "capitalize" }}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DeactivateBundleRateCodeConfirmationModal;
