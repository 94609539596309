import React from "react";
import { Box, Container, Grid } from "@material-ui/core";

import Notification from "../../components/Notification";

import { useStyles } from "./AuthLayout.styles";

import logo from "../../assets/logo/LevelUp-Logo1-01_300.png";

export const AuthLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={4} className={classes.secondaryPanel}>
          <img alt="Company logo" src={logo} className={classes.logo} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Container className={classes.container} maxWidth="sm" disableGutters={true}>
            <div className={classes.content}>{children}</div>
          </Container>
        </Grid>
      </Grid>
      <Notification />
    </Box>
  );
};
