import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";

import { ArrowBackIcon } from "../../../components/icons";
import CreateFacilityForm from "../../../components/forms/CreateFacilityForm";

import { RouterLink } from "../../../lib";
import { useAction, superAdminActions } from "../../../state";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
  },
}));

const CreateFacilityPage = () => {
  const classes = useStyles();
  const getRegions = useAction(superAdminActions.getRegions);

  useEffect(() => {
    getRegions();
  }, [getRegions]);

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton component={RouterLink} to="/super-admin/facilities">
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            Create Medical Facility
          </Typography>
        </Grid>
      </Grid>

      <CreateFacilityForm />
    </>
  );
};

export default CreateFacilityPage;
