import { makeStyles, alpha } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  switcher: {
    width: 270,
  },
  successBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: alpha(theme.palette.success.light, 0.5),
    "&.MuiToggleButton-root.Mui-selected": {
      color: "#fff",
      backgroundColor: theme.palette.success.dark,
    },
  },
  pendingBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: alpha(theme.palette.warning.light, 0.5),
    "&.MuiToggleButton-root.Mui-selected": {
      color: "#fff",
      backgroundColor: theme.palette.warning.dark,
    },
  },
  canceledBtn: {
    color: theme.palette.primary.contrastText,
    backgroundColor: alpha(theme.palette.error.light, 0.5),
    "&.MuiToggleButton-root.Mui-selected": {
      color: "#fff",
      backgroundColor: theme.palette.error.dark,
    },
  },
}));
