import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
  editorContainer: {
    "& .wrapper": {
      border: "1px solid lightgrey",
      padding: 8,
    },
  },
});
