import { Accordion as MuiAccordion } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

export const Accordion = withStyles({
  root: {
    border: "1px solid #E0E0E0",
    boxShadow: "none",

    "&:not(:last-child)": {
      marginBottom: "16px !important",
    },

    "&:before": {
      display: "none",
    },

    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

export const useStyles = makeStyles((theme) => ({
  btn: {
    color: "rgba(98, 98, 98, 0.87)",

    "& .MuiButton-label": {
      fontSize: "1rem",
      fontWeight: 500,
    },
  },
  root: {
    padding: "0 12px",
  },
  header: {
    paddingBottom: theme.spacing(3),
  },
  closeIcon: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  servicesTitle: {
    padding: "8px 0px",
    textTransform: "uppercase",

    "& .MuiTypography-root": {
      fontSize: 16,
      fontWeight: "bold",
    },
  },
  serviceListWrap: {
    paddingBottom: 20,
  },
  reason: {
    paddingTop: 8,
  },
  reasonTitle: {
    paddingBottom: 8,
    fontSize: "0.75rem",
    color: "rgba(0, 0, 0, 0.5)",
    textTransform: "uppercase",
  },
  reasonText: {},
  fileUploadWrap: {
    width: "100%",
  },
  fileUploadComponentWrap: {
    backgroundColor: "#fff",
    borderColor: "#E0E0E0",
    borderRadius: 0,
  },
  fileLinkWrap: {
    marginTop: 20,
  },
  fileLinkItem: {
    display: "flex",
    alignItems: "center",
  },
  fileLink: {
    color: "rgba(0, 0, 0, 0.87)",
  },
  servicesList: {
    width: 880,
  },
  servicesGroup: {
    display: "flex",
    flexWrap: "wrap",
  },
  service: {
    // TODO figure out another way to override css props
    textTransform: "initial",
    padding: `${theme.spacing(2)}px !important`,
    margin: `${theme.spacing(1)}px !important`,
    border: "1px solid rgba(0, 0, 0, 0.23) !important",
    borderRadius: "4px !important",

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#fff",
    },

    "&:disabled": {
      border: "1px solid rgba(0, 0, 0, 0.1) !important",
    },
  },
  modalFooter: {
    paddingTop: theme.spacing(3),
  },
}));
