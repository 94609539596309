import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";

const _state = (state: AppState) => state.lists;

const _facilitiesList = createSelector(_state, (state) => state.facilitiesList ?? []);
const _agenciesList = createSelector(_state, (state) => state.agenciesList ?? []);
const _facilityListLoading = createSelector(_state, (state) => state.facilityListLoading ?? false);

export const listsSelectors = {
  facilitiesList: _facilitiesList,
  agenciesList: _agenciesList,
  facilityListLoading: _facilityListLoading,
};
