import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";

import { ArrowBackIcon } from "../../../components/icons";
import CreateAgencyForm from "../../../components/forms/CreateAgencyForm";

import { RouterLink } from "../../../lib";
import { superAdminActions, useDispatch } from "../../../state";

export const useStyles = makeStyles(() => ({
  header: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
    margin: 0,
  },
}));

const EditAgencyPage = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const agencyId = Number(props.match.params.id);

  useEffect(() => {
    dispatch(superAdminActions.getAgency(agencyId));
  }, [dispatch, agencyId]);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton component={RouterLink} to="/super-admin/agencies">
            <ArrowBackIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            Modify Agency
          </Typography>
        </Grid>
      </Grid>

      <CreateAgencyForm {...{ activeStep, handleNext, handleBack }} />
    </>
  );
};

export default EditAgencyPage;
