import React, { useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import AppointmentHeader from "./AppointmentHeader";
import AppointmentServices from "./AppointmentServices";
import AppointmentStepper from "../../../../components/steppers/AppointmentStepper";
import { Prompt } from "react-router";
import {
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
  useAction,
} from "../../../../state";
import AppointmentNotes from "./AppointmentNotes";
import AppointmentInfo from "./AppointmentInfo/AppointmentInfo";
import AppointmentAlerts from "./AppointmentAlerts";
import AppointmentSupportingDocs from "./AppointmentSupportingDocs";

// TODO add types and interfaces
const AppointmentPage = ({ ...props }) => {
  const { id } = props.match.params;
  const getAppointmentByID = useAction(agencyActions.getAppointmentByID);
  const getApptSupportingDocuments = useAction(agencyActions.getApptSupportingDocuments);
  const clearOpenedAppointment = useAction(agencyActions.clearOpenedAppointment);
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const view = useSelector(authSelectors.activeEntityType);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  const showExitPrompt =
    view === "facility" &&
    openedAppointment?.payer === "patient" &&
    openedAppointment.amount_paid < openedAppointment.total &&
    openedAppointment.medical_facility_id === activeEntityId;

  useEffect(() => {
    getAppointmentByID(activeEntityId, id, view);
    getApptSupportingDocuments(activeEntityId, id, view);
    return () => {
      clearOpenedAppointment();
    };
  }, [
    getAppointmentByID,
    id,
    clearOpenedAppointment,
    activeEntityId,
    view,
    getApptSupportingDocuments,
  ]);

  return (
    !!openedAppointment && (
      <Box>
        <AppointmentHeader />
        <Grid item container spacing={3} style={{ padding: 24 }}>
          <Grid item xs={3}>
            <AppointmentInfo />
            <AppointmentNotes />
            <AppointmentSupportingDocs />
          </Grid>
          <Grid item xs={9}>
            {view !== "agency" && <AppointmentAlerts />}
            <AppointmentStepper />
            <AppointmentServices />
          </Grid>
        </Grid>
        <Prompt when={showExitPrompt} message={"Are you sure that this appointment wasn’t paid?"} />
      </Box>
    )
  );
};

export default React.memo(AppointmentPage);
