import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import FileUpload from "../FileUpload";
import { Form, useFormik } from "../../../lib";
import {
  useAction,
  superAdminActions,
  useSelector,
  superAdminSelectors,
  useDispatch,
  uiActions,
  fileActions,
} from "../../../state";
import { CREATE_BUNDLE_INITIAL_VALUES as bundleInitialValues } from "../../../constants";
import { CREATE_DEFAULT_BUNDLE_VALIDATION_SCHEMA as validationSchema } from "../../../utils";
import { useStyles } from "./DefaultBundleForm.styles";
import { FileOutlinedIcon } from "../../icons";

const DefaultBundleForm = () => {
  const classes = useStyles();
  const { id }: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const createDefaultBundle = useAction(superAdminActions.createDefaultBundle);
  const updateDefaultBundle = useAction(superAdminActions.updateDefaultBundle);
  const rateCodes = useSelector(superAdminSelectors.defaultRateCodes);
  const editBundle = useSelector(superAdminSelectors.activeDefaultBundle);
  const isEdit = Boolean(id);
  const [initialValues, setInitialValues] = useState(bundleInitialValues);
  const deleteFile = useAction(fileActions.deleteFileById);

  useEffect(() => {
    dispatch(superAdminActions.getDefaultRateCodes());
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(superAdminActions.getDefaultBundleById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (editBundle?.id === Number(id)) {
      setInitialValues({
        ...editBundle,
        rateCodes: rateCodes?.map((rc) => ({
          ...rc,
          active: !!editBundle.default_bundle_rate_codes?.find(
            (b) => b.default_rate_code_id === rc.id,
          ),
        })),
      });
    } else {
      setInitialValues({ ...bundleInitialValues, rateCodes: rateCodes });
    }
  }, [dispatch, editBundle, id, rateCodes]);

  const form = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema,
    async onSubmit(values) {
      values.bundle_rate_codes = values.rateCodes?.filter((rc) => rc.active);
      if (!values.bundle_rate_codes.length) {
        return dispatch(
          uiActions.showError("At least one service is required to save default bundle"),
        );
      }
      const { rateCodes, ...data } = values;
      await (isEdit ? updateDefaultBundle(id, data) : createDefaultBundle(data));
    },
  });

  const handleRemoveFile = () => {
    deleteFile(form.values.filenames_id);
    form.setFieldValue("filenames_id", null);
  };

  const handleBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Form form={form} className={classes.form}>
      <Grid container direction="column">
        <Grid item container style={{ gap: "40px" }}>
          <Grid item className={classes.formControl}>
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              name="name"
              label="Bundle Name"
              value={form.values.name}
              onChange={form.handleChange}
              error={form.touched.name && Boolean(form.errors.name)}
              helperText={form.touched.name && form.errors.name}
            />
          </Grid>
          <Grid item className={classes.formControl}>
            <TextField
              fullWidth
              variant="outlined"
              id="default_rate"
              type="number"
              name="default_rate"
              label="Default Price"
              disabled={isEdit}
              value={form.values.default_rate || ""}
              onChange={form.handleChange}
              error={form.touched.default_rate && Boolean(form.errors.default_rate)}
              helperText={form.touched.default_rate && form.errors.default_rate}
            />
          </Grid>
        </Grid>

        {!isEdit && (
          <>
            <Grid item className={classes.switch}>
              <Switch
                id="form_required"
                color="primary"
                name="form_required"
                onChange={form.handleChange}
                checked={form.values.form_required}
              />
              Form required
            </Grid>
            <Grid item>
              <Typography className={classes.sectionTitle}>Form upload</Typography>
              <Box>
                <FileUpload
                  form={form}
                  name="filenames_id"
                  accept="application/pdf"
                  uploadType="private"
                />

                {form.touched.filenames_id && Boolean(form.errors.filenames_id) && (
                  <FormHelperText error variant="outlined">
                    {form.errors.filenames_id}
                  </FormHelperText>
                )}
              </Box>
              {form?.values?.filenames_id && (
                <Grid container alignItems="center" style={{ gap: "8px" }}>
                  <FileOutlinedIcon />
                  <Typography> {form.values.filenames_id}</Typography>
                  <Button onClick={handleRemoveFile}>x</Button>
                </Grid>
              )}
            </Grid>
          </>
        )}

        <Grid item>
          <Typography className={classes.serviceTitle}>Services included</Typography>

          {form.values?.rateCodes?.map((code, index) => {
            //todo: fix on edit to pre select rc in bundle
            return (
              <Grid container alignItems="center" className={classes.serviceRows} key={index}>
                <Grid item xs={4}>
                  <Switch
                    id="rate-code-active"
                    color="primary"
                    name={`rateCodes.${index}.active`}
                    onChange={form.handleChange}
                    checked={form.values.rateCodes[index].active || false}
                    value={form.values.rateCodes[index].active}
                  />{" "}
                  {code.code}
                </Grid>

                <Grid item xs={6}>
                  {code.description}
                </Grid>

                <Grid item xs={2}>
                  ${code.default_rate}
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid container item className={classes.formActions} spacing={4}>
          <Grid item>
            <Button onClick={handleBackClick} color="primary" type="reset">
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit">
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default DefaultBundleForm;
