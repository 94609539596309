import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main, //can switch when we actually have a secondary color  theme.palette.secondary.main,
    maxWidth: 93,
    maxHeight: 36,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 14,
    paddingRight: 24,
    justifyContent: "space-between",
    borderRadius: 20,
    textTransform: "capitalize",
  },
  label: {
    lineHeight: "2.25rem",
  },
}));
