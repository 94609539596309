import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Card, CardContent, CardActionArea, Typography } from "@material-ui/core";
import clsx from "clsx";

import { LocalHospitalIcon, BusinessIcon } from "../../components/icons";

import {
  useAction,
  superAdminActions,
  useSelector,
  authSelectors,
  superAdminSelectors,
} from "../../state";
import Pages from "../../pages";

export const useStyles = makeStyles(
  theme => ({
    fullHeight: {
      height: "100%",
    },
    container: {
      paddingTop: "6em",
    },
    header: {
      padding: "1em 0",
    },
    cardWrap: {
      padding: theme.spacing(2),
    },
    cardIcon: {
      margin: "auto 2em auto 0",

      "& svg": {
        color: theme.palette.primary.main,
        width: "2em",
        height: "auto",
      },
    },
    card: {
      boxShadow: "0 2px 12px 0 #F5F6FE, 0 2px 8px 0 #D5D8EC",
      borderRadius: 0,
    },
    cardPadding: {
      paddingLeft: "2em",
      paddingRight: "2em",

      "& .MuiCardHeader-content .MuiTypography-root": {
        fontSize: "1.15em",
      },
    },
  }),
  { classNamePrefix: "SuperAdminDashboardPage" },
);

// TODO divide on more smaller components
const DashboardPage = () => {
  const classes = useStyles();

  const getEntities = useAction(superAdminActions.getEntities);

  const firstName = useSelector(authSelectors.userFirstName);
  const agenciesCount = useSelector(superAdminSelectors.agenciesCount);
  const facilitiesCount = useSelector(superAdminSelectors.facilitiesCount);

  useEffect(() => {
    getEntities();
  }, [getEntities]);

  return (
    <Box className={classes.container}>
      <Grid container justifyContent="space-between" component="header" className={classes.header}>
        <Typography id="welcome-text" variant="h4" component="h1">
          Welcome, {firstName}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item xs={6} className={classes.cardWrap}>
          <Link id={"agency"} to={Pages.superAdmin.agencies.path}>
            <Card variant="outlined" className={clsx(classes.card, classes.fullHeight)}>
              <CardActionArea>
                <CardContent className={clsx(classes.cardPadding, classes.fullHeight)}>
                  <Grid container>
                    <Grid item className={classes.cardIcon}>
                      <BusinessIcon />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        className={classes.fullHeight}
                      >
                        <Typography id="agency-count" variant="h6" gutterBottom>
                          {agenciesCount}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Agencies
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={6} className={classes.cardWrap}>
          <Link id={"facility"} to={Pages.superAdmin.facilities.path}>
            <Card variant="outlined" className={clsx(classes.card, classes.fullHeight)}>
              <CardActionArea>
                <CardContent className={clsx(classes.cardPadding, classes.fullHeight)}>
                  <Grid container>
                    <Grid item className={classes.cardIcon}>
                      <LocalHospitalIcon />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        className={classes.fullHeight}
                      >
                        <Typography id="facilities-count" variant="h6" gutterBottom>
                          {facilitiesCount}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                          Medical facilities
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(DashboardPage);
