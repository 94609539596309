import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FilterButton } from "../../../components/buttons";
import AppointmentsTable from "../../../components/tables/AppointmentsTable";
import { useInputDebounced } from "../../../lib";
import { agencyActions, useAction } from "../../../state";
import { SearchField } from "../../inputs";

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3),
    border: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: 0,
  },
}));

// TODO add types
interface Props {
  view: "super_admin" | "agency" | "facility";
  columns: any;
  extensions: any;
  entity?: any;
}

const AppointmentsTab = ({ view, columns, extensions, entity }: Props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(agencyActions.setSearchQuery);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  return (
    <Box>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={4}>
          <SearchField
            id="search"
            label="Search for caregiver id or name"
            value={searchQuery}
            onChange={handleChange}
          />
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between">
            <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
          </Grid>
        </Grid>
      </Grid>

      <AppointmentsTable
        view={view}
        tabView
        isFiltersVisible={expanded}
        columns={columns}
        extensions={extensions}
        rowBaseUrl={"/super-admin/appointment"}
        entity={entity}
      />
    </Box>
  );
};

export default React.memo(AppointmentsTab);
