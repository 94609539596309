import React from "react";
import { Button } from "@material-ui/core";
import { authSelectors, superAdminActions, useAction, useSelector } from "../../state";
import { CheckIcon, CloseIcon } from "../icons";
export const MarkPaidButton = (row) => {
  const { entity_id, id, is_paid } = row;
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const toggleInvoicePaid = useAction(superAdminActions.markInvoicePaid);

  return (
    <>
      {isSuperAdmin ? (
        <Button onClick={() => toggleInvoicePaid(entity_id, id, { is_paid: !is_paid })}>
          {row.is_paid ? "Mark UnPaid" : "Mark Paid"}
        </Button>
      ) :
        row.is_paid ? <CheckIcon /> : <CloseIcon />
      }
    </>
  );
};
