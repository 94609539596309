import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Table from "../Table";
import { useDispatch, superAdminActions, useSelector, superAdminSelectors } from "../../../state";
import {
  TABLE_ROW_EDIT_ACTION,
  SUPER_ADMIN_DEFAULT_BUNDLES_COLUMNS_EXTENSIONS as extensions,
  SUPER_ADMIN_DEFAULT_BUNDLES_COLUMNS as columns,
} from "../../../constants";
import SuperAdminPages from "../../../pages/super-admin";

const DefaultRateCodesTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const bundles = useSelector(superAdminSelectors.defaultBundles);

  useEffect(() => {
    dispatch(superAdminActions.getDefaultBundles());
  }, [dispatch]);

  const handleEdit = useCallback(
    (row) => {
      history.push(SuperAdminPages.editDefaultBundle.path.replace(":id", row.id));
    },
    [history],
  );

  return (
    <>
      <Table
        rows={bundles}
        columns={columns}
        editHandler={handleEdit}
        tableColumnExtensions={extensions}
        actions={[TABLE_ROW_EDIT_ACTION]}
        exportEnabled={true}
        exportedFileName="DefaultBundles"
      />
    </>
  );
};

export default React.memo(DefaultRateCodesTable);
