import React, { useEffect, useState, useMemo } from "react";
import { Box, Button, CircularProgress, FormHelperText, Grid, Typography } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  agencyActions,
  useAction,
  agencySelectors,
  useSelector,
  facilityActions,
  useDispatch,
  authSelectors,
  Bundle,
  uiActions,
} from "../../../../state";
import { Form, useFormik } from "../../../../lib";
import {
  APPOINTMENT_STATUSES_STATES,
  CREATE_APPOINTMENT_SERVICES_INITIAL_VALUES as initialValues,
} from "../../../../constants";
import { CREATE_APPOINTMENT_SERVICES_VALIDATION_SCHEMA as validationSchema } from "../../../../utils";
import { useStyles } from "./AppointmentServices.styles";
import { AppointmentRequested } from "../AppointmentRequested";

interface RateCode {
  active: boolean;
  id: number;
  code?: string;
  description: string;
  rate: number;
  form_location?: any;
  parent_id?: number;
}

const AppointmentServices = ({ activeStep, handleBack, handleNext, entityType, handleReset }) => {
  const activeRateCodes: RateCode[] = useSelector(agencySelectors.activeRateCodes);
  const rateCodesLoading = useSelector(agencySelectors.rateCodesLoading);
  const bundles = useSelector(agencySelectors.bundles);
  const appointmentPatient = useSelector(agencySelectors.appointmentPatient);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const activeEntity = useSelector(authSelectors.activeEntity);
  const [showAptRequestedSuccess, setShowApptRequestedSuccess] = useState(false);
  const getRateCodesAction =
    entityType === "facility" ? facilityActions.getRateCodeByPatientId : agencyActions.getRateCodes;
  const saveAppointmentServices = useAction(agencyActions.saveAppointmentServices);
  const setActiveAppointmentServices = useAction(agencyActions.setActiveAppointmentServices);
  const saveNewAppointment = useAction(agencyActions.saveNewAppointment);
  const [selectedRCs, setSelectedRCs] = useState<RateCode[]>([]);
  const [selectedBundle, setSelectedBundle] = useState<undefined | Bundle>();
  const isAppointmentConfirmed = useSelector(agencySelectors.isAppointmentConfirmed);
  const setAppointmentConfirmed = useAction(agencyActions.setAppointmentConfirmed);

  const bundleRateCodeIDs = useMemo(() => {
    return selectedBundle?.home_health_agency_bundle_rate_codes?.map((s) => s.rate_code_id) ?? [];
  }, [selectedBundle]);

  const rcInActiveBundle = (rcId) => bundleRateCodeIDs.includes(rcId);

  const handleBundleChange = (e, bundle) => {
    if (bundle === null) {
      const oldRCsNotInBundle = selectedRCs.filter((r) => !bundleRateCodeIDs.includes(r.id));
      setSelectedRCs(oldRCsNotInBundle);
      setSelectedBundle(null);
      form.setFieldValue("appointment_rate_codes", selectedRCs);
      form.setFieldValue("bundles", selectedBundle);
      return;
    }
    const newRCIds: number[] = bundle.home_health_agency_bundle_rate_codes.map(
      (s) => s.rate_code_id,
    );
    const oldRCsNotInBundle = selectedRCs.filter((r) => !bundleRateCodeIDs.includes(r.id));
    let servicesFromBundle = activeRateCodes.filter((rc) => newRCIds.includes(rc.id));
    const RCs = [...oldRCsNotInBundle, ...servicesFromBundle];
    setSelectedBundle(bundle);
    setSelectedRCs(RCs);
    const { id, description, rate } = bundle;
    const allRCs = [...RCs, { active: 1, id, description, rate }];
    form.setFieldValue("bundles", bundle);
    form.setFieldValue("appointment_rate_codes", allRCs);
  };

  const handleServices = (e, services) => {
    const newIds = services.map((s) => s.id);
    const oldIds = selectedRCs.map((r) => r.id);
    if (newIds.length < oldIds.length) {
      //If removing check bundles
      const [idRemoving] = oldIds.filter((value) => !newIds.includes(value));
      if (rcInActiveBundle(idRemoving)) {
        // don't remove the rc if it's part of a bundle
        return;
      }
      let updatedRCs = services.filter((r) => r.id !== idRemoving);
      if (selectedBundle) {
        // if a bundle is selected need to add it back to the list of rcs
        const { id, description, rate } = selectedBundle;
        updatedRCs.push({ active: true, id, description, rate: +rate });
      }
      setSelectedRCs(updatedRCs);
      form.setFieldValue("appointment_rate_codes", updatedRCs);
    } else {
      //if adding a rate code
      setSelectedRCs(services);
      let allRCs = [...services];
      if (selectedBundle) {
        const { id, description, rate } = selectedBundle;
        allRCs.push({ active: 1, id, description, rate });
      }
      form.setFieldValue("appointment_rate_codes", allRCs);
    }
  };

  const handleRequestApptClick = async () => {
    const errors = await form.validateForm();

    if (errors && errors["appointment_rate_codes"]) {
      dispatch(uiActions.showError("Please select at least one service"));
      return;
    }

    await saveAppointmentServices(form.values);
    await setActiveAppointmentServices(form.values);
    const values = {
      ...form.values,
      appointment_status_id: APPOINTMENT_STATUSES_STATES.UNSCHEDULED,
    };
    saveNewAppointment(values, activeEntityId);
  };

  useEffect(() => {
    if (isAppointmentConfirmed) {
      setShowApptRequestedSuccess(true);
      setAppointmentConfirmed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppointmentConfirmed]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    async onSubmit(values: any) {
      await saveAppointmentServices(values);
      await setActiveAppointmentServices(values);
      handleNext();
    },
  });

  const isServicesError =
    form.touched.appointment_rate_codes && Boolean(form.errors.appointment_rate_codes);

  const classes = useStyles({ isServicesError });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getRateCodesAction(activeEntityId));
  }, [appointmentPatient, activeEntityId, dispatch, getRateCodesAction]);
  return (
    <>
      {showAptRequestedSuccess ? (
        <AppointmentRequested setShow={setShowApptRequestedSuccess} reset={handleReset} />
      ) : (
        <Form form={form} className={classes.form}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item className={classes.controlsWrap}>
              <Box className={classes.services}>
                <Grid container>
                  <Typography className={classes.servicesTitle}>
                    Select bundles to add to appointment
                  </Typography>

                  {form.touched.appointment_rate_codes &&
                    Boolean(form.errors.appointment_rate_codes) && (
                      <FormHelperText error id="appointment_rate_codes" variant="outlined">
                        {form.errors.appointment_rate_codes}
                      </FormHelperText>
                    )}
                </Grid>
                {rateCodesLoading ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress size={32} />
                  </Grid>
                ) : (
                  <ToggleButtonGroup
                    className={classes.servicesGroup}
                    value={selectedBundle}
                    onChange={handleBundleChange}
                    exclusive
                  >
                    {bundles?.map((code) => (
                      <ToggleButton
                        type="button"
                        key={`appointment-service-${code.id}`}
                        className={classes.service}
                        value={code}
                        id={code.description}
                      >
                        {code.description}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
                <Grid container>
                  <Typography className={classes.servicesTitle}>
                    Select all the services needed
                  </Typography>

                  {form.touched.appointment_rate_codes &&
                    Boolean(form.errors.appointment_rate_codes) && (
                      <FormHelperText error id="appointment_rate_codes" variant="outlined">
                        {form.errors.appointment_rate_codes}
                      </FormHelperText>
                    )}
                </Grid>

                {rateCodesLoading ? (
                  <Grid container justifyContent="center" alignItems="center">
                    <CircularProgress size={32} />
                  </Grid>
                ) : (
                  <ToggleButtonGroup
                    className={classes.servicesGroup}
                    value={selectedRCs}
                    onChange={handleServices}
                    key={`appointment-grp-${selectedBundle?.id ?? 0}`}
                  >
                    {activeRateCodes.map((code) => (
                      <ToggleButton
                        type="button"
                        key={`appointment-service-${code.id}`}
                        className={classes.service}
                        value={code}
                        style={{
                          backgroundColor: rcInActiveBundle(code.id)
                            ? "rgba(163, 25, 30, 0.6)"
                            : undefined,
                        }}
                        id={code.description}
                      >
                        {code.description}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                )}
              </Box>

              <Grid
                container
                justifyContent="space-between"
                className={classes.formActions}
                spacing={4}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    type="button"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  {!!activeEntity?.home_health_agency_profile?.allows_unscheduled_appts ? (
                    <>
                      <Button id="next" variant="text" color="primary" size="large" type="submit">
                        Schedule appointment
                      </Button>
                      <Button
                        style={{ marginLeft: 16 }}
                        id="request_appt"
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleRequestApptClick}
                      >
                        Request appointment
                      </Button>
                    </>
                  ) : (
                    <Button
                      id="next"
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                    >
                      Next
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </>
  );
};

export default AppointmentServices;
