import React from "react";
import { Button } from "@material-ui/core";
import { EditIcon } from "../icons";

const EditButton = (props) => {
  return (
    <Button color="primary" size="large" startIcon={<EditIcon />} {...props}>
      Edit
    </Button>
  );
};

export default React.memo(EditButton);
