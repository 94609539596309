import React from "react";
import { useParams } from "react-router-dom";
import { Grid, IconButton, Typography } from "@material-ui/core";

import { ArrowBackIcon } from "../../../../components/icons";
import FacilityUserForm from "../../../../components/forms/FacilityUserForm";

import FacilityPages from "../../";
import { RouterLink } from "../../../../lib";

import { useStyles } from "./UserPage.styles";

const UserPage = () => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton component={RouterLink} to={FacilityPages.users.path}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            {id ? "Edit" : "Create"} Facility User
          </Typography>
        </Grid>
      </Grid>

      <FacilityUserForm />
    </>
  );
};

export default UserPage;
