import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { Fade, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

import Logo from "./Logo";
import Navigation from "./Navigation";
import { AccountCircle } from "../../components/icons";

import { authSelectors, useSelector } from "../../state";
import { RouterLink, useRouterLocation } from "../../lib";

import { useStyles } from "./MainMenu.styles";

// TODO add types and interfaces

const MainMenu = () => {
  const [open, setOpen] = useState(false);

  const classes = useStyles();
  const userFirstName = useSelector(authSelectors.userFirstName);

  const location = useRouterLocation();

  const handleDrawerOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const drawerOpenClassNames = clsx({
    [classes.drawerOpen]: open,
    [classes.drawerClose]: !open,
  });
  const drawerClassNames = clsx(classes.drawer, classes.root, drawerOpenClassNames, {
    [classes.menuOpen]: open,
  });
  const drawerPaperClassNames = {
    paper: clsx(classes.paper, drawerOpenClassNames),
  };

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      open={open}
      onMouseOver={handleDrawerOpen}
      onMouseLeave={handleDrawerClose}
      className={drawerClassNames}
      classes={drawerPaperClassNames}
      onClick={(e) => setOpen(!open)}
    >
      <div>
        <Logo open={open} />
        <div style={{ marginTop: 160 }}>
          <Navigation open={open} />
        </div>
      </div>

      <List classes={{ root: classes.accountWrap }}>
        <ListItem
          button
          className={classes.listItem}
          component={RouterLink}
          to="/profile"
          selected={location.pathname === "/profile"}
        >
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <AccountCircle />
          </ListItemIcon>
          <Fade in={open}>
            <ListItemText className={classes.listItemText} primary={`Hello, ${userFirstName}`} />
          </Fade>
        </ListItem>
      </List>
    </Drawer>
  );
};

export default React.memo(MainMenu);
