import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: 90,
  },
  controlsWrap: {
    width: "65vw",
  },
  formControl: {
    width: "50%",
    minWidth: 330,
    padding: "0 32px 32px",
  },
  switcherGroup: {
    width: "100%",
    padding: "0 0 15px 0",
  },
  switcher: {
    width: "50%",
    textTransform: "initial",
    borderColor: "rgba(0, 0, 0, 0.23)",

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  switcherError: {
    borderColor: theme.palette.error.main,
  },
  note: {
    padding: "0 32px",
  },
  formActions: {
    marginTop: 64,
    borderTop: "1px solid #E0E0E0",
    padding: "0 32px",
  },
}));
