import { makeStyles, Theme } from "@material-ui/core";

export const drawerWidth = 260;

export const useStyles = makeStyles(
  (theme: Theme) => ({
    logoWrap: {
      position: "relative",
    },
    logo: {
      position: "absolute",
      // to remove unnecessary extra padding at the bottom
      fontSize: 0,
    },

    root: {
      display: "flex",
      // TODO double check is there any way to make it not hardcoded
      width: 55,
      transition: "all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
      maxHeight: 0,
    },
    hide: {
      display: "none",
    },
    menuOpen: {
      width: drawerWidth,
    },

    paper: {
      paddingBottom: 0,
      paddingLeft: 24,
      paddingRight: 24,
      width: drawerWidth,
      flexDirection: "column",
      flexShrink: 0,
      justifyContent: "space-between",
      borderRight: "1px solid #E0E0E0",
    },
    listContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    listItem: {
      margin: "0 -24px",
      paddingLeft: theme.spacing(2),
      width: "calc(100% + 48px)",
    },
    navListItem: {
      opacity: 0.5,
      color: "rgba(0, 0, 0, 0.87)",
    },
    listItemSelected: {
      opacity: 1,
      color: theme.palette.primary.main,
      backgroundColor: "transparent !important",
    },
    listItemText: {
      "& span": {
        fontSize: 16,
      },
    },
    listItemIcon: {
      color: "inherit !important",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      position: "fixed",
      zIndex: 2,
      "& .MuiPaper-root": {
        overflowX: "hidden",
      }
    },
    drawerOpen: {
      boxShadow: "0 2px 12px 0 #F5F6FE, 0 2px 8px 0 #D5D8EC",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    },
    accountWrap: {
      paddingBottom: 0,
    },
  }),
  {
    classNamePrefix: "MainMenu",
  },
);
