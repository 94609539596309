import React from "react";
import { debounce } from "./utils";
import { useInputValue } from "./hooks";
/**
 * Hook that returns an input value, debounced value, `onChange` handler and a
 * value setter.
 * @template TValue
 * @param {TValue} [defaultValue] The default value. (`""`)
 * @param {UseInputValueOptions<TValue> | number} options Options or delay in
 * milliseconds.
 * @returns {string[]}
 *
 * @example
 *   // Basic usage
 *   const [email, delayedEmail, onChangeEmail] = useInputValue();
 *   React.useEffect(() => {
 *     console.log("Delayed email changed: ", delayedEmail);
 *   }, [delayedEmail]);
 *   return <input onChange={onChangeEmail} value={email} />;
 */
export function useInputDebounced(defaultValue = "", options): [string, string, any, any] {
  let delay = 1000;
  if (options) {
    if (typeof options === "number") {
      delay = options;
      options = undefined;
    } else if (options.delay) {
      delay = options.delay;
    }
  }
  const [value, onChange, setValue] = useInputValue(defaultValue, options);
  const [debouncedValue, setDebouncedValue] = React.useState(defaultValue);

  const onChangeDebounce = React.useCallback(
    debounce((value) => {
      setDebouncedValue(value);
    }, delay),
    [],
  );

  React.useEffect(() => {
    onChangeDebounce(value);
  }, [value, onChangeDebounce]);

  return [value, debouncedValue, onChange, setValue];
}
