import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    position: "relative",
    paddingTop: "2em",
  },
  sectionTitle: {
    padding: "0 2em 1em",

    "& .MuiTypography-root": {
      fontSize: "1.25em",
      fontWeight: 500,
    },
  },
  controlsWrap: {
    width: "65vw",
  },
  formControl: {
    padding: "0 2em 2em",
    flexGrow: 1,
  },
  description: {
    width: 320,
  },
  fileUploadWrap: {
    padding: "0 2em 1em",
  },
  formActions: {
    marginTop: 64,
    borderTop: "1px solid #E0E0E0",
    padding: "0 2em",
  },
  switcherGroup: {
    width: "100%",
  },
  switcher: {
    width: "50%",

    "&.MuiToggleButton-root": {
      borderRadius: 0,
      borderColor: "#CDD2E9",
      textTransform: "initial",
      color: "#AFB6D4",
    },

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  switcherError: {
    borderColor: theme.palette.error.main,
  },
}));
