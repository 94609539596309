import React from "react";
import { Paper, Backdrop, Modal as MaterialModal } from "@material-ui/core";

import Fade from "../../transitions/Fade";

import { useStyles } from "./Modal.styles";
import { useStyles as layoutUseStyles } from "../../../layouts/main/MainLayout.styles";

const Modal = ({ open, handleClose, ...props }) => {
  const classes = useStyles();
  const layoutClasses = layoutUseStyles();

  return (
    <MaterialModal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ className: layoutClasses.backdrop }}
      {...props}
    >
      <Fade in={open}>
        <Paper square variant="outlined" className={classes.paper}>
          {props.children}
        </Paper>
      </Fade>
    </MaterialModal>
  );
};

export default Modal;
