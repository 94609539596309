import React from "react";
import { List } from "@material-ui/core";
import FileUploadItem from "./FileUploadItem";
import {
  agencySelectors,
  authSelectors,
  fileActions,
  useAction,
  useSelector,
} from "../../../state";
import { useRole } from "../../../hooks";
import { arrayToObjById } from "../../../state/utils";
import { RATE_CODE_STATUSES_STATES } from "../../../constants";
import Dropzone from "./Dropzone";

interface FileUploadProps {
  name?: string;
  accept: string;
  uploadType: string;
  // TODO do not use ANY!
  form?: any;
  classNames?: any;
  onUpload?: any;
  id?: any;
  maxFiles?: number;
  multiple?: boolean;
  downloadEnabled?: boolean;
  downloadHandler?: any;
  showZone?: any;
}

const FileUpload = ({
  form = {},
  showZone = true,
  name = "",
  accept,
  uploadType,
  classNames,
  onUpload,
  id,
  maxFiles = 1,
  multiple = false,
}: FileUploadProps) => {
  const { isAgencyRole } = useRole();
  const downloadFile = useAction(fileActions.getFileUrl);
  const updateFileApproveStatus = useAction(fileActions.updateFileApproveStatus);
  const deleteFile = useAction(fileActions.deleteFile);
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const view = useSelector(authSelectors.activeEntityType);
  const forms = openedAppointment
    ? ((openedAppointment.appointment_rate_codes || []).find((r) => r.id === id) || {}).forms
    : [];

  const rateCodes = arrayToObjById(openedAppointment.appointment_rate_codes, "id");
  const role = useRole();
  const hhaId = openedAppointment?.patient?.home_health_agency_profile?.entity_id;
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const activeEntityIdWithSuperAdminCheck = role.isSuperAdmin ? hhaId : activeEntityId;

  return (
    <>
      {showZone && (
        <Dropzone
          form={form}
          showZone={showZone}
          name={name}
          accept={accept}
          uploadType={uploadType}
          classNames={classNames}
          onUpload={onUpload}
          id={id}
          maxFiles={maxFiles}
          multiple={multiple}
        />
      )}
      <List>
        {!!forms?.length &&
          forms.map((file) => (
            <>
              {(!isAgencyRole || !!file.approved) && (
                <FileUploadItem
                  key={file?.filename}
                  primaryText={file?.filename?.name}
                  approved={file?.approved}
                  downloadEnabled={true}
                  deleteEnabled={
                    !isAgencyRole &&
                    rateCodes[id].rate_code_status_id !== RATE_CODE_STATUSES_STATES.COMPLETED
                  }
                  hideApprove={
                    rateCodes[id].rate_code_status_id === RATE_CODE_STATUSES_STATES.CANCELED
                  }
                  downloadHandler={(e) =>
                    downloadFile(
                      activeEntityIdWithSuperAdminCheck,
                      openedAppointment.id,
                      id,
                      file.id,
                    )
                  }
                  approveHandler={() =>
                    updateFileApproveStatus(openedAppointment.id, id, file.id, true)
                  }
                  unapproveHandler={() =>
                    updateFileApproveStatus(openedAppointment.id, id, file.id, false)
                  }
                  deleteHandler={(e) =>
                    deleteFile(
                      openedAppointment.medical_facility_id,
                      openedAppointment.id,
                      id,
                      file.id,
                      view,
                    )
                  }
                />
              )}
            </>
          ))}
      </List>
    </>
  );
};

export default React.memo(FileUpload);
