import React, { useCallback } from "react";
import NumberFormat from "react-number-format";

interface NumberFormatProps {
  // @ts-ignore
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
}

const PriceFormat = (props: NumberFormatProps) => {
  const { inputRef, onChange, ...other } = props;

  const onValueChange = useCallback(
    (values) => {
      onChange({
        target: {
          name: props.name,
          value: Number(values.value),
        },
      });
    },
    [onChange, props],
  );

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={onValueChange}
      thousandSeparator
      prefix="$"
    />
  );
};

export default PriceFormat;
