import React from "react";

import {
  DashboardIcon,
  BusinessIcon,
  LocalHospitalIcon,
  FormatListBulletedIcon,
  ScienceIcon,
  ScheduleIcon,
  AttachMoneyIcon,
  PeopleOutlineIcon,
  NotificationsIcon,
  WbIridescentIcon,
  UpdateOutlinedIcon,
  GroupWorkOutlinedIcon,
  AppointmentsOutlinedIcon,
  LocationOutlinedIcon,
  GroupOutlinedIcon,
} from "../../../components/icons";
import Pages from "../../../pages";

import { ROLES } from "../../../state";

export const superAdminLinks = [
  {
    label: "Dashboard",
    to: "/super-admin/dashboard",
    iconComponent: <DashboardIcon />,
  },
  {
    label: "Agencies",
    to: "/super-admin/agencies",
    iconComponent: <BusinessIcon />,
  },
  {
    label: "Facilities",
    to: "/super-admin/facilities",
    iconComponent: <LocalHospitalIcon />,
  },
  {
    label: "Appointments",
    to: "/super-admin/appointments",
    iconComponent: <AppointmentsOutlinedIcon />,
  },
  {
    label: "Caregivers",
    to: "/super-admin/caregivers",
    iconComponent: <GroupOutlinedIcon />,
  },
  {
    label: "Default Rate Codes",
    to: "/super-admin/default-rate-codes",
    iconComponent: <FormatListBulletedIcon />,
  },
  {
    label: "Default Bundles",
    to: "/super-admin/default-bundles",
    iconComponent: <GroupWorkOutlinedIcon />,
  },
  {
    label: "Labs",
    to: "/super-admin/labs",
    iconComponent: <ScienceIcon />,
  },
  {
    label: "Regions",
    to: "/super-admin/regions",
    iconComponent: <LocationOutlinedIcon />,
  },
  {
    label: "Admin users",
    to: "/super-admin/admin-users",
    iconComponent: <GroupOutlinedIcon />,
  },
  {
    label: "All users",
    to: "/super-admin/all-users",
    iconComponent: <GroupOutlinedIcon />,
  },
];

export const agencyAdminLinks = [
  {
    label: "Dashboard",
    to: "/agency/dashboard",
    iconComponent: <DashboardIcon />,
  },
  {
    label: "Caregivers",
    to: "/agency/patients",
    iconComponent: <GroupOutlinedIcon />,
  },
  {
    label: "Appointments",
    to: "/agency/appointments",
    iconComponent: <AppointmentsOutlinedIcon />,
  },
  {
    label: "Updates",
    to: "/agency/updates",
    iconComponent: <UpdateOutlinedIcon />,
  },
  {
    label: "Users",
    to: Pages.agency.users.path,
    iconComponent: <GroupOutlinedIcon />,
  },
  {
    label: "Billing",
    to: "/agency/billing",
    iconComponent: <AttachMoneyIcon />,
  },
  {
    label: "Notification Settings",
    to: "/agency/notifications",
    iconComponent: <NotificationsIcon />,
  },
];

export const agencySchedulerLinks = [
  {
    label: "Dashboard",
    to: "/agency/dashboard",
    iconComponent: <DashboardIcon />,
  },
  {
    label: "Caregivers",
    to: "/agency/patients",
    iconComponent: <GroupOutlinedIcon />,
  },
  {
    label: "Appointments",
    to: "/agency/appointments",
    iconComponent: <AppointmentsOutlinedIcon />,
  },
  {
    label: "Updates",
    to: "/agency/updates",
    iconComponent: <UpdateOutlinedIcon />,
  },
  {
    label: "Billing",
    to: "/agency/billing",
    iconComponent: <AttachMoneyIcon />,
  },
];

export const agencyBillerLinks = [
  {
    label: "Dashboard",
    to: "/agency/dashboard",
    iconComponent: <DashboardIcon />,
  },
  {
    label: "Caregivers",
    to: "/agency/patients",
    iconComponent: <GroupOutlinedIcon />,
  },
  {
    label: "Appointments",
    to: "/agency/appointments",
    iconComponent: <ScheduleIcon />,
  },
  {
    label: "Billing",
    to: "/agency/billing",
    iconComponent: <AttachMoneyIcon />,
  },
];

const upcomingAppointmentsLink = {
  label: "Upcoming",
  to: "/facility/upcoming-appointments",
  iconComponent: <WbIridescentIcon />,
};

const checkedinAppointmentsLink = {
  label: "Checked in",
  to: "/facility/checkedin-appointments",
  iconComponent: <WbIridescentIcon />,
};

export const facilitySharedLinks = [
  {
    label: "Appointments",
    to: "/facility/appointments",
    iconComponent: <AppointmentsOutlinedIcon />,
  },
  {
    label: "Caregivers",
    to: "/facility/patients",
    iconComponent: <GroupOutlinedIcon />,
  },

  {
    label: "Notification Settings",
    to: "/agency/notifications",
    iconComponent: <NotificationsIcon />,
  },
  // {
  //   label: "Updates",
  //   to: "/facility/updates",
  //   iconComponent: <DescriptionIcon />,
  // },
  // {
  //   label: "Upload Updates",
  //   to: "/facility/upload-updates",
  //   iconComponent: <BackupIcon />,
  // },
];

export const facilityIntakeLinks = [upcomingAppointmentsLink, ...facilitySharedLinks];

export const facilityProviderLinks = [checkedinAppointmentsLink, ...facilitySharedLinks];

export const facilityBillerLinks = [
  upcomingAppointmentsLink,
  ...facilitySharedLinks,
  {
    label: "Billing History",
    to: "/facility/billing-history",
    iconComponent: <AttachMoneyIcon />,
  },
];

export const facilityAdminLinks = [
  ...facilityBillerLinks,
  {
    label: "Users",
    to: "/facility/users",
    iconComponent: <PeopleOutlineIcon />,
  },
];

export const linksByRole = (role) => {
  if (!role) return;
  switch (role) {
    case ROLES.AGENCY_ADMIN:
      return agencyAdminLinks;
    case ROLES.AGENCY_SCHEDULER:
      return agencySchedulerLinks;
    case ROLES.AGENCY_BILLER:
      return agencyBillerLinks;
    case ROLES.FACILITY_ADMIN:
      return facilityAdminLinks;
    case ROLES.FACILITY_INTAKE:
      return facilityIntakeLinks;
    case ROLES.FACILITY_BILLER:
      return facilityBillerLinks;
    case ROLES.FACILITY_PROVIDER:
      return facilityProviderLinks;
    case ROLES.SUPER_ADMIN:
      return superAdminLinks;
  }
};
