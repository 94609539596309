import React from "react";
import { Box } from "@material-ui/core";

import AppointmentsByDate from "./AppointmentsByDate";
import AppointmentsByDateDrawer from "./AppointmentsByDateDrawer";

import { useStyles } from "./AppointmentsByDatePage.styles";

const AppointmentsByDatePage = () => {
  const classes = useStyles();

  return (
    <Box>
      <AppointmentsByDateDrawer />

      <Box className={classes.content}>
        <AppointmentsByDate />
      </Box>
    </Box>
  );
};

export default AppointmentsByDatePage;
