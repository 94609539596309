import React, { useCallback } from "react";
import { Box, Button, Typography } from "@material-ui/core";

import { authActions, useAction } from "../../../state";
import { useLocation } from "../../../lib";

import { useStyles } from "../Auth.styles";

const RecoverySentPage = () => {
  const classes = useStyles();
  const recoverPassword = useAction(authActions.recoverPassword);

  const location = useLocation();

  const resendEmail = useCallback(() => {
    recoverPassword({ email: location.query.email });
  }, [location, recoverPassword]);

  return (
    <Box className={classes.authCard}>
      <Typography variant="h2" component="h1" className={classes.titleText}>
        Forgot Password
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitleText}>
        We’ve sent you an email with a link and instructions to reset your password.
        <br />
        <br />
        Didn’t receive the email? Check your junk mail or have another one sent to you by clicking
        the button below.
      </Typography>
      <div className={classes.buttonBox + " " + classes.buttonBoxSingle}>
        <Button color="primary" variant="contained" type="button" onClick={resendEmail}>
          RESEND EMAIL
        </Button>
      </div>
    </Box>
  );
};

export default React.memo(RecoverySentPage);
