import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FilterButton } from "../../buttons";
import BillingHistoryTable from "../../tables/BillingHistoryTable";
import { useAction, agencyActions, superAdminSelectors, useSelector } from "../../../state";
import { useInputDebounced } from "../../../lib";
import { SearchField } from "../../inputs";

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3),
    border: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: 0,
  },
}));

interface Props {
  view: "super_admin" | "agency" | "facility";
}

const BillingHistoryTab = (props: Props) => {
  const { view } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const agency = useSelector(superAdminSelectors.activeAgency);

  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(agencyActions.setSearchQuery);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  return (
    <Box>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={4}>
          <SearchField
            id="search"
            label="Search for caregiver id or name"
            value={searchQuery}
            onChange={handleChange}
          />
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between">
            <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
            {/*<Button*/}
            {/*  variant="contained"*/}
            {/*  color="primary"*/}
            {/*  component={RouterLink}*/}
            {/*  to="/agency/patients/create"*/}
            {/*  startIcon={<GetAppIcon />}*/}
            {/*>*/}
            {/*  Add*/}
            {/*</Button>*/}
          </Grid>
        </Grid>
      </Grid>

      <BillingHistoryTable view={view} tabView isFiltersVisible={expanded} entity={agency} />
    </Box>
  );
};

export default React.memo(BillingHistoryTab);
