import React, { useEffect, useState } from "react";
import { Box, TextField, Typography } from "@material-ui/core";
import { createDateSlots } from "../../../../utils";
import { useStyles } from "./AppointmentTimeSlots.styles";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";

const AppointmentTimeSlots = ({ form, facility }) => {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(form?.values?.timeframe ?? new Date());
  let availableDates: any = [];
  try {
    availableDates = facility?.hours ? createDateSlots(facility.hours) : null;
  } catch (e) {
    console.error(e);
  }

  useEffect(() => {
    form.setFieldValue("timeframe", selectedDate?.toString());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return Boolean(availableDates) ? (
    <Box className={classes.container}>
      <LocalizationProvider dateAdapter={DateFnsUtils}>
        <DatePicker
          renderInput={(props) => <TextField {...props} variant="outlined" />}
          value={selectedDate}
          disablePast={true}
          onChange={(date: any) => handleDateChange(date)}
        />
      </LocalizationProvider>
    </Box>
  ) : (
    <Typography align="center">No available time for selected medical facility.</Typography>
  );
};

export default React.memo(AppointmentTimeSlots);
