import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    fontSize: 34,
    fontWeight: "bold",
    letterSpacing: 0.25,
    textTransform: "uppercase",
  },
  filterRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
}));
