import React from "react";
import { Grid, Button, TextField, Card, CardHeader, CardContent } from "@material-ui/core";

import {
  useAction,
  authActions,
  useSelector,
  authSelectors,
  superAdminActions,
} from "../../../../state";
import { Form, useFormik, yup } from "../../../../lib";

import { useStyles } from "../ProfileForms.styles";
import { Navigation } from "../../../../lib";
import SuperAdminPages from "../../../../pages/super-admin";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
});

const ProfileForm = ({ isNewUser = false }) => {
  const classes = useStyles();

  const user = useSelector(authSelectors.user) || {};
  const updateProfile = useAction(authActions.updateProfile);
  const addAdmin = useAction(superAdminActions.createAdmin);
  const emptyUser = {
    first_name: "",
    last_name: "",
    email: "",
  };
  const profileForm = useFormik({
    initialValues: isNewUser ? emptyUser : user,
    validationSchema,
    async onSubmit(values: any) {
      if (isNewUser) {
        const result: any = await addAdmin(values);
        if (result.status === 200) {
          Navigation.go(SuperAdminPages.adminUsers.path);
        }
      } else {
        await updateProfile(values);
      }
    },
  });

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardHeader className={classes.sectionTitle} title="Personal details" />
      <CardContent className={classes.formWrap}>
        <Form form={profileForm}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="first_name"
                name="first_name"
                label="First Name"
                value={profileForm.values.first_name}
                onChange={profileForm.handleChange}
                error={profileForm.touched.first_name && Boolean(profileForm.errors.first_name)}
                helperText={profileForm.touched.first_name && profileForm.errors.first_name}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="last_name"
                name="last_name"
                label="Last Name"
                value={profileForm.values.last_name}
                onChange={profileForm.handleChange}
                error={profileForm.touched.last_name && Boolean(profileForm.errors.last_name)}
                helperText={profileForm.touched.last_name && profileForm.errors.last_name}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label="Email address"
                onChange={profileForm.handleChange}
                error={profileForm.touched.email && Boolean(profileForm.errors.email)}
                value={profileForm.values.email}
                disabled={!isNewUser}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={profileForm.isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Card>
  );
};

export default ProfileForm;
