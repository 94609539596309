import React from "react";

import { Box, Button } from "@material-ui/core";

import { useStyles } from "./ButtonPill.styles";

const ButtonPill = ({ children, ...props }) => {
  const classes = useStyles(props);

  return (
    <Button variant="outlined" classes={{ root: classes.root }} {...props}>
      {children}
      {props.badge !== undefined && <Box className={classes.badge}>{props.badge}</Box>}
    </Button>
  );
};

export default React.memo(ButtonPill);
