import ProfilePage from "./ProfilePage";
import UploadUpdatesPage from "./UploadUpdatesPage";
import { HomePage } from "./HomePage/HomePage";
import { NotFoundPage } from "./NotFoundPage";

export const MainPages = {
  profile: {
    anon: true,
    path: "/profile",
    title: "Profile",
    type: "PAGE_PROFILE",
    view: ProfilePage,
  },
  updates: {
    anon: true,
    path: "/updates",
    title: "Upload Updates",
    type: "UPLOAD_UPDATES_PAGE",
    view: UploadUpdatesPage,
  },
  home: {
    pathExact: true,
    anon: true,
    path: "/",
    title: "Home Page",
    type: "HOME_PAGE",
    view: HomePage,
  },
  notFound: {
    anon: true,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
};

export default MainPages;

export const MainArea = {
  pages: MainPages,
};
