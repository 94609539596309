import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Modal from "./Modal";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(3),
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
    textTransform: "uppercase",
  },
  button: {
    margin: 8,
  },
  subText: {
    maxWidth: 400,
    fontStyle: "italic",
    fontSize: 14,
  },
  modalFooter: {
    paddingTop: theme.spacing(1),
  },
  infoText: {
    marginTop: 8,
  },
}));

interface DeleteModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  deleteMessage: string;
  deleteSubMessage: string;
  setConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
}

const DeleteConfirmationModal = (props: DeleteModalProps) => {
  const { show, setShow, deleteMessage, setConfirmDelete, deleteSubMessage } = props;
  const classes = useStyles();

  const handleCloseModal = () => {
    setShow(false);
  };

  return (
    <Modal open={show} handleClose={handleCloseModal}>
      <Typography variant="h4" className={classes.header}>
        {deleteMessage}
      </Typography>
      <Typography className={classes.subText}>{deleteSubMessage}</Typography>
      <Grid
        component="footer"
        container
        justifyContent="flex-end"
        classes={{ container: classes.modalFooter }}
      >
        <Button onClick={handleCloseModal} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => setConfirmDelete(true)}
          color="primary"
          size="small"
          className={classes.button}
          variant="contained"
        >
          Yes, Delete
        </Button>
      </Grid>
    </Modal>
  );
};

export default React.memo(DeleteConfirmationModal);
