import { createSlice } from "@reduxjs/toolkit";

export interface FacilityState {
  name?: string;
}

const initialState: FacilityState = {};

export const facility = {
  ...createSlice({
    name: "facility",
    initialState,
    reducers: {},
  }),
  persist: false,
};
