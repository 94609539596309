import React from "react";
import { Box, Link, Typography } from "@material-ui/core";

import BillingTabs from "../../components/tabs/BillingTabs";
import {
  useAction,
  superAdminActions,
  useSelector,
  authSelectors,
  agencySelectors,
} from "../../state";

const BillingPage = () => {
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const getContractLink = useAction(superAdminActions.getContractLink);
  const agency = useSelector(agencySelectors.agency);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle2">Billing</Typography>
        <Link
          style={{ marginTop: 20, cursor: "pointer" }}
          onClick={() => getContractLink(activeEntityId)}
        >
          View Contract
        </Link>
      </Box>
      <BillingTabs view="agency" entity={agency} />
    </Box>
  );
};

export default React.memo(BillingPage);
