import React, { useCallback } from "react";
import { Link as RRDLink } from "react-router-dom";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import {
  LaunchIcon,
  ScheduleIcon,
  PhoneIconOutlined,
  DescriptionOutlineIcon,
  LocationOutlineIcon,
} from "../../../components/icons";
import { EditButton } from "../../../components/buttons";
import { StatusAvailabilityTag } from "../../../components/tags";

import Pages from "../../../pages";
import { AGENCY_TYPE } from "../../../constants";
import { useLocation } from "../../../lib";
import { useAction, authActions, superAdminActions } from "../../../state";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 34,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  icon: {
    marginRight: "8px",
  },
  telLink: {
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  contractLink: {
    color: theme.palette.primary.main,
    "& .MuiLink-underlineHover": {
      cursor: "pointer",
    },
  },
  contract: {
    paddingRight: 5,
    fontSize: "0.9375rem",
    fontWeight: 500,
    color: "#939393",
  },
}));

const EntityHeader = ({ entity }) => {
  const classes = useStyles();

  const impersonate = useAction(authActions.impersonate);
  const getContractLink = useAction(superAdminActions.getContractLink);

  const {
    params: { id },
  }: any = useLocation();

  const isAgency = entity.type === AGENCY_TYPE;

  const handleImpersonate = useCallback(() => {
    impersonate(id);
  }, [impersonate, id]);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={RRDLink as any}
          underline="hover"
          color="inherit"
          to={Pages.superAdmin[isAgency ? "agencies" : "facilities"].path}
        >
          {isAgency ? "Agencies" : "Facilities"}
        </Link>

        <Typography color="textPrimary">{entity.name}</Typography>
      </Breadcrumbs>
      <Grid component="header" container>
        <Grid
          container
          xs={12}
          md={12}
          item
          justifyContent="space-between"
          alignItems="center"
          style={{ marginBottom: 16, marginTop: 16 }}
        >
          <Grid item xs={9} container alignItems="center" style={{ gap: 30 }}>
            <Typography variant="h5" className={classes.title}>
              {entity.name}
            </Typography>
            <StatusAvailabilityTag status={entity.active} />
          </Grid>

          <Grid
            item
            xs={3}
            container
            alignItems="center"
            justifyContent="flex-end"
            style={{ gap: 24 }}
          >
            <RRDLink
              to={Pages.superAdmin[isAgency ? "editAgency" : "editFacility"].path.replace(
                ":id",
                id,
              )}
            >
              <EditButton />
            </RRDLink>
            <Button
              onClick={handleImpersonate}
              color="primary"
              size="large"
              variant="contained"
              startIcon={<LaunchIcon />}
            >
              Login As {isAgency ? "Agency" : "Facility"}
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={3}>
          <Grid item>
            <a href={`tel:${entity?.phone}`} className={classes.telLink}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <PhoneIconOutlined className={classes.icon} />
                <NumberFormat
                  format="+1 (###) ###-####"
                  mask="_"
                  value={entity?.phone}
                  displayType="text"
                />
              </Grid>
            </a>
          </Grid>
          {entity.address && (
            <Grid item>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <LocationOutlineIcon className={classes.icon} />
                {entity.address}
              </Grid>
            </Grid>
          )}
          <Grid item>
            {isAgency ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={classes.contractLink}
              >
                <DescriptionOutlineIcon className={classes.icon} />
                <Link underline="always" onClick={() => getContractLink(id)}>
                  View Contract
                </Link>
              </Grid>
            ) : (
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <ScheduleIcon className={classes.icon} />
                {entity.appointments_per_hour} Appointments per hour
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(EntityHeader);
