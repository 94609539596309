import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Table from "../Table";

import {
  TABLE_ROW_EDIT_ACTION,
  SUPER_ADMIN_REGION_TABLE_COLUMNS,
  SUPER_ADMIN_LABS_TABLE_COLUMN_EXTENSIONS,
} from "../../../constants";
import SuperAdminPages from "../../../pages/super-admin";
import { useAction, superAdminActions, useSelector, superAdminSelectors } from "../../../state";

// TODO add types and interfaces
const RegionsTable = props => {
  const history = useHistory();

  const getRegions = useAction(superAdminActions.getRegions);
  const deleteRegion = useAction(superAdminActions.deleteRegion);

  const handleDelete = useCallback(
    r => {
      deleteRegion(r.id);
    },
    [deleteRegion],
  );

  const handleEdit = useCallback(
    e => history.push(SuperAdminPages.editRegion.path.replace(":id", e.id)),
    [history],
  );

  useEffect(() => {
    getRegions();
  }, [getRegions]);

  const regions = useSelector(superAdminSelectors.regions);

  return (
    <Table
      {...props}
      rows={regions}
      editHandler={handleEdit}
      deleteHandler={handleDelete}
      columns={SUPER_ADMIN_REGION_TABLE_COLUMNS}
      tableColumnExtensions={SUPER_ADMIN_LABS_TABLE_COLUMN_EXTENSIONS}
      actions={[TABLE_ROW_EDIT_ACTION]}
      exportEnabled={true}
      exportedFileName="Regions"
    />
  );
};

export default React.memo(RegionsTable);
