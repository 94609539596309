import React, { useCallback, useMemo, useState } from "react";
import { Box, Grid, Button, Typography, IconButton, CircularProgress } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Modal from "../../../../../components/modals/Modal";
import { CloseIcon } from "../../../../../components/icons";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../../../state";
import { useStyles } from "./AppointmentServices.styles";

const AppointmentRateCodesModal = (props) => {
  const { open, setOpen } = props;
  const classes = useStyles();
  const patientRateCodes = useSelector(agencySelectors.patientRateCodes);
  const openedAppointment = useSelector(agencySelectors.openedAppointment);
  const patientRateCodesLoading = useSelector(agencySelectors.patientRateCodesLoading);
  const addAppointmentRateCode = useAction(agencyActions.addAppointmentRateCode);
  const [selectedServices, setSelectedServices] = useState<Record<any, any>[]>([]);
  const view = useSelector(authSelectors.activeEntityType);

  const rateCodes = useMemo(
    () => openedAppointment?.appointment_rate_codes || [],
    [openedAppointment?.appointment_rate_codes],
  );

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleServices = useCallback(
    (event: React.MouseEvent<HTMLElement>, newServices: Record<any, any>[]) => {
      setSelectedServices(newServices);
    },
    [],
  );

  const handleAddSelectedServices = useCallback(async () => {
    addAppointmentRateCode(
      view !== "facility"
        ? openedAppointment.patient.home_health_agency_id
        : openedAppointment.medical_facility_id,
      {
        openedAppointment,
        rateCodes: selectedServices.map((r) => r.id),
      },
      view,
    );
    setSelectedServices(null);
    setOpen(false);
  }, [addAppointmentRateCode, openedAppointment, selectedServices, setOpen, view]);

  return (
    <Modal open={open} handleClose={() => setOpen(false)}>
      {patientRateCodesLoading ? (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress size={32} />
        </Grid>
      ) : patientRateCodes.length ? (
        <>
          <Grid
            container
            component="header"
            justifyContent="space-between"
            alignItems="center"
            classes={{ container: classes.header }}
          >
            <Grid item>
              <Typography variant="h4" component="h1">
                Add services
              </Typography>
            </Grid>

            <Grid item>
              <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Grid>
          </Grid>

          <Box className={classes.servicesList}>
            <ToggleButtonGroup
              className={classes.servicesGroup}
              value={selectedServices}
              onChange={handleServices}
            >
              {patientRateCodes.map((code) => (
                <ToggleButton
                  type="button"
                  key={`service-${code.id}`}
                  className={classes.service}
                  value={code}
                  disabled={
                    rateCodes
                      .map((rateCode) => rateCode.hha_service_id) //toDO: figure out what this is doing
                      .indexOf(code.id) !== -1
                  }
                >
                  {code.default_rate_code.code} | {code.default_rate_code.description}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Grid
            container
            component="footer"
            justifyContent="flex-end"
            alignItems="center"
            classes={{ container: classes.modalFooter }}
            spacing={2}
          >
            <Grid item>
              <Button variant="outlined" color="primary" size="large" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={handleAddSelectedServices}
                disabled={!selectedServices?.length}
              >
                Add Selected
              </Button>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Modal>
  );
};

export default AppointmentRateCodesModal;
