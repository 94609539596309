import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Box, Typography } from "@material-ui/core";

import { AddButton } from "../../../components/buttons";
import UsersTable from "../../../components/tables/UsersTable";

import AgencyPages from "../index";
import { TABLE_ROW_DELETE_ACTION, TABLE_ROW_EDIT_ACTION } from "../../../constants";

import { useStyles } from "./UsersPage.styles";
import { authSelectors, useSelector } from "../../../state";
// import { useRole } from "../../../hooks";
// import { facility } from "../../../state/facility/state";

// TODO add types and interfaces
const UsersPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const agency = useSelector(authSelectors.activeEntity);
  // const { isFacilityRole } = useRole();
  const handleDelete = useCallback(() => {}, []);
  const handleEdit = useCallback(
    (e) => {
      history.push(AgencyPages.editUser.path.replace(":id", e.id));
    },
    [history],
  );

  return (
    <Box>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Typography variant="subtitle2">Users</Typography>
        <Grid item>
          <AddButton to={AgencyPages.createUser.path} />
        </Grid>
      </Grid>

      <UsersTable
        view="agency_admin"
        editHandler={handleEdit}
        deleteHandler={handleDelete}
        actions={[TABLE_ROW_EDIT_ACTION, TABLE_ROW_DELETE_ACTION]}
        entity={agency}
      />
    </Box>
  );
};

export default React.memo(UsersPage);
