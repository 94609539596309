import React from "react";

import Table from "../Table";

import { superAdminSelectors, useSelector } from "../../../state";
import {
  SUPER_ADMIN_AGENCIES_TABLE_COLUMNS,
  SUPER_ADMIN_AGENCIES_TABLE_COLUMN_EXTENSIONS,
} from "../../../constants";
import SuperAdminPages from "../../../pages/super-admin";

// TODO add types and interfaces
const AgenciesTable = ({ ...props }) => {
  const agencies = useSelector(superAdminSelectors.agencies);
  const searchQuery = useSelector(superAdminSelectors.searchQuery);

  return (
    <Table
      {...props}
      rows={agencies}
      columns={SUPER_ADMIN_AGENCIES_TABLE_COLUMNS}
      searchValue={searchQuery}
      tableColumnExtensions={SUPER_ADMIN_AGENCIES_TABLE_COLUMN_EXTENSIONS}
      rowBaseUrl={SuperAdminPages.agencies.path}
      exportEnabled={false}
      exportedFileName="agencies"
    />
  );
};

export default React.memo(AgenciesTable);
