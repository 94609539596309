import { makeStyles } from "@material-ui/core";

interface tagStylesProps {
  backgroundColor: string;
  color?: string;
}

export const useStyles = makeStyles({
  tag: {
    padding: "0px 8px",
    width: "max-content",
    borderRadius: 100,
    textAlign: "center",
    lineHeight: "21px",
    fontSize: 12,
    fontWeight: 500,
    color: (props: tagStylesProps) => props.color,
    backgroundColor: (props: tagStylesProps) => props.backgroundColor,
  },
  rcTag: {
    marginLeft: 16,
    borderRadius: 20,
    textAlign: "center",
    lineHeight: "20px",
    fontSize: 12,
    fontWeight: 400,
    height: "20px",
    padding: "0px 8px",
    color: (props: tagStylesProps) => props.color,
    backgroundColor: (props: tagStylesProps) => props.backgroundColor,
  },
  tagAvailability: {
    position: "relative",
    paddingLeft: 20,

    "&:before": {
      content: "''",
      position: "absolute",
      top: "50%",
      left: 0,
      width: 10,
      height: 10,
      transform: "translate(0, -50%)",
      borderRadius: "50%",
      backgroundColor: (props: tagStylesProps) => props.backgroundColor,
    },
  },
});
