import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Grid,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import { CancelOutlinedIcon, CheckCircleOutlineIcon } from "../../../icons";
import { authSelectors, useSelector } from "../../../../state";
import { DeleteOutlinedIcon, DescriptionOutlineIcon, DownloadOutlinedIcon } from "../../../icons";

const useStyles = makeStyles((theme) => ({
  btnText: {
    marginLeft: 8,
    textDecoration: "underline",
  },
  approved: {
    marginLeft: 8,
    marginRight: 16,
    fontSize: 14,
    fontWeight: 600,
    color: "#403E3E",
  },
  textContainer: {
    color: theme.palette.primary.main,
    alignItems: "center",
    gap: "8px",
    flexWrap: "nowrap",
  },
}));

interface FileUploadItemProps {
  primaryText?: string;
  downloadEnabled?: boolean;
  downloadHandler?: any;
  deleteEnabled?: boolean;
  approved?: boolean;
  deleteHandler?: any;
  approveHandler?: any;
  unapproveHandler?: any;
  background?: boolean;
  hideApprove?: boolean;
}

const FileUploadItem = ({
  primaryText,
  background = true,
  downloadEnabled,
  deleteEnabled = false,
  approved,
  downloadHandler,
  deleteHandler,
  approveHandler,
  unapproveHandler,
  hideApprove = false,
}: FileUploadItemProps) => {
  const isSuperAdmin = useSelector(authSelectors.isSuperAdmin);
  const classes = useStyles();
  return (
    <ListItem
      style={{
        backgroundColor: background ? "#ECECEC" : "inherit",
        marginBottom: 8,
        height: 54,
        borderRadius: 3,
      }}
    >
      <Grid container className={classes.textContainer}>
        <DescriptionOutlineIcon />
        <ListItemText
          primary={primaryText}
          onClick={downloadEnabled && downloadHandler}
          style={{
            textDecoration: downloadEnabled ? "underline" : "none",
            cursor: downloadEnabled ? "pointer" : "",
          }}
        />
      </Grid>

      <ListItemIcon style={{ display: "flex", alignItems: "center", color: "#403E3E" }}>
        {isSuperAdmin && approveHandler && !hideApprove && (
          <>
            {!approved && (
              <Button onClick={approveHandler}>
                <CheckCircleOutlineIcon fontSize="small" />
                <Typography className={classes.btnText}>Approve document</Typography>
              </Button>
            )}
            {approved && (
              <>
                <CheckCircleOutlineIcon fontSize="small" />
                <Typography className={classes.approved}>APPROVED</Typography>
                <Button onClick={unapproveHandler}>
                  <CancelOutlinedIcon fontSize="small" />
                  <Typography className={classes.btnText}>Unapprove</Typography>
                </Button>
              </>
            )}
          </>
        )}
        {downloadEnabled && (
          <IconButton onClick={downloadHandler}>
            <DownloadOutlinedIcon />
          </IconButton>
        )}
        {deleteEnabled && (
          <IconButton onClick={deleteHandler}>
            <DeleteOutlinedIcon />
          </IconButton>
        )}
      </ListItemIcon>
    </ListItem>
  );
};

export default FileUploadItem;
