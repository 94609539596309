import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    flex: {
      display: "flex",
      alignItems: "center",
    },
    updateTotals: {
      justifyContent: "space-between",
      display: "flex",
      flexDirection: "column",
    },
    fullHeight: {
      height: "100%",
    },
    container: {
      paddingTop: "6em",
    },
    header: {
      paddingBottom: "3em",
    },
    cardWrap: {
      padding: theme.spacing(2),
    },
    firstRowCard: {
      minHeight: 360,
    },
    card: {
      borderRadius: 0,
    },
    cardPadding: {
      paddingLeft: "2em",
      paddingRight: "2em",

      "& .MuiCardHeader-content .MuiTypography-root": {
        fontSize: "1.15em",
      },
    },
    cardHeaderAction: {
      marginRight: 0,
    },
    bottomLinks: {
      marginBottom: 17,
    },
    ml20: {
      marginLeft: 20,
    },
  }),
  { classNamePrefix: "AgencyDashboardPage" },
);
