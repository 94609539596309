import React, { useCallback, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { fileActions } from "../../../state";
import { useStyles } from "./FileUpload.styles";
import { useDispatch } from "../../../state/utils";
import { CloudUploadOutlinedIcon } from "../../icons";

interface FileUploadProps {
  name?: string;
  accept: string;
  uploadType: string;
  // TODO do not use ANY!
  form?: any;
  classNames?: any;
  onUpload?: any;
  id?: any;
  maxFiles?: number;
  multiple?: boolean;
  downloadEnabled?: boolean;
  downloadHandler?: any;
  showZone?: any;
}

const Dropzone = ({
  form = {},
  name = "",
  accept,
  uploadType,
  classNames,
  onUpload,
  id,
  maxFiles = 1,
  multiple = false,
}: FileUploadProps) => {
  const isManual = Boolean(id);
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fileName, setFileName] = useState(null);
  const formFileError = isManual ? null : form?.touched[name] && Boolean(form.errors[name]);
  const classes = useStyles({ fileError: isManual ? null : formFileError });

  const onDrop = useCallback(
    (acceptedFiles) => {
      const uploadedFiles = acceptedFiles.map((file) => {
        if (uploadType === "public") {
          return dispatch(fileActions.uploadPublic(file));
        } else {
          return dispatch(fileActions.uploadPrivate(file));
        }
      });

      Promise.all(uploadedFiles).then((values: any) => {
        const response = multiple
          ? values.filter((value) => value.status === 200).map((value) => value.data.fileName)
          : values.length && values[0].data.fileName;

        if (isManual) {
          onUpload({
            id,
            [multiple ? "fileNames" : "fileName"]: response,
          });
          setFileName(response);
        } else {
          form.setFieldValue(name, response);
        }
      });
    },
    [uploadType, dispatch, multiple, isManual, onUpload, id, form, name],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    maxFiles,
  });
  return (
    <Box {...getRootProps()} className={clsx(classes.uploadWrap, classNames?.wrap)}>
      <input {...getInputProps()} />
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <CloudUploadOutlinedIcon fontSize="large" className={classes.uploadIcon} />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className={classes.uploadText}
        >
          {isDragActive ? (
            <Typography>Drop a file here ...</Typography>
          ) : (
            <Typography>
              Drag and drop file here or
              <Button
                color="primary"
                style={{
                  textDecoration: "underline",
                  paddingBottom: 8,
                  textTransform: "none",
                }}
                autoFocus
              >
                browse
              </Button>
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(Dropzone);
