import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { FilterButton } from "../../../components/buttons";
import PatientsTable from "../../../components/tables/PatientsTable";
import { useInputDebounced } from "../../../lib";
import { agencyActions, useAction } from "../../../state";
import { useStyles } from "./PatientsPage.styles";
import { SearchField } from "../../../components/inputs";

// TODO add types and interfaces
const PatientsPage = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(agencyActions.setSearchQuery);
  const setEditablePatient = useAction(agencyActions.setEditablePatient);

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  useEffect(() => {
    setEditablePatient(null);
  }, [setEditablePatient]);

  return (
    <Box>
      <Typography variant="subtitle2">Caregivers</Typography>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={4}>
          <SearchField
            label="Search for caregiver id or name"
            id="search"
            value={searchQuery}
            onChange={handleChange}
          />
        </Grid>

        <Grid item>
          <Grid container>
            <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
          </Grid>
        </Grid>
      </Grid>

      <PatientsTable view="facility" isFiltersVisible={expanded} />
    </Box>
  );
};

export default React.memo(PatientsPage);
