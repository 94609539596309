import React from "react";
import { Router, Switch, Route } from "react-router-dom";
// import { io } from "socket.io-client";
import Pages from "./pages";
import { AuthLayout, MainLayout } from "./layouts";
import { RouteSecurityProvider, history, renderPageRoutes } from "./lib";
import { useSelector, authSelectors } from "./state";

export const App = React.memo(function App() {
  const auth = useSelector(authSelectors.route);
  // const socket = io(`${REACT_APP_SOCKET_URL}`, { auth: { token: token } });

  // socket.on("disconnect", (reason) => {
  // dispatch(uiActions.showError(`Disconnect: ${reason}`));
  // });

  // socket.on("error", reason => {
  //   dispatch(uiActions.showError(`Chat error: ${reason}`));
  // });

  // socket.on("new_message", ({ action }) => {
  //   if (action === "refresh") {
  //     dispatch(uiActions.showSuccess(`New message!`));
  //     dispatch(agencyActions.getChats(activeEntityId, view));
  //   }
  // });

  return (
    <RouteSecurityProvider auth={auth} check={checkAuth}>
      <Router history={history}>
        <Switch>
          <Route path="/auth">
            <AuthLayout>
              <Switch>{renderPageRoutes(Pages.auth)}</Switch>
            </AuthLayout>
          </Route>
          <Route path="/agency">
            <MainLayout>
              <Switch>{renderPageRoutes(Pages.agency)}</Switch>
            </MainLayout>
          </Route>
          <Route path="/super-admin">
            <MainLayout>
              <Switch>{renderPageRoutes(Pages.superAdmin)}</Switch>
            </MainLayout>
          </Route>
          <Route path="/facility">
            <MainLayout>
              <Switch>{renderPageRoutes(Pages.facilities)}</Switch>
            </MainLayout>
          </Route>
          <Route path="/">
            <MainLayout>
              <Switch>{renderPageRoutes(Pages.main)}</Switch>
            </MainLayout>
          </Route>
        </Switch>
      </Router>
    </RouteSecurityProvider>
  );
});

/**
 * Function that should return true to allow the guarded route to render or
 * false to redirect using the default redirect which was passed to
 * `RouteSecurityProvider` (if any) or a `LocationDescriptor` object to redirect
 * to a specific `pathname` and `search` query.
 *
 * @param {RouteCheckProps<ReturnType<typeof authSelectors.route>>} props
 * @returns {ReturnType<RouteCheckProps["check"]>}
 */
function checkAuth(props: any) {
  const { auth, location, roles: requiredRoles } = props;
  let allowed = false;

  if (auth.isAdmin === true) return true;

  if (requiredRoles) {
    allowed = requiredRoles.includes(auth.activeRole);
  } else {
    allowed = auth.isLoggedIn;
  }

  if (!allowed) {
    // Return a LocationDescriptor object to redirect to login, then back to
    // the current location after login...
    return {
      pathname: Pages.auth.login.path,
      search: "?after=" + location.pathname + location.search,
    };
  }
  // Return true to allow the route to render.
  return true;
}
