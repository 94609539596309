import React, { useCallback, useEffect, useState } from "react";
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import Table from "../Table";
import Modal from "../../modals/Modal";
import { CloseIcon } from "../../icons";
import AgencyRateCodeRow from "./AgencyRateCodeRow";
import {
  useDispatch,
  useAction,
  uiActions,
  superAdminActions,
  useSelector,
  superAdminSelectors,
} from "../../../state";
import {
  TABLE_ROW_EDIT_ACTION,
  EDIT_RATE_CODE_INITIAL_VALUES as initialValues,
  SUPER_ADMIN_AGENCY_RATE_CODES_TABLE_COLUMNS as columns,
} from "../../../constants";
import { Form, useFormik, yup } from "../../../lib";
import { useStyles } from "./RateCodesTable.styles";
import DeactivateBundleRateCodeConfirmationModal from "../../modals/DeactivateBundleRateCodeConfirmationModal";

const validationSchema = yup.object({});

const RateCodesTable = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [rateCodeUpdate, setRateCodeUpdate] = useState(false);
  const [newValues, setNewValues]: any = useState();
  const [openedRateCode, setOpenedRateCode] = useState(initialValues);

  const setLoading = useAction(uiActions.setLoading);
  const updateAgencyRateCode = useAction(superAdminActions.updateAgencyRateCode);
  const getBundlesForRateCode = useAction(superAdminActions.getBundlesForRateCode);
  const bundleForRateCodes = useSelector(superAdminSelectors.bundlesForRateCodes);

  useEffect(() => {
    if (props.agency.id) {
      dispatch(superAdminActions.getRateCodes(props.agency.id));
    }
  }, [dispatch, props.agency.id]);

  useEffect(() => {
    if (openedRateCode?.id) {
      getBundlesForRateCode(openedRateCode?.id);
    }
  }, [getBundlesForRateCode, openedRateCode?.id]);

  const rateCodes: any[] = useSelector(superAdminSelectors.rateCodes);

  const handleEdit = useCallback(
    (row) => {
      const rateCode = rateCodes.find((code) => code.id === row.id);
      setOpen(true);
      setOpenedRateCode(rateCode);
    },
    [rateCodes],
  );

  const handleCloseModal = useCallback(() => {
    setOpen(false);
  }, []);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: openedRateCode,
    validationSchema,
    async onSubmit(values) {
      if (openedRateCode.active && values.active === false && !!bundleForRateCodes.length) {
        handleCloseModal();
        setNewValues(values);
        setOpenConfirmationModal(true);
      } else {
        handleSubmit(values);
      }
    },
  });

  const handleSubmit = async (values) => {
    setLoading(true);
    await updateAgencyRateCode(props.agency.id, openedRateCode.id, values);
    setLoading(false);
    handleCloseModal();
  };

  useEffect(() => {
    if (rateCodeUpdate) {
      setOpenConfirmationModal(false);
      handleSubmit(newValues);
      setRateCodeUpdate(false);
      setNewValues(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateCodeUpdate]);

  return (
    <>
      <Table
        rows={rateCodes}
        columns={columns}
        editHandler={handleEdit}
        actions={[TABLE_ROW_EDIT_ACTION]}
        exportEnabled={true}
        exportedFileName="AgencyRateCodes"
      />
      {
        <Modal open={open} handleClose={handleCloseModal}>
          <Form form={form}>
            <Grid
              component="header"
              container
              justifyContent="space-between"
              alignItems="center"
              classes={{ container: classes.header }}
            >
              <Grid item>
                <Typography variant="h4" component="h1">
                  Edit rate code
                </Typography>
              </Grid>

              <Grid item>
                <IconButton edge="start" color="inherit" onClick={handleCloseModal}>
                  <CloseIcon className={classes.closeIcon} />
                </IconButton>
              </Grid>
            </Grid>

            <AgencyRateCodeRow form={form} rateCode={openedRateCode} />

            <Grid
              component="footer"
              container
              justifyContent="space-between"
              alignItems="center"
              classes={{ container: classes.modalFooter }}
            >
              <Grid item>
                <Button variant="contained" color="primary" size="large" type="submit">
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Modal>
      }
      <DeactivateBundleRateCodeConfirmationModal
        open={openConfirmationModal}
        form={form}
        setOpen={setOpenConfirmationModal}
        setRateCodeUpdate={setRateCodeUpdate}
        setCancelUpdate={setOpen}
      />
    </>
  );
};

export default React.memo(RateCodesTable);
