import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, IconButton, Typography } from "@material-ui/core";

import { ArrowBackIcon } from "../../../../components/icons";
import CreateAppointmentStepper from "../../../../components/steppers/CreateAppointmentStepper";

import { useSelector, agencySelectors } from "../../../../state";

import { useStyles } from "./NewAppointmentPage.styles";

const NewAppointmentPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const isAppointmentConfirmed = useSelector(agencySelectors.isAppointmentConfirmed);

  const handleBackClick = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <>
      <Grid
        container
        component="header"
        alignItems="center"
        justifyContent={isAppointmentConfirmed ? "center" : "flex-start"}
        spacing={1}
        className={classes.header}
      >
        {!isAppointmentConfirmed && (
          <Grid item>
            <IconButton onClick={handleBackClick}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <Typography variant="h4" component="h1">
            {!isAppointmentConfirmed ? "Create Appointment" : "Appointment Confirmed"}
          </Typography>
        </Grid>
      </Grid>

      <CreateAppointmentStepper entityType={"agency"} />
    </>
  );
};

export default React.memo(NewAppointmentPage);
