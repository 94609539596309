import { Box } from "@material-ui/core";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useStyles } from "./index";

const _EditorField = ({ editorState, onChange }) => {
  const classes = useStyles();
  return (
    <Box className={classes.editorContainer}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapper"
        editorClassName="editorClassName"
        onEditorStateChange={onChange}
      />
    </Box>
  );
};

export const EditorField = React.memo(_EditorField);
