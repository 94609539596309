import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  root: {
    minWidth: 117,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 14,
    paddingRight: 24,
    justifyContent: "space-between",
  },
  label: {
    lineHeight: "2.25rem",
  },
}));
