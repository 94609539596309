import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    maxWidth: 950,
    paddingTop: "2em",
  },
  sectionTitle: {
    fontSize: 16,
    marginTop: 16,
    marginBottom: 16,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  formControl: {
    flexGrow: 1,
  },
  formActions: {
    marginTop: 24,
  },
  switch: {
    marginTop: 16,
  },
  serviceTitle: {
    borderBottom: "1px solid lightgrey",
    fontSize: 16,
    marginTop: 16,
    paddingBottom: 16,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  serviceRows: {
    padding: 8,
    borderBottom: "1px solid lightgrey",
  },
}));
