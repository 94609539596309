import {
  add,
  eachDayOfInterval,
  eachHourOfInterval,
  format,
  isFriday,
  isMonday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  set,
} from "date-fns";

export const formatToDate = (date) => date && format(new Date(date), "MM/dd/yyyy");
export const formatToDateTime = (date) => (date ? format(new Date(date), "M/d/yyyy") : "");
export const formatToDateTimeMinutes = (date) => format(new Date(date), "M/d/yy - h:mm a");
export const formatToTime = (date) => {
  try {
    return format(new Date(date), "h a");
  } catch (e) {
    console.error("Invalid Time", date, e);
    return "Invalid Time";
  }
};
export const formatToShortDayOfWeek = (date) => format(new Date(date), "EEE");
export const formatToFullDayOfWeek = (date) => format(new Date(date), "EEEE");
export const formatDateSlot = (date) => {
  try {
    return format(new Date(date), "EEEE - M/d/yyyy");
  } catch (e) {
    console.error("Invalid Date", date, e);
    return "Invalid Date";
  }
};
export const formatTimeSlot = (time) =>
  `${formatToTime(time)} - ${formatToTime(add(new Date(time), { hours: 1 }))}`;
export const formatAppointmentsHistoryPeriod = (date) => format(new Date(date), "yyyy-M-d");

export function createTimeSlots(day, hours) {
  const hoursKey = isSunday(day)
    ? "sun"
    : isMonday(day)
    ? "mon"
    : isTuesday(day)
    ? "tue"
    : isWednesday(day)
    ? "wed"
    : isThursday(day)
    ? "thu"
    : isFriday(day)
    ? "fri"
    : "sat";
  // if (hours[hoursKey].open == "" && hours[hoursKey].close == "") {
  //   return null;
  // }
  const interval = {
    start: set(day, { hours: hours[hoursKey].open }),
    end: set(day, { hours: hours[hoursKey].close }),
  };

  return eachHourOfInterval(interval).map((hour) => ({
    id: Math.round(Math.random() * 10000),
    time: hour,
    available: true,
  }));
}

export function createDateSlots(hours) {
  const interval = {
    start: new Date(),
    end: add(new Date(), { months: 1 }),
  };

  return eachDayOfInterval(interval).map((day) => ({
    id: Math.round(Math.random() * 10000),
    date: day,
    slots: createTimeSlots(day, hours).slice(0, -1), //TODO:Kludge to remove last slot this way but blame datefns
  }));
}
