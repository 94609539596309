import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Link } from "@material-ui/core";

import { ProfileForm, PasswordForm } from "../../components/forms/Profile";
import { VersionText } from "../../components";

export const useStyles = makeStyles(theme => ({
  title: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  container: {
    width: "65vw",
    margin: "0 auto",
  },
}));

const ProfilePage = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h4" component="h1" className={classes.title}>
        Profile
      </Typography>
      <Box className={classes.container}>
        <ProfileForm />
        <PasswordForm />
      </Box>
      {/** TODO: use react router */}
      <Typography
        style={{
          position: "absolute",
          top: 50,
          right: 100,
        }}
        variant="button"
      >
        <Link href="/auth/logout">Logout</Link>
      </Typography>
      <VersionText />
    </Box>
  );
};

export default React.memo(ProfilePage);
