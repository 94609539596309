import React, { useCallback, useEffect, useState } from "react";
import { Collapse, Grid } from "@material-ui/core";
import Table from "../Table";
import AppointmentsFilters from "./AppointmentsFilters";
import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
  Entity,
} from "../../../state";
import { APPOINTMENTS_FILTERS, APPOINTMENTS_FILTERS_EXTENSIONS } from "../../../constants";
import { useStyles } from "./AppointmentsTable.styles";

interface Props {
  id?: string;
  defaultHiddenColumnNames?: any[];
  showSuperAdminFilters?: boolean;
  entity?: Entity;
  selection?: [];
  onSelectionChange?: any;
  view: "agency" | "facility" | "super_admin" | "global";
  extensions?: any;
  isFiltersVisible: boolean;
  columns: any;
  tabView?: boolean;
  rowBaseUrl?: string;
  defaultSorting?: any;
  appointmentListType?: "current_fac" | "all_fac";
  showSelection?: boolean;
  rowDetailComponent?: any;
  withDetail?: boolean;
}

const AppointmentsTable = (props: Props) => {
  const classes = useStyles(props);
  const getAppointments = useAction(agencyActions.getAppointments);
  const appointments = useSelector(agencySelectors.appointments);
  const appointmentsLoading = useSelector(agencySelectors.appointmentsLoading);
  const searchQuery = useSelector(agencySelectors.searchQuery);
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const [selectedFilters, setSelectedFilters] = useState<any[]>(APPOINTMENTS_FILTERS);

  const handleRemoveFilter = useCallback(
    (status, filterColumn = "appointment_status_id") => {
      const updatedFilters = selectedFilters.map((filter) => {
        if (filter?.columnName !== filterColumn) {
          return { ...filter };
        } else {
          return {
            ...filter,
            value:
              typeof filter.value !== "string"
                ? filter.value.filter((filterValue) => status !== filterValue)
                : "",
          };
        }
      });
      setSelectedFilters(updatedFilters);
    },
    [selectedFilters],
  );

  const handleFilterChange = useCallback(
    (fieldName, value) => {
      const updatedFilters = selectedFilters.map((filter) =>
        filter.columnName === fieldName ? { ...filter, value } : filter,
      );
      setSelectedFilters(updatedFilters);
    },
    [selectedFilters],
  );

  useEffect(() => {
    if (!props.entity && props.view !== "global") {
      return;
    }
    let _view;
    switch (props.view) {
      case "super_admin":
        _view = props.entity.type === "medical_facility" ? "facility" : "agency";
        break;
      case "agency":
        _view = "agency";
        break;
      case "facility":
        _view = "facility";
        break;
      case "global":
        _view = "global";
        break;
    }
    const entityId = props.view === "super_admin" ? props.entity.id : activeEntityId;
    getAppointments(entityId, _view, props.appointmentListType);
  }, [
    getAppointments,
    props.view,
    props.entity,
    activeEntityId,
    props.entity?.type,
    props.appointmentListType,
  ]);

  return (
    <>
      <Collapse
        in={props.isFiltersVisible}
        timeout="auto"
        unmountOnExit
        className={classes.container}
      >
        <Grid container>
          <AppointmentsFilters
            showSuperAdminFilters={props.showSuperAdminFilters}
            selectedFilters={selectedFilters}
            handleFilterChange={handleFilterChange}
            handleRemoveFilter={handleRemoveFilter}
          />
        </Grid>
      </Collapse>
      <Table
        id="appointments-table"
        {...props}
        rows={appointments}
        onSelectionChange={props.onSelectionChange}
        selection={props.selection}
        columns={props.columns}
        loading={appointmentsLoading}
        searchValue={searchQuery}
        withFilters={true}
        selectedFilters={selectedFilters}
        tableColumnExtensions={props.extensions}
        tableFiltersExtensions={APPOINTMENTS_FILTERS_EXTENSIONS}
        exportEnabled={true}
        exportedFileName="Appointments"
        showSelection={props.showSelection}
        withDetail={props.withDetail ?? false}
        rowDetailComponent={props.rowDetailComponent}
      />
    </>
  );
};

export default React.memo(AppointmentsTable);
