import React, { useEffect } from "react";
import { Box } from "@material-ui/core";

import AgencyTabs from "../../../components/tabs/AgencyTabs";
import EntityHeader from "../../../components/headers/EntityHeader";

import { useAction, superAdminActions, useSelector, superAdminSelectors } from "../../../state";

// TODO add types and interfaces
const AgencyPage = ({ ...props }) => {
  const agencyId = Number(props.match.params.id);
  const getAgency = useAction(superAdminActions.getAgency);
  const agency = useSelector(superAdminSelectors.activeAgency);

  useEffect(() => {
    getAgency(agencyId);
  }, [getAgency, agencyId]);

  return (
    <Box>
      <EntityHeader entity={agency} />
      <AgencyTabs entity={agency} />
    </Box>
  );
};

export default React.memo(AgencyPage);
