import { sub } from "date-fns";
import { agency } from "./state";
import { uiActions } from "../ui/actions";
import { authClient } from "../auth/actions";
import { history, parseQueryString } from "../../lib";
import {
  ENTITIES_API,
  AGENCY_API,
  FACILITIES_API,
  PatientInitialValues,
  AppointmentTimeInitialValues,
  AgencyInitialValues,
  AppointmentPatientInitialValues,
  CreateAppointmentServicesInitialValues,
} from "../../constants";
import { clean, formatAppointmentsHistoryPeriod } from "../../utils";
import { AppThunk, View } from "../types";
import { superAdminActions } from "../states";

const { actions } = agency;

export const agencyActions = {
  ...actions,
  setSearchQuery(query: string) {
    return actions.setSearchQuery(query);
  },
  reprint(appointment) {
    return async (dispatch, getState) => {
      const { id } = getState().auth?.user?.entities[0];
      const { data, status } = await authClient.get(
        `/facilities/${id}/appointments/${appointment}/print`,
      );
      if (status === 204) {
        uiActions.showMessage("Printing Succeeded");
      } else {
        dispatch(uiActions.showError("Error printing forms " + data.message));
      }
    };
  },

  // CHATS nad MESSAGES
  getChats(id: number, view) {
    return async (dispatch, getState) => {
      const { agency } = getState();
      const openedAppointment = agency?.openedAppointment || {};
      const isAppointmentOpened = Boolean(Object.keys(openedAppointment).length);
      const userEntityID = id;
      let type;
      if (view === "facility" || view === "facility_admin") {
        type = "facility";
      } else if (view === "agency" || view === "agency_admin" || view === "global") {
        type = "agency";
      } else {
        throw new Error("getChats invalid view");
      }

      dispatch(actions.setChatsLoading(true));

      const { status, data: chats } = await authClient.get(`/chats/${type}/${userEntityID}`);

      if (isAppointmentOpened) {
        const { status, data: messages } = await authClient.get(
          `/chat/${type}/${userEntityID}/appointment/${openedAppointment.id}`,
        );

        if (status === 200) {
          dispatch(actions.setMessages(messages.rows));
        } else {
          dispatch(uiActions.showError("Error getting messages"));
        }
      }

      if (status === 200) {
        dispatch(actions.setChats(chats));
      } else {
        dispatch(uiActions.showError("Error getting chats"));
      }

      dispatch(actions.setChatsLoading(false));

      return chats;
    };
  },
  getMessages(activeEntityId, appointmentId, view) {
    return async (dispatch, getState) => {
      const userEntityID = activeEntityId;

      // we do not support multiple roles yet
      let type;
      if (view === "facility_admin") {
        type = "facility";
      } else if (view === "agency_admin") {
        type = "agency";
      } else {
        throw new Error("invalid getMessages view");
      }

      const { status, data } = await authClient.get(
        `/chat/${type}/${userEntityID}/appointment/${appointmentId}`,
      );

      if (status === 200) {
        dispatch(actions.setMessages(data.rows));
      } else {
        dispatch(uiActions.showError("Error getting messages"));
      }

      return data.rows;
    };
  },
  sendMessage(
    activeEntityId,
    appointmentID,
    message,
    view,
  ): AppThunk<
    Promise<{
      appointment_id: string;
      body: string;
      from: string;
      id: number;
      message_at: string;
    }>
  > {
    return async (dispatch, getState) => {
      // we do not support multiple roles yet
      let role;
      if (view === "facility_admin") {
        role = "facility";
      } else if (view === "agency_admin") {
        role = "agency";
      } else {
        throw new Error("invalid sendMessage view");
      }

      dispatch(actions.setMessageSending(true));

      const { status, data } = await authClient.post(
        `/chat/${role}/${activeEntityId}/appointment/${appointmentID}`,
        {
          body: message,
        },
      );
      dispatch(actions.setMessageSending(false));

      if (status === 200) {
        return data;
      } else {
        dispatch(uiActions.showError("Error sending message"));
        throw new Error("chat post failed");
      }
    };
  },
  readMessage(entityId, appointmentID, messageID, view) {
    return async (dispatch, getState) => {
      const userEntityID = entityId;

      // we do not support multiple roles yet
      let role;
      if (view === "facility_admin") {
        role = "facility";
      } else if (view === "agency_admin") {
        role = "agency";
      } else {
        throw new Error("invalid readMessage view");
      }

      const { data } = await authClient.put(
        `/chat/${role}/${userEntityID}/appointments/${appointmentID}/message/${messageID}`,
        {},
      );

      return data;
    };
  },

  // ENTITIES
  getEntities() {
    return async (dispatch) => {
      dispatch(actions.setEntitiesLoading(true));
      const { status, data, data: entities } = await authClient.get(ENTITIES_API);
      if (status === 200) {
        dispatch(actions.setEntities(entities));
      } else {
        dispatch(uiActions.showError("Error getting entities (agency action) " + data.message));
      }

      dispatch(actions.setEntitiesLoading(false));
      return entities;
    };
  },
  getMedicalFacilities(id: number) {
    return async (dispatch) => {
      dispatch(actions.setMedicalFacilitiesLoading(true));

      const { status, data } = await authClient.get(`/regions/${id}`);
      if (status === 200) {
        dispatch(actions.setMedicalFacilities(data.rows.medicalFacilities));
      } else {
        dispatch(uiActions.showError("Error fetching medical facilities " + data.message));
      }
      dispatch(actions.setMedicalFacilitiesLoading(false));
      return data.row.medicalFacilities;
    };
  },

  // USERS
  //this function is called getAgencyUsers in the swagger file
  getUsers(id: number, filter = "", view) {
    //TODO: implement filter
    return async (dispatch, getState) => {
      dispatch(actions.setUsersLoading(true));

      // we do not support multiple roles yet
      const entityId = id;

      let API;
      if (view === "facility") {
        API = `/facilities/${entityId}/users`;
      } else if ("agency") {
        API = `/agency/${entityId}/users`;
      } else if ("super_admin") {
        API = id ? `/entity/${id}/users` : "/entity/users";
      } else {
        throw new Error("invalid getUsers view");
      }
      const { status, data, data: users } = await authClient.get(API);
      if (status === 200) {
        dispatch(actions.setUsers(users.result || users));
      } else {
        dispatch(uiActions.showError("Error fetching users " + data.message));
      }
      dispatch(actions.setUsersLoading(false));
      return users;
    };
  },

  getNotifications(entityId: number, view) {
    //TODO: implement filter
    return async (dispatch, getState) => {
      dispatch(actions.setNotificationSettingsLoading(true));

      // we do not support multiple roles yet
      // const isSuperAdmin = role === ROLES.SUPER_ADMIN;

      let API;
      if (view === "facility_admin") {
        API = `/facilities/${entityId}/notifications`;
      } else if (view === "agency_admin") {
        API = `/agency/${entityId}/notifications`;
      } else {
        throw new Error("invalid getNotifications view");
      }
      const { status, data, data: notifications } = await authClient.get(API);
      if (status === 200) {
        dispatch(actions.setNotificationSettings(notifications.result || notifications));
      } else {
        dispatch(uiActions.showError("Error fetching notification settings " + data.message));
      }
      dispatch(actions.setNotificationSettingsLoading(false));
      return notifications;
    };
  },
  deleteUser(person, entityId, view) {
    return async (dispatch) => {
      dispatch(actions.setUsersLoading(true));
      let API;
      let reloadAction;
      if (view === "facility") {
        API = `/facilities/${entityId}/users/${person.id}`;
        reloadAction = agencyActions.getUsers;
      } else if (view === "agency_admin") {
        API = `/agency/${entityId}/users/${person.id}`;
        reloadAction = agencyActions.getUsers;
      } else {
        throw new Error("invalid deleteUser view");
      }
      const { status, data } = await authClient.delete(API);

      if (status === 204 || status === 200) {
        dispatch(uiActions.showSuccess("Successfully deleted user"));
        dispatch(reloadAction(entityId));
      } else {
        dispatch(uiActions.showError("Error deleting user. " + data.message));
      }
      dispatch(actions.setUsersLoading(false));
      return data;
    };
  },
  deleteNotification(entityId: number, notificationId = null, view, filter = "") {
    //TODO: implement filter
    return async (dispatch, getState) => {
      dispatch(actions.setNotificationSettingsLoading(true));

      // we do not support multiple roles yet
      // const isSuperAdmin = role === ROLES.SUPER_ADMIN;

      let API;
      if (view === "facility_admin") {
        API = `/facilities/${entityId}/notifications/${notificationId}`;
      } else if (view === "agency_admin") {
        API = `/agency/${entityId}/notifications/${notificationId}`;
      } else {
        throw new Error("invalid deleteNotification view");
      }
      const { status, data } = await authClient.delete(API);

      if (status === 204) {
        dispatch(uiActions.showSuccess("Deleted notification settings "));
        dispatch(agencyActions.getNotifications(entityId, view));
      } else {
        dispatch(uiActions.showError("Error deleting notification setting " + data.message));
      }
      dispatch(actions.setNotificationSettingsLoading(false));
      return data;
    };
  },
  getNotificationTypes(entityId, view) {
    //TODO: implement filter
    return async (dispatch, getState) => {
      let API;
      if (view === "facility_admin") {
        API = `/facilities/notification_types`;
      } else if (view === "agency_admin") {
        API = `/agencies/notification_types`;
      } else {
        throw new Error("getNotificationTypes invalid view");
      }
      const { status, data, data: notification_types } = await authClient.get(API);
      if (status === 200) {
        dispatch(actions.setNotificationTypes(notification_types.result || notification_types));
      } else {
        dispatch(uiActions.showError("Error fetching notification settings " + data.message));
      }
    };
  },
  createAgencyUser(entityId: number, values, activeEntityType): any {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.post(`/agency/${entityId}/users`, values);
      if (status === 200) {
        history.push(`/${activeEntityType}/users`);
        dispatch(uiActions.showSuccess("agency user successfully created"));
      } else {
        dispatch(uiActions.showError("Error creating agency user. " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return { data, status };
    };
  },
  createNotification(entityId: number, values, view): any {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      let API;
      switch (view) {
        case "agency_admin":
          API = `/agency/${entityId}/notifications`;
          break;
        case "facility_admin":
          API = `/facilities/${entityId}/notifications`;
          break;
        default:
          throw new Error("Invalid view");
      }

      const { data, status } = await authClient.post(API, values);
      if (status === 200) {
        dispatch(uiActions.showSuccess("Notification successfully created"));
      } else {
        dispatch(uiActions.showError("Error creating notification " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return { data, status };
    };
  },
  updateAgencyUser(entityId: number, values, activeEntityType): any {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      const { data, status } = await authClient.put(
        `/agency/${entityId}/users/${values.id}`,
        values,
      );
      if (status === 200) {
        history.push(`/${activeEntityType}/users`);
        dispatch(uiActions.showSuccess("Agency user successfully updated"));
      } else {
        dispatch(uiActions.showError("Error updating agency user " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return { data, status };
    };
  },

  // PATIENTS
  getPatients(id: number, view: "agency" | "facility" | "global") {
    return async (dispatch, getState) => {
      dispatch(actions.setPatientsLoading(true));
      let url;
      switch (view) {
        case "agency":
          url = `${AGENCY_API}/${id}/patients`;
          break;
        case "facility":
          url = `${FACILITIES_API}/${id}/patients`;
          break;
        case "global":
          url = `/caregivers`;
          break;
        default:
          throw new Error("Invalid view");
      }

      const { data, status, data: patients } = await authClient.get(url);
      if (status === 200) {
        dispatch(actions.setPatients(patients));
      } else {
        dispatch(uiActions.showError("Error loading caregivers " + data.message));
      }
      dispatch(actions.setPatientsLoading(false));
      return patients;
    };
  },
  getPatientByID(id: number) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const { id: entityID } = getState().auth?.user?.entities[0];
      const {
        data,
        status,
        data: patient,
      } = await authClient.get(`${AGENCY_API}/${entityID}/patients/${id}`);

      if (status === 200) {
        dispatch(uiActions.showSuccess("Agency user successfully updated"));
      } else {
        dispatch(uiActions.showError("Error loading caregiver by id " + data.message));
      }
      dispatch(uiActions.setLoading(false));
      return patient;
    };
  },
  createPatient(values: PatientInitialValues, view, entityId) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      const {
        data,
        status,
        data: patient,
      } = await authClient.post(`${AGENCY_API}/${entityId}/patients`, {
        ...clean(values),
        home_health_agency_id: entityId,
      });
      if (status === 200) {
        dispatch(uiActions.showSuccess("User successfully created"));
      } else {
        dispatch(uiActions.showError("Error creating user " + data.message));
      }
      dispatch(uiActions.setLoading(false));
      // return status;
      return patient;
    };
  },
  //this action is called updatePatient in the swagger file
  editPatient(values: any, view, entityId, isExitingPatient: boolean, appointmentId = null) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      const id = view === "super_admin" ? values.home_health_agency_id : entityId;

      // we do not support multiple roles yet
      let API;
      if (view === "facility" || view === "facility_admin") {
        API = FACILITIES_API;
      } else if (view === "agency" || view === "agency_admin" || view === "super_admin") {
        API = AGENCY_API;
      } else {
        throw new Error("invalid editPatient view");
      }

      const patientID = isExitingPatient ? values.patient_id : values.id;
      // cleanup unnecessary `latest_appointment_status_id` field
      const { latest_appointment_status_id, ...payload } = values;

      const { data, status } = await authClient.put(`${API}/${id}/patients/${patientID}`, {
        ...clean(payload),
        home_health_agency_id: id,
      });
      if (status === 204) {
        dispatch(uiActions.showSuccess("User successfully modified"));
        if (view === "super_admin") {
          dispatch(agencyActions.getAppointmentByID(id, appointmentId, view));
        }
      } else {
        dispatch(uiActions.showError("Error editing user " + data.message));
      }
      dispatch(uiActions.setLoading(false));
      dispatch(actions.setEditablePatient(null));
      // return status;
      return data;
    };
  },
  checkMrn(mrn, entity_id, view): AppThunk<Promise<boolean>> {
    return async (dispatch, getState) => {
      if (!mrn) {
        return;
      }

      let URL;
      if (view === "facility" || view === "facility_admin" || view === "medical_facility") {
        URL = "facility";
      } else if (view === "agency" || view === "agency_admin" || view === "super_admin") {
        URL = "agency";
      } else {
        throw new Error("invalid getAppointments view");
      }

      dispatch(uiActions.setLoading(true));
      const { data, status } = await authClient.get(`${URL}/${entity_id}/patients/mrn/${mrn}`);
      if (status === 200) {
        if (data.duplicate) {
          dispatch(uiActions.showError("Duplicate MRN"));
        }
      } else {
        dispatch(uiActions.showError("Failed to check for duplicate MRN"));
      }
      dispatch(uiActions.setLoading(false));
      return data.duplicate;
    };
  },

  // APPOINTMENT
  getAppointments(
    id: number | null,
    view: "agency_admin" | "agency" | "facility_admin" | "facility" | "global",
    appointmentListType: "current_fac" | "all_fac" = "current_fac",
  ) {
    return async (dispatch, getState) => {
      const entityId = id;
      dispatch(actions.setAppointmentsLoading(true));

      // we do not support multiple roles yet
      let API;
      if (view === "facility" || view === "facility_admin") {
        API = `${FACILITIES_API}/${entityId}/`;
      } else if (view === "agency" || view === "agency_admin") {
        API = `${AGENCY_API}/${entityId}/`;
      } else if (view === "global") {
        return dispatch(superAdminActions.getAllAppointments());
      } else {
        throw new Error("invalid getAppointments view");
      }
      const {
        status,
        data,
        data: appointments,
      } = await authClient.get(`${API}appointments?listType=${appointmentListType}`);
      if (status === 200) {
        dispatch(actions.setAppointments(appointments));
      } else {
        dispatch(uiActions.showError("Error fetching appointments " + data.message));
      }

      dispatch(actions.setAppointmentsLoading(false));
      return appointments;
    };
  },
  getAppointmentByID(entityId: number, appointmentID: number, view: View) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      let API;
      switch (view) {
        case "facility":
          API = `${FACILITIES_API}/${entityId}/`;
          break;
        case "agency":
          API = `${AGENCY_API}/${entityId}/`;
          break;
        case "super_admin":
          API = "/";
          break;
        default:
          throw new Error("invalid getAppointmentByID view");
      }
      const { status, data: appointment } = await authClient.get(
        `${API}appointments/${appointmentID}`,
      );
      if (status === 200) {
        dispatch(actions.setOpenedAppointment(appointment));
      } else {
        dispatch(uiActions.showError(`Failed to get appointment details - ${appointment.message}`));
        history.push(`/${view}/appointments`);
      }
      dispatch(uiActions.setLoading(false));

      return appointment;
    };
  },
  scheduleAppointment(values, view, activeEntityId = null, editMode = false) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      let API;
      if (view === "agency") {
        API = `${AGENCY_API}/${activeEntityId}/${
          editMode ? "update_appointment_location" : "schedule_appointment"
        }/${values.appointment_id}`;
      } else if (view === "super_admin") {
        API = `/appointments/${editMode ? "update_appointment_location" : "schedule_appointment"}/${
          values.appointment_id
        }`;
      } else {
        throw new Error("invalid scheduleAppointment view");
      }
      const { data, status } = await authClient.post(API, values);
      if (status === 200) {
        dispatch(actions.setOpenedAppointment(data));
        dispatch(
          uiActions.showSuccess(`Appointment ${editMode ? "updated" : "scheduled"} successfully`),
        );
      } else {
        dispatch(
          uiActions.showError(
            `Error ${editMode ? "updating" : "scheduling"} appointment ` + data.message,
          ),
        );
      }

      dispatch(uiActions.setLoading(false));
    };
  },
  updateAppointment(appointment: any, entityType: View, entityID: number) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const appointmentID = appointment.id;
      let API = "";
      switch (entityType) {
        case "facility":
          API = `${FACILITIES_API}/${entityID}`;
          break;
        case "super_admin":
          API = `${AGENCY_API}/${appointment.patient.home_health_agency_id}`;
          break;
        default:
          API = `${AGENCY_API}/${entityID}`;
          break;
      }
      const { data, status } = await authClient.put(
        `${API}/appointments/${appointmentID}`,
        appointment,
      );
      if (status === 200) {
        dispatch(actions.setOpenedAppointment(appointment));
        dispatch(uiActions.showSuccess("Appointment updated successfully"));
      } else {
        dispatch(uiActions.showError("Error updating appointment " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return appointment;
    };
  },
  updateAppointmentStatus(status_id: number, appointment: any, activeEntityId: number, view: View) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      // we do not support multiple roles yet
      let API;
      if (view === "facility") {
        API = `${FACILITIES_API}/${activeEntityId}`;
      } else if (view === "agency") {
        API = `${AGENCY_API}/${activeEntityId}`;
      } else if (view === "super_admin") {
        API = `${FACILITIES_API}/${appointment.medical_facility_id ?? 0}`;
      } else {
        throw new Error("invalid getAppointmentByID view");
      }
      const { id, medical_facility_id, patient_id, datetime } = appointment;
      const payload = {
        id,
        medical_facility_id,
        patient_id,
        datetime,
        appointment_status_id: typeof status_id === "number" ? status_id.toString() : status_id,
      };
      let request = `${API}/appointments/${id}`;
      if (appointment.updateLocationId) {
        request += `?updateLocationId=${appointment.updateLocationId}`;
      }

      const { data, status } = await authClient.put(request, payload);
      const { appointment_status_id, ...values } = data;
      if (status === 204) {
        if (appointment.updateLocationId) {
          values.medical_facility_id = appointment.updateLocationId;
        }
        dispatch(
          actions.setOpenedAppointment({
            ...appointment,
            ...payload,
            ...values,
          }),
        );
        dispatch(uiActions.showSuccess("Appointment status updated successfully"));
      } else {
        dispatch(uiActions.showError("Error updating appointment status " + data.message));
      }

      dispatch(uiActions.setLoading(false));

      return appointment;
    };
  },
  updateAppointmentRateCode({ openedAppointment, rateCode, activeEntityId }: any) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      let request = `${FACILITIES_API}/${activeEntityId}/appointments/${openedAppointment.id}/rate_code/${rateCode.id}`;
      if (openedAppointment.updateLocationId) {
        request += `?updateLocationId=${openedAppointment.updateLocationId}`;
      }
      const { data, status } = await authClient.put(request, rateCode);

      if (status === 200) {
        if (openedAppointment.updateLocationId) {
          openedAppointment.medical_facility_id = openedAppointment.updateLocationId;
        }
        dispatch(
          actions.setOpenedAppointment({
            ...openedAppointment,
            appointment_rate_codes: openedAppointment.appointment_rate_codes.map((code) =>
              code.id === rateCode.id
                ? {
                    ...code,
                    form_location: rateCode.form_location,
                    rate_code_status_id: rateCode.rate_code_status_id,
                  }
                : code,
            ),
          }),
        );
      } else {
        dispatch(uiActions.showError("Error updating appointment rate code " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return rateCode;
    };
  },
  addAppointmentRateCode(
    entityId: number,
    { openedAppointment, rateCodes }: any,
    view: View = "facility",
  ) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      for (let rateCode of rateCodes) {
        await authClient.post(
          `${FACILITIES_API}/${entityId}/appointments/${openedAppointment.id}/rate_codes`,
          { id: rateCode, view },
        );
      }
      await dispatch(agencyActions.getAppointmentByID(entityId, openedAppointment.id, view));
      dispatch(uiActions.setLoading(false));
      return rateCodes;
    };
  },
  uploadSupportingDocuments(
    entity_id: number,
    appointment_id: number,
    filenames: any,
    view: View = "agency",
  ) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const path =
        view === "agency"
          ? `${AGENCY_API}/${entity_id}/appointments/${appointment_id}/supporting_documents`
          : `/appointments/${appointment_id}/supporting_documents`;
      const { status, data } = await authClient.post(path, {
        filenames: filenames,
      });
      if (status === 200) {
        dispatch(actions.setSupportingDocs(data));
      } else {
        dispatch(uiActions.showError("Error saving files to appointment"));
      }
      dispatch(uiActions.setLoading(false));
      return status === 200;
    };
  },
  deleteSupportingDoc(
    entity_id: number,
    appointment_id: number,
    form_id: number,
    view: View = "agency",
  ) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const path =
        view === "agency"
          ? `${AGENCY_API}/${entity_id}/appointments/${appointment_id}/supporting_documents/${form_id}`
          : `/appointments/${appointment_id}/supporting_documents/${form_id}`;
      const { status, data } = await authClient.delete(path);
      if (status === 200) {
        dispatch(actions.setSupportingDocs(data));
        dispatch(uiActions.showSuccess("File successfully deleted"));
      } else {
        dispatch(uiActions.showError("Error deleting supporting document"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getApptSupportingDocuments(entity_id: number, appointment_id: number, view: View = "agency") {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      let API = "";
      switch (view) {
        case "facility":
          API = `${FACILITIES_API}/${entity_id}`;
          break;
        case "super_admin":
          API = ``;
          break;
        default:
          API = `${AGENCY_API}/${entity_id}`;
          break;
      }
      const { status, data } = await authClient.get(
        `${API}/appointments/${appointment_id}/supporting_documents`,
      );
      if (status === 200) {
        dispatch(actions.setSupportingDocs(data));
      } else {
        dispatch(uiActions.showError("Failed to retrieve supporting docs for this appointment"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  downloadSupportingDoc(
    entity_id: number,
    appointment_id: number,
    form_id: number,
    view: View = "agency",
  ) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      let API = "";
      switch (view) {
        case "facility":
          API = `${FACILITIES_API}/${entity_id}`;
          break;
        case "super_admin":
          API = ``;
          break;
        default:
          API = `${AGENCY_API}/${entity_id}`;
          break;
      }
      const { status, data } = await authClient.get(
        `${API}/appointments/${appointment_id}/supporting_documents/${form_id}`,
      );
      if (status === 200) {
        window.open(data.url, "_blank");
      } else {
        dispatch(uiActions.showError("Download failed " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  createAppointmentPatient(values: AppointmentPatientInitialValues, activeEntityId: number) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      // remove patientType from payload
      const { patientType, ...payload } = values;

      const {
        status,
        data,
        data: patient,
      } = await authClient.post(`${AGENCY_API}/${activeEntityId}/patients`, {
        ...clean(payload),
        home_health_agency_id: activeEntityId,
      });
      if (status === 200) {
        dispatch(actions.setAppointmentPatient(patient));
        dispatch(uiActions.showSuccess("Caregiver successfully created"));
      } else {
        dispatch(uiActions.showError("Error creating caregiver " + data.message));
      }
      dispatch(uiActions.setLoading(false));
      return patient;
    };
  },
  editAppointmentPatient(values: AppointmentPatientInitialValues, activeEntityId: number) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));

      const { id: patientID } = values;
      // remove patientType from payload
      const { patientType, ...payload } = values;

      const {
        status,
        data,
        data: patient,
      } = await authClient.put(`${AGENCY_API}/${activeEntityId}/patients/${patientID}`, {
        ...clean(payload),
        home_health_agency_id: activeEntityId,
      });
      if (status === 200) {
        dispatch(actions.setAppointmentPatient(patient));
        dispatch(uiActions.showSuccess("Caregiver successfully updated"));
      } else {
        dispatch(uiActions.showError("Error updating caregiver " + data.message));
      }
      dispatch(uiActions.setLoading(false));
      return patient;
    };
  },
  //TODO: what is this
  saveAppointmentServices(values: CreateAppointmentServicesInitialValues) {
    return async (dispatch) => {
      dispatch(actions.saveAppointmentServices(values));
    };
  },
  //this action is called createAppointment in swagger file
  saveNewAppointment(values: AppointmentTimeInitialValues, activeEntityId: number) {
    return async (dispatch, getState) => {
      const {
        appointmentPatient,
        appointmentExistingPatientID,
        appointmentServices,
        activeAppointment,
      } = getState().agency;

      // detach default_rate_code field from appointment_rate_codes
      const appointment_rate_codes = appointmentServices.appointment_rate_codes.map((code) => {
        const { default_rate_code, default_rate_code_id, home_health_agency_id, ...restFields } =
          code;

        return {
          ...restFields,
          // home_health_agency_rate_code_id: code.id,
        };
      });

      const payload = {
        medical_facility_id: values.facility,
        patient_id: appointmentPatient.id || appointmentExistingPatientID,
        appointment_rate_codes,
        datetime: values?.timeframe ? new Date(values.timeframe).toISOString() : null,
        appointment_status_id: values?.appointment_status_id,
        payer: activeAppointment.appointment.payer,
      };

      dispatch(actions.setAppointmentTimeslot(values));
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.post(
        `${AGENCY_API}/${activeEntityId}/appointments`,
        payload,
      );
      if (status === 200) {
        dispatch(actions.setConfirmedAppointment(data.apptResult));
        dispatch(actions.setAppointmentConfirmed(true));
        dispatch(uiActions.showSuccess("Appointment Saved"));
      } else {
        dispatch(uiActions.showError("Error saving new appointment " + data.message));
      }
      dispatch(uiActions.setLoading(false));

      return data.apptResult;
    };
  },
  getAppointmentsHistory(activeEntityId: number, days: number) {
    return async (dispatch, getState) => {
      const startdate = formatAppointmentsHistoryPeriod(sub(new Date(), { days }));
      const enddate = formatAppointmentsHistoryPeriod(new Date());

      const { data: history } = await authClient.get(
        `${AGENCY_API}/${activeEntityId}/appointments/history`,
        {
          params: { startdate, enddate },
        },
      );

      dispatch(actions.setAppointmentsHistory(history));
    };
  },
  getAppointmentHistoryList(activeEntityId: number, appointmentId: number) {
    return async (dispatch) => {
      const { status, data: history } = await authClient.get(
        `${AGENCY_API}/${activeEntityId}/appointment/${appointmentId}/history`,
      );

      if (status === 200) {
        dispatch(actions.setAppointmentHistoryList(history));
      } else {
        dispatch(uiActions.showError("Error getting appointment history " + history.message));
      }
    };
  },
  getAppointmentUpdates(activeEntityId, q, updatesType) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      let statuses;
      switch (updatesType) {
        case "updates":
          statuses = [4];
          break;
        case "expected-updates":
          statuses = [2, 3];
          break;
        case "canceled":
          statuses = [5, 6];
          break;
        default:
          throw new Error("Invalid updatesType");
      }
      const { data, status } = await authClient.get(
        `${AGENCY_API}/${activeEntityId}/appointments/updates?filter[q]=${q}&filter[statuses]=[${statuses}]`,
      );
      if (status === 200) {
        dispatch(actions.setAppointmentUpdates(data));
      } else {
        dispatch(uiActions.showError("failed to get appointments"));
        console.error(data);
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  downloadAppointmentFiles(activeEntityId, appointmentId) {
    return async (dispatch) => {
      const url = `${AGENCY_API}/${activeEntityId}/appointments/${appointmentId}/download-urls`;
      const { data, status } = await authClient.get(url);
      if (status === 200) {
        data.forEach((u, idx) => {
          window.open(u.url, "_blank");
        });
      } else {
        dispatch(uiActions.showError("Failed to get download links"));
      }
    };
  },
  dismissAppointment(activeEntityId, appointmentId, appointment_status_id) {
    return async (dispatch) => {
      const url = `${AGENCY_API}/${activeEntityId}/appointments/${appointmentId}/dismiss-update`;
      const { status } = await authClient.put(url, {
        appointment_status_id: appointment_status_id,
      });
      if (status === 204) {
        const query = parseQueryString(window.location.search);
        dispatch(agencyActions.getAppointmentUpdates(activeEntityId, query.q, query.updatesType));
      } else {
        dispatch(uiActions.showError("Failed to dismiss appointment"));
      }
    };
  },

  // AGENCY
  createAgency(values: AgencyInitialValues): AppThunk<Promise<any>> {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));

      const { status, data } = await authClient.post(ENTITIES_API, values);
      const { entity, admin, hhaRates, hhaBundles } = data;
      if (status === 200) {
        dispatch(actions.setBundles(hhaBundles));
        dispatch(actions.setRateCodes({ rateCodes: hhaRates }));
        dispatch(actions.setAgency({ ...values, ...admin, ...entity }));
        dispatch(uiActions.showSuccess("Agency Saved"));
      } else {
        dispatch(uiActions.showError("Error saving agency " + data.message));
      }

      dispatch(uiActions.setLoading(false));
      return status;
    };
  },
  updateAgency({ id, rateCodes, ...restValues }): AppThunk<Promise<any>> {
    return async (dispatch) => {
      const { status, data } = await authClient.put(`${ENTITIES_API}/${id}`, {
        ...restValues,
        entity_id: id,
        rates: rateCodes,
      });
      if (status === 200) {
        dispatch(actions.updateAgency(restValues as AgencyInitialValues));
        dispatch(uiActions.showSuccess("Agency updated"));
      } else {
        dispatch(uiActions.showError("Error updating agency " + data.message));
      }
      return data;
    };
  },

  // RATE CODES
  getRateCodes(agencyId: number, setFullResponse = false) {
    return async (dispatch, getState) => {
      dispatch(actions.setRateCodesLoading(true));

      const { data, status } = await authClient.get(`${AGENCY_API}/${agencyId}/rate-codes`);
      if (status === 200) {
        dispatch(actions.setRateCodes({ rateCodes: data.rateCodeResult.rows, setFullResponse }));
        dispatch(actions.setBundles({ bundles: data.bundleResult.rows, setFullResponse }));
      } else {
        dispatch(uiActions.showError("Error loading agency rate codes " + data.message));
      }
      dispatch(actions.setRateCodesLoading(false));

      return data.rows;
    };
  },
  getRateCodeByPatientId(patientId) {
    return async (dispatch) => {
      dispatch(actions.setPatientRateCodesLoading(true));
      const { data, status } = await authClient.get(
        `${FACILITIES_API}/patients/${patientId}/rate-codes`,
      );
      if (status === 200) {
        dispatch(actions.setPatientRateCodes(data.rateCodeResult));
      } else {
        dispatch(uiActions.showError("Error loading potential rate codes " + data.message));
      }
      dispatch(actions.setPatientRateCodesLoading(false));

      return data.rows;
    };
  },
  updateAgencyRateCodes({ id, bundles, rateCodes, ...restValues }) {
    return async (dispatch) => {
      const payload = {
        ...restValues,
        entity_id: id,
        rates: rateCodes,
        bundles: bundles,
      };
      const { data, status } = await authClient.post(`${AGENCY_API}/${id}/rate-codes`, payload);
      if (status === 200) {
        dispatch(agencyActions.getRateCodes(id, true));
        dispatch(uiActions.showSuccess("Updated rate codes"));
      } else {
        dispatch(uiActions.showError("Error updating rate codes " + data.message));
      }

      return data;
    };
  },

  // BILLING
  getBilling(agencyId: number, view) {
    return async (dispatch, getState) => {
      dispatch(actions.setBillingLoading(true));
      let url;
      if (view === "super_admin" || view === "agency") {
        url = `${AGENCY_API}/${agencyId}/billing`;
      } else {
        url = `${FACILITIES_API}/${agencyId}/billing`;
      }

      const { data, status } = await authClient.get(url);
      if (status === 200) {
        dispatch(actions.setBilling(data.rows));
      } else {
        dispatch(uiActions.showError("Error loading billing " + data.message));
      }
      dispatch(actions.setBillingLoading(false));

      return data.rows;
    };
  },

  // REGIONS
  getRegions() {
    return async (dispatch) => {
      dispatch(actions.setRegionsLoading(true));

      const { data, status } = await authClient.get("/regions");
      if (status === 200) {
        dispatch(actions.setRegions(data.rows));
      } else {
        dispatch(uiActions.showError("Error loading regions " + data.message));
      }
      dispatch(actions.setRegionsLoading(false));

      return data.rows;
    };
  },
  //UPDATES
  getUpdates(id: number) {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const entityId = id;

      const { data, status } = await authClient.get(`/agency/${entityId}/updates`);

      if (status === 200) {
        dispatch(actions.setUpdates(data.rows));
      } else {
        dispatch(uiActions.showError("Error loading updates " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getExpectedUpdates(id: number) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const entityId = id;

      const { data, status } = await authClient.get(`/agency/${entityId}/expected-updates`);
      if (status === 200) {
        dispatch(actions.setExpectedUpdates(data.rows));
      } else {
        dispatch(uiActions.showError("Error loading expected updates " + data.message));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getFormDownloadLink(rcid) {
    return async (dispatch, getState) => {
      dispatch(uiActions.setLoading(true));
      const entityId = getState().auth.user.entities[0].id;

      const { data, status } = await authClient.get(`/agency/${entityId}/rate-codes/${rcid}`);
      dispatch(uiActions.setLoading(false));
      if (status === 200) {
        dispatch(uiActions.showSuccess("Download Link generated"));
      } else {
        dispatch(uiActions.showError("Generating Form download Link failed " + data.message));
      }
      return data;
    };
  },
  getFileDownloadLink(entityID, appointmentId, rateCodeId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { status, data } = await authClient.get(
        `/agency/${entityID}/appointments/${appointmentId}/rate_code/${rateCodeId}/form`,
      );
      dispatch(uiActions.setLoading(false));
      if (status === 200) {
        window.open(data.url, "_blank");
      } else {
        dispatch(uiActions.showError("Failed to get file"));
      }
    };
  },
};
