import React from "react";
import { Switch, TableRow, TableCell, TextField } from "@material-ui/core";
import clsx from "clsx";

import { FileTextInput, PriceFormatInput } from "../../../inputs";

import { useStyles } from "./RateCodesTable.styles";

const RateCodeRow = ({ index, form, code, error }) => {
  const classes = useStyles({ error });

  const isCodeSelected = form.values.rateCodes[index].active;

  return (
    <TableRow className={clsx(classes.tableRow, !isCodeSelected && classes.tableRowDisabled)}>
      <TableCell className={classes.switchCell}>
        <Switch
          id="rate-code-active"
          color="primary"
          name={`rateCodes.${index}.active`}
          onChange={form.handleChange}
          checked={isCodeSelected}
        />
      </TableCell>
      <TableCell align="center" className={classes.rateCode}>
        {code.code}
      </TableCell>
      <TableCell className={classes.description}>{code.description}</TableCell>
      <TableCell className={classes.uploadFormCell}>
        {isCodeSelected && (
          <FileTextInput
            form={form}
            name={`rateCodes.${index}.form_location`}
            label={`Upload form${Boolean(form.values.rateCodes[index].form_required) ? " *" : ""}`}
            accept="application/pdf"
            error={
              form.touched.rateCodes &&
              form.touched.rateCodes[index].form_location &&
              Boolean(form.errors?.rateCodes) &&
              Boolean(form.errors?.rateCodes[index]) &&
              Boolean(form.errors?.rateCodes[index].form_location)
            }
          />
        )}
      </TableCell>
      <TableCell className={classes.chargeCell}>
        {isCodeSelected && (
          <TextField
            fullWidth
            id="rate-code-rate"
            name={`rateCodes.${index}.rate`}
            label="Charge *"
            value={form.values.rateCodes[index].rate}
            onChange={form.handleChange}
            error={
              Boolean(form.touched?.rateCodes) &&
              Boolean(form.touched?.rateCodes[index]) &&
              Boolean(form.errors?.rateCodes) &&
              Boolean(form.errors?.rateCodes[index]) &&
              Boolean(form.errors?.rateCodes[index].rate)
            }
            InputProps={{ inputComponent: PriceFormatInput as any }}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default RateCodeRow;
