import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Box, Typography } from "@material-ui/core";

import { AddButton } from "../../../components/buttons";
import UsersTable from "../../../components/tables/UsersTable";

import FacilityPages from "../../facility";
import {
  TABLE_ROW_EDIT_ACTION,
  FACILITY_USERS_TABLE_COLUMNS as columns,
  TABLE_ROW_DELETE_ACTION,
} from "../../../constants";

import { useStyles } from "./UsersPage.styles";
import { authSelectors, useSelector } from "../../../state";

// TODO add types and interfaces
const UsersPage = () => {
  const classes = useStyles();
  const history = useHistory();

  const entity = useSelector(authSelectors.activeEntity);
  const handleEdit = useCallback(
    (e) => {
      history.push(FacilityPages.editUser.path.replace(":id", e.id));
    },
    [history],
  );

  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Typography variant="subtitle2">Users</Typography>
        <AddButton to={FacilityPages.createUser.path} />
      </Grid>

      <UsersTable
        editHandler={handleEdit}
        actions={[TABLE_ROW_EDIT_ACTION, TABLE_ROW_DELETE_ACTION]}
        columns={columns}
        entity={entity}
        view="facility"
      />
    </Box>
  );
};

export default React.memo(UsersPage);
