import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDrawer-paper": {
      overflow: "auto",
      position: "absolute",
      height: "100vh",
      bottom: 0,
    },
  },
  title: {
    padding: "24px 14px 32px",
  },
}));
