import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  formCard: {
    borderRadius: 0,
    marginBottom: "2em",
  },
  formWrap: {
    paddingTop: 24,
    paddingLeft: 0,
    paddingRight: 0,
  },
  sectionTitle: {
    paddingBottom: 4,
    paddingLeft: "2em",
    paddingRight: "2em",

    "& .MuiTypography-root": {
      fontSize: "1.375em",
      fontWeight: 400,
    },
  },
  formControl: {
    maxWidth: "50%",
    padding: "0 2em 2em",
    flexGrow: 1,
  },
  formAction: {
    paddingRight: "2em",
  },
}));
