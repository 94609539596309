import React, { useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, TextField, ButtonBase, CircularProgress } from "@material-ui/core";

import { fileActions, useDispatch } from "../../state";

const useStyles = makeStyles((theme) => ({
  field: {
    margin: 0,

    "& .MuiFormLabel-root.Mui-disabled": {
      color: theme.palette.text.secondary,
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomType: "solid",
    },
  },
  button: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
}));

const FileInput = ({ form, label, name, accept, error }) => {

  const ref: React.MutableRefObject<any> = useRef();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const [attachment, setAttachment] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handleChange = async (event) => {
    setLoading(true);
    const files = Array.from(event.target.files);
    const [file] = files;
    const value: any = await dispatch(fileActions.uploadPrivate(file));
    form.setFieldValue(name, value.data.fileName);
    setLoading(false);
    setAttachment(file);
    // if (!!onChange) onChange({ target: { value: file } });
  };

  return (
    <Box
      position="relative"
      height={48}
      color={!!error ? theme.palette.error.main : theme.palette.background.paper}
      borderBottom={4}
    >
      <Box position="absolute" top={0} bottom={0} left={0} right={0} mx={2}>
        <TextField
          variant="standard"
          className={classes.field}
          margin="normal"
          fullWidth
          disabled
          label={label}
          value={attachment?.name || ""}
          error={!!error}
          helperText={error?.message || " "}
          InputProps={{
            endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
          }}
        />
      </Box>
      <ButtonBase
        className={classes.button}
        component="label"
        onKeyDown={(e) => e.keyCode === 32 && ref.current?.click()}
      >
        <input ref={ref} type="file" accept={accept} hidden onChange={handleChange} />
      </ButtonBase>
    </Box>
  );
};

export default FileInput;
