import React from "react";
// Local
import { REACT_APP_VERSION } from "../../config";

const aboutVersionText = `Version:${REACT_APP_VERSION}`;

const style = {
  cursor: "pointer",
};

export const VersionText = React.memo(function _VersionText() {
  return (
    <span title={aboutVersionText} style={style}>
      {aboutVersionText}
    </span>
  );
});
