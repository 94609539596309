import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  viewBtn: {
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.87)",
  },
  uploadBtn: {
    marginRight: 20,
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.87)",

    "& .MuiButton-label": {
      fontSize: "0.9375rem",
    },
  },
  checkinBtn: {
    marginRight: 20,
  },
}));
