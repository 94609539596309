import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    height: 38,
    border: "1px solid #E0E0E0",
    borderRadius: 22.5,
    backgroundColor: "transparent",
    textTransform: "initial",
    textAlign: (props: any) => (props.badge ? "left" : "center"),
  },
  badge: {
    marginLeft: 20,
    minHeight: 24,
    minWidth: 24,
    padding: "0 8px",
    borderRadius: 16.5,
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    lineHeight: "24px",
  },
}));
