import React, { useEffect } from "react";
// import { useParams } from "react-router-dom";
import { Grid, Button, TextField, Card, CardContent, MenuItem } from "@material-ui/core";

import {
  useAction,
  useSelector,
  agencySelectors,
  agencyActions,
  authSelectors,
  useDispatch,
} from "../../../state";
import { Navigation, Form, useFormik, yup } from "../../../lib";

import { useStyles } from "./NotificationSettingForm.styles";
import AgencyPages from "../../../pages/agency";
// import { superAdminSelectors } from "../../../state";

const validationSchema = yup.object({
  method: yup.string().required("Method is required"),
  notification_type_id: yup.string().required("Notification type is required"),
  target: yup.string().email("Enter a valid email").required("target is required"),
});

const emptyNotificationInitialValues = {
  method: "email",
  notification_type_id: "",
  target: "",
};

const NotificationSettingForm = () => {
  const classes = useStyles();
  // const { id }: any = useParams();
  // const isEdit = Boolean(id);
  // const notifications = useSelector(superAdminSelectors.rateCodes);
  // const notification = notifications.find(n => n.id === Number(id));
  const availableNotifications = useSelector(agencySelectors.notificationTypes);
  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityId = activeEntity.id;
  const view = activeEntity.type === "home_health_agency" ? "agency_admin" : "facility_admin";

  const getNotificationTypes = useAction(agencyActions.getNotificationTypes);

  // const userRole = isEdit && user?.user_role_entities && user.user_role_entities[0].role.id;
  // const initialValues = isEdit ? { ...notification } : emptyNotificationInitialValues;
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: emptyNotificationInitialValues,
    validationSchema,
    async onSubmit(values) {
      // if (isEdit) {
      //   const { status } = await updateAgencyUser(values);
      //   if (status === 200) Navigation.go(AgencyPages.notificationsSettings.path);
      // } else {
      //eslint-disable-next-line
      const r: any = await dispatch(agencyActions.createNotification(activeEntityId, values, view));
      const { status } = r;
      if (status === 200) Navigation.go(AgencyPages.notificationsSettings.path);
      // }
    },
  });

  useEffect(() => {
    getNotificationTypes(activeEntityId, view);
  }, [activeEntityId, getNotificationTypes, view]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <Form form={form}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="method"
                name="method"
                label="Method"
                value={form.values.method}
                onChange={form.handleChange}
                error={form.touched.method && Boolean(form.errors.method)}
                helperText={form.touched.method && form.errors.method}
                disabled
              />
            </Grid>

            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="target"
                name="target"
                label="Target Email Address"
                value={form.values.target}
                onChange={form.handleChange}
                error={form.touched.target && Boolean(form.errors.target)}
                helperText={form.touched.target && form.errors.target}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                name="notification_type_id"
                id="notification_type_id"
                label="Notification Type"
                variant="outlined"
                value={form.values.notification_type_id}
                onChange={(value) => {
                  form.handleChange(value);
                }}
                error={
                  form.touched.notification_type_id && Boolean(form.errors.notification_type_id)
                }
                helperText={form.touched.notification_type_id && form.errors.notification_type_id}
              >
                {availableNotifications.map((notification) => (
                  <MenuItem
                    key={`notification-${notification.notification_type_id}-`}
                    value={notification.id}
                  >
                    {notification.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Card>
  );
};

export default NotificationSettingForm;
