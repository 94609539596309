import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tabs, Tab } from "@material-ui/core";
import UsersTable from "../../tables/UsersTable";
import RateCodesTable from "../../tables/RateCodesTable";
import InvoicesTable from "../../tables/InvoicesTable";
import TabPanel from "../TabPanel";
import PatientsTab from "../PatientsTab";
import BundleTab from "../BundleTab";
import AppointmentsTab from "../AppointmentsTab";
import BillingHistoryTab from "../BillingHistoryTab";

import {
  SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_COLUMNS,
  SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_COLUMN_EXTENSIONS,
} from "../../../constants";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
  },
  tab: {
    color: "#000",
    textTransform: "capitalize",
    "&.MuiTab-textColorInherit": {
      opacity: 0.5,

      "&.Mui-selected": {
        opacity: 1,
      },
    },
  },
}));

const AgencyTabs = ({ entity }) => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState("rate-codes");

  const handleChange = useCallback((event, newValue) => {
    setSelectedTab(newValue);
  }, []);

  return (
    <Box className={classes.container}>
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab
          value="rate-codes"
          label="Rate Codes"
          id="tab-rate-codes"
          disableRipple
          className={classes.tab}
        />
        <Tab
          value="bundles"
          label="Bundles"
          id="tab-bundles"
          disableRipple
          className={classes.tab}
        />
        <Tab
          value="patients"
          label="Caregivers"
          id="tab-patients"
          disableRipple
          className={classes.tab}
        />
        <Tab
          value="appointments"
          label="Appointments"
          id="tab-appointments"
          disableRipple
          className={classes.tab}
        />
        <Tab
          value="billing-history"
          label="Billing History"
          id="tab-billing-history"
          disableRipple
          className={classes.tab}
        />
        <Tab
          value="invoices"
          label="Invoices"
          id="tab-invoices"
          disableRipple
          className={classes.tab}
        />
        <Tab value="users" label="Users" id="tab-users" disableRipple className={classes.tab} />
      </Tabs>
      <TabPanel value={selectedTab} index="rate-codes">
        <RateCodesTable agency={entity} />
      </TabPanel>
      <TabPanel value={selectedTab} index="bundles">
        <BundleTab />
      </TabPanel>
      <TabPanel value={selectedTab} index="patients">
        <PatientsTab view="super_admin" entity={entity} />
      </TabPanel>
      <TabPanel value={selectedTab} index="appointments">
        <AppointmentsTab
          view="super_admin"
          entity={entity}
          columns={SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_COLUMNS}
          extensions={SUPER_ADMIN_AGENCY_APPOINTMENTS_TABLE_COLUMN_EXTENSIONS}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index="billing-history">
        {/* <BillingHistoryTab view="super_admin" entity={entity}/> */}
        <BillingHistoryTab view="super_admin" />
      </TabPanel>
      <TabPanel value={selectedTab} index="invoices">
        <InvoicesTable view="super_admin" entity={entity} />
      </TabPanel>
      <TabPanel value={selectedTab} index="users">
        <UsersTable view="agency_admin" entity={entity} />
      </TabPanel>
    </Box>
  );
};

export default React.memo(AgencyTabs);
