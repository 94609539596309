import React, { useCallback, useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Chip } from "@material-ui/core";
import {
  APPOINTMENT_STATUSES_STATES,
  APPOINTMENT_STATUSES_STATES_ARRAY,
} from "../../../../constants";
import { getStatusLabel } from "../../../tags/StatusTag";
import { useStyles } from "./PatientsFilters.styles";
import { listsActions, listsSelectors, useAction, useSelector } from "../../../../state";

const PatientsFilters = ({
  selectedFilters,
  handleFilterChange,
  handleRemoveFilter,
  view = "agency",
}) => {
  const classes = useStyles();

  const statusFilterValue = selectedFilters.find(
    (filter) => filter.columnName === "latest_appointment_status_id",
  ).value;
  const agencyFilterValue = selectedFilters.find((filter) => filter.columnName === "agency")?.value;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const getAgencies = useAction(listsActions.getAgenciesList);
  const agencies = useSelector(listsSelectors.agenciesList);

  const handleStatusFilterChange = useCallback(
    ({ target: { value } }) => {
      handleFilterChange("latest_appointment_status_id", value);
      setIsOpen(false);
    },
    [handleFilterChange],
  );

  useEffect(() => {
    if (view === "global") {
      getAgencies();
    }
  }, [view, getAgencies]);

  const handleAgencyFilterChange = useCallback(
    ({ target: { value } }) => {
      handleFilterChange("agency", value);
      setIsOpen(false);
    },
    [handleFilterChange],
  );

  return (
    <>
      <FormControl variant="outlined" size="small" className={classes.statusFormControl}>
        <InputLabel id="latest-appointment-status-label">Latest appointment status</InputLabel>
        <Select
          multiple
          id="latest-appointment-status"
          label="Latest appointment status"
          labelId="latest-appointment-status-label"
          value={statusFilterValue}
          onChange={handleStatusFilterChange}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          renderValue={(selected: any) =>
            selected.map((selectedFilter, i) => (
              <Chip
                key={`selected-filter-${selectedFilter}-${i}`}
                size="small"
                variant="outlined"
                color="primary"
                // label={getStatusLabel(selectedFilter) || TYPES[selectedFilter]}
                label={getStatusLabel(selectedFilter)}
                style={{ marginRight: 8 }}
                onDelete={() => handleRemoveFilter(selectedFilter)}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            ))
          }
        >
          {APPOINTMENT_STATUSES_STATES_ARRAY.map((status, i) => (
            <MenuItem
              key={`statuses-${APPOINTMENT_STATUSES_STATES[status]}-${i}`}
              value={APPOINTMENT_STATUSES_STATES[status]}
            >
              {getStatusLabel(APPOINTMENT_STATUSES_STATES[status])}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {view === "global" && (
        <FormControl variant="outlined" className={classes.statusFormControl} size="small">
          <InputLabel id="facility-label">Agency</InputLabel>
          <Select
            id="agency"
            label="Agency scheduled by"
            labelId="agency-label"
            value={agencyFilterValue}
            onChange={handleAgencyFilterChange}
          >
            <MenuItem value="">ALL</MenuItem>
            {agencies?.map((type) => (
              <MenuItem key={type?.id} value={type?.name}>
                {type?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default React.memo(PatientsFilters);
