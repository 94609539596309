import React from "react";
import { useParams } from "react-router-dom";
import { Typography, Breadcrumbs, Link } from "@material-ui/core";
import DefaultBundleForm from "../../../../components/forms/DefaultBundleForm";
import Pages from "../../..";
import { Link as RRDLink } from "react-router-dom";
import BundleCreatedSuccessModal from "../../../../components/modals/BundleCreatedSuccessModal";
import { uiSelectors, useSelector } from "../../../../state";

const DefaultBundleFormPage = () => {
  const { id }: any = useParams();
  const { open } = useSelector(uiSelectors.bundleSuccessModal);

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          component={RRDLink as any}
          underline="hover"
          color="inherit"
          to={Pages.superAdmin.defaultBundles.path}
        >
          Bundles
        </Link>
        <Typography color="textPrimary"> {id ? "Edit" : "Add new"} bundle</Typography>
      </Breadcrumbs>
      <Typography variant="h4">{id ? "Edit" : "Add new"} bundle</Typography>
      <DefaultBundleForm />
      {open && <BundleCreatedSuccessModal />}
    </>
  );
};

export default DefaultBundleFormPage;
