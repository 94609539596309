import React from "react";
import { useStyles } from "./ToggleButton.styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";

const GenericToggleButton = ({ value, onChange, options }) => {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      size="large"
      onChange={(e, value) => onChange(value)}
      className={classes.toggleContainer}
    >
      {options.map((o) => {
        return (
          <ToggleButton
            className={clsx(
              classes.toggleOption,
              o.class === "pending" && classes.pending,
              o.class === "completed" && classes.completed,
            )}
            value={o.value}
          >
            {o.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default React.memo(GenericToggleButton);
