import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: 20,
  },
  controlsWrap: {
    width: "65vw",
  },
  formControl: {
    width: "100%",
    minWidth: 330,
    padding: "0 24px 24px",
  },
  switcherGroup: {
    width: "100%",
  },
  switcher: {
    width: "50%",
    textTransform: "initial",
    borderColor: "rgba(0, 0, 0, 0.23)",

    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
  switcherError: {
    borderColor: theme.palette.error.main,
  },
  note: {
    padding: "0 32px",
  },
  selectPatient: {
    padding: "0 32px",
    marginBottom: 32,
  },
  formActions: {
    marginTop: 36,
    borderTop: "1px solid #E0E0E0",
    padding: "0 32px",
  },
  selectLoader: {
    position: "absolute",
    right: 35,
  },
  sectionTitle: {
    textTransform: "uppercase",
    fontSize: "1.25em",
    fontWeight: 500,
    marginLeft: 24,
  },
}));
