import * as Sentry from "@sentry/react";

import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@material-ui/core";

import { LocalizationProvider } from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Local
import { authActions, store } from "./state";

import { defaultTheme } from "./themes";
import "./assets/css/styles.css";

import { App } from "./App";
import { ErrorSentryFallBack, setUser, init } from "./components/errors/ErrorSentry";

/**
 * Startup function.
 */
function main() {
  store.dispatch(authActions.load({}));
  init();
  const state = store.getState();
  if (state.auth) {
    setUser({ email: state.auth?.user?.email, id: state.auth?.user?.id });
  }
  render();
}

/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 */

function render() {
  // TODO: Add <ErrorSentry> as root.
  ReactDOM.render(
    <Sentry.ErrorBoundary fallback={ErrorSentryFallBack} showDialog>
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={DateFnsUtils}>
          <ReduxProvider store={store}>
            <CssBaseline />
            <App />
          </ReduxProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>,
    document.getElementById("root"),
  );
}

main();
