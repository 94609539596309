import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(3),
  },
  closeIcon: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  modalFooter: {
    paddingTop: theme.spacing(3),
  },
}));
