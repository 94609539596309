import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    paddingTop: 20,
  },
  controlsWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "65vw",
    height: "70vh",
  },
  topControlsWrap: {
    height: "100%",
  },
  title: {
    paddingBottom: theme.spacing(5),
  },
  formControlWrap: {
    width: "50%",
    minWidth: 330,
    padding: "0 32px 32px",
  },
  formControl: {
    width: "100%",
  },
  locationFormControl: {
    width: 220,
  },
  facilityFormControl: {
    width: 480,
  },
  formActions: {
    marginTop: 64,
    borderTop: "1px solid #E0E0E0",
    padding: "0 32px",
  },
  selectLoader: {
    position: "absolute",
    right: 35,
  },
}));
