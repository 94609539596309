import { createSelector } from "@reduxjs/toolkit";

import { AppState } from "../types";

const _state = (state: AppState) => state.file;

const _files = createSelector(_state, (state) => state.files ?? []);
const _file = createSelector(_state, (state) => state.file ?? "");

export const fileSelectors = {
  files: _files,
  file: _file,

  /** Selector used for getting the entire agency state. */
  state: createSelector(_files, (files) => ({
    files,
  })),
};
