import DashboardPage from "./DashboardPage";
import PatientsPage from "./PatientsPage";
import NewPatientPage from "./NewPatientPage/NewPatientPage";
import BillingPage from "./BillingPage";
import NewAppointmentPage from "./Appointment/NewAppointmentPage";
import AppointmentsPage from "./Appointment/AppointmentsPage";
import AppointmentsByDatePage from "./Appointment/AppointmentsByDatePage";

import { ROLES } from "../../state";
import UpdatesPage from "./UpdatesPage/UpdatesPage";
import RateCodesPage from "./RateCode/RateCodesPage";
import UsersPage from "./UsersPage/UsersPage";
import UsersFormPage from "./Users/UsersFormPage";
import NotificationSettingsPage from "./NotificaitonSettingsPage/NotificationSettingsPage";
import NotificationSettingsFormPage from "./NotificationFormPage/NotificationSettingsFormPage";
import AppointmentPage from "./Appointment/AppointmentPage/AppointmentPage";

export const AgencyPages = {
  dashboard: {
    anon: false,
    path: "/agency/dashboard",
    title: "Agency Dashboard",
    type: "PAGE_AGENCY_DASHBOARD",
    view: DashboardPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER, ROLES.AGENCY_BILLER],
  },
  createPatient: {
    anon: false,
    path: "/agency/patients/create",
    title: "Create New Caregiver",
    type: "PAGE_AGENCY_CREATE_PATIENT",
    view: NewPatientPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER],
  },
  editPatient: {
    anon: false,
    path: "/agency/patients/:id",
    title: "Edit Caregiver",
    type: "PAGE_AGENCY_EDIT_PATIENT",
    view: NewPatientPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER],
  },
  patients: {
    anon: false,
    path: "/agency/patients",
    title: "Agency Caregivers",
    type: "PAGE_AGENCY_PATIENTS",
    view: PatientsPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER, ROLES.AGENCY_BILLER],
  },
  updates: {
    anon: false,
    path: "/agency/updates",
    title: "Appointments By Date",
    type: "PAGE_AGENCY_UPDATES",
    view: UpdatesPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER],
  },
  appointmentsByDate: {
    anon: false,
    path: "/agency/appointments-by-date",
    title: "Appointments By Date",
    type: "PAGE_AGENCY_APPOINTMENTS_BY_DATE",
    view: AppointmentsByDatePage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER],
  },
  createAppointment: {
    anon: false,
    path: "/agency/appointments/create",
    title: "Create New Appointment",
    type: "PAGE_AGENCY_CREATE_APPOINTMENT",
    view: NewAppointmentPage,
    roles: [
      ROLES.SUPER_ADMIN,
      ROLES.AGENCY_ADMIN,
      ROLES.AGENCY_SCHEDULER,
      ROLES.FACILITY_ADMIN,
      ROLES.FACILITY_PROVIDER,
    ],
  },
  appointment: {
    anon: false,
    path: "/agency/appointments/:id",
    title: "Appointment",
    type: "PAGE_AGENCY_APPOINTMENT",
    view: AppointmentPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER],
  },
  appointments: {
    anon: false,
    path: "/agency/appointments",
    title: "Appointments",
    type: "PAGE_AGENCY_APPOINTMENTS",
    view: AppointmentsPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER, ROLES.AGENCY_BILLER],
  },
  billing: {
    anon: false,
    path: "/agency/billing",
    title: "Billing",
    type: "PAGE_AGENCY_BILLING",
    view: BillingPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER, ROLES.AGENCY_BILLER],
  },
  history: {
    anon: false,
    path: "/agency/history",
    title: "History",
    type: "PAGE_AGENCY_HISTORY",
    view: BillingPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER, ROLES.AGENCY_BILLER],
  },
  rateCodes: {
    anon: false,
    path: "/agency/ratecodes",
    title: "History",
    type: "PAGE_AGENCY_RATECODES",
    view: RateCodesPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.AGENCY_SCHEDULER, ROLES.AGENCY_BILLER],
  },
  createUser: {
    anon: false,
    path: "/agency/users/create",
    title: "Create Agency User",
    type: "PAGE_AGENCY_CREATE_USER",
    view: UsersFormPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN],
  },
  editUser: {
    anon: false,
    path: "/agency/users/:id",
    title: "Edit Agency Users",
    type: "PAGE_AGENCY_EDIT_USER",
    view: UsersFormPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN],
  },
  users: {
    anon: false,
    path: "/agency/users",
    title: "Users",
    type: "PAGE_AGENCY_USERS",
    view: UsersPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN],
  },
  notificationsSettingsFormNew: {
    anon: false,
    path: "/agency/notifications/add",
    title: "Notification Settings",
    type: "PAGE_ENTITY_NOTIFICATION_SETTINGS_FORM_NEW_PAGE",
    view: NotificationSettingsFormPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.FACILITY_ADMIN],
  },
  notificationsSettingsFormEdit: {
    anon: false,
    path: "/agency/notifications/:id",
    title: "Notification Settings",
    type: "PAGE_ENTITY_NOTIFICATION_SETTINGS_FORM_EDIT_PAGE",
    view: NotificationSettingsFormPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.FACILITY_ADMIN],
  },
  notificationsSettings: {
    anon: false,
    path: "/agency/notifications",
    title: "Notification Settings",
    type: "PAGE_ENTITY_NOTIFICATION_SETTINGS_PAGE",
    view: NotificationSettingsPage,
    roles: [ROLES.SUPER_ADMIN, ROLES.AGENCY_ADMIN, ROLES.FACILITY_ADMIN],
  },
};

export default AgencyPages;

export const AgencyArea = {
  pages: AgencyPages,
};
