import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  statusFormControl: {
    width: 280,
    marginRight: 10,
  },
  typeFormControl: {
    width: 100,
  },
}));
