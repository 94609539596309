import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Table from "../Table";
import { useDispatch, superAdminActions, useSelector, superAdminSelectors } from "../../../state";
import {
  TABLE_ROW_EDIT_ACTION,
  SUPER_ADMIN_DEFAULT_RATE_CODES_TABLE_COLUMNS as columns,
} from "../../../constants";
import SuperAdminPages from "../../../pages/super-admin";

const DefaultRateCodesTable = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(superAdminActions.getDefaultRateCodes());
  }, [dispatch]);

  const history = useHistory();

  const rateCodes = useSelector(superAdminSelectors.defaultRateCodes);
  const handleEdit = useCallback(
    row => {
      history.push(SuperAdminPages.editDefaultRateCode.path.replace(":id", row.id));
    },
    [history],
  );
  const handleDelete = useCallback(
    row => {
      dispatch(superAdminActions.deleteDefaultRateCode(row.id));
    },
    [dispatch],
  );

  return (
    <>
      <Table
        rows={rateCodes}
        columns={columns}
        deleteHandler={handleDelete}
        editHandler={handleEdit}
        actions={[TABLE_ROW_EDIT_ACTION]}
        exportEnabled={true}
        exportedFileName="DefaultRateCodes"
      />
    </>
  );
};

export default React.memo(DefaultRateCodesTable);
