import React from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";

import LabForm from "../../../components/forms/LabForm";
import { ArrowBackIcon } from "../../../components/icons";

import { RouterLink } from "../../../lib";

import { useStyles } from "./LabsFormPage.styles";

const LabsFormPage = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton component={RouterLink} to="/super-admin/labs">
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            Lab Form
          </Typography>
        </Grid>
      </Grid>

      <LabForm />
    </>
  );
};

export default LabsFormPage;
