import React, { useCallback, useEffect, useState } from "react";
import Table from "../Table";
import {
  agencySelectors,
  superAdminActions,
  superAdminSelectors,
  useAction,
  useSelector,
} from "../../../state";
import {
  SUPER_ADMIN_AGENCY_BUNDLE_COLUMNS,
  SUPER_ADMIN_AGENCY_BUNDLES_TABLE_COLUMN_EXTENSIONS,
  TABLE_ROW_EDIT_ACTION,
} from "../../../constants";
import EditAgencyBundleModal from "../../modals/EditAgencyBundleModal";

const AgencyBundleTable = () => {
  const bundles = useSelector(superAdminSelectors.agencyBundles);
  const agency = useSelector(superAdminSelectors.activeAgency);
  const searchQuery = useSelector(agencySelectors.searchQuery);
  const getAgencyBundles = useAction(superAdminActions.getAgencyBundles);
  const [open, setOpen] = useState(false);
  const [openedBundle, setOpenedBundle] = useState();

  useEffect(() => {
    if (agency?.id) getAgencyBundles(agency.id);
  }, [getAgencyBundles, agency?.id]);

  const handleEdit = useCallback(
    (row) => {
      const bundle = bundles.find((bundle) => bundle.id === row.id);
      setOpenedBundle(bundle);
      setOpen(true);
    },
    [bundles],
  );

  return (
    <>
      <Table
        rows={bundles}
        columns={SUPER_ADMIN_AGENCY_BUNDLE_COLUMNS}
        tableColumnExtensions={SUPER_ADMIN_AGENCY_BUNDLES_TABLE_COLUMN_EXTENSIONS}
        exportEnabled={true}
        searchValue={searchQuery}
        editHandler={handleEdit}
        actions={[TABLE_ROW_EDIT_ACTION]}
        exportedFileName="Agency-Bundles"
      />
      {open && <EditAgencyBundleModal open={open} setOpen={setOpen} bundle={openedBundle} />}
    </>
  );
};

export default React.memo(AgencyBundleTable);
