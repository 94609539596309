import React from "react";
import { useParams } from "react-router-dom";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ArrowBackIcon } from "../../../components/icons";
import ProfileForm from "../../../components/forms/AgencyUserForm";

import AgencyPages from "../index";
import { RouterLink } from "../../../lib";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
  },
  bottom: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
    margin: 0,
  },
}));

const UsersFormPage = () => {
  const classes = useStyles();
  const { id } = useParams();

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton component={RouterLink} to={AgencyPages.users.path}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            {id ? "Edit" : "Create"} Agency User
          </Typography>
        </Grid>
      </Grid>
      <ProfileForm />
    </>
  );
};

export default UsersFormPage;
