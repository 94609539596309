import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    marginTop: 20,
    marginBottom: 20,
    color: "#A41717",
  },
  header: {
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
  },
  closeIcon: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  modalFooter: {
    paddingTop: theme.spacing(3),
  },
  activityTitle: {
    fontWeight: 800,
    textTransform: "uppercase",
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    padding: "0 0 3em",
  },
  table: {
    borderTop: "1px solid rgba(151, 151, 151, 0.3)",
  },
  tableRow: {
    "& .MuiTableCell-root": {
      padding: "0.5em",
    },
  },
  tableCell: {
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
  },
}));
