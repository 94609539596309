import React, { useCallback, useEffect, useState } from "react";
import { Chip, Collapse, Grid, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import { FilterButton, AddButton } from "../../../../../components/buttons";
import { SearchIcon, ViewDayIcon, ViewListIcon } from "../../../../../components/icons";
import AppointmentsByDateTable from "../../../../../components/tables/AppointmentsByDateTable";
import AppointmentsFilters from "../../../../../components/tables/AppointmentsTable/AppointmentsFilters";
import AppointmentsTablesByHour from "../../../../../components/tables/AppointmentsByDateTable/AppointmentsTablesByHour";

import {
  // TYPES,
  APPOINTMENTS_FILTERS,
  APPOINTMENTS_FILTERS_EXTENSIONS,
} from "../../../../../constants";
import AgencyPages from "../../../index";
import { useInputDebounced } from "../../../../../lib";
import { agencyActions, useAction } from "../../../../../state";
import { getStatusLabel } from "../../../../../components/tags/StatusTag";

import { useStyles } from "./AppointmentsByDate.styles";

const AppointmentsByDate = () => {
  const classes = useStyles();
  const setSearchQuery = useAction(agencyActions.setSearchQuery);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  const [expanded, setExpanded] = useState(false);
  const [view, setView] = useState<string>("list");

  const [selectedFilters, setSelectedFilters] = useState<any[]>(APPOINTMENTS_FILTERS);
  const filtersChips = selectedFilters.flatMap((filter) => filter.value).filter(Boolean);

  const handleFilterChange = useCallback(
    ({ target: { value } }) => {
      if (!selectedFilters.includes(value)) {
        setSelectedFilters([...selectedFilters, value]);
      }
    },
    [selectedFilters],
  );

  const handleRemoveFilter = useCallback(
    (status) => {
      setSelectedFilters(selectedFilters.filter((filter) => status !== filter));
    },
    [selectedFilters],
  );

  const handleExpandClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const handleChangeView = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
      if (newView !== null) {
        setView(newView);
      }
    },
    [],
  );

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  return (
    <div>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={5}>
          <TextField
            fullWidth
            id="search"
            label="Search"
            variant="outlined"
            value={searchQuery}
            // @ts-ignore
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton type="submit">
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item>
          <Grid container justifyContent="space-between">
            <FilterButton value={expanded} selected={expanded} onChange={handleExpandClick} />
            <AddButton to={AgencyPages.createAppointment.path} />
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={expanded} timeout="auto" unmountOnExit className={classes.container}>
        <Grid container>
          <AppointmentsFilters
            selectedFilters={selectedFilters}
            handleFilterChange={handleFilterChange}
            handleRemoveFilter={handleRemoveFilter}
          />
        </Grid>
      </Collapse>

      {!!filtersChips.length && (
        <Grid container className={classes.container} spacing={1}>
          {filtersChips.map((filter, i) => (
            <Chip
              key={`selected-filter-${filter}-${i}`}
              variant="outlined"
              color="primary"
              onDelete={() => handleRemoveFilter(filter)}
              // label={TYPES[filter] || getStatusLabel[filter]}
              label={getStatusLabel[filter]}
              className={classes.selectedFilter}
            />
          ))}
        </Grid>
      )}

      <Grid container justifyContent="flex-end" alignItems="center" className={classes.switchView}>
        <Grid item>
          <ToggleButtonGroup exclusive value={view} onChange={handleChangeView}>
            <ToggleButton value="list" size="small">
              <ViewDayIcon />
            </ToggleButton>
            <ToggleButton value="table" size="small">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>

      {view === "table" && (
        <AppointmentsByDateTable
          selectedFilters={selectedFilters}
          tableFiltersExtensions={APPOINTMENTS_FILTERS_EXTENSIONS}
        />
      )}
      {view === "list" && (
        <AppointmentsTablesByHour
          selectedFilters={selectedFilters}
          tableFiltersExtensions={APPOINTMENTS_FILTERS_EXTENSIONS}
        />
      )}
    </div>
  );
};

export default AppointmentsByDate;
