import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  hour: {
    marginBottom: 15,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "0.9375rem",
    fontWeight: "bold",
  },
}));
