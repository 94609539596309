import { generateWords } from "../utils/generate";

export function createLabResult() {
  return {
    id: Math.round(Math.random() * 10000),
    facility_name: generateWords(2, "spaced"),
    region: generateWords(2, "spaced"),
    location: generateWords(2, "spaced"),
    result: `${Math.round(Math.random() * 10)} days`,
  };
}
