import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  btn: {
    maxWidth: 64,
    marginTop: 24,
    backgroundColor: "#17A45E",
    color: "#FFFFFF",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 40,
  },
  subTitle: {
    fontSize: 16,
    marginTop: 16,
    fontWeight: 600,
    letterSpacing: 0.15,
    color: "#A8A8A8",
  },
}));
