import React from "react";
import Table from "../../tables/Table";
import {
  SCHEDULED_APPOINTMENTS_TABLE_COLUMNS as columns,
} from "../../../constants";
import { useSelector, agencySelectors } from "../../../state";
//import { useStyles } from "./ScheduledAppointmentsTabs.styles";
import FacilityPages from "../../../pages/facility";

const ScheduledAppointmentsTabs = () => {
  //const classes = useStyles();
  const scheduledAppointments = useSelector(agencySelectors.scheduledAppointments);

  const appointmentsLoading = useSelector(agencySelectors.appointmentsLoading);
  let now = new Date();
  let nowString = now.toDateString();
  return (
    <Table
      rows={scheduledAppointments}
      columns={columns}
      // tableColumnExtensions={extensions}
      loading={appointmentsLoading}
      // searchValue={searchQuery}
      // actions={actions}
      rowBaseUrl={FacilityPages.appointments.path}
      exportEnabled={true}
      exportedFileName={`CheckedInAppointments-${nowString}`}
    />
  )
};

export default ScheduledAppointmentsTabs;
