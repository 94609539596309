import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DefaultBundlesTable from "../../../components/tables/DefaultBundlesTable";
import { AddButton } from "../../../components";
import SuperAdminPages from "..";

export const useStyles = makeStyles(
  (theme) => ({
    header: {
      paddingBottom: theme.spacing(3),
    },
  }),
  { classNamePrefix: "SuperAdminBundlesPage" },
);
export default function DefaultBundlesPage() {
  const classes = useStyles();

  return (
    <Box>
      <Grid
        container
        component="header"
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item>
          <Typography variant="subtitle2">Default Bundles</Typography>
        </Grid>
        <Grid item>
          <AddButton to={SuperAdminPages.defaultBundleFormPage.path} />
        </Grid>
      </Grid>

      <DefaultBundlesTable />
    </Box>
  );
}
