import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { AddButton } from "../../../../components/buttons";
import FacilitiesTable from "../../../../components/tables/FacilitiesTable";
import SuperAdminPages from "../../index";
import { useInputDebounced } from "../../../../lib";
import { useAction, superAdminActions } from "../../../../state";
import { useStyles } from "./FacilitiesPage.styles";
import { SearchField } from "../../../../components/inputs";

// TODO add types and interfaces
const FacilitiesPage = () => {
  const classes = useStyles();

  const getFacilities = useAction(superAdminActions.getFacilities);
  // TODO refactor into separate component/hooks
  const setSearchQuery = useAction(superAdminActions.setSearchQuery);

  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setSearchQuery(debouncedSearchQuery);
  }, [setSearchQuery, debouncedSearchQuery]);

  useEffect(() => {
    getFacilities();
  }, [getFacilities]);

  return (
    <Box>
      <Typography variant="subtitle2">Medical Facilities</Typography>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={4}>
          <SearchField id="search" label="Search" value={searchQuery} onChange={handleChange} />
        </Grid>

        <Grid item>
          <AddButton id={"create"} to={SuperAdminPages.createFacility.path} />
        </Grid>
      </Grid>

      <FacilitiesTable />
    </Box>
  );
};

export default React.memo(FacilitiesPage);
