import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
    textTransform: "uppercase",
    marginBottom: 16,
  },
  phone: {
    fontSize: "1.15rem",
  },
  scheduleBtn: {
    marginTop: 16,
    padding: 0,
  },
  cardWithDetails: {
    padding: 24,
    paddingLeft: 32,
    minHeight: 400,
  },
  totalPaid: {
    marginBottom: 24,
  },
  subTitle: {
    fontSize: 16,
    letterSpacing: 0.15,
    fontWeight: 600,
    marginTop: 16,
    marginBottom: 8,
  },
  addBtn: {
    borderRadius: 20,
    marginLeft: 10,
  },
  link: {
    textDecoration: "underline",
    display: "block",
    marginTop: 16,
  },
  originalAptDatetime: {
    height: "24px",
    color: "#D11D1D",
    textDecoration: "line-through",
  },
  reviewedIcon: {
    color: theme.palette.success.main,
    display: "flex",
    gap: 8,
    marginTop: 8,
    alignItems: "center",
  },
  modal: {
    marginTop: 20,
    marginBottom: 20,
    color: "#A41717",
  },
}));
