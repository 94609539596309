import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 117,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 14,
    paddingRight: 24,
    justifyContent: "space-between",
    marginRight: 10,
    border: 0,
    color: theme.palette.primary.main,
  },
  label: {
    lineHeight: "2.25rem",
    ...theme?.overrides?.MuiButton?.label,
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: "#fff !important",
  },
}));
