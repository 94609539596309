import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Typography } from "@material-ui/core";

import { ArrowBackIcon } from "../../../components/icons";

import { RouterLink } from "../../../lib";
import CreateRegionForm from "../../../components/forms/CreateRegionForm";
import SuperAdminPages from "..";

export const useStyles = makeStyles(theme => ({
  header: {
    paddingBottom: 20,
    borderBottom: "1px solid #E0E0E0",
  },
}));

const CreateRegionPage = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container component="header" alignItems="center" spacing={1} className={classes.header}>
        <Grid item>
          <IconButton component={RouterLink} to={SuperAdminPages.regions.path}>
            <ArrowBackIcon fontSize="large" />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography variant="h4" component="h1">
            Region Form
          </Typography>
        </Grid>
      </Grid>

      <CreateRegionForm />
    </>
  );
};

export default CreateRegionPage;
