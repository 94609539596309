import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0 2em 3em",
  },
  table: {
    borderTop: "1px solid rgba(151, 151, 151, 0.3)",
    borderColor: (props: any) =>
      props.error ? theme.palette.error.main : "rgba(151, 151, 151, 0.3)",

    "& .MuiTableRow-root:last-child": {
      "& .MuiTableCell-root": {
        borderColor: (props: any) =>
          props.error ? theme.palette.error.main : "rgba(151, 151, 151, 0.3)",
      },
    },
  },
  tableRow: {
    "& .MuiTableCell-root": {
      padding: "0.5em",
    },
  },
  tableRowDisabled: {
    opacity: 0.5,
  },
  switchCell: {
    width: 60,
    paddingLeft: 0,
    paddingRight: 0,
  },
  rateCode: {
    width: 55,
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
  },
  description: {
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
  },
  uploadFormCell: {
    width: 200,
  },
  chargeCell: {
    width: 95,
  },
  noRateCodes: {
    width: "100%",
    padding: "3em 2em",
  },
}));
