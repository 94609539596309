import React, { useCallback, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, Chip } from "@material-ui/core";

// import { TYPES, TYPES_ARRAY } from "../../../../constants";
import { useAction, agencyActions, useSelector, agencySelectors, authSelectors } from "../../../../state";

import { useStyles } from "./UploadUpdatesFilters.styles";

// TODO define filters (right now they are just copied since no filters found in designs)
const UploadUpdatesFilters = ({ selectedFilters, handleFilterChange }) => {
  const classes = useStyles();

  const getRateCodes = useAction(agencyActions.getRateCodes);
  const rateCodes: any[] = useSelector(agencySelectors.rateCodes);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  const rateCodeFilterValue = selectedFilters.find((filter) => filter.columnName === "code").value;
  // const typeFilterValue = selectedFilters.find((filter) => filter.columnName === "type").value;

  const handleRateCodeFilterChange = useCallback(
    ({ target: { value } }) => {
      handleFilterChange("code", value);
    },
    [handleFilterChange],
  );

  // const handleTypeFilterChange = useCallback(
  //   ({ target: { value } }) => {
  //     handleFilterChange("type", value);
  //   },
  //   [handleFilterChange],
  // );

  useEffect(() => {
    getRateCodes(activeEntityId);
  }, [getRateCodes, activeEntityId]);

  return (
    <>
      <FormControl variant="outlined" className={classes.statusFormControl}>
        <InputLabel id="latest-appointment-status-label">Rate Code</InputLabel>
        <Select
          multiple
          id="rate-code"
          label="Rate Code"
          labelId="rate-code-label"
          value={rateCodeFilterValue}
          onChange={handleRateCodeFilterChange}
          renderValue={(selected: any) =>
            selected.map((selectedFilter, i) => (
              <Chip
                key={`selected-filter-${selectedFilter}-${i}`}
                size="small"
                variant="outlined"
                color="primary"
                label={selectedFilter}
              />
            ))
          }
        >
          {rateCodes.map((code) => (
            <MenuItem key={`statuses-${code.id}`} value={code.code || code.default_rate_code.code}>
              {code.code || code.default_rate_code.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* <FormControl variant="outlined" className={classes.typeFormControl}>
        <InputLabel id="type-label">Type</InputLabel>
        <Select
          id="type"
          label="Type"
          labelId="type-label"
          value={typeFilterValue}
          onChange={handleTypeFilterChange}
        >
          {TYPES_ARRAY.map((type, i) => (
            <MenuItem key={`types-${TYPES[type]}-${i}`} value={type}>
              {TYPES[type]}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
    </>
  );
};

export default React.memo(UploadUpdatesFilters);
