import { AppState } from "../types";
import { UIViewState } from "./state";

export const uiSelectors = {
  loading(state: AppState) {
    return state.ui.loading;
  },
  notification(state: AppState) {
    return state.ui.notification;
  },
  messages(state: AppState) {
    return state.ui.messages;
  },

  // Views
  /** Dynamically creates a selector to select the given `view[name]`. */
  forView(name: keyof UIViewState) {
    return (state: AppState) => state.ui.view[name];
  },

  // Modals
  uploadModal(state: AppState) {
    return state.ui.uploadModal;
  },
  bundleSuccessModal(state: AppState) {
    return state.ui.bundlesSuccessModal;
  },
};
