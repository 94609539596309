import React, { useCallback } from "react";
import { useFormikContext } from "formik";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { formatToDate } from "../../../../utils";
import { agencySelectors, useSelector } from "../../../../state";

const ExistingPatientAutoComplete = () => {
  const patients = useSelector(agencySelectors.patients);
  const { setFieldValue, errors, touched, values } = useFormikContext<any>();

  const handleAutoCompleteSelect = useCallback(
    (e, value) => {
      setFieldValue("patient_id", value?.id);
    },
    [setFieldValue],
  );
  return (
    <Autocomplete
      fullWidth
      options={patients}
      value={patients.find((p) => p.id === values.patient_id) ?? null}
      getOptionLabel={({ first_name, last_name, date_of_birth }) =>
        `${first_name} ${last_name}   |   ${formatToDate(date_of_birth)}`
      }
      //TODO: format nicer
      // renderOption={(option, state) => (
      //   <Grid container>
      //     <Grid item>{`${option.first_name} ${option.last_name}`}</Grid>
      //     <Grid> {`${formatToDate(option.date_of_birth)}`}</Grid>
      //   </Grid>
      // )}
      style={{ maxWidth: 536 }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Select an existing caregiver"
          variant="outlined"
          error={touched.patient_id && Boolean(errors.patient_id)}
          helperText={touched.patient_id && errors.patient_id}
        />
      )}
      onChange={handleAutoCompleteSelect}
    />
  );
};

export default React.memo(ExistingPatientAutoComplete);
