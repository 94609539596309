import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import checkIcon from "../../../../assets/img/greenCheck.svg";

import { useStyles } from "./AppointmentRequested.styles";
import AgenciesPage from "../../../../pages/agency";
import { Navigation } from "../../../../lib";

const _AppointmentRequested = ({ setShow, reset }) => {
  const classes = useStyles();

  const handleClose = () => {
    reset();
    Navigation.go(AgenciesPage.appointments.path);
    setShow(false);
  };
  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      style={{ marginTop: 80 }}
    >
      <img alt="check" src={checkIcon} />
      <Typography className={classes.title}>
        Appointment request was submitted to Level up
      </Typography>
      <Typography className={classes.subTitle}>
        Level up will reach out to the caregiver to schedule an appointment
      </Typography>
      <Button variant="contained" onClick={handleClose} className={classes.btn}>
        Close
      </Button>
    </Grid>
  );
};

export const AppointmentRequested = React.memo(_AppointmentRequested);
