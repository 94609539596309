import React, { useCallback } from "react";
import { Button, Box, IconButton } from "@material-ui/core";

import { EditIcon, DeleteIcon } from "../../icons";

import {
  TABLE_ROW_EDIT_ACTION,
  TABLE_ROW_DELETE_ACTION,
  TABLE_ROW_DOWNLOAD_ACTION,
  TABLE_ROW_VIEW_ACTION,
  TABLE_ROW_UPLOAD_ACTION,
  TABLE_ROW_CHECKIN_ACTION,
} from "../../../constants";
import { uiActions, useAction } from "../../../state";

import { useStyles } from "./TableActionsCell.styles";

interface TableActionsCellProps {
  row: any[];
  actions?: string[];
  editHandler?: any;
  deleteHandler?: any;
  downloadHandler?: any;
  checkinHandler?: any;
  viewHandler?: any;
}

export const TableActionsCell = ({
  row,
  actions = [],
  editHandler = (f) => f,
  deleteHandler = (f) => f,
  downloadHandler = (f) => f,
  checkinHandler = (f) => f,
  viewHandler = (f) => f,
}: TableActionsCellProps) => {
  const classes = useStyles();
  const openUploadModal = useAction(uiActions.openUploadModal);

  const handleEdit = useCallback(() => {
    // handle opening edit mode here
    editHandler(row);
  }, [editHandler, row]);

  const handleDelete = useCallback(() => {
    // handle opening delete confirmation modal
    deleteHandler(row);
  }, [deleteHandler, row]);

  const handleDownload = useCallback(() => {
    downloadHandler(row);
  }, [downloadHandler, row]);

  const handleView = useCallback(() => {
    viewHandler(row);
  }, [viewHandler, row]);

  const handleCheckin = useCallback(
    (event) => {
      checkinHandler({ event, row });
    },
    [checkinHandler, row],
  );

  const handleOpenUploadModal = useCallback(() => {
    openUploadModal(row);
  }, [openUploadModal, row]);

  const isEdit = actions.includes(TABLE_ROW_EDIT_ACTION);
  const isDelete = actions.includes(TABLE_ROW_DELETE_ACTION);
  const isDownload = actions.includes(TABLE_ROW_DOWNLOAD_ACTION);
  const isView = actions.includes(TABLE_ROW_VIEW_ACTION);
  const isUpload = actions.includes(TABLE_ROW_UPLOAD_ACTION);
  const isCheckin = actions.includes(TABLE_ROW_CHECKIN_ACTION);
  const isActionsEnabled = isEdit || isDelete || isDownload || isView || isUpload || isCheckin;

  return isActionsEnabled ? (
    <Box>
      {isEdit && (
        <IconButton onClick={handleEdit} size="small">
          <EditIcon />
        </IconButton>
      )}

      {isDelete && (
        <IconButton onClick={handleDelete} size="small">
          <DeleteIcon />
        </IconButton>
      )}

      {isDownload && (
        <Button color="primary" onClick={handleDownload} size="small">
          Download
        </Button>
      )}

      {isView && (
        <Button color="primary" size="small" onClick={handleView} className={classes.viewBtn}>
          View Appointment
        </Button>
      )}

      {isUpload && (
        <Button
          color="primary"
          size="small"
          onClick={handleOpenUploadModal}
          className={classes.uploadBtn}
        >
          Upload
        </Button>
      )}

      {isCheckin && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleCheckin}
          className={classes.checkinBtn}
        >
          Check In
        </Button>
      )}
    </Box>
  ) : null;
};

export const renderActionsCell = (props) => <TableActionsCell {...props} />;
