import React from "react";
import { BarSeries } from "@devexpress/dx-react-chart-material-ui";

import ChartBase from "./ChartBase";

import { capitalizeFirstLetter } from "../../utils";
import { agencySelectors, useSelector } from "../../state";

// TODO add types and interfaces
const DashboardHistoryChart = ({ ...props }) => {
  const appointmentsHistory = useSelector(agencySelectors.appointmentsHistory);
  const keys = Object.keys(appointmentsHistory || []);

  const history = keys.map((item) => ({
    argument: capitalizeFirstLetter(item),
    value: appointmentsHistory[item],
  }));

  return Boolean(keys.length) ? (
    <ChartBase
      data={history}
      series={<BarSeries valueField="value" argumentField="argument" color="#0200A2" />}
      {...props}
    />
  ) : null;
};

export default React.memo(DashboardHistoryChart);
