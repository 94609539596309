import React from "react";
import { TableRow, TableCell, TableContainer, Table } from "@material-ui/core";
import { useStyles } from "./AppointmentHistoryModal.styles";
import { formatToDateTimeMinutes } from "../../../../../utils";

const HistoryTable = (appointmentHistoryList) => {
  const classes = useStyles();

  const { historyList } = appointmentHistoryList;

  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          {historyList.map((item) => (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableCell}>{item.description}</TableCell>
              <TableCell className={classes.tableCell}>
                {formatToDateTimeMinutes(item.datetime)}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {item.user ? `${item.user?.first_name} ${item.user?.last_name}` : "System"}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </>
  );
};

export default HistoryTable;
