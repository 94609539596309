import React from "react";
import { Button } from "@material-ui/core";

import { AddIcon } from "../../icons";
import { RouterLink } from "../../../lib";

import { useStyles } from "./AddButton.styles";

const AddButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      component={RouterLink}
      classes={classes}
      startIcon={<AddIcon fontSize="large" />}
      {...props}
    >
      Add
    </Button>
  );
};

export default React.memo(AddButton);
