// USERS
export const USERS_API = "/users";

// ENTITIES
export const ENTITIES_API = "/entities";

// AGENCIES
export const AGENCY_API = "/agency";

// Facilities
export const FACILITIES_API = "/facilities";

// UPLOAD
export const UPLOAD_PUBLIC_API = "/upload/public";
export const UPLOAD_PRIVATE_API = "/upload/private";
