import React, { useCallback, useEffect } from "react";
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import clsx from "clsx";

import FileUpload from "../FileUpload";

import {
  WEEK_DAYS,
  DAY_HOURS,
  US_STATES,
  CREATE_FACILITY_INITIAL_VALUES as initialValues,
} from "../../../constants";
import {
  CREATE_FACILITY_VALIDATION_SCHEMA as validationSchema,
  EDIT_FACILITY_VALIDATION_SCHEMA as editValidationSchema,
} from "../../../utils";
import SuperAdminPages from "../../../pages/super-admin";
import { Form, Navigation, useFormik, RouterLink } from "../../../lib";
import { useAction, superAdminActions, useSelector, superAdminSelectors } from "../../../state";

import { useStyles } from "./CreateFacilityForm.styles";

interface Props {
  id?: string | null;
}
const CreateFacilityForm = ({ id = null }: Props) => {
  const isEditFacility = Boolean(id);
  const facility = useSelector(superAdminSelectors.facility);

  const editFacility = useAction(superAdminActions.editFacility);
  const createFacility = useAction(superAdminActions.createFacility);
  const getFacilityById = useAction(superAdminActions.getFacilityById);

  const form = useFormik({
    initialValues: { ...initialValues, ...facility },
    validationSchema: isEditFacility ? editValidationSchema : validationSchema,
    enableReinitialize: true,
    async onSubmit(values) {
      const action = isEditFacility ? editFacility : createFacility;
      await action(values);
      Navigation.go(SuperAdminPages.facilities.path);
    },
  });

  const classes = useStyles({ isStatusError: !!form?.errors?.active });
  const regions = useSelector(superAdminSelectors.regions);
  const handleStatusChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newStatus: boolean | null) => {
      form.setFieldValue("active", newStatus);
    },
    [form],
  );

  useEffect(() => {
    if (isEditFacility) getFacilityById(+id);
  }, [isEditFacility, getFacilityById, id]);

  return (
    <Form form={form} className={classes.form}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.controlsWrap}>
          <Box className={classes.sectionTitle}>
            <Typography>Facility details</Typography>
          </Box>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="name"
                name="name"
                label="Facility Name"
                value={form.values.name}
                onChange={form.handleChange}
                error={form.touched.name && Boolean(form.errors.name)}
                helperText={form.touched.name && form.errors.name}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="phone"
                name="phone"
                label="Facility phone number"
                value={form.values.phone}
                onChange={form.handleChange}
                error={form.touched.phone && Boolean(form.errors.phone)}
                helperText={form.touched.phone && form.errors.phone}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={clsx(classes.formControl, classes.address)}>
              <TextField
                fullWidth
                variant="outlined"
                id="address"
                name="address"
                label="Address"
                value={form.values.address}
                onChange={form.handleChange}
                error={form.touched.address && Boolean(form.errors.address)}
                helperText={form.touched.address && form.errors.address}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="city"
                name="city"
                label="City"
                value={form.values.city}
                onChange={form.handleChange}
                error={form.touched.city && Boolean(form.errors.city)}
                helperText={form.touched.city && form.errors.city}
              />
            </Grid>
            <Grid item className={clsx(classes.formControl, classes.state)}>
              <TextField
                fullWidth
                variant="outlined"
                select
                name="state"
                id="state"
                label="State"
                value={form.values.state}
                onChange={form.handleChange}
                error={form.touched.state && Boolean(form.errors.state)}
                helperText={form.touched.state && form.errors.state}
              >
                {US_STATES.map((state, i) => (
                  <MenuItem key={`location-${state.label}-${i}`} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="zip"
                name="zip"
                label="Zip code"
                value={form.values.zip}
                onChange={form.handleChange}
                error={form.touched.zip && Boolean(form.errors.zip)}
                helperText={form.touched.zip && form.errors.zip}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={clsx(classes.formControl, classes.address)}>
              <TextField
                fullWidth
                variant="outlined"
                id="note"
                name="note"
                label="Facility Note"
                value={form.values.note}
                onChange={form.handleChange}
                error={form.touched.note && Boolean(form.errors.note)}
                helperText={form.touched.note && form.errors.note}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <ToggleButtonGroup
                className={classes.switcherGroup}
                exclusive
                size="large"
                value={form.values.active}
                onChange={handleStatusChange}
              >
                <ToggleButton value={true} className={classes.switcher}>
                  Active
                </ToggleButton>
                <ToggleButton value={false} className={classes.switcher}>
                  Inactive
                </ToggleButton>
              </ToggleButtonGroup>
              {form.errors && !!form.errors.active && (
                <FormHelperText error id="active" variant="outlined">
                  {form.errors.active}
                </FormHelperText>
              )}
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                type="number"
                fullWidth
                variant="outlined"
                id="appointments_per_hour"
                name="appointments_per_hour"
                label="Appointments per hour"
                value={form.values.appointments_per_hour}
                onChange={form.handleChange}
                error={Boolean(form.errors.appointments_per_hour)}
                helperText={form.errors.appointments_per_hour}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={clsx(classes.formControl, classes.select)}>
              <TextField
                select
                variant="outlined"
                name="region_id"
                id="region_id"
                label="Region"
                value={form.values.region_id}
                onChange={form.handleChange}
                error={form.touched.region_id && Boolean(form.errors.region_id)}
                helperText={form.touched.region_id && form.errors.region_id}
              >
                {/*<MenuItem value={1}>Dummy region</MenuItem>*/}
                {/* TODO wait for regions list API */}
                {regions.map((region, i) => (
                  <MenuItem key={`region-${region.name}-${i}`} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {/* TODO wait for printer API */}
            {/*<Grid item className={clsx(classes.formControl, classes.select)}>*/}
            {/*  <TextField*/}
            {/*    select*/}
            {/*    variant="outlined"*/}
            {/*    name="printer"*/}
            {/*    id="printer"*/}
            {/*    label="Printer"*/}
            {/*    value={form.values.printer}*/}
            {/*    onChange={form.handleChange}*/}
            {/*    error={form.touched.printer && Boolean(form.errors.printer)}*/}
            {/*    helperText={form.touched.printer && form.errors.printer}*/}
            {/*  >*/}
            {/*    {printers.map((printer, i) => (*/}
            {/*      <MenuItem*/}
            {/*        key={`location-${printer.name}-${i}`}*/}
            {/*        value={printer.id}*/}
            {/*      >*/}
            {/*        {printer.name}*/}
            {/*      </MenuItem>*/}
            {/*    ))}*/}
            {/*  </TextField>*/}
            {/*</Grid>*/}
          </Grid>

          {!isEditFacility && (
            <>
              <Box className={classes.sectionTitle}>
                <Typography>Facility admin</Typography>
              </Box>

              <Grid container>
                <Grid item className={classes.formControl}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="first_name"
                    name="first_name"
                    label="First Name"
                    value={form.values.first_name}
                    onChange={form.handleChange}
                    error={form.touched.first_name && Boolean(form.errors.first_name)}
                    helperText={form.touched.first_name && form.errors.first_name}
                  />
                </Grid>
                <Grid item className={classes.formControl}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="last_name"
                    name="last_name"
                    label="Last Name"
                    value={form.values.last_name}
                    onChange={form.handleChange}
                    error={form.touched.last_name && Boolean(form.errors.last_name)}
                    helperText={form.touched.last_name && form.errors.last_name}
                  />
                </Grid>
              </Grid>

              <Grid container justifyContent="flex-start">
                <Grid item className={classes.formControl}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type="email"
                    id="email"
                    name="email"
                    label="Email address"
                    value={form.values.email}
                    onChange={form.handleChange}
                    error={form.touched.email && Boolean(form.errors.email)}
                    helperText={form.touched.email && form.errors.email}
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Box className={classes.sectionTitle}>
            <Typography>Opening hours</Typography>
          </Box>

          {WEEK_DAYS.map((day) => (
            <Grid container key={`day-${day.label}`}>
              <Grid item className={clsx(classes.formControl, classes.hoursTitle)}>
                <Typography>{day.label}</Typography>
              </Grid>
              <Grid item className={clsx(classes.formControl, classes.hours)}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  name={`hours.${day.value}.open`}
                  id={`hours.${day.value}.open`}
                  label="Open"
                  value={form.values.hours[day.value]?.open}
                  onChange={form.handleChange}
                  error={
                    form.touched.hours &&
                    form.touched.hours[day.value]?.open &&
                    Boolean(form.errors.hours && form.errors.hours[day.value]?.open)
                  }
                  helperText={
                    form.touched.hours &&
                    form.touched.hours[day.value]?.open &&
                    form.errors.hours &&
                    form.errors.hours[day.value]?.open
                  }
                >
                  {DAY_HOURS.map((hour, i) => {
                    return (
                      <MenuItem key={`hours-${hour.label}-${i}`} value={hour.value}>
                        {hour.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item className={clsx(classes.formControl, classes.hours)}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  name={`hours.${day.value}.close`}
                  id={`hours.${day.value}.close`}
                  label="Close"
                  value={form.values.hours[day.value]?.close}
                  onChange={form.handleChange}
                  error={
                    form.touched.hours &&
                    form.touched.hours[day.value]?.close &&
                    Boolean(form.errors.hours && form.errors.hours[day.value]?.close)
                  }
                  helperText={
                    form.touched.hours &&
                    form.touched.hours[day.value]?.close &&
                    form.errors.hours &&
                    form.errors.hours[day.value]?.close
                  }
                >
                  {DAY_HOURS.map((hour, i) => (
                    <MenuItem key={`hours-${hour.label}-${i}`} value={hour.value}>
                      {hour.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          ))}

          <Box className={classes.sectionTitle}>
            <Typography>Logo upload</Typography>
          </Box>

          <Box className={classes.fileUploadWrap}>
            <FileUpload form={form} name="logo" accept="image/*" uploadType="public" />
            {form.touched.logo && Boolean(form.errors.logo) && (
              <FormHelperText error variant="outlined">
                {form.errors.logo}
              </FormHelperText>
            )}
          </Box>

          <Grid container justifyContent="center" className={classes.formActions} spacing={4}>
            <Grid item>
              <Button
                component={RouterLink}
                to="/super-admin/facilities"
                variant="outlined"
                color="primary"
                size="large"
                type="reset"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="large" type="submit">
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default React.memo(CreateFacilityForm);
