import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  actions: {
    marginTop: 34,
    borderTop: "1px solid rgba(0, 0, 0, 0.23)",
    padding: "0 32px",
  },
  reviewWrap: {
    width: "65vw",
  },
  reviewItemsWrap: {
    paddingLeft: 70,
    paddingRight: 70,
    paddingBottom: 30,
  },
  reviewItem: {
    width: "50%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100%",
    borderRadius: 0,
  },
  cardWithCode: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    textAlign: "center",
  },
  cardWithCodeContent: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  name: {
    fontSize: "1.1875em",
  },
  phone: {
    fontSize: "1.25em",
  },
  cardWithDetails: {
    paddingTop: 38,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: 38,
    fontSize: "0.9375rem",
  },
  facility: {
    paddingBottom: 46,
  },
  timeSlotWrap: {
    paddingTop: "2em",
    paddingBottom: "1.5em",
    textAlign: "center",
  },
  timeSlot: {
    display: "inline-block",
    width: 160,
    border: "1px solid rgba(0, 0, 0, 0.23)",
    color: "#000",
  },
  timeSlotText: {
    lineHeight: "2.25rem",
  },
  service: {},
  note: {
    width: "50vw",
    margin: "auto",
  },
  notesWrap: {
    paddingLeft: 70,
    paddingRight: 70,
  },
  notes: {
    width: "100%",
    padding: "0 12px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 0,
  },
  title: {
    padding: "12px 0",

    "& .MuiTypography-root": {
      fontSize: "1.25em",
      fontWeight: 500,
    },
  },
  notesText: {
    paddingBottom: theme.spacing(3),
  },
  actionsWrap: {
    paddingTop: theme.spacing(3),
  },
}));
