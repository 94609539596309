import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    margin: 0,
    padding: (props: any) => theme.spacing(0, props.tabView ? 3 : 0, 3),
    border: (props: any) => props.tabView && "1px solid rgba(224, 224, 224, 1)",
    borderTop: (props: any) => props.tabView && 0,
    borderBottom: (props: any) => props.tabView && 0,
  },
  selectedFilter: {
    marginRight: 10,
  },
}));
