import { capitalizeFirstLetter } from "./common";
import { isBefore, isEqual } from "date-fns";

export function getRole(row) {
  //TODO: multi role
  if (row) {
    const role = row.user_role_entities[0].role.name.split("_").join(" ");
    return capitalizeFirstLetter(role);
  }
}
export function getNotificationType(row) {
  return row.notification_type.description;
}
export function getFullName(row) {
  return `${row?.first_name || ""} ${row?.last_name || ""}`;
}

export function getFullLocation(row) {
  return `${row.facility_name || ""} - ${row.region || ""}`;
}

export function getRowId(row) {
  return row.id;
}

export function statusCompareFunc(a, b) {
  if (a === "-" && b === "-") return -1;
  const statusA = a.props ? a.props.status : 0;
  const statusB = b.props ? b.props.status : 0;
  if (statusA === statusB) return 0;
  return statusA < statusB ? -1 : 1;
}

export function reviewedCompareFunc(a, b) {
  if (a.props?.titleAccess === b?.props?.titleAccess) return 0;
  return a.props?.titleAccess ? -1 : 1;
}
export function stringCompareFunc(a: any, b: any) {
  const stringA =
    typeof a === "string"
      ? a?.toLowerCase()
      : typeof a === "object"
      ? typeof a?.props?.title === "string"
        ? a?.props?.title?.toLowerCase()
        : a?.props?.title
      : a;
  const stringB =
    typeof b === "string"
      ? b?.toLowerCase()
      : typeof b === "object"
      ? typeof b?.props?.title === "string"
        ? b?.props?.title?.toLowerCase()
        : b?.props?.title
      : b;
  if (stringA === stringB) return 0;
  return stringA < stringB ? -1 : 1;
}
export function sortDate(a, b) {
  const dateOne = new Date(a);
  const dateTwo = new Date(b);
  if (isEqual(dateOne, dateTwo)) return 0;
  return isBefore(dateOne, dateTwo) ? 1 : -1;
}
export function sortAmount(a, b) {
  const numberA = typeof a === "object" ? a?.props?.value : a;
  const numberB = typeof b === "object" ? b?.props?.value : b;
  if (Number(numberA) === Number(numberB)) return 0;
  return Number(numberA) > Number(numberB) ? 1 : -1;
}
