import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import Modal from "./Modal";
import { Navigation } from "../../lib";
import { useSelector } from "react-redux";
import { uiActions, uiSelectors, useAction } from "../../state";
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing(3),
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
    textTransform: "uppercase",
  },
  modalFooter: {
    paddingTop: theme.spacing(1),
  },
  infoText: {
    marginTop: 8,
  },
}));

const BundleCreatedSuccessModal = () => {
  const { bundle, open } = useSelector(uiSelectors.bundleSuccessModal);
  const close = useAction(uiActions.closeBundleSuccessModal);
  const classes = useStyles();

  const handleCloseModal = () => {
    close();
    Navigation.goBackHandler(undefined);
  };

  return (
    <Modal open={open} handleClose={handleCloseModal}>
      <Typography variant="h4" className={classes.header}>
        {bundle.name} {bundle.isEdit ? "updated" : "created"} successfully
      </Typography>
      <Typography>
        {bundle.isEdit ? (
          <>
            {bundle.name} was updated for all existing agencies. Please note the following system
            impacts:
            <Typography className={classes.infoText}>
              &#x2022; If the bundle name was changed, it will impact the bundle name on all
              existing appointments. New appointments created with this bundle will also use the new
              bundle name.
            </Typography>
            <Typography className={classes.infoText}>
              &#x2022; If the services were changed, it will not impact existing appointments. New
              appointments created with this bundle will include the updated services.
            </Typography>
          </>
        ) : (
          `${bundle.name} was created for all existing agencies
        with the default status of inactive. You can activate it for existing agencies on the agency
        level.`
        )}
      </Typography>
      <Grid
        component="footer"
        container
        justifyContent="flex-end"
        classes={{ container: classes.modalFooter }}
      >
        <Button color="primary" size="large" onClick={handleCloseModal}>
          OK
        </Button>
      </Grid>
    </Modal>
  );
};

export default React.memo(BundleCreatedSuccessModal);
