import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDrawer-paper": {
      overflow: "auto",
      position: "absolute",
      height: "100vh",
      bottom: 0,
    },
  },
  title: {
    padding: "24px 14px 32px",
  },
  header: {
    paddingTop: theme.spacing(1),
  },
  switchView: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  container: {
    margin: 0,
    padding: theme.spacing(3, 0, 0),
  },
  selectedFilter: {
    marginRight: 10,
  },
}));
