import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  stepper: {
    width: "100%",
    maxWidth: "60vw",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  stepperLabel: {
    color: theme.palette.primary.main,
  },
  button: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.main,
  },
}));
