import React from "react";

import Table from "../Table";

import {
  APPOINTMENTS_BY_DATE_TABLE_COLUMNS as columns,
  APPOINTMENTS_BY_DATE_TABLE_COLUMN_EXTENSIONS as extensions,
} from "../../../constants";
import { agencySelectors, useSelector } from "../../../state";

// TODO add types and interfaces
const AppointmentsByDateTable = ({ selectedFilters, tableFiltersExtensions }) => {
  const appointments = useSelector(agencySelectors.appointments);
  const searchQuery = useSelector(agencySelectors.searchQuery);

  return (
    <Table
      rows={appointments}
      columns={columns}
      searchValue={searchQuery}
      withFilters={true}
      selectedFilters={selectedFilters}
      tableColumnExtensions={extensions}
      tableFiltersExtensions={tableFiltersExtensions}
      withPagination={false}
      exportEnabled={true}
      exportedFileName="appointmentsByDate"
    />
  );
};

export default React.memo(AppointmentsByDateTable);
