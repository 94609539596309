import { createTheme, useMediaQuery } from "@material-ui/core";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type from "@material-ui/lab/themeAugmentation";

/** Material-ui default theme, to copy from where necessary. */
// const defaults = createTheme();

// #region Example to copy shadows from default theme.
/**
 * Override box shadows[3]. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
// const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
// const shadows = [...defaults.shadows];
// shadows[3] = boxShadow;
// #endregion

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with example overrides.
 */
export const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    //pageTitle
    subtitle2: {
      color: "#000000",
      fontSize: 34,
      fontWeight: "bold",
      letterSpacing: 0.25,
      textTransform: "uppercase",
    },

    h4: {
      fontSize: "1.75rem",
    },
    h3: {
      fontSize: "2rem",
    },
    h2: {
      fontSize: "2.375rem",
      fontWeight: 400,
    },

    body2: {
      fontSize: "1rem",
    },
  },

  palette: {
    type: "light",
    primary: { main: "#a41717" },
    secondary: { main: "#a41717", dark: "rgba(0, 0, 0, 0.5)" },
    success: { main: "#17A45E" },
  },

  overrides: {
    MuiTableCell: {
      root: {
        fontSize: "0.9375rem",
      },
      head: {
        fontSize: "0.75rem",
        color: "#939393",
      },
    },
    MuiToggleButton: {
      label: {
        fontSize: "1rem",
      },
    },
    MuiButton: {
      root: {
        textTransform: "capitalize",
        boxShadow: "none",
      },
      contained: {
        borderRadius: 20,
        boxShadow: "none",
        maxHeight: 36,

        "&:hover": {
          boxShadow: "none",
        },
      },

      label: {
        fontSize: "0.875rem",
      },
    },
  },

  // #region Example global overrides for this theme:
  // overrides: {

  //   // Example to override "contained" variant of the Material-ui Button:
  //   // MuiButton: {
  //   //   contained: {
  //   //     boxShadow: "none",
  //   //     "&:hover": {
  //   //       boxShadow: "none",
  //   //     },
  //   //   },
  //   // },

  //   // Example to override the "contained" variant of Material-ui ButtonGroup:
  //   // MuiButtonGroup: {
  //   //   contained: {
  //   //     boxShadow: "none",
  //   //     border: "1px solid rgba(0,0,0,0.16)",
  //   //   },
  //   // },

  //   // Example to override the paper style of the Material-ui Menu:
  //   // MuiMenu: {
  //   //   paper: {
  //   //     boxShadow,
  //   //   },
  //   // },

  // },
  // #endregion

  // #region Example default props for any Material-ui components rendered with
  // this theme:
  // props: {
  //   MuiCard: {
  //     variant: "outlined",
  //   },
  // },
  // #endregion

  // Example to set custom shadows:
  // shadows,
});

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size: number | "xs" | "sm" | "md" | "lg" | "xl" = "xs") {
  return useMediaQuery(defaultTheme.breakpoints.down(size));
}
