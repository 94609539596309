import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import {
  Box,
  Button,
  Grid,
  TextField,
  FormHelperText,
  MenuItem,
  Typography,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DatePicker } from "@material-ui/pickers";

import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../../state";
import { Form, useFormik } from "../../../../lib";
import { CREATE_APPOINTMENT_PATIENT_INITIAL_VALUES } from "../../../../constants";
import { CREATE_APPOINTMENT_PATIENT_VALIDATION_SCHEMA as validationSchema } from "../../../../utils";
import { useStyles } from "./AppointmentPatient.styles";
import ExistingPatientAutoComplete from "./ExistingPatientAutoComplete";
import { useDispatch } from "../../../../state";
import { LanguageSelect } from "../../../inputs";

const AppointmentPatient = ({ activeStep, handleNext, handleBack }) => {
  const patients = useSelector(agencySelectors.patients);
  const createdPatient = useSelector(agencySelectors.appointmentPatient);
  const rescheduleAppointment = useSelector(agencySelectors.rescheduleAppointment);
  const activeEntity = useSelector(authSelectors.activeEntity);
  const getPatients = useAction(agencyActions.getPatients);
  const editAppointmentPatient = useAction(agencyActions.editAppointmentPatient);
  const createAppointmentPatient = useAction(agencyActions.createAppointmentPatient);
  const editPatient = useAction(agencyActions.editPatient);
  const setAppointmentPatient = useAction(agencyActions.setAppointmentPatient);
  const setAppointmentExistingPatientID = useAction(agencyActions.setAppointmentExistingPatientID);
  const setActiveAppointmentPatient = useAction(agencyActions.setActiveAppointmentPatient);
  const setActiveAppointmentPayer = useAction(agencyActions.setActiveAppointmentPayer);
  const dispatch = useDispatch();
  const [isMrnDuplicate, setIsMrnDuplicate] = useState(false);

  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const activeEntityType = useSelector(authSelectors.activeEntityType);
  const isEdit =
    (Boolean(createdPatient) && createdPatient?.id) ||
    (Boolean(rescheduleAppointment) && rescheduleAppointment?.id);
  const initialValues = isEdit
    ? { ...CREATE_APPOINTMENT_PATIENT_INITIAL_VALUES, ...(createdPatient || rescheduleAppointment) }
    : CREATE_APPOINTMENT_PATIENT_INITIAL_VALUES;

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    async onSubmit(values) {
      const patientID = values.patient_id;
      const isExistingPatient = Boolean(patientID);
      if (isExistingPatient) {
        const patient = patients.find((patient) => patient.id === patientID);
        const patientToUpdate = {
          ...patient,
          insured: form.values.insured,
          patient_id: form.values.patient_id,
          notes: form.values.notes,
        };
        await editPatient(patientToUpdate, activeEntityType, activeEntityId, isExistingPatient);
        await setAppointmentExistingPatientID(patientID);
        await setAppointmentPatient(patient);
        setActiveAppointmentPatient(patient);
      } else {
        const patientAction = isEdit ? editAppointmentPatient : createAppointmentPatient;
        if (!isEdit && isMrnDuplicate) {
          return;
        }
        await patientAction(values, activeEntityId);
        setActiveAppointmentPatient(values);
      }

      handleNext();
    },
  });

  const isInsuredError = form.touched.insured && Boolean(form.errors.insured);

  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(initialValues.date_of_birth);

  const handleInsuredChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newInsured: boolean | null) => {
      if (newInsured !== null) {
        // setInsured(newInsured);
        form.setFieldValue("insured", newInsured);
      }
    },
    [form],
  );

  const handlePatientTypeChange = useCallback(
    (event: React.MouseEvent<HTMLElement>, newPatientType: string) => {
      if (newPatientType !== null) {
        form.setFieldValue("patientType", newPatientType);
      }
    },
    [form],
  );

  const isValidDate = (d: any) => {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const handleDateChange = (date) => {
    if (isValidDate(date)) {
      setSelectedDate(date);
      form.setFieldValue("date_of_birth", date);
    } else {
      //TODO: figure out why it is not working
      setSelectedDate("");
      form.setFieldValue("date_of_birth", undefined);
    }
  };

  async function handleBlur(mrn: string) {
    const mrnDuplicate: any = await dispatch(
      agencyActions.checkMrn(mrn, activeEntityId, activeEntityType),
    );
    setIsMrnDuplicate(mrnDuplicate);
  }

  const handlePaymentChange = (e, values) => {
    //TODO - why is the setActiveAppointmentPayer action needed? See if we can take it out.
    if (!!values) {
      setActiveAppointmentPayer(values);
    }
    form.setFieldValue("payer", values);
  };

  useEffect(() => {
    if (form.values.id && form.values.patientType === "new") {
      form.setFieldValue("patientType", "existing");
      form.setFieldValue("patient_id", form.values.id);
    }
  }, [form, form.values.id]);

  useEffect(() => {
    if (form.values.patientType !== "new") getPatients(activeEntityId, "agency");
  }, [form.values.patientType, getPatients, activeEntityId]);

  return (
    <Form form={form} className={classes.form}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.controlsWrap}>
          <Grid container justifyContent="flex-start">
            <Grid item className={classes.formControl}>
              <ToggleButtonGroup
                className={classes.switcherGroup}
                exclusive
                size="large"
                value={form.values.patientType}
                onChange={handlePatientTypeChange}
              >
                <ToggleButton value="new" className={classes.switcher}>
                  Create new
                </ToggleButton>
                <ToggleButton value="existing" className={classes.switcher}>
                  Select from existing
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          {form.values.patientType === "new" ? (
            <>
              <Box display={"flex"}>
                <Grid container direction="column">
                  <Grid item className={classes.formControl}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="first_name"
                      name="first_name"
                      label="First Name"
                      value={form.values.first_name}
                      onChange={form.handleChange}
                      error={form.touched.first_name && Boolean(form.errors.first_name)}
                      helperText={form.touched.first_name && form.errors.first_name}
                    />
                  </Grid>
                  <Grid item className={classes.formControl}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="last_name"
                      name="last_name"
                      label="Last Name"
                      value={form.values.last_name}
                      onChange={form.handleChange}
                      error={form.touched.last_name && Boolean(form.errors.last_name)}
                      helperText={form.touched.last_name && form.errors.last_name}
                    />
                  </Grid>
                  <Grid item className={classes.formControl}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="phone"
                      name="phone"
                      label="Phone number"
                      value={form.values.phone}
                      onChange={form.handleChange}
                      error={form.touched.phone && Boolean(form.errors.phone)}
                      helperText={form.touched.phone && form.errors.phone}
                    />
                  </Grid>
                  <Grid item className={classes.formControl}>
                    <LanguageSelect form={form} />
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid item className={classes.formControl}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="email"
                      name="email"
                      label="Email address"
                      value={form.values.email}
                      onChange={form.handleChange}
                      error={form.touched.email && Boolean(form.errors.email)}
                      helperText={form.touched.email && form.errors.email}
                    />
                  </Grid>
                  <Grid item className={classes.formControl}>
                    <DatePicker
                      disableFuture
                      openTo="year"
                      inputFormat="MM/dd/yyyy"
                      label="Date Of Birth"
                      views={["year", "month", "date"]}
                      value={selectedDate}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={form.handleChange}
                          fullWidth
                          variant="outlined"
                          id="date_of_birth"
                          name="date_of_birth"
                          error={form.touched.date_of_birth && Boolean(form.errors.date_of_birth)}
                          helperText={form.touched.date_of_birth && form.errors.date_of_birth}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item className={classes.formControl}>
                    <TextField
                      select
                      fullWidth
                      name="gender"
                      label="Gender"
                      variant="outlined"
                      id="gender"
                      value={form.values.gender}
                      onChange={form.handleChange}
                    >
                      <MenuItem value={"male"}>Male</MenuItem>
                      <MenuItem value={"female"}>Female</MenuItem>
                      <MenuItem value={"other"}>Other</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item className={classes.formControl}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="employee_id"
                      name="employee_id"
                      label="Caregiver code"
                      value={form.values.employee_id}
                      onChange={form.handleChange}
                    />
                  </Grid>
                  {activeEntityType === "facility" && (
                    <Grid item className={classes.formControl}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="mrn"
                        name="mrn"
                        label="MRN #"
                        value={form.values.mrn}
                        onChange={form.handleChange}
                        error={form.touched.mrn && Boolean(form.errors.mrn || isMrnDuplicate)}
                        helperText={
                          form.touched.mrn &&
                          (!!form.errors.mrn || isMrnDuplicate) &&
                          isMrnDuplicate
                            ? "Caregiver MRN already exists"
                            : form.errors.mrn
                        }
                        onBlur={(e) => {
                          handleBlur(form.values.mrn);
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          ) : (
            <Box className={classes.selectPatient}>
              <ExistingPatientAutoComplete />
            </Box>
          )}
          <Grid container>
            {activeEntity?.accepts_insurance && (
              <Grid item className={classes.formControl}>
                <ToggleButtonGroup
                  className={classes.switcherGroup}
                  exclusive
                  size="large"
                  value={form.values.insured}
                  onChange={handleInsuredChange}
                >
                  <ToggleButton
                    type="button"
                    className={clsx(classes.switcher, isInsuredError && classes.switcherError)}
                    value={false}
                  >
                    Uninsured
                  </ToggleButton>
                  <ToggleButton
                    type="button"
                    className={clsx(classes.switcher, isInsuredError && classes.switcherError)}
                    value={true}
                  >
                    Insured
                  </ToggleButton>
                </ToggleButtonGroup>
                {form.touched.insured && Boolean(form.errors.insured) && (
                  <FormHelperText error id="insured-type" variant="outlined">
                    {form.errors.insured}
                  </FormHelperText>
                )}
              </Grid>
            )}
          </Grid>
          <Typography className={classes.sectionTitle}>Payment Method</Typography>

          <Grid item className={classes.formControl}>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                onChange={handlePaymentChange}
              >
                {activeEntity?.accepts_insurance && (
                  <FormControlLabel
                    value="insurance"
                    control={<Radio />}
                    label="Caregiver's insurance will pay"
                    disabled={!form.values.insured}
                  />
                )}
                {activeEntity?.accepts_patient_payments && (
                  <FormControlLabel
                    value="patient"
                    control={<Radio />}
                    label="Caregiver will pay (flex card)"
                  />
                )}
                {activeEntity?.accepts_agency_payments && (
                  <FormControlLabel value="agency" control={<Radio />} label="Agency will pay" />
                )}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            className={classes.formActions}
            spacing={4}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                type="button"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!!Object.keys(form.errors).length || isMrnDuplicate}
                id="next"
                variant="contained"
                color="primary"
                size="large"
                type="submit"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default React.memo(AppointmentPatient);
