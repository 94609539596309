import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { AddButton } from "../../../components/buttons";
import RegionsTable from "../../../components/tables/RegionsTable";

import SuperAdminPages from "../index";

const useStyles = makeStyles(
  (theme) => ({
    header: {
      paddingBottom: theme.spacing(3),
    },
  }),
  { classNamePrefix: "SuperAdminRegionsPage" },
);

// TODO add types and interfaces
const RegionsPage = () => {
  const classes = useStyles();

  return (
    <Box>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item>
          <Typography variant="subtitle2">Regions</Typography>
        </Grid>

        <Grid item>
          <AddButton to={SuperAdminPages.createRegion.path} />
        </Grid>
      </Grid>

      <RegionsTable />
    </Box>
  );
};

export default RegionsPage;
