import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Button, TextField, Card, CardContent, MenuItem } from "@material-ui/core";

import {
  useAction,
  useSelector,
  superAdminActions,
  agencySelectors,
  superAdminSelectors,
  authSelectors,
} from "../../../state";
import FacilityPages from "../../../pages/facility";
import { Navigation, Form, useFormik, yup } from "../../../lib";

import { useStyles } from "./FacilityUserForm.styles";

const validationSchema = yup.object({
  first_name: yup.string().required("First Name is required"),
  last_name: yup.string().required("Last Name is required"),
  email: yup.string().email("Enter a valid email").required("Email is required"),
});

const emptyUserInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  role: "",
};

const FacilityUserForm = () => {
  const classes = useStyles();
  const { id } = useParams();
  const isEdit = Boolean(id);

  const users = useSelector(agencySelectors.users);
  const availableRoles = useSelector(superAdminSelectors.availableRoles);

  const getRoles = useAction(superAdminActions.getRoles);
  const createFacilityUser = useAction(superAdminActions.createFacilityUser);
  const updateFacilityUser = useAction(superAdminActions.updateFacilityUser);

  const user = users.find((u) => u.id === Number(id));
  const userRoleId = isEdit && user?.user_role_entities && user.user_role_entities[0].role.id;
  const initialValues = isEdit ? { ...user, role_id: userRoleId } : emptyUserInitialValues;
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  const activeEntityType = useSelector(authSelectors.activeEntityType);

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      if (isEdit) {
        const { status }: any = await updateFacilityUser(activeEntityId, values, activeEntityType);
      } else {
        const { status }: any = await createFacilityUser(activeEntityId, values, activeEntityType);
        if (status === 200) Navigation.go(FacilityPages.users.path);
      }
    },
  });

  useEffect(() => {
    getRoles();
  }, [getRoles]);

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardContent className={classes.formWrap}>
        <Form form={form}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="first_name"
                name="first_name"
                label="First Name"
                value={form.values.first_name}
                onChange={form.handleChange}
                error={form.touched.first_name && Boolean(form.errors.first_name)}
                helperText={form.touched.first_name && form.errors.first_name}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                id="last_name"
                name="last_name"
                label="Last Name"
                value={form.values.last_name}
                onChange={form.handleChange}
                error={form.touched.last_name && Boolean(form.errors.last_name)}
                helperText={form.touched.last_name && form.errors.last_name}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <TextField
                fullWidth
                variant="outlined"
                type="email"
                id="email"
                name="email"
                label="Email address"
                disabled={isEdit}
                onChange={form.handleChange}
                error={form.touched.email && Boolean(form.errors.email)}
                value={form.values.email}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <TextField
                select
                fullWidth
                name="role_id"
                id="role_id"
                label="Role"
                variant="outlined"
                value={form.values.role_id}
                onChange={form.handleChange}
                error={form.touched.role_id && Boolean(form.errors.role_id)}
                helperText={form.touched.role_id && form.errors.role_id}
              >
                {availableRoles
                  .filter((r) => r.entity_type === "medical_facility")
                  .map((role, i) => (
                    <MenuItem key={`role-${role.name}-${i}`} value={role.id}>
                      {role.label}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={form.isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Card>
  );
};

export default FacilityUserForm;
