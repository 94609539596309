import React from "react";
import Table from "../../../components/tables/Table";
import FacilityPages from "../../../pages/facility";
import {
  UPCOMING_APPOINTMENTS_TABLE_COLUMNS as columns,
  UPCOMING_APPOINTMENTS_TABLE_EXTENSION as extensions,
} from "../../../constants";
import { useSelector, agencySelectors } from "../../../state";

interface UpcomingAppointmentsTableProps {
  searchQuery?: string;
  actions?: string[];
}

const CheckedInAppointmentsTable = ({ searchQuery, actions }: UpcomingAppointmentsTableProps) => {
  const checkedInAppointments = useSelector(agencySelectors.checkedInAppointments);
  const appointmentsLoading = useSelector(agencySelectors.appointmentsLoading);
  let now = new Date();
  let nowString = now.toDateString();
  return (
    <Table
      rows={checkedInAppointments}
      columns={columns}
      tableColumnExtensions={extensions}
      loading={appointmentsLoading}
      searchValue={searchQuery}
      actions={actions}
      rowBaseUrl={FacilityPages.appointments.path}
      exportEnabled={true}
      exportedFileName={`CheckedInAppointments-${nowString}`}
    />
  );
};

export default CheckedInAppointmentsTable;
