import React from "react";
import NumberFormat from "react-number-format";

export const PhoneNumber = ({ row }) => {
  const flattenedRow = { ...row, ...row?.patient };
  const { phone, number } = flattenedRow;

  return (
    <NumberFormat mask="_" displayType="text" format="+1 (###) ###-####" value={phone || number} />
  );
};

export const renderPhoneNumberCell = (row) => <PhoneNumber row={row} />;
