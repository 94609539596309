import React from "react";
import { IconButton } from "@material-ui/core";
import { Navigation } from "../../../lib";
import { DownloadIcon, VisibilityIcon, ClearIcon } from "../../../components/icons";
import { useDispatch, useSelector } from "react-redux";
import { agencyActions, authSelectors } from "../../../state";

export const DownloadButton = ({id}) => {
  const dispatch = useDispatch();
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  return (
    <IconButton onClick={()=> dispatch(agencyActions.downloadAppointmentFiles(activeEntityId ,id))}>
      <DownloadIcon />
    </IconButton>
  );
};
export const ViewButton = ({id}) => {
  return (
    <IconButton onClick={()=> Navigation.go(`/agency/appointments/${id}`)}>
      <VisibilityIcon />
    </IconButton>
  );
};
export const RemoveButton = ({id, appointment_status_id}) => {
  const dispatch = useDispatch();
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  return (
    <IconButton onClick={()=> dispatch(agencyActions.dismissAppointment(activeEntityId ,id, appointment_status_id))}>
      <ClearIcon />
    </IconButton>
  );
};

export const UpdatesActionButtons = (props) => {
  return (
    <>
      <DownloadButton {...props} />
      <ViewButton {...props} />
      <RemoveButton {...props} />
    </>
  );
};
