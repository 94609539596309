import React from "react";
import {
  Switch,
  TableRow,
  TableCell,
  TextField,
  TableBody,
  TableContainer,
  Table,
} from "@material-ui/core";
import clsx from "clsx";

import { FileTextInput, PriceFormatInput } from "../../../inputs";

import { useStyles } from "./AgencyRateCodeRow.styles";

const AgencyRateCodeRow = ({ form, rateCode }) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableBody>
          <TableRow
            className={clsx(classes.tableRow /*!form.values.active && classes.tableRowDisabled*/)}
          >
            <TableCell className={classes.switchCell}>
              Active
              <Switch
                id="rate-code-active"
                color="primary"
                name="active"
                onChange={form.handleChange}
                checked={form.values.active}
              />
            </TableCell>
            <TableCell align="center" className={classes.rateCode}>
              {rateCode.code}
            </TableCell>
            <TableCell className={classes.description}>
              Form location: {form.values.form_location}
            </TableCell>
            <TableCell className={classes.uploadFormCell}>
              {
                <FileTextInput
                  form={form}
                  name="form_location"
                  label="Upload form"
                  accept="application/pdf"
                  error={Boolean(form.touched.form_location) && Boolean(form.errors?.form_location)}
                />
              }
            </TableCell>
            <TableCell className={classes.switchCell}>
              Form required
              <Switch
                id="rate-code-form_required"
                color="primary"
                name="form_required"
                onChange={form.handleChange}
                checked={form.values.form_required}
              />
            </TableCell>
            <TableCell className={classes.chargeCell}>
              {
                <TextField
                  fullWidth
                  id="rate-code-rate"
                  name="rate"
                  label="Charge *"
                  value={form.values.rate}
                  onChange={form.handleChange}
                  error={Boolean(form.touched?.rate) && Boolean(form.errors?.rate)}
                  InputProps={{ inputComponent: PriceFormatInput as any }}
                />
              }
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AgencyRateCodeRow;
