import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(
  (theme) => ({
    fullHeight: {
      height: "100%",
    },
    header: {
      paddingBottom: (props: any) => (props.isFacilityIntake ? "1rem" : "2rem"),
    },
    title: {
      paddingBottom: (props: any) => (props.isFacilityIntake ? 0 : "2rem"),
    },
    searchWrap: {
      minWidth: 400,
    },
    cardWrap: {
      padding: theme.spacing(2),
    },
    firstRowCard: {
      minHeight: 360,
    },
    card: {
      borderRadius: 0,
    },
    cardPadding: {
      paddingLeft: "2em",
      paddingRight: "2em",

      "& .MuiCardHeader-content .MuiTypography-root": {
        fontSize: "1.15em",
      },
    },
    cardHeaderAction: {
      marginRight: 0,
    },
    bottomLinks: {
      marginBottom: 17,
    },
  }),
  { classNamePrefix: "UpcomingAppointmentsPage" },
);
