import { makeStyles } from "@material-ui/core";

import levelUpBG from "../../assets/img/levelup-background-red.jpg";

export const useStyles = makeStyles(
  (theme) => ({
    root: {},
    secondaryPanel: {
      overflow: "hidden",
      position: "relative",
      //padding: "2.8em 0 0 3.5em",
      borderRadius: "0 48px 48px 0",
      textAlign: "center",

      "&:before": {
        content: "''",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundImage: `url(${levelUpBG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
        backgroundSize: "cover",
        opacity: 0.6,
        [theme.breakpoints.down("xs")]: {
          backgroundImage: "none",
        },
      },
    },
    logo: {
      position: "relative",
      zIndex: 3,
      //height: 40,
      width: 300,
      margin: "40px auto",
    },
    container: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        height: "100vh",
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: "10vh",
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 0,
      },
    },
    content: {
      maxWidth: 1280,
      margin: "0px auto",
    },
    backdrop: {
      zIndex: theme.zIndex.modal + 100,
      backgroundColor: "rgba(255, 255, 255, 0.5)",
    },
  }),
  { classNamePrefix: "AuthLayout" },
);
