import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { AddButton } from "../../../../components/buttons";
import UsersTable from "../../../../components/tables/UsersTable";
import SuperAdminPages from "../../index";
import { useInputDebounced } from "../../../../lib";
import { useStyles } from "./AdminUsersPage.styles";
import { SearchField } from "../../../../components/inputs";

// TODO add types and interfaces
export default function AdminUsersPage() {
  const classes = useStyles();
  const [filter, setFilter] = useState("");
  const [searchQuery, debouncedSearchQuery, handleChange] = useInputDebounced("", 250);

  useEffect(() => {
    setFilter(debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  return (
    <Box>
      <Grid item>
        <Typography variant="subtitle2">Admin Users</Typography>
      </Grid>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Grid item xs={4}>
          <SearchField id="search" label="Search" value={searchQuery} onChange={handleChange} />
        </Grid>
        <Grid item>
          <AddButton to={SuperAdminPages.createUsers.path} />
        </Grid>
      </Grid>

      <UsersTable view="super_user_admin" filter={filter} />
    </Box>
  );
}
