import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createLabResult } from "../../dummyData/superAdmin";

export interface SuperAdminState {
  agencies: any[];
  activeAgency?: Record<string, any>;
  facilities: any[];
  labResults?: any[];
  searchQuery?: string;
  rateCodes?: any[];
  defaultRateCodes?: any[];
  defaultBundles?: any[];
  defaultRateCode?: any;
  rateCode?: any;
  invoices?: any[];
  facility?: any;
  labs?: any[];
  regions?: any[];
  users?: any[];
  usersLoading?: boolean;
  availableRoles?: any[];
  agencyBundles?: any[];
  activeDefaultBundle?: any;
  bundlesForRateCodes?: any[];
  superAdminFilterDocs: boolean;
}

const defaultActiveAgency = {
  entity_id: null,
  contract: null,
  id: null,
  name: "",
  type: "",
  active: true,
  logo: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  invoice_prefix: "",
  requisition_note: "",
  allows_unscheduled_appts: false,
};

// Fill state with dummy data
const initialState: SuperAdminState = {
  users: [],
  usersLoading: false,
  agencies: [],
  activeAgency: defaultActiveAgency,
  labResults: [
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
    createLabResult(),
  ],
  rateCodes: [],
  defaultRateCodes: [],
  defaultBundles: [],
  defaultRateCode: {},
  invoices: [],
  facilities: [],
  facility: {},
  labs: [],
  regions: [],
  agencyBundles: [],
  activeDefaultBundle: {},
  bundlesForRateCodes: [],
  superAdminFilterDocs: false,
};

export const superAdmin = {
  ...createSlice({
    name: "superAdmin",
    initialState,
    reducers: {
      setSuperAdminState(state, { payload }: PayloadAction<SuperAdminState | undefined>) {
        if (!payload) return initialState;
        Object.assign(state, payload);
      },
      setAgencies(state, { payload }: PayloadAction<any>) {
        state.agencies = payload.rows.map((r) => {
          return { ...r.entity, number: r.entity.phone };
        });
      },
      setActiveAgency(state, { payload }: PayloadAction<any>) {
        state.activeAgency = {
          ...payload.entity,
          contract: payload.contract,
          invoice_prefix: payload.invoice_prefix,
          allows_unscheduled_appts: payload.allows_unscheduled_appts,
          requisition_note: payload.requisition_note,
        };
      },
      clearActiveAgency(state) {
        state.activeAgency = defaultActiveAgency;
      },
      setSuperAdminFilterDocs(state, { payload }: PayloadAction<any>) {
        state.superAdminFilterDocs = payload;
      },
      setRateCodes(state, { payload }: PayloadAction<any>) {
        const rows = payload.rows ? payload.rows : payload;
        state.rateCodes = rows;
      },
      setDefaultBundles(state, { payload }: PayloadAction<any>) {
        const rows = payload.rows ? payload.rows : payload;
        state.defaultBundles = rows;
      },
      setDefaultRateCodes(state, { payload }: PayloadAction<any>) {
        const rows = payload.rows ? payload.rows : payload;
        state.defaultRateCodes = rows;
      },
      setAgencyBundles(state, { payload }: PayloadAction<any>) {
        state.agencyBundles = payload;
      },
      setDefaultRateCode(state, { payload }: PayloadAction<any>) {
        const rows = payload.rows ? payload.rows : payload;
        state.defaultRateCode = rows;
      },
      setBundlesForRateCode(state, { payload }: PayloadAction<any>) {
        const rows = payload.rows ? payload.rows : payload;
        state.bundlesForRateCodes = rows;
      },
      setRateCode(state, { payload }: PayloadAction<any>) {
        state.rateCode = payload;
      },
      setActiveDefaultBundle(state, { payload }: PayloadAction<any>) {
        state.activeDefaultBundle = payload;
      },

      setFacilities(state, { payload }: PayloadAction<any>) {
        state.facilities = payload.rows.map((r) => {
          r.entity.region = r.region.name;
          r.entity.appointments_per_hour = r.appointments_per_hour;
          return r.entity;
        });
      },
      setFacility(state, { payload }: PayloadAction<any>) {
        state.facility = { ...payload, ...payload.entity };
      },
      setSearchQuery(state, { payload }: PayloadAction<string>) {
        state.searchQuery = payload;
      },
      setInvoices(state, { payload }: PayloadAction<any>) {
        state.invoices = payload.rows;
      },
      setLabs(state, { payload }: PayloadAction<any>) {
        state.labs = payload.rows;
      },
      setRegions(state, { payload }: PayloadAction<any>) {
        state.regions = payload.rows;
      },
      setUsersLoading(state, { payload }: PayloadAction<boolean>) {
        state.usersLoading = payload;
      },
      setUsers(state, { payload }: PayloadAction<any>) {
        state.users = payload.rows;
      },
      setRoles(state, { payload }: PayloadAction<any>) {
        state.availableRoles = payload.rows;
      },
    },
  }),
  persist: false,
};
