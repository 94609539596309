import React from "react";
import { Button, Card, CardHeader, CardContent, Grid } from "@material-ui/core";

import PasswordField from "../../../inputs/PasswordField";

import { Form, useFormik, yup } from "../../../../lib";

import { useStyles } from "../ProfileForms.styles";
import { authActions, useAction } from "../../../../state";

const passwordValidationSchema = yup.object({
  oldPassword: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password1: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  newPassword: yup
    .string()
    .oneOf([yup.ref("password1"), null], "Passwords must match")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const PasswordForm = () => {
  const classes = useStyles();

  const changePassword = useAction(authActions.changePassword);

  const passwordForm = useFormik({
    initialValues: {
      oldPassword: "",
      password1: "",
      newPassword: "",
    },
    validationSchema: passwordValidationSchema,
    async onSubmit(values, { resetForm }) {
      const { password1, ...passwordFields } = values;
      await changePassword(passwordFields);
      resetForm({});
    },
  });

  return (
    <Card variant="outlined" className={classes.formCard}>
      <CardHeader className={classes.sectionTitle} title="Reset Password" />
      <CardContent className={classes.formWrap}>
        <Form form={passwordForm}>
          <Grid container>
            <Grid item className={classes.formControl}>
              <PasswordField
                variant="outlined"
                name="oldPassword"
                label="Current password"
                value={passwordForm.values.oldPassword}
                handleChange={passwordForm.handleChange}
                touched={passwordForm.touched.oldPassword}
                error={passwordForm.errors.oldPassword}
              />
            </Grid>
            <Grid item className={classes.formControl}>
              <PasswordField
                variant="outlined"
                name="password1"
                label="New password"
                value={passwordForm.values.password1}
                handleChange={passwordForm.handleChange}
                touched={passwordForm.touched.password1}
                error={passwordForm.errors.password1}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item className={classes.formControl}>
              <PasswordField
                variant="outlined"
                name="newPassword"
                label="Confirm new password"
                value={passwordForm.values.newPassword}
                handleChange={passwordForm.handleChange}
                touched={passwordForm.touched.newPassword}
                error={passwordForm.errors.newPassword}
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="flex-end">
            <Grid item className={classes.formAction}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={passwordForm.isSubmitting}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Form>
      </CardContent>
    </Card>
  );
};

export default PasswordForm;
