import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 12px",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 0,
    maxWidth: 450,
  },
  header: {
    paddingBottom: theme.spacing(3),
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
    textTransform: "uppercase",
  },
  titleContainer: {
    padding: "12px 0",
  },
  notes: {
    // paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  actionsWrap: {
    paddingTop: theme.spacing(3),
  },
}));
