import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Table from "../Table";

import {
  useAction,
  agencyActions,
  superAdminActions,
  useSelector,
  agencySelectors,
  superAdminSelectors,
  authSelectors,
} from "../../../state";
import { AGENCY_USERS_TABLE_COLUMNS } from "../../../constants";
import DeleteConfirmationModal from "../../modals/DeleteConfirmationModal";
import { useRole } from "../../../hooks";
// TODO add types and interfaces
const UsersTable = (props) => {
  const { view } = props;
  const activeEntityId = useSelector(authSelectors.activeEntityId);
  let selector: any;
  let actions;
  let entityId;
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState();
  const { isAgencyRole, isSuperAdmin } = useRole();

  if (view === "super_user_all") {
    selector = superAdminSelectors;
    actions = superAdminActions;
    entityId = activeEntityId;
  } else if (view === "super_user_admin") {
    selector = superAdminSelectors;
    actions = superAdminActions;
    entityId = activeEntityId;
  } else if (view === "agency_admin") {
    selector = agencySelectors;
    actions = agencyActions;
    entityId = id === undefined ? activeEntityId : id;
  } else {
    selector = agencySelectors;
    actions = agencyActions;
    entityId = id === undefined ? activeEntityId : id;
  }
  const getUsers = useAction(actions.getUsers);
  const deleteUser = useAction(actions.deleteUser);
  const users = useSelector(selector.users);
  const usersLoading: boolean = useSelector(selector.usersLoading);

  useEffect(() => {
    let _view;
    switch (view) {
      case "super_admin":
        _view = "super_admin";
        break;
      case "agency_admin":
        _view = "agency";
        break;
      case "facility":
        _view = "facility";
        break;
    }
    getUsers(entityId, props.filter, _view);
  }, [getUsers, view, props.filter, entityId]);

  const handleDelete = useCallback((user) => {
    setDeleteUserData(user);
    setOpen(true);
  }, []);

  useEffect(() => {
    if (confirmDelete) {
      deleteUser(deleteUserData, entityId, view);
      setDeleteUserData(null);
      setConfirmDelete(false);
      setOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDelete]);

  return (
    <>
      <Table
        loading={usersLoading}
        rows={users}
        columns={AGENCY_USERS_TABLE_COLUMNS}
        {...props}
        deleteHandler={(record) => handleDelete(record)}
        exportEnabled={false}
        exportedFileName="Users"
      />
      {open && (
        <DeleteConfirmationModal
          setShow={setOpen}
          show={open}
          deleteMessage={"Are you sure you want to delete this user?"}
          deleteSubMessage={
            isSuperAdmin || isAgencyRole
              ? "Please note: This user may also have access to other entities. Deleting this user will ONLY remove them from this entity."
              : ""
          }
          setConfirmDelete={setConfirmDelete}
        />
      )}
    </>
  );
};

export default UsersTable;
