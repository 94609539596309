import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FileState {
  files?: any[];
  file?: any;
}

const initialState: FileState = {};

export const file = {
  ...createSlice({
    name: "file",
    initialState,
    reducers: {
      setFileState(state, { payload }: PayloadAction<FileState | undefined>) {
        if (!payload) return initialState;
        Object.assign(state, payload);
      },
      setFiles(state, { payload }: PayloadAction<any>) {
        state.files = payload;
      },
      setFile(state, { payload }: PayloadAction<any>) {
        state.file = payload;
      },
    },
  }),
  persist: false,
};
