import React, { useEffect } from "react";
import Table from "../Table";
import {
  useDispatch,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../state";
import { AGENCY_VIEW_RATE_CODES_TABLE_COLUMNS as columns } from "../../../constants";

const ViewOnlyAgencyRateCodesTable = (props) => {
  const dispatch = useDispatch();
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  useEffect(() => {
    dispatch(agencyActions.getRateCodes(activeEntityId));
  }, [activeEntityId, dispatch]);

  const rateCodes: any[] = useSelector(agencySelectors.rateCodes);

  return (
    <>
      <Table
        rows={rateCodes}
        columns={columns}
        exportEnabled={true}
        exportedFileName="AgencyRateCodes"
      />
    </>
  );
};

export default React.memo(ViewOnlyAgencyRateCodesTable);
