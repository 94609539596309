import React, { useCallback, useState } from "react";
import { Drawer, Typography } from "@material-ui/core";
import { add, startOfYear } from "date-fns";
import { Calendar } from "react-date-range";

import { useStyles } from "./AppointmentsByDateDrawer.styles";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const AppointmentsByDateDrawer = () => {
  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleSelect = useCallback((date) => {
    setSelectedDate(date);
  }, []);

  return (
    <Drawer anchor="left" open={true} variant="permanent" className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.title}>
        Appointments
      </Typography>

      <Calendar
        date={selectedDate}
        onChange={handleSelect}
        months={2}
        color="#0F0190"
        minDate={startOfYear(new Date())}
        maxDate={add(new Date(), { years: 1 })}
        onPreviewChange={(e) => {
          console.group("e");
          console.log(e);
          console.groupEnd();
        }}
      />
    </Drawer>
  );
};

export default AppointmentsByDateDrawer;
