import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(3),
    borderBottom: 0,
  },
  icon: {
    marginRight: 8,
  },
  title: {
    fontSize: 34,
    fontWeight: "bold",
    marginRight: 16,
    letterSpacing: 0.25,
    lineHeight: "42px",
  },
  checkInBtn: {
    borderRadius: 18,
    marginLeft: 16,
    textTransform: "capitalize",
  },
  btn: { marginLeft: 16 },
  btnMargin: {
    marginLeft: "2rem",
  },
  detailContainer: {
    color: "#A8A8A8",
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0.15,
  },
}));
