import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UIState = {
  loading: false,
  notification: undefined,
  view: {},
  uploadModal: { open: false },
  bundlesSuccessModal: { open: false },
  messages: false,
  chats: [],
};

export const ui = {
  ...createSlice({
    name: "ui",
    initialState,
    reducers: {
      hideNotification(state) {
        state.notification = undefined;
      },
      setLoading(state, { payload }: PayloadAction<boolean>) {
        state.loading = payload;
      },
      setView(state, { payload }: PayloadAction<Partial<UIViewState>>) {
        state.view = {
          ...state.view,
          ...payload,
        };
      },
      showNotification(state, { payload }: PayloadAction<UINotification>) {
        if (!payload.duration) payload.duration = 15000;
        state.notification = payload;
      },
      toggleExpandMessages(state) {
        state.messages = !state.messages;
      },
      triggerUploadModal(state, { payload }: PayloadAction<UIModal>) {
        state.uploadModal = payload;
      },
      showBundlesSuccessModal(state, { payload }: PayloadAction<BundleSuccessModal>) {
        state.bundlesSuccessModal = payload;
      },
    },
  }),
  persist: false,
};

export interface UINotification {
  message: string;
  variant?: "error" | "warning" | "info" | "success";
  duration?: number;
}

export interface UIModal {
  open: boolean;
  row?: any[];
}
export interface BundleSuccessModal {
  open: boolean;
  bundle?: { name: any; isEdit?: boolean };
}

export interface ChatType {
  id: number;
  facility: string;
  messages: any[];
  patient: {
    id: number;
    first_name: string;
    last_name: string;
  };
}

export interface UIState {
  loading: boolean;
  messages: boolean;
  chats?: ChatType[];
  notification?: UINotification;
  view: UIViewState;
  uploadModal?: UIModal;
  bundlesSuccessModal?: BundleSuccessModal;
}

export type UIViewType = boolean | string | Record<string, any> | undefined;

export interface UIViewState {}
