import { lists } from "./state";
import { uiActions } from "../ui/actions";
import { authClient } from "../auth/actions";

const { actions } = lists;

export const listsActions = {
  ...actions,
  getFacilitiesList() {
    return async (dispatch) => {
      dispatch(actions.setFacilityListLoading(true));
      const { status, data } = await authClient.get(`/lists/facilities`);
      if (status === 200) {
        dispatch(actions.setFacilityList(data));
      } else {
        dispatch(uiActions.showError("Error fetching facility list. " + data.message));
      }
      dispatch(actions.setFacilityListLoading(false));
      return data;
    };
  },
  getAgenciesList() {
    return async (dispatch) => {
      const { status, data } = await authClient.get(`/lists/agencies`);
      if (status === 200) {
        dispatch(actions.setAgenciesList(data));
      } else {
        dispatch(uiActions.showError("Error fetching agencies list. " + data.message));
      }
      return data;
    };
  },
};
