import ConfirmAccountPage from "./ConfirmAccountPage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import LoginPage from "./LoginPage";
import LoginAfterResetPage from "./LoginAfterResetPage";
import LogoutPage from "./LogoutPage";
import NewPasswordPage from "./NewPasswordPage";
import RecoverySentPage from "./RecoverySentPage";

export const AuthPages = {
  confirmAccount: {
    anon: true,
    path: "/auth/confirm-account",
    title: "Confirm Page",
    view: ConfirmAccountPage,
  },
  forgotPassword: {
    anon: true,
    path: "/auth/forgot-password",
    title: "Forgot Password",
    view: ForgotPasswordPage,
  },
  login: {
    anon: true,
    path: "/auth/login",
    title: "Login",
    view: LoginPage,
  },
  loginAfterReset: {
    anon: true,
    path: "/auth/login-after-reset",
    title: "Login",
    view: LoginAfterResetPage,
  },
  logout: {
    anon: true,
    path: "/auth/logout",
    title: "Logout",
    view: LogoutPage,
  },
  newPassword: {
    anon: true,
    path: "/auth/new-password",
    title: "New Password",
    view: NewPasswordPage,
  },
  recoverySent: {
    anon: true,
    path: "/auth/recovery-sent",
    title: "Recovery Sent",
    view: RecoverySentPage,
  },
};
