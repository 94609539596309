import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";

import { FilterListIcon } from "../../icons";

import { useStyles } from "./FilterButton.styles";

const FilterButton = (props) => {
  const classes = useStyles();

  return (
    <ToggleButton color="primary" classes={classes} {...props}>
      <FilterListIcon />
      Filter
    </ToggleButton>
  );
};

export default React.memo(FilterButton);
