import React, { useState } from "react";
import { Button, Box, Link, TextField, Typography } from "@material-ui/core";
import PasswordField from "../../components/inputs/PasswordField";
import { Form, RouterLink, useFormik, yup } from "../../lib";
import { useAction, authActions, ROLES } from "../../state";
import { useStyles } from "./Auth.styles";

const validationSchema = yup.object({
  email: yup.string().email("Enter a valid email").required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const dashboardPath = (roles) => {
  // we do not support multiple roles yet
  const { name: role } = roles[0];
  switch (role) {
    case ROLES.SUPER_ADMIN:
      return "/super-admin/dashboard";
    case ROLES.AGENCY_ADMIN:
    case ROLES.AGENCY_SCHEDULER:
    case ROLES.AGENCY_BILLER:
      return "/agency/dashboard";
    case ROLES.FACILITY_ADMIN:
    case ROLES.FACILITY_INTAKE:
    case ROLES.FACILITY_BILLER:
      return "/facility/upcoming-appointments";
    case ROLES.FACILITY_PROVIDER:
      return "/facility/checkedin-appointments";
    default:
      return "/dashboard";
  }
};

const LoginPage = () => {
  const classes = useStyles();
  const login = useAction(authActions.login);
  const [error, setError] = useState("");

  const form = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    async onSubmit(values) {
      try {
        await login(values);
      } catch (ex) {
        setError("Invalid username or password. Please try again.");
      }
    },
  });

  return (
    <Box className={classes.authCard}>
      <Typography variant="h2" component="h1" className={classes.titleText}>
        Welcome
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitleText}>
        {error ? (
          <span className={classes.errorText + " error-message"}>{error}</span>
        ) : (
          "Sign in to LevelUp MD"
        )}
      </Typography>
      <Form form={form} className={classes.form + " login-form"}>
        <TextField
          autoFocus
          fullWidth
          id="email"
          name="email"
          label="Email"
          value={form.values.email}
          onChange={form.handleChange}
          error={form.touched.email && Boolean(form.errors.email)}
          helperText={form.touched.email && form.errors.email}
        />

        <PasswordField
          id="password"
          name="password"
          label="Password"
          value={form.values.password}
          handleChange={form.handleChange}
          touched={form.touched.password}
          error={form.errors.password}
        />

        <div className={classes.buttonBox}>
          <Button
            className="submit-button"
            color="primary"
            variant="contained"
            type="submit"
            id="submit"
            disabled={form.isSubmitting}
          >
            Sign In
          </Button>
          <div className={classes.bottomLink}>
            <Link
              component={RouterLink}
              to={`/auth/forgot-password?email=${encodeURIComponent(form.values.email)}`}
            >
              Forgot Password?
            </Link>
          </div>
        </div>
      </Form>
    </Box>
  );
};

export default React.memo(LoginPage);
