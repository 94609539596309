import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Grid, TextField } from "@material-ui/core";

import { Form, useFormik } from "../../../lib";
import SuperAdminPages from "../../../pages/super-admin";
import { useAction, superAdminActions } from "../../../state";
import { CREATE_REGION_INITIAL_VALUES } from "../../../constants";
import { CREATE_REGION_VALIDATION_SCHEMA as validationSchema } from "../../../utils";

import { useStyles } from "./CreateRegionForm.styles";

const CreateRegionForm = () => {
  const classes = useStyles({});

  const { id } = useParams();
  const history = useHistory();

  const [initialValues, setInitialValues] = useState(CREATE_REGION_INITIAL_VALUES);

  const saveRegion = useAction(superAdminActions.saveRegion);
  const createRegion = useAction(superAdminActions.createRegion);

  const isEdit = Boolean(id);
  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    async onSubmit(values) {
      const saveAction = isEdit ? saveRegion : createRegion;
      await saveAction(values);
      history.push(SuperAdminPages.regions.path);
    },
  });

  useEffect(() => {
    (async () => {
      if (id) {
        const { data } = await superAdminActions.getRegionById(id);
        setInitialValues(data);
      }
    })();
  }, [id]);

  return (
    <Form form={form} className={classes.form}>
      <Grid container>
        <Grid xs={8} item>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            name="name"
            label="Region Name"
            value={form.values.name}
            onChange={form.handleChange}
            error={form.touched.name && Boolean(form.errors.name)}
            helperText={form.touched.name && form.errors.name}
          />
        </Grid>
        <Grid xs={4} container className={classes.end} item>
          <Button variant="contained" color="primary" size="large" type="submit">
            Save
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default React.memo(CreateRegionForm);
