import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Entity, Role, User } from "../types";
export interface AuthState {
  oldAuth?: any;
  roles?: any[];
  token?: string;
  userId?: number;
  userName?: string;
  user?: User;
  entities?: Entity[];
  activeEntityId: number | undefined;
  activeRole: Role | undefined
}

const initialState: AuthState = {
  entities: [],
  activeEntityId: undefined,
  activeRole: undefined
};

export const auth = {
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) return initialState;
        Object.assign(state, payload);
      },
      setImpersonateAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) return state;
        const { oldAuth, ...rest } = state;
        state.activeEntityId = payload.activeEntityId;
        state.userId = payload.userId;
        state.roles = payload.roles;
        state.token = payload.token;
        state.userName = payload.userName;
        state.user = payload.user;
        state.entities = payload.entities;
        state.oldAuth = rest;
      },
      exitImpersonateAuthState(state) {
        const oldAuth = { ...state.oldAuth };
        state = oldAuth;
        state.oldAuth = undefined;
        return state;
      },
      setActiveEntityId(state, { payload }: PayloadAction<number>) {
        state.activeEntityId = payload;
      },
    },
  }),
  persist: true,
};
