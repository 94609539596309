import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { formatToDateTime } from "../../../utils";
import { RCStatusStateTag } from "../../tags/StatusTag";
import FileUploadItem from "../../forms/FileUpload/FileUploadItem";
import { authSelectors, fileActions, useAction, useSelector } from "../../../state";
import { RATE_CODE_STATUSES_STATES } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 8,
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
  },
  row: {
    background: "#fff",
    borderTop: "1px solid rgba(224, 224, 224, 1)",
    lineHeight: 1.43,
    padding: 16,
  },
  forms: {
    width: "100%",
    maxWidth: 1500,
    marginTop: 8,
  },
}));

const ApptRateCodeDetailRow = ({ row, patient, medicalFacId }) => {
  const classes = useStyles();
  const {
    id,
    appointment_id,
    type,
    home_health_agency_rate_code,
    home_health_agency_bundle,
    completed_at,
    rate_code_status_id,
    forms = [],
  } = row;

  const view = useSelector(authSelectors.activeEntityType);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  const downloadFile = useAction(fileActions.getFileUrl);
  const deleteFile = useAction(fileActions.deleteFile);
  const updateApproveStatus = useAction(fileActions.updateFileApproveStatus);

  const entityId =
    view === "super_admin" ? patient?.home_health_agency_profile?.entity_id : activeEntityId;

  return (
    <Grid container className={classes.row}>
      <Grid item container spacing={2} justifyContent="flex-start">
        <Grid item xs={3}>
          {type === "rate_code"
            ? home_health_agency_rate_code?.default_rate_code?.description
            : home_health_agency_bundle?.description}
        </Grid>
        <Grid item xs={1}>
          {(type === "rate_code" && !!home_health_agency_rate_code?.form_required) ||
          (type === "bundle" && !!home_health_agency_bundle?.form_required) ? (
            <div>Form required</div>
          ) : (
            <div>Form not required</div>
          )}
        </Grid>
        <Grid item xs={2}>
          {formatToDateTime(completed_at)}
        </Grid>
        <Grid item xs={3}>
          <RCStatusStateTag
            status={rate_code_status_id}
            documents_pending={view === "super_admin"}
          />
        </Grid>
      </Grid>
      <Grid className={classes.forms} item xs={10}>
        {forms?.map(({ id: fileId, filename, approved }) => (
          <FileUploadItem
            key={filename}
            primaryText={filename?.name}
            downloadEnabled={true}
            deleteEnabled={rate_code_status_id !== RATE_CODE_STATUSES_STATES.COMPLETED}
            downloadHandler={() => downloadFile(entityId, appointment_id, id, fileId)}
            deleteHandler={() => deleteFile(medicalFacId, appointment_id, id, fileId, view, true)}
            approveHandler={() => updateApproveStatus(appointment_id, id, fileId, true, true)}
            unapproveHandler={() => updateApproveStatus(appointment_id, id, fileId, false, true)}
            approved={approved}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default ApptRateCodeDetailRow;
