import React from "react";
import { Alert } from "@material-ui/lab";
import { useSelector, agencySelectors } from "../../../../state";

const AppointmentAlerts = () => {
  const openedAppointment = useSelector(agencySelectors.openedAppointment);

  return (
    <>
      {openedAppointment?.payer === "patient" && openedAppointment?.patient?.insured !== true && (
        <Alert
          severity="warning"
          style={{ marginBottom: 20, backgroundColor: "rgba(255, 189, 32, .09)" }}
        >
          Request Flex Card for Payment
        </Alert>
      )}
      {openedAppointment?.payer === "insurance" && (
        <Alert
          severity="info"
          variant="filled"
          style={{
            marginBottom: 20,
            backgroundColor: "rgba(255, 189, 32, .09)",
            color: "#5C5B51 ",
          }}
        >
          Request Insurance Card
        </Alert>
      )}
    </>
  );
};

export default React.memo(AppointmentAlerts);
