import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    // width: "100%",
    textAlign: "center",
  },
  // dayTitle: {
  //   marginBottom: theme.spacing(2),
  //   textAlign: "center",
  //   fontWeight: "bold",
  // },
  date: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  // timeSlotGroup: {
  //   width: "100%",
  //   flexDirection: "column",
  // },
  // timeSlot: {
  //   marginBottom: theme.spacing(1),
  //   padding: 0,
  //   border: "1px solid #E0E0E0 !important",
  //   lineHeight: "2.25rem",
  //   textAlign: "center",
  //   borderRadius: 0,
  //   color: "#000",
  //   fontWeight: 400,

  //   "&.Mui-selected, &.Mui-selected:hover": {
  //     backgroundColor: theme.palette.primary.main,
  //     borderColor: theme.palette.primary.main,
  //     color: "#fff",
  //   },
  // },
}));
