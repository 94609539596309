import React, { useCallback } from "react";
// import { useHistory } from "react-router-dom";
import { Grid, Box, Typography } from "@material-ui/core";

import { AddButton } from "../../../components/buttons";

import AgencyPages from "../index";
import { TABLE_ROW_DELETE_ACTION } from "../../../constants";

import { useStyles } from "./NotificationSettingsPage.styles";
import NotificationSettingsTable from "../../../components/tables/NotificationSettingsTable";
import { agencyActions, authSelectors, useAction, useSelector } from "../../../state";

// TODO add types and interfaces
const NotificationSettingsPage = () => {
  const classes = useStyles();
  const activeEntity = useSelector(authSelectors.activeEntity);
  const activeEntityId = activeEntity.id;
  const view = activeEntity.type === "home_health_agency" ? "agency_admin" : "facility_admin";
  // const history = useHistory();
  const deleteNotification = useAction(agencyActions.deleteNotification);
  const handleDelete = useCallback((t) => {
    deleteNotification(activeEntityId, t.id, view);
    //eslint-disable-next-line
  }, []);

  // const handleEdit = useCallback(
  //   e => {
  //     history.push(AgencyPages.editUser.path.replace(":id", e.id));
  //   },
  //   [history],
  // );

  return (
    <Box>
      <Grid
        component="header"
        container
        justifyContent="space-between"
        alignItems="center"
        classes={{ container: classes.header }}
      >
        <Typography variant="subtitle2">Notification Settings</Typography>
        <Grid item>
          <AddButton to={AgencyPages.notificationsSettingsFormNew.path} />
        </Grid>
      </Grid>

      <NotificationSettingsTable
        view="agency_admin"
        // editHandler={handleEdit}
        deleteHandler={handleDelete}
        actions={[TABLE_ROW_DELETE_ACTION]}
      />
    </Box>
  );
};

export default NotificationSettingsPage;
