import React, { useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControl,
  TextField,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";

import {
  useAction,
  agencyActions,
  useSelector,
  agencySelectors,
  authSelectors,
} from "../../../../state";
import {
  CREATE_APPOINTMENT_STEPS,
  CREATE_APPOINTMENT_TIME_INITIAL_VALUES as initialValues,
} from "../../../../constants";
import { Form, useFormik } from "../../../../lib";
import { CREATE_APPOINTMENT_TIME_VALIDATION_SCHEMA as validationSchema } from "../../../../utils";

import AppointmentTimeSlots from "../AppointmentTimeSlots";

import { useStyles } from "./AppointmentTime.styles";
import { Alert } from "@material-ui/lab";

const AppointmentTime = ({ activeStep, handleNext, handleBack }) => {
  const getRegions = useAction(agencyActions.getRegions);
  const saveNewAppointment = useAction(agencyActions.saveNewAppointment);
  const regions = useSelector(agencySelectors.regions);
  const regionsLoading = useSelector(agencySelectors.regionsLoading);
  const activeEntityId = useSelector(authSelectors.activeEntityId);

  const form = useFormik({
    initialValues,
    validationSchema,
    async onSubmit(values) {
      await saveNewAppointment(values, activeEntityId);
      handleNext();
    },
  });

  const classes = useStyles();

  useEffect(() => {
    getRegions();
  }, [getRegions, activeEntityId]);

  const isFacilitySelected = Boolean(form.values.location) && Boolean(form.values.facility);
  const facilities = Boolean(form.values.location)
    ? regions.find((region) => region.id === form.values.location).medical_facility_profiles
    : [];
  const selectedFacility = Boolean(form.values.facility)
    ? facilities.find((facility) => facility.entity_id === form.values.facility)
    : {};

  return (
    <Form form={form} className={classes.form}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item className={classes.controlsWrap}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            className={classes.topControlsWrap}
          >
            <Box className={classes.title}>
              <Typography variant="h4" align="center">
                Select a medical facility
              </Typography>
            </Box>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item className={clsx(classes.formControlWrap, classes.locationFormControl)}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    variant="outlined"
                    select
                    name="location"
                    id="location"
                    label="Region"
                    value={form.values.location || ""}
                    onChange={form.handleChange}
                    error={form.touched.location && Boolean(form.errors.location)}
                    helperText={form.touched.location && form.errors.location}
                    disabled={!Boolean(regions.length)}
                    InputProps={{
                      endAdornment: regionsLoading ? (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          className={classes.selectLoader}
                        />
                      ) : null,
                    }}
                  >
                    {regions.map((region) => (
                      <MenuItem id={region.name} key={`location-${region.id}`} value={region.id}>
                        {region.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item className={clsx(classes.formControlWrap, classes.facilityFormControl)}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <TextField
                    variant="outlined"
                    select
                    name="facility"
                    id="facility"
                    label="Medical facility"
                    value={form.values.facility || ""}
                    onChange={form.handleChange}
                    error={form.touched.facility && Boolean(form.errors.facility)}
                    helperText={form.touched.facility && form.errors.facility}
                    disabled={!form.values.location}
                  >
                    {(facilities || [])
                      .filter((f) => !!f.entity?.active)
                      .map((facility) => (
                        <MenuItem
                          id={facility.entity.name.split(" ").join("-")}
                          key={`facility-${facility.entity_id}`}
                          value={facility.entity_id}
                        >
                          {facility.entity.name}
                        </MenuItem>
                      ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {isFacilitySelected && (
            <>
              {selectedFacility?.entity?.note && (
                <Alert severity="info" style={{ marginBottom: 20 }}>
                  {selectedFacility?.entity?.note}
                </Alert>
              )}
              <Box className={classes.title}>
                <Typography variant="h4" align="center">
                  Select a date
                </Typography>
                <br></br>
                <AppointmentTimeSlots form={form} facility={selectedFacility} />
              </Box>
            </>
          )}

          <Grid
            container
            justifyContent="space-between"
            className={classes.formActions}
            spacing={4}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                type="button"
                disabled={activeStep === 0}
                onClick={handleBack}
              >
                Back
              </Button>
            </Grid>
            <Grid item>
              <Button id="finish" variant="contained" color="primary" size="large" type="submit">
                {activeStep === CREATE_APPOINTMENT_STEPS.length - 1 ? "Finish" : "Next"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default React.memo(AppointmentTime);
