import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import ApptRateCodeDetailRow from "./ApptRateCodeDetailRow";

const useStyles = makeStyles((theme) => ({
  header: {
    padding: 8,
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: "bold",
    letterSpacing: 0.12,
  },
}));

const ApptRateCodeDetails = ({ row }) => {
  const classes = useStyles();
  return (
    <Box p={2}>
      <Typography variant="h5" className={classes.header}>
        Services
      </Typography>

      {row.appointment_rate_codes?.map((apptRc) => (
        <ApptRateCodeDetailRow
          row={apptRc}
          patient={row.patient}
          medicalFacId={row.medical_facility_id}
        />
      ))}
    </Box>
  );
};

export default ApptRateCodeDetails;
